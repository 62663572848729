import React, { useState } from "react";
import styled from "styled-components";
import HeaderFilters from "../../Components/HeaderFilters";
import {
  ApplyLeaveForm,
  DeleteConfirm,
} from "../../Components/AuditorSnippets";
import { BackBlack } from "../../assets/ImageUrl";
import ProfileCardAudit from "../../Components/ProfileCardAudit";
import ActivityList from "../../Components/ActivityList";
import data from "../../Data/auditor.json";
import { EditGrad, DeleteGrad } from "../../assets/ImageUrl";
import Popup from "../../Components/Popup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  actionReqGetDeleteAuditor,
  actionReqGetUpdateAuditor,
} from "../../Store/SagasActions/AuditorSagaAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

export default function ApplyAuditLeave() {
  const location = useLocation();
  const auditorList = location.state.name;
  const [act, setAct] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);
  const [selectedAuditImg, setSelectedAuditImg] = useState("");

  const cardData = data.auditors[0].additionalInfo.card;
  const activityData = data.auditors[0].additionalInfo.dailyActivity;
  const navigate = useNavigate();
  const HeadWithBackButton = () => (
    <>
      <img
        src={BackBlack}
        style={{ marginRight: "14px", cursor: "pointer" }}
        alt="back to auditor page"
        onClick={() => navigate("/dashboard/auditor")}
      />{" "}
      Auditor view
      {/* Auditor list view */}
    </>
  );
  const headerLeaveActions = [
    {
      img: EditGrad,
      key:8,
      func: (e) => editAuditorUser(),
    },
    {
      img: DeleteGrad,
      key:9,
      func: () => setAct("delete"),
    },
  ];

  const editAuditorUser = () => {
    setIsEditable((prev) => !prev);
  };

  const deleteComp = {
    disc: "Are you sure you want to delete the Auditor?",
    cancel: () => setAct(""),
    delete: (remarks) => deleteAuditorUser(remarks),
  };

  const deleteAuditorUser = (remarks) => {
    if (remarks) {
      const model = {
        auditor_userid: auditorList.auditor_userid,
        remarks: remarks,
      };
      dispatch(
        actionReqGetDeleteAuditor({ model: model, callback: getDeleteRes })
      );
    } else {
      toast.error("Please enter remarks");
    }
  };

  const getDeleteRes = (data) => {
    if (data && data?.respcode === "200") {
      navigate("/dashboard/auditor");
    }
  };

  const updateAuditorDetails = (data) => {
    setIsLoading(true);
    setIsDisabled(true);
    // const model = {
    //   userid: data
    // }

    let model;
    if (selectedAuditImg) {
      model = { ...data, auditor_pht: selectedAuditImg };
    } else {
      model = data;
    }
    dispatch(
      actionReqGetUpdateAuditor({
        model: model,
        callback: getUpdatedAuditorRes,
      })
    );
  };
  const updatedAuditorProfilePic = (val) => {
    setSelectedAuditImg(val);
  };

  const getUpdatedAuditorRes = (data) => {
    if (data && data?.respcode === "200") {
      navigate("/dashboard/auditor");
    } else {
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <>
      <ApplyLeaveStyle>
        <HeaderFilters
          heading={{ name: <HeadWithBackButton /> }}
          actionBtns={headerLeaveActions}
        />
        <div className="secContainer">
          <div className="userCard">
            <ProfileCardAudit
              data={auditorList}
              updatedAuditorProfilePic={updatedAuditorProfilePic}
              isEditable={isEditable}
              form={data?.applyLeaveForm}
            />
          </div>
          <div className="formContainer">
            <ApplyLeaveForm
              form={data.applyLeaveForm}
              isEditable={isEditable}
              updateAuditorDetails={updateAuditorDetails}
              isLoading={isLoading}
              disabled={isdisabled}
            />
          </div>
          <div className="activities">
            <ActivityList
              data={activityData}
              userid={auditorList?.auditor_userid}
            />
          </div>
        </div>
      </ApplyLeaveStyle>

      {act === "delete" && (
        <Popup
          heading="Delete Auditor"
          body={<DeleteConfirm comp={deleteComp} />}
          close={() => setAct("")}
          className="px500"
        />
      )}
    </>
  );
}

const ApplyLeaveStyle = styled.div`
  box-shadow: var(--cardShadow);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  background: var(--white);
  .secContainer {
    display: flex;
    gap: 16px;
    .userCard {
      flex: 1;
    }
    .formContainer {
      flex: 2;
    }
    .activities {
      flex: 1;
    }
  }
`;
