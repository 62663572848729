import {
    ACTION_GET_STATUSBAR_UPDATE_STATUS,
    ACTION_STATUSBAR_UPDATE,
    ACTION_END_VIDEO_CALL_BY_AGENT_REQ,
    ACTION_POST_USER_PROFILE_DATA
} from "./actionTypes"
import {
    ACTION_GET_LOCATION_DETAILS_REQ, ACTION_GET_NOTIFICATIONS_LIST_REQ,
    ACTION_GET_NOTIFICATIONS_PUSH_REQ, APISTATUS, ACTION_GET_DASHBOARD_VCIPID_COUNT_REQ
} from "./SagaActionTypes"

export const actionReqResStatusLoaderSagaAction = (payload) => {
    return {
        type: APISTATUS,
        payload: payload
    }
}

