import React, { useState } from 'react'
import { useNavigate } from "react-router";
import styled from 'styled-components';
import AsideBar from '../Components/AsideBar';
import NavBar from '../Components/NavBar';
import { Outlet } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function Dashboard() {
    // const navigate = useNavigate();
    // const authkey = sessionStorage.getItem("authkey");
    // if (!authkey) {
    //     navigate("/")
    // }

    // const dispatch = useDispatch()


    return (
        <>
            <DashboardStyle>
                <div className="asideBar">
                    <AsideBar />
                </div>
                <div className="mainSec">
                    <NavBar />
                    <main>
                        <Outlet />
                    </main>
                </div>
            </DashboardStyle>
        </>
    )
}

const DashboardStyle = styled.div`
background: var(--lightBackground);
    display: flex;
    .mainSec {
        flex: 1;
        display: flex;
        flex-direction: column;
        main {
            padding: 20px;
            height: calc(100vh - 80px);
            overflow: auto;
        }
    }
`







