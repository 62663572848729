import {
  ACTION_GET_ADMIN_DASHBOARD_COUNT_REQ,
  APISTATUS,
  ACTION_POST_ADMIN_ACCOUNTS,
  ACTION_POST_ADMIN_DASHBOARD_CREATE_AGENT,
  ACTION_POST_ADMIN_DASHBOARD_DELETE_AGENT,
  ACTION_GET_CALL_HISTORY_REQ,
  ACTION_GET_REPORT_MASTER_REQ,
  ACTION_POST_ADMIN_DASHBOARD_ACCOUNT_CONFIGURE_LIST,
  ACTION_GET_CUSTOMER_QUEQE_REQ,
  ACTION_GET_ACCOUNT_REDUCER_REQ,
  ACTION_GET_CUSTOMER_QUEQE_REDUCER_REQ,
  ACTION_POST_GET_REDUCER_LANGUAGES,
  SAMPLE,
  ACTION_SETUP_GLOBAL_ACCOUNT,
  ACTION_GET_SETUP_GLOBAL_ACCOUNT,
  ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ,
  ACTION_GET_SINGLE_REFERENCE_CALL_HISTORY_REQ,
  CLIENT_ACCOUNT_ID,
  SERVICE_WISE_TXN_COUNT,
  GET_TOKEN_NUMBER_DASHBOARD,
} from "./SagaActionTypes";

export const actionReqGetAdminDashboardCountSagaAction = (payload) => {
  return {
    type: ACTION_GET_ADMIN_DASHBOARD_COUNT_REQ,
    payload: payload,
  };
};

export const actionReqResStatusLoaderSagaAction = (payload) => {
  return {
    type: APISTATUS,
    payload: payload,
  };
};

export const actionReqAdminAccounts = (payload) => {
  return {
    type: ACTION_POST_ADMIN_ACCOUNTS,
    payload: payload,
  };
};
export const actionaccid = (payload) => {
  return {
    type: SAMPLE,
    payload: payload,
  };
};

//Create Agent

export const actionReqGetCreateAgent = (payload) => {
  return {
    type: ACTION_POST_ADMIN_DASHBOARD_CREATE_AGENT,
    payload: payload,
  };
};

export const actionReqGetDeleteAgent = (payload) => {
  return {
    type: ACTION_POST_ADMIN_DASHBOARD_DELETE_AGENT,
    payload: payload,
  };
};

export const getCallHistorySagaAction = (payload) => {
  return {
    type: ACTION_GET_CALL_HISTORY_REQ,
    payload: payload,
  };
};

export const getSingleReferenceCallHistorySagaAction = (payload) => {
  return {
    type: ACTION_GET_SINGLE_REFERENCE_CALL_HISTORY_REQ,
    payload: payload,
  };
};

export const getCustomerQueueSagaAction = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_QUEQE_REQ,
    payload: payload,
  };
};

export const getReportMasterSagaAction = (payload) => {
  return {
    type: ACTION_GET_REPORT_MASTER_REQ,
    payload: payload,
  };
};

export const getAccountListreducerAction = (payload) => {
  return {
    type: ACTION_GET_ACCOUNT_REDUCER_REQ,
    payload: payload,
  };
};

export const getCustomerQueueReducerSagaAction = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_QUEQE_REDUCER_REQ,
    payload: payload,
  };
};

export const getLanguagesReducerAction = (payload) => {
  return {
    type: ACTION_POST_GET_REDUCER_LANGUAGES,
    payload: payload,
  };
};

export const globalAccountSetup = (payload) => {
  return {
    type: ACTION_SETUP_GLOBAL_ACCOUNT,
    payload: payload,
  };
};

export const actionGetVcipDetails = (payload) => ({
  type: ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ,
  payload,
});

// export const actionAccountConfigureSagaAction = (payload) => {
//     return {
//         type: ACTION_POST_ADMIN_DASHBOARD_ACCOUNT_CONFIGURE_LIST,
//         payload: payload
//     }
// }
// export const actionConfigureAccountSagaAction = (payload) => {
//     return {
//         type: ACTION_POST_ADMIN_DASHBOARD_CONFIGURE_ACCOUNT,
//         payload: payload
//     }
// }

export const setClientAccountId = (payload) => {
  return {
    type: CLIENT_ACCOUNT_ID,
    payload,
  };
};

// Service Txn count

export const getServiceTxnCount = (payload) => {
  return {
    type: SERVICE_WISE_TXN_COUNT,
    payload,
  };
};

// Get Token DashBoard Number

export const getTokenNumberDashboardData = (payload) => {
  return {
    type: GET_TOKEN_NUMBER_DASHBOARD,
    payload: payload,
  };
};
