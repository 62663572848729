import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DoughnutChart from "../../Components/DoughnutChart";
import Popup from "../../Components/Popup";
import dashboardData from "../../Data/dashboard.json";
import uuid from "react-uuid";
import CardNode from "../../Components/CardNode";
import ChartLabels from "../../Components/ChartLabels";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionReqGetAdminDashboardCountSagaAction,
  actionReqAdminAccounts,
} from "../../Store/SagasActions/DashboardOverviewSagaAction";
import { actionReqGetAgentListSagaAction } from "../../Store/SagasActions/AgentSagaAction";
import { useSelector } from "react-redux";
import AgentActiveStatuses from "../../Constants/Status";
import { actionReqGetAuditorListSagaAction } from "../../Store/SagasActions/AuditorSagaAction";
import LoaderClr from "../../Components/LoaderClr";
import moment from "moment";

export default function DashboardIndex() {
  // const [dashboardData, setDashboardData] = useState([]);
  // const [filteredAgentList, setFilteredAgentList] = useState([]);
  // const [filteredAuditorList, setFilteredAuditorList] = useState([]);

  const activityDropdownList = ["Today", "Overall"];
  const [dashboardCount, setDashboardCount] = useState();
  const [agentList, setAgentList] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [chartData, setChartData] = useState({});
  const [ledgersAuditor, setLedgersAuditor] = useState([]);
  const [chartDataAuditor, setChartDataAuditor] = useState({});
  const [auditorList, setAuditorList] = useState([]);
  const [listPop, setListPop] = useState({});
  const [selectedUser, setSelectedUser] = useState("");
  const [selectlisttype, setSelectlisttype] = useState("");
  const [agentSelectedListType, setAgentSelectedListType] = useState("Overall-1");
  const [auditorSelectedListType, setAuditorSelectedListType] = useState("Overall-1");

  // 
  const dispatch = useDispatch();

  // const save = localStorage.setItem("accounts")
  // const globalAccountId = useSelector((state) => state?.GlobalRecuder.globalAccountId);

  // Reducer data
  const accid = useSelector(
    (state) => state.DashboardReducer?.accountaccid?.accounts
  );
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const Account = sessionStorage.getItem("role")
  let accidResp = accid?.length > 0 ? accid[0].accid : sessionStorage.getItem("accid");


  useEffect(() => {
    if (account) {
      getDashaboardCountDataByAccnt(account);
      setAgentSelectedListType("Overall-1")
      setAuditorSelectedListType("Overall-1")
    }
  }, [account])


  useEffect(() => {
    if (Account === "Account Admin") {
      getDashaboardCountDataByAccnt(accidResp)
    }
    //  else {
    //   getDashaboardCountDataByAccnt(account)
    // }
  }, []);

  const getDashaboardCountDataByAccnt = (accid) => {
    const payload = {
      endpoint: "GetAdminDashboardCount",
      model: {
        accid: accid,
        reqtype: "1",
      }
    }
    dispatch(
      actionReqGetAdminDashboardCountSagaAction({
        model: payload,
        callback: respUserDashboardCount,
      })
    );
  }

  const getAgentList = (id) => {
    const model = {
      accid: account,
      listtype: String(id),
    };
    dispatch(
      actionReqGetAgentListSagaAction({
        model: model,
        callback: getAgentListResp,
      })
    );
  };

  const getAgentListResp = (data) => {
    if (data) {
      let agentActiveList = data?.agentlist?.filter(
        (item) => item?.status === AgentActiveStatuses.ACTIVE
      );
      setAgentList(agentActiveList);
    }
  };

  const getAuditorUserList = (id) => {
    const model = {
      accid: account,
      listtype: String(id),
    };
    dispatch(
      actionReqGetAuditorListSagaAction({
        model: model,
        callback: getAuditorListResp,
      })
    );
  };

  const getAuditorListResp = (respData) => {
    if (respData) {
      let auditorActiveList = respData?.auditorlist?.filter(
        (item) => item?.status === AgentActiveStatuses.ACTIVE
      );
      setAuditorList(auditorActiveList);
    }
  };

  const respUserDashboardCount = (data, selectedDashaboard) => {
    setDashboardCount(data);
    const totalagent =
      parseInt(data?.agentacceptedcount) +
      parseInt(data?.agentissuedcount) +
      parseInt(data?.agentrejectedcount);
    const chart = {
      labels: ["Rejected", "Discrepancy", "Approved"],
      labels: {
        display: false,
      },
      chartFor: "Total Calls",
      count: totalagent,
      datasets: [
        {
          data: [
            data?.agentrejectedcount,
            data?.agentissuedcount,
            data?.agentacceptedcount,
          ],
          backgroundColor: [
            "rgba(242, 71, 71, 1)",
            "rgba(249, 199, 23, 1)",
            "rgba(77, 174, 104, 1)",
          ],
        },
      ],
    };
    const ledgersResp = [
      {
        name: "Approved",
        no: data?.agentacceptedcount,
        color: "#4DAE68",
      },
      {
        name: "Rejected",
        no: data?.agentrejectedcount,
        color: "#F24747",
      },
      {
        name: "Discrepancy",
        no: data?.agentissuedcount,
        color: "#FF912B",
      },
    ];
    const totalauditor =
      parseInt(data?.auditoracceptedcount) +
      parseInt(data?.auditorrejectedcount) +
      parseInt(data?.auditorissuedcount);
    const chartAuditor = {
      labels: ["Rejected", "Discrepancy", "Approved"],
      labels: {
        display: false,
      },
      chartFor: "Total Calls",
      count: totalauditor,
      datasets: [
        {
          data: [
            data?.auditorrejectedcount,
            data?.auditorissuedcount,
            data?.auditoracceptedcount,
          ],
          backgroundColor: [
            "rgba(242, 71, 71, 1)",
            "rgba(249, 199, 23, 1)",
            "rgba(77, 174, 104, 1)",
          ],
        },
      ],
    };
    const ledgersRespAuditor = [
      {
        name: "Approved",
        no: data?.auditoracceptedcount,
        color: "#4DAE68",
      },
      {
        name: "Rejected",
        no: data?.auditorrejectedcount,
        color: "#F24747",
      },
      // {
      //   name: "Discrepancy",
      //   no: data?.auditorissuedcount,
      //   color: "#FF912B",
      // },
    ];
    if (selectedDashaboard === undefined || selectedDashaboard === null) {
      setLedgers(ledgersResp);
      setChartData(chart);
      setLedgersAuditor(ledgersRespAuditor);
      setChartDataAuditor(chartAuditor);
    } else if (selectedDashaboard === 0) {
      setLedgers(ledgersResp);
      setChartData(chart);
    } else if (selectedDashaboard === 1) {
      setLedgersAuditor(ledgersRespAuditor);
      setChartDataAuditor(chartAuditor);
    }
  };

  const changeActivity = (event, selectedDashaboard) => {
    const { value } = event.target;
    if (value) {
      const selectedData = value.split("-")
      if (selectedDashaboard === 0) {
        setAgentSelectedListType(value)
      } else {
        setAuditorSelectedListType(value)
      }
      const model = {
        accid: account,
        reqtype: selectedData[1],
      };
      dispatch(
        actionReqGetAdminDashboardCountSagaAction({
          model: model,
          callback: (data) => respUserDashboardCount(data, selectedDashaboard),
        })
      );
    } else {
      setAuditorSelectedListType("")
    }
  };

  // const insertingListPop = obj => setListPop({ show: true, ...obj })

  const dispPopup = (selectedVal, agent) => {
    // selectedVal : 0 - AGENT, 1 - AUDITOR
    setSelectedUser(selectedVal);
    setSelectlisttype(agent);
    if (selectedVal === 4) {
      getAgentList(agent);
    }
    if (selectedVal === 5) {
      getAuditorUserList(agent);
    }
    setListPop({ show: true });
    // insertingListPop(popContent);
  };
  const UserList = ({ users }) => {
    return (
      <>
        <div className="main-loader">
          <LoaderClr />
        </div>
        <ListPopupStyle className="peopleList">
          {selectedUser === 4
            ? agentList?.map((agent) => (
              <div className="personContainer" key={uuid()}>
                <CardNode
                  img={
                    agent?.agent_pht.length >= 10
                      ? agent?.agent_pht
                      : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
                  }
                  name={agent.agent_name ? agent.agent_name : "N/A"}
                  id={agent.agent_employeeid ? agent.agent_employeeid : "N/A"}
                  flogin={agent.firstlogin ? moment(agent.firstlogin).format("lll") : "-"}
                  lastlogout={agent.lastlogout ? moment(agent.lastlogout).format("lll") : "-"}
                />
              </div>
            ))
            : auditorList?.map((auditor) => (
              <div className="personContainer" key={uuid()}>
                <CardNode
                  img={
                    auditor?.auditor_pht.length >= 10
                      ? auditor?.auditor_pht
                      : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
                  }
                  name={auditor.auditor_name ? auditor.auditor_name : "N/A"}
                  id={auditor.auditor_employeeid ? auditor.auditor_employeeid : "N/A"}
                  flogin={auditor.firstlogin ? moment(auditor.firstlogin).format("lll") : "-"}
                  lastlogout={auditor.lastlogout ? moment(auditor.lastlogout).format("lll") : "-"}
                />
              </div>
            ))}
        </ListPopupStyle>
      </>
    );
  };

  return (
    <>
      <DashboardIndexStyle>
        {/* <DoughnutChart /> */}
        <div className="overviewList">
          {/* AGENT DASHBOARD */}
          <div className="overviewCard">
            <h4 className="fd3">Agent Overview</h4>
            <div className="actionContainer">
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.totalagents > 0 ? dispPopup(4, 0) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.totalagents}</h3>
                </div>
                <h5 className="fg1">Total</h5>
              </div>
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.offlineagents > 0 ? dispPopup(4, 2) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.offlineagents}</h3>
                </div>
                <h5 className="fg1">Present</h5>
              </div>
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.onlineagents > 0 ? dispPopup(4, 1) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.onlineagents}</h3>
                </div>
                <h5 className="fg1">Online</h5>
              </div>
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.busyagents > 0 ? dispPopup(4, 3) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.busyagents}</h3>
                </div>
                <h5 className="fg1">Busy</h5>
              </div>
            </div>
            <div className="doughnutContainer">
              <div className="header">
                <h6 className="fd8">Agent Summary</h6>
                <div className="selectDay">
                  <label>Show: </label>
                  <select
                    className="fg2"
                    onChange={(e) => changeActivity(e, 0)}
                    value={agentSelectedListType}
                  >
                    {/* <option defaultValue={""}>Select Activity</option> */}
                    {activityDropdownList?.map((result, index) => (
                      <>
                        <option
                          key={index}
                          value={result + "-" + index}
                        // defaultValue={index - 1}
                        >
                          {result}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
              <div className="summaryContainer">
                <div className="chartList">
                  <DoughnutChart
                    customData={chartData}
                    count={chartData.count}
                    disc={chartData.chartFor}
                    height={160}
                    width={160}
                  />
                </div>
                <ChartLabels labels={ledgers} />
              </div>
            </div>
          </div>
          {/* AUDITOR DASHBOARD */}
          <div className="overviewCard">
            <h4 className="fd3">Auditor Overview</h4>
            <div className="actionContainer">
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.totalauditors > 0 ? dispPopup(5, 0) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.totalauditors}</h3>
                </div>
                <h5 className="fg1">Total</h5>
              </div>
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.offlineauditors > 0 ? dispPopup(5, 2) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.offlineauditors}</h3>
                </div>
                <h5 className="fg1">Present</h5>
              </div>
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.onlineauditors > 0 ? dispPopup(5, 1) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.onlineauditors}</h3>
                </div>
                <h5 className="fg1">Online</h5>
              </div>
              <div
                className="actionCard"
                onClick={() =>
                  dashboardCount?.busyauditors > 0 ? dispPopup(5, 3) : ""
                }
              >
                <div className="circle">
                  <h3 className="fd4">{dashboardCount?.busyauditors}</h3>
                </div>
                <h5 className="fg1">Busy</h5>
              </div>
            </div>
            <div className="doughnutContainer">
              <div className="header">
                <h6 className="fd8">Auditor Summary</h6>
                <div className="selectDay">
                  <label>Show: </label>
                  <select
                    className="fg2"
                    onChange={(e) => changeActivity(e, 1)}
                    value={auditorSelectedListType}
                  >
                    {/* <option value={""}>Select Activity</option> */}
                    {activityDropdownList?.map((result, index) => (
                      <>
                        <option
                          key={index}
                          value={result + "-" + index}
                        // defaultValue={index - 1}
                        >
                          {result}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
              <div className="summaryContainer">
                <div className="chartList">
                  <DoughnutChart
                    customData={chartDataAuditor}
                    count={chartDataAuditor.count}
                    disc={chartDataAuditor.chartFor}
                    height={160}
                    width={160}
                  />
                </div>
                <ChartLabels labels={ledgersAuditor} />
              </div>
            </div>
          </div>
          {/* {dashboardData?.map(node => <div key={uuid()} className="overviewCard">
          <h4 className="fd3">{node.title}</h4>
          <div className="actionContainer" onChange={changeActivity}>
            {node.activities.map(activity => <div key={uuid()} className="actionCard" onClick={() => dispPopup(activity.popup)}>
              <div className="circle">
                <h3 className="fd4">{activity.count}</h3>
              </div>
              <h5 className="fg1">{activity.name}</h5>
            </div>)}
          </div>
          <div className="doughnutContainer">
            <div className="header">
              <h6 className="fd8">{node.summary.name}</h6>
              <div className="selectDay">
                <label>show: </label>
                <select className="fg2" onChange={changeActivity}>
                  <option defaultValue={""}>Select Activity</option>
                  {node.summary.resultsFor.map((result, index) => <>
                    <option key={uuid()} defaultValue={index} value={index - 1}>{result}</option>
                  </>)}
                </select>
              </div>
            </div>
            <div className="summaryContainer">
              <div className="chartList">
                {node.summary.chart.map(chart => <DoughnutChart key={uuid()} customData={chart} count={chart.count} disc={chart.chartFor} height={160} width={160} />)}
              </div>
              <ChartLabels labels={node.summary.ledgers} />
            </div>
          </div>
        </div>)} */}
        </div>
      </DashboardIndexStyle>

      {listPop.show && (
        <Popup
          heading={listPop.heading}
          body={<UserList users={listPop.userList} />}
          close={() => setListPop({})}
          className="px588"
        />
      )}
    </>
  );
}

const DashboardIndexStyle = styled.div`
  .overviewList {
    display: flex;
    gap: 16px;
    .overviewCard {
      flex: 1;
      background: var(--white);
      border: var(--cardBorder);
      box-shadow: var(--cardShadow);
      border-radius: 6px;
      padding: 20px 24px;
      .fd3 {
        font-weight: 700;
        font-size: 20px;
        color: var(--dark2);
        padding-bottom: 12px;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 16px;
      }
      .actionContainer {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        .actionCard {
          border: var(--border);
          padding: 18px;
          flex: 1;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 8px;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            transform: scale(1.03);
            box-shadow: var(--selectShadow);
          }
          .circle {
            height: 54px;
            width: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--secondLight);
            border-radius: 50%;
            .fd4 {
              background: var(--fg3);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
          .fg1 {
          }
        }
      }
      .doughnutContainer {
        border: var(--border);
        margin-top: 16px;
        border-radius: 5px;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 16px;
          border-bottom: 1px solid var(--border-color);
          padding-bottom: 12px;
          .fd8 {
          }
          .selectDay {
            label {
              font-weight: 600;
              color: var(--dark5);
            }
            select {
              border: none;
              &.fg2 {
              }
            }
          }
        }
        .chartList {
          display: flex;
          justify-content: center;
          margin-bottom: 34px;
        }
      }
    }
  }
  .ledgers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 48px;
    margin-bottom: 28px;
    padding: 0 50px;
    .ledger {
      display: flex;
      justify-content: center;
      align-items: center;
      .color {
        height: 8px;
        width: 8px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 8px;
      }
      .ledgerName {
      }
      .ledgerNo {
        font-weight: 700;
      }
    }
  }
`;

const ListPopupStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  row-gap: 10px;
  .personContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    width: calc(50% - 4px);
    margin: 10px 0;
  }
`;
