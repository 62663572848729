const rollBackOption = {
    KYC_ONLY: ["KYC Only", 1],
    PAN_ONLY: ["PAN Only", 2],
    VIDEO_CALL_ONLY: ["VideoCall Only", 3],
    PAN_VIDEOCALL: ["PAN & VideoCall", 4],
    KYC_PAN_VIDEOCALL: ["KYC, PAN & VideoCall", 5],
    AGEN_STATUS: ["Agent Status", 6],
    AUDITOR_STATUS: ["Auditor Status", 7]

}

const RollBackOptions = Object.freeze(rollBackOption);

export default RollBackOptions;


