import * as SagaActionTypes from "../SagasActions/SagaActionTypes";
import * as actionTypes from "../SagasActions/actionTypes";

// const localstorageacc = localStorage.getItem("accid")
const localstorageacc = sessionStorage.getItem("accid");
const initial = {
  UserDashBoardCount: undefined,
  createagent: {},
  agentList: {},
  accountaccid: {},
  livecount: {},
  schedulecount: {},
  waitingcount: {},
  liveList: {},
  waitingList: {},
  getlanguage: [],
  accid: localstorageacc,
  isClrLoader: false,
};

const DashboardReducer = (state = initial, action) => {
  switch (action.type) {
    case SagaActionTypes.ACTION_GET_ADMIN_DASHBOARD_COUNT_REQ:
      return { ...state, UserDashBoardCount: action.payload };

    case SagaActionTypes.SAMPLE:
      return { ...state, accid: action.payload };

    case SagaActionTypes.ACTION_POST_ADMIN_DASHBOARD_CREATE_AGENT:
      return { ...state, createagent: action.payload };

    case SagaActionTypes.ACTION_GET_AGENT_LIST_REQ:
      return { ...state, agentList: action.payload };

    case SagaActionTypes.ACTION_GET_ACCOUNT_REDUCER_REQ:
      return { ...state, accountaccid: action.payload };

    case SagaActionTypes.ACTION_GET_CUSTOMER_QUEQE_REDUCER_REQ:
      return action.payload?.model?.listtype === 1
        ? { ...state, waitingList: action.payload }
        : { ...state, liveList: action.payload };

    case SagaActionTypes.ACTION_POST_GET_REDUCER_LANGUAGES:
      return { ...state, getlanguage: action.payload };

    case actionTypes.ACTION_POST_ADD_LOADERCLR_REQ:
      return { ...state, isClrLoader: action.payload };

    default:
      return state;
  }
};

export default DashboardReducer;
