import React, { useEffect } from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AllCallHistory from '../../Components/AllCallHistory';
import LoaderClr from '../../Components/LoaderClr';
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { actionGetVcipDetails, getCallHistorySagaAction, getSingleReferenceCallHistorySagaAction } from '../../Store/SagasActions/DashboardOverviewSagaAction';
import { vcipRollBackSagaAction } from '../../Store/SagasActions/OperationsActions/OperationsSagaAction';
import VcipIdStatusOptions from '../../Constants/VcipIdStatusOptions';
import HeaderFilters from '../../Components/HeaderFilters';
import { CallHistoryTabs } from '../../Components/CustomersSnippets';
import RollBackOptions from '../../Constants/RollBackOptions';
import WaitingCustomerQueue from '../../Components/WaitingCustomerQueue';
import styled from 'styled-components';
import { CalanderGrad, SearchGrad } from '../../assets/ImageUrl';


const VcipRollBack = () => {

  const location = useLocation();
  const [pop, setPop] = useState("");
  const [callHistoryTab, setCallHistoryTab] = useState("");
  const [refCallHistory, setRefCallHistory] = useState([]);
  const [refValues, setRefValues] = useState([]);
  const [refSearch, setRefSearch] = useState("");
  const [rollBackRemarks, setRollBackRemarks] = useState("");
  const [rollBackStage, setRollBackStage] = useState("");
  const [showCurrentVcipStatus, setShowCurrentVcipStatus] = useState("");

  const dispatch = useDispatch();

  const roleID = sessionStorage.getItem("role");

  const account = useSelector((state) => state?.DashboardReducer.accid);

  const AccountSwap =
    roleID === "Account Admin" || roleID === "Operations" ? sessionStorage.getItem("accid") : account;

  const [viewdet, setViewDet] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [inputError, setInputError] = useState();

  // clearing Search & Dropdown on single VCIP Search
  useEffect(() => {
    setRefValues("");
    setRefSearch("");
    setRefCallHistory("")

  }, [location]);

  const getCallHistoryList = () => {
    const model = {
      accid: AccountSwap,
      listtype: "1",
      fdate: "",
      tdate: "",
    };
    dispatch(getCallHistorySagaAction({ model: model, callback: getLiveData }));
  };

  const getLiveData = (data) => { };

  // pop data calling GetVCIPIDDetails
  const getVcipUserDetails = (type, data) => {
    if (data.vcipid == viewdet.vcipid && type !== "viewReport") {
      setPop(type);
    } else {
      const model = {
        vcipkey: data?.vcipkey,
        type: type,
        vtid: data.vtid,
      };
      dispatch(
        actionGetVcipDetails({ model: model, callback: getEachVcipDetails })
      );
      setPop(type);
    }
  };

  const getEachVcipDetails = (data, typeid) => {
    setViewDet(data);
    setPop(typeid);
  };


  const acccode = sessionStorage.getItem("acccode");


  const headerActions = [
    {
      img: SearchGrad,
      key: "4",
      func: (e) => e.persist(),
    },
    {
      img: CalanderGrad,
      key: "cal",
      func: () => setShowDate(true),
    },
  ];

  const searchCustomerDetails = (val) => {
    setSearchedText(val);
  };

  // Dropdown Handler
  const handleDropDownChange = (e) => {
    const { value } = e.target;
    setRefValues(value.split(","));
    setRefSearch("");
    setInputError("");
  };

  // Search Handler
  const searchInputHandler = (e) => {

    if (refValues[0] == "vcipid" && e.target.value.length <= 10) {
      setRefSearch(e.target.value);
      setInputError("");
    } else if (refValues[0] == "mobile" && e.target.value.length <= 10) {
      setRefSearch(e.target.value);
      setInputError("");
    } else if (refValues[0] == "customerid" && e.target.value.length < 100) {
      setRefSearch(e.target.value);
      setInputError("");
    } else {
      return;
    }
  };

  // Reference Call History API Hit
  const handleSubmitSingleVcip = () => {
    setCallHistoryTab("all")
    let payload = {
      endpoint: "GetReferenceCallHistory",
      model: {
        refid: refSearch,
        reftype: refValues[0],
        accid: AccountSwap,
      }
    };

    if (location.pathname === opsRollBackPath) {
      setCallHistoryTab("")
      payload = {
        endpoint: "GetCustomerVCIPStatus",
        model: {
          refid: refSearch, //1
          reftype: refValues[1], // 22181
          acccode: AccountSwap,
        }
      }
    }

    if (payload.model.reftype == "mobile" && payload.model.refid.length < 10) {
      setInputError("Enter Valid Mobile Number");
    } else if (!refSearch) {
      setInputError("Please fill all the fields.");
    } else {
      dispatch(
        getSingleReferenceCallHistorySagaAction({
          model: payload,
          callback: getSingleRefCallData,
        })
      );
    }
  };

  // handle role back
  const handleRollBack = (event) => {
    let model = {
      acccode: acccode, // accode
      type: refValues[1] == 1 ? "3" : refValues[1], // vcipid vciplink
      id: refSearch,
      stage: rollBackStage, //1-KYC Only, 2-PAN Only, 3-VideoCall only, 4-PAN & VideoCall, 5-KYC,PAN & VideoCall, 6.Agent Status, 7.Auditor Status
      remark: rollBackRemarks
    }
    dispatch(
      vcipRollBackSagaAction({
        model: model,
        callback: rollBackCallBack,
      })
    );
  }

  const handleDiscardBtn = () => {
    setRefCallHistory("")
    setInputError("");
    setShowCurrentVcipStatus("");
    setRollBackRemarks("")

  }

  const rollBackCallBack = (data) => {
    if (data?.respcode == "200") {
      toast.success(data?.respdesc)
      handleSubmitSingleVcip(true);
    } else {
      toast.error(data?.respdesc)
    }
    setRollBackRemarks("")
  }

  const getModifiedStatus = (num) => {
    if (num == 1) {
      return "Completed"
    } else {
      return "Pending"
    }
  };

  const getSingleRefCallData = ([data, endpoint]) => {
    if (data?.respcode === "200" && endpoint === "GetReferenceCallHistory") {
      setRefCallHistory(data?.vciplist);
      toast.success(data?.respdesc);
    } else if (data?.respcode === "200" && endpoint === "GetCustomerVCIPStatus") {
      let obj = [
        {
          vcipid: data?.data[0]?.vcipid,
          kycstatus: getModifiedStatus(data?.data[0].kycstatus),
          panstatus: getModifiedStatus(data?.data[0].panstatus),
          agentstatus: getModifiedStatus(data?.data[0].agentstatus),
          agentremarks: data?.data[0].agentremarks || "N/A",
          auditorstatus: getModifiedStatus(data?.data[0].auditorstatus),
          auditorremarks: data?.data[0].auditorremarks || "N/A",
          // vcipidstatus: VcipIdStatusOptions[data?.data[0].vcipidstatus]
        }
      ]
      // const keysToFilter = ["agentstatus", "auditorstatus", "panstatus"];
      // const filteredPairs = Object.entries(data?.data[0])
      //   .filter(([key, value]) => keysToFilter.includes(key)
      //     .map(([key, value]) => ({ [key]: value })))
      setRefCallHistory(obj)
      // toast.success(data?.respdesc);
      setShowCurrentVcipStatus(VcipIdStatusOptions[data?.data[0].vcipidstatus])
    } else {
      toast.error(data?.respdesc);
      setRefCallHistory([]);
    }
  };

  // Object for Payload
  const rollBackDropdown = [
    {
      id: "1",
      value: "vcipid",
      label: "Vcip ID",
    },
    {
      id: "4",
      value: "vcipLink",
      label: "Vcip Link",
    },
  ];

  const dropoDown_client = [
    {
      id: "1",
      value: "vcipid",
      label: "VCIP ID",
    },
    {
      id: "2",
      value: "mobile",
      label: "Mobile Number",
    },
    {
      id: "3",
      value: "customerid",
      label: "Customer ID",
    },
  ];

  let opsRollBackPath = "/dashboard/vcip-roll-back"

  let dropDown_options = location.pathname === opsRollBackPath ? rollBackDropdown : dropoDown_client;
  let currentStatusColor = showCurrentVcipStatus == VcipIdStatusOptions[2] || showCurrentVcipStatus == VcipIdStatusOptions[4] ? "#4DAE68" : showCurrentVcipStatus == VcipIdStatusOptions[1] ? "#ffe54f" : "red"


  return (
    <>
      <div className="main-loader">
        <LoaderClr />
      </div>
      <CallHistoryStyle>
        {
          location.pathname != opsRollBackPath &&
          <HeaderFilters
            isComponent={true}
            heading={
              location.pathname === "/dashboard/customers/single-vcip-search" ? (
                <SingleVcipStyle>
                  <div className="actions">
                    <select
                      className="custom-select"
                      name="accid"
                      onChange={handleDropDownChange}
                      // value={refSearch}
                      defaultValue={"Default"}
                    >
                      <option disabled value="Default">
                        Select Type
                      </option>
                      {dropDown_options.map((item, x) => {
                        return (
                          <option value={[item.value, item.id]} key={item.id}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="search-div">
                        <input
                          type={refValues[0] == "customerid" ? "text" : "number"}
                          className="custom-search"
                          placeholder="Search Here..."
                          onChange={searchInputHandler}
                          value={refSearch}
                          disabled={!refValues[0]}
                          style={{
                            marginTop: inputError ? "1.4rem" : "0",
                          }}
                        />
                        <p style={{ color: "red" }}>{inputError}</p>
                      </div>
                      <button
                        className="btn-submit"
                        onClick={handleSubmitSingleVcip}
                        disabled={!refSearch}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </SingleVcipStyle>
              ) : (location.pathname != opsRollBackPath) ? <CallHistoryTabs
                status={callHistoryTab}
                action={setCallHistoryTab}
              /> : null
            }
            className="customerActions"
            actionBtns={
              location.pathname === "/dashboard/customers/single-vcip-search" || location.pathname === opsRollBackPath
                ? null
                : headerActions
            }
            change={(e) => searchCustomerDetails(e.target.value)}
          />
        }

        {/* Operations Rollback Page JSX */}
        {
          location.pathname === opsRollBackPath &&
          <VcipRollBackStyle>
            <div className="opsDas">
              <h4 className="main-heading">VCIP Roll Back</h4>
              <hr className='customHr' />
              <div className="actions">
                <select
                  className="custom-select"
                  name="accid"
                  onChange={handleDropDownChange}
                  defaultValue={"Default"}
                >
                  <option disabled value="Default">
                    Select Type
                  </option>
                  {dropDown_options.map((item, x) => {
                    return (
                      <option value={[item.value, item.id]} key={item.id}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                <div className="search-div">
                  <input
                    type={refValues[0] == "customerid" ? "text" : "number"}
                    className="custom-search"
                    placeholder="Search Here..."
                    onChange={searchInputHandler}
                    value={refSearch}
                    disabled={!refValues[0]}
                    style={{
                      marginTop: inputError ? "1.4rem" : "0",
                    }}
                  />
                  <p style={{ color: "red" }}>{inputError}</p>
                </div>
                <button
                  className="btn-submit"
                  onClick={handleSubmitSingleVcip}
                  disabled={!refSearch}
                >
                  Submit
                </button>
              </div>

              {showCurrentVcipStatus && (location.pathname === opsRollBackPath) ? <>
                {/* <h3 className="fd3">Current status of VCIP:  <span style={{ `${} color: "#4DAE68", paddingLeft: "5px"` }}> {showCurrentVcipStatus}</span></h3> */}
                <h3 className="vcipStatusHeading">Current status of VCIP:  <span style={{ paddingLeft: "5px", color: currentStatusColor }}> {showCurrentVcipStatus}</span></h3>
                {
                  <>
                    <AllCallHistory
                      // dispPop={getVcipUserDetails}
                      onClick={getCallHistoryList}
                      searchedText={searchedText}
                      showDate={showDate}
                      setShowDate={setShowDate}
                      refCallHistory={refCallHistory}
                      filteration={false}
                    />
                    <hr className='customHr' />

                    <h2 className='vcipStatusHeading' style={{ margin: "10px 0px" }}>Roll Back Options </h2>
                    <div className="radioBtn">
                      {
                        Object.keys(RollBackOptions).map(key => {
                          let [rollBackRoleName, rollBackRoleValue] = RollBackOptions[key];
                          return <>
                            <div className='radioWrapper'>
                              <input type="radio"
                                name="key"
                                id={rollBackRoleName}
                                value={rollBackRoleValue}
                                onChange={() => {
                                  setRollBackStage(rollBackRoleValue)
                                }}
                              />
                              <label htmlFor="" className='RollBackOptionsStyle'>{rollBackRoleName}</label>
                            </div>
                          </>
                        })
                      }

                    </div>
                    <textarea className="custom-rollbackBtn" placeholder="Write Remark Here" name="postContent" rows={2} cols={20} onChange={(e) => setRollBackRemarks(e.target.value)} value={rollBackRemarks} />
                    <div className='flexBtn'>
                      <button className="btn-submit" onClick={handleDiscardBtn}>Discard</button>
                      <button className="btn-submit" onClick={handleRollBack} disabled={!rollBackRemarks}>Roll Back</button>
                    </div>

                  </>


                }
              </> : null
              }
            </div>

          </VcipRollBackStyle>
        }

        {
          <div className="callHistoryTableContainer">
            {callHistoryTab === "all" && (
              <AllCallHistory
                dispPop={getVcipUserDetails}
                onClick={getCallHistoryList}
                searchedText={searchedText}
                showDate={showDate}
                setShowDate={setShowDate}
                refCallHistory={refCallHistory}
                filteration={true}
              />
            )}
            {callHistoryTab === "direct" && <AllCallHistory dispPop={setPop} />}
            {callHistoryTab === "assigned" && <WaitingCustomerQueue />}
          </div>
        }
      </CallHistoryStyle>
    </>

  )
}


const CallHistoryStyle = styled.div`
  .callHistoryTableContainer {
    width: 100%;
    overflow-x: auto;
    position: relative;
  }
`;

const VcipRollBackStyle = styled.div`

.main-heading {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 6px;
}

.vcipStatusHeading {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  padding: 10px 0px;
}

.customHr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.RollBackOptionsStyle {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0px 6px; 
}

.radioBtn {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: space-between;
  gap: 8px;
  .radioWrapper {
    display: flex;
  }
}

.flexBtn {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
}
.opsDas {
  box-shadow: var(--cardShadow);
  background: var(--white) !important;
  padding: 20px 24px 0 !important;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  border-radius: 8px;
  width: 100%;
}


.actions {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0 16px 0;
  margin-top: 20px;
}
.custom-select {
  width: 15rem;
  padding: 9px;
  font-size: 16px;
  border: 1px solid var(--border-color2);
  border-radius: 5px;
  background: transparent;
}
.custom-search {
  width: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid var(--border-color2);
  border-radius: 5px;
  background: transparent;
}
.custom-rollbackBtn {
  display: inline-block;
  width: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid #ADB3BC;
  border-radius: 5px;
  background: transparent;
  margin: 16px 0;
  padding: 8px 15px;
}
.search-div {
  position: relative;
  height: auto;
  padding: 0px;
  margin: 0px;
}
.search-icon {
  position: absolute;
  top: 0.72rem;
  right: 10px;
}
.btn-submit {
  background: var(--buttonBg);
  text-align: center;
  padding: 0.6rem 1.2rem;
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  height: auto;
  // margin: 0px 4px;
}

.whitebuttonBg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(269.82deg, #38A1F7 0.1%, #38568F 99.85%);
  border-radius: 5px;
  // font-family: 'ProximaNova-semibold';
  font-size: 14px;
  text-align: center;
  padding: 6px 20px;
  color: #FFFFFF;
  position: relative;
  z-index: 0;
  border: none;
}
.fd3 {
  display: flex;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
  padding-bottom: 8px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}
`;
const SingleVcipStyle = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  background: var(--white);
  padding-top: 1rem;
  overflow: auto;
  width: 100%;
  position: relative;

 

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 0 16px 0;
  }
  .custom-select {
    width: 15rem;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
  }
  .custom-search {
    width: 20rem;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
  }
  .search-div {
    position: relative;
  }
  .search-icon {
    position: absolute;
    top: 0.72rem;
    right: 10px;
  }
  .btn-submit {
    background: var(--buttonBg);
    text-align: center;
    padding: 0.4rem 1.2rem;
    color: var(--white);
    font-weight: 600;
    font-size: 22px;
    border-radius: 5px;
    border: none;
    margin: 0px 6px;
  }
  .fd3 {
    display: flex;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export default VcipRollBack;