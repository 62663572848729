import React, {useState} from 'react'
import styled from 'styled-components'
import HeaderFilters from '../../Components/HeaderFilters'
import { headerActions, MainTabs } from '../../Components/CustomersSnippets'
import { Outlet } from 'react-router'

export default function Customers() {
  const [mainTab, setMainTab] = useState("queue")
  return (
    <CustomerStyle>
      <MainTabs action={setMainTab} status={mainTab} />
      {/* <HeaderFilters heading={{name: 'Total Auditors'}} className="customerActions" actionBtns={headerActions} /> */}
      <div className="mainContent">
        <Outlet />
      </div>
    </CustomerStyle>
  )
}

const CustomerStyle = styled.div`
  box-shadow: var(--cardShadow);
  background: var(--white);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  .customerActions {
    border: none;
    padding-top: 24px;
  }
`