import React, { useState, useEffect } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import { Text, Button } from "../Components/Elements";
import { Mail, LockColor } from "../assets/ImageUrl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import base64 from "base-64";
import { resetPasswordSagaAction } from "../Store/SagasActions/AuthSagaActions";
// import { resetPasswordSagaAction } from '../Store/SagasActions/AuthSagaActions'
import {
  BoxMenu,
  Headset,
  UserSelect,
  MultiUserWhite,
  Settings,
  FileSearch,
  FileIcon,
  LockWhite,
  CircleDirect,
  DownArrowWhite,
  Lock,
  Eye,
} from "../assets/ImageUrl";
import { Eye_close } from "../assets/ImageUrl";

export default function ChangePassword({ pop }) {
  const [changepasswordObj, setChangePassWordObj] = useState({
    newPsw: "",
    reEnterNewPsw: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangePassWordObj({ ...changepasswordObj, [name]: value });
  };

  const resetpassword = (e) => {
    e.preventDefault();
    // if (loginObj.username && loginObj.password && roleObj?.rid && roleObj?.rolename) {
    if (changepasswordObj.newPsw) {
      const encodePwd1 = base64.encode(changepasswordObj.newPsw);

      const model = {
        token: params?.token,
        new_pwd: encodePwd1,
      };
      dispatch(
        resetPasswordSagaAction({
          model: model,
          callback: resetpasswordRespData,
        })
      );
    } else {
      toast.error("Please check all inputs");
    }
  };

  const resetpasswordRespData = (data) => {
    // setIsLoading(isLoading);

    if (data.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (data.respcode == "200") {
      toast.success(data.respdesc);
      navigate("/");
      sessionStorage.setItem("username", data?.username);
    } else if (data.respcode == "4003") {
      toast.error(data.respdesc);
      navigate("/");
    } else if (data.respcode == "468") {
      navigate("/");
    } else if (data.respcode == "4002") {
      navigate("/");
      toast.error(data.respdesc);
    } else {
      toast.error(data.respdesc);
    }
  };

  return (
    <ChangePasswodStyle className="form">
      <h1 className="fd1">Reset Password</h1>
      <h2 className="fd2">Please enter your Password</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="elementContainer">
          <Text
            name="newPsw"
            type="password"
            placeholder="New Password"
            icons={{
              left: { src: Lock, alt: "lock icon" },
              right: {
                src: Eye,
                src2: Eye_close,
                iconClass: "eye",
                alt: "eye icon",
              },
            }}
            change={handleChange}
          />
        </div>
        <div className="elementContainer">
          <Text
            name="reEnterNewPsw"
            type="password"
            placeholder="Confirm Password"
            icons={{
              left: { src: Lock, alt: "lock icon" },
              right: {
                src: Eye,
                src2: Eye_close,
                iconClass: "eye",
                alt: "eye icon",
              },
            }}
            change={handleChange}
          />
        </div>
        <div className="btnContainer">
          <Button
            name="updatePassword"
            dispName="Change"
            click={resetpassword}
          />
        </div>
      </form>
    </ChangePasswodStyle>
  );
}

const ChangePasswodStyle = styled.div`
  width: 70%;
  & > .fd1:after {
    background: url(${LockColor});
  }
`;
