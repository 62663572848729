import { all } from "redux-saga/effects";
import DashboardWatcherSaga from "./DashboardWatcherSaga";
import AgentWatcherSaga from "./AgentWatcherSaga";
import AuthWatcherSaga from "./AuthWatcherSaga";
import AuditorWatcherSaga from "./AuditorWatcherSaga";
import ReportWatcherSaga from "./ReportWatcherSaga";
import OperationsWatcherSaga from "./OperationsWatchers/OperationsWatcherSaga";
export default function* rootSage() {
  yield all([
    DashboardWatcherSaga(),
    // agent
    AgentWatcherSaga(),
    AuthWatcherSaga(),
    AuditorWatcherSaga(),
    ReportWatcherSaga(),
    OperationsWatcherSaga()
  ]);
}
