import React, { useState } from "react";
import styled from "styled-components";
import { DownGray } from "../assets/ImageUrl";

export const Text = ({
  className,
  name,
  type,
  placeholder,
  icons,
  inputClass,
  value,
  change,
  max,
}) => {
  const [dispPassword, setDispPassword] = useState(true);
  const [file, setFile] = useState("");
  return type === "file" ? (
    <TextStyle>
      {/* <input type="file" onChange={e => setFile(e.target.files[0].name)} /> */}
      <input
        type="file"
        name={name && name}
        accept="image/*"
        onChange={(e) => change && change(e)}
      />
      <input
        type="text"
        placeholder={placeholder && placeholder}
        onChange={(e) => change && change(e)}
        maxlength={max}
      />
      {icons?.right && (
        <img
          src={icons.right.src || icons.right}
          className={
            (icons.iconClass && icons.iconClass) ||
            (icons.right && icons.right.iconClass)
          }
          alt={(icons.alt && icons.alt) || (icons.right && icons.right.alt)}
          onClick={() => type === "password" && setDispPassword(!dispPassword)}
        />
      )}
    </TextStyle>
  ) : (
    <TextStyle className={className && className}>
      {icons?.left && (
        <img
          src={icons.left.src || icons.left}
          className={
            (icons.iconClass && icons.iconClass) ||
            (icons.left && icons.left.iconClass)
          }
          alt={(icons.alt && icons.alt) || (icons.left && icons.left.alt)}
        />
      )}
      <input
        type={
          type === "password" ? (dispPassword ? "password" : "text") : "text"
        }
        value={value && value}
        className={inputClass && inputClass}
        name={name && name}
        placeholder={placeholder && placeholder}
        onChange={(e) => change && change(e)}
      />
      {icons?.right && (
        <img
          src={
            dispPassword
              ? icons.right.src || icons.right
              : icons.right.src2 || icons.right
          }
          className={
            (icons.iconClass && icons.iconClass) ||
            (icons.right && icons.right.iconClass)
          }
          alt={(icons.alt && icons.alt) || (icons.right && icons.right.alt)}
          onClick={() => type === "password" && setDispPassword(!dispPassword)}
        />
      )}
    </TextStyle>
  );
};

export const Select = ({
  options,
  clickChange,
  arrowStyle,
  defaultOption,
  classNames,
  showSearch,
}) => {
  const [selectElement, setSelectElement] = useState({
    visibility: false,
    selectedOption: "",
    search: "",
  });
  return (
    <SelectStyle
      className={classNames && classNames}
      onClick={(e) => {
        // e.currentTarget.children[0].children[1].classList.add =  selectElement.visibility ? "down" : "up"
        // e.currentTarget.children[0].children[1].innerHTML = selectElement.visibility ? "down" : "up";
      }}
    >
      <div
        className={
          selectElement.selectedOption === ""
            ? "selected-option default"
            : "selected-option"
        }
        onClick={() =>
          setSelectElement({
            ...selectElement,
            visibility: !selectElement.visibility,
            search: "",
          })
        }
      >
        <span
          title={
            selectElement.selectedOption === ""
              ? defaultOption || ""
              : selectElement.selectedOption
          }
        >
          {selectElement.selectedOption === ""
            ? defaultOption || ""
            : selectElement.selectedOption}
          {/* {selectElement.selectedOption === ""
              ? "Select a state"
              : selectElement.selectedOption.length <= 20
              ? selectElement.selectedOption
              : `${selectElement.selectedOption.slice(0, 20)}...`} */}
        </span>
        <i
          className={selectElement.visibility ? "down" : "up"}
          style={arrowStyle && arrowStyle}
        ></i>
      </div>
      {selectElement.visibility && (
        <div className="options">
          {showSearch && (
            <div className="search-options">
              <input
                type="search"
                placeholder="Search states"
                defaultValue={selectElement.search}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  setSelectElement({ ...selectElement, search: e.target.value })
                }
              />
            </div>
          )}
          <ul>
            <li
              data-value="default"
              onClick={() =>
                setSelectElement({ ...selectElement, selectedOption: "" })
              }
            >
              {defaultOption}
            </li>
            {options
              .filter((option) =>
                option
                  .toLowerCase()
                  .includes(selectElement.search.toLowerCase())
              )
              .map((option, index) => (
                <li
                  key={index}
                  className={
                    selectElement.selectedOption === option
                      ? "active-option"
                      : null
                  }
                  onClick={() => {
                    setSelectElement({
                      ...selectElement,
                      selectedOption: option,
                      visibility: !selectElement.visibility,
                    });
                    clickChange && clickChange(option);
                  }}
                >
                  {option}
                </li>
              ))}
          </ul>
        </div>
      )}
    </SelectStyle>
  );
};

export const PopupBody = ({ comp }) => (
  <PopupBodyStyle>
    <h3>{comp.disc}</h3>
    <div className="btnContainer">
      <Button
        name="applyLeave"
        dispName={comp.btn1}
        click={() => comp.btn1Func()}
        className="cancel fg1"
      />
      <Button
        name="applyLeave"
        dispName={comp.btn2}
        click={(e) => comp.btn2Func()}
      />
    </div>
  </PopupBodyStyle>
);

export const RadioBtn = ({ name, classNames, value, change }) => (
  <RadioStyle
    className={classNames && classNames}
    type="radio"
    name={name && name}
    value={value && value}
    onChange={(e) => change && change(e)}
  />
);

export const Button = ({
  name,
  dispName,
  click,
  className,
  isLoading,
  disabled,
}) => (
  <ButtonStyle
    className={className && className}
    name={name && name}
    onClick={click && ((e) => click(e))}
    disabled={disabled && disabled}
  >
    {dispName}
    {isLoading ? <span className="spinner1"></span> : null}
  </ButtonStyle>
);

const TextStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  border: var(--border);
  border-radius: 5px;
  padding: 14px;
  position: relative;

  input[type="text"],
  input[type="password"] {
    flex: 1;
    width: 100%;
    outline: none;
    border: none;
    font-size: 20px;
    background: transparent;
  }

  input[type="file"] {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
`;
const SelectStyle = styled.div`
  width: 100%;
  position: relative;
  & > .selected-option {
    height: inherit;
    border: var(--border);
    border-radius: 5px;
    padding: 14px;
    font-size: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    &.default {
      color: var(--dark6);
    }
    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i {
      background: url(${DownGray});
      height: 20px;
      width: 20px;
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      cursor: pointer;
      transition: all 0.2s ease;
      &.up {
        transform: rotate(-180deg);
      }
    }
  }
  & > .options {
    padding: 16px;
    box-shadow: var(--selectShadow);
    border-radius: 3px;
    border: var(--borderLight);
    position: absolute;
    top: 50px;
    left: 0;
    max-height: 249px;
    overflow-y: scroll;
    width: 100%;
    background: #ffffff;
    z-index: 99;
    .search-options {
      margin-bottom: 8px;
      input[type="search"] {
        width: 100%;
        border: var(--border);
        border-radius: 5px;
        font-size: 16px;
        padding: 8px;
      }
    }
    ul {
      li {
        padding: 8px;
        cursor: pointer;
        transition: all 0.2s linear;
        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }
        &:not(:first-child):hover,
        &.active-option {
          background: var(--fg3);
          font-weight: 600;
          text-fill-color: transparent;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }
  }
`;

const RadioStyle = styled.input`
  height: 0;
  width: 0;
  margin: 10px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: -10px;
    top: -10px;
    border: var(--border);
    border-width: 2px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background: #ffffff;
  }
  &:checked:before {
    border: double 2px transparent;
    border-radius: 80px;
    background-image: var(--borderGradient);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  &:after {
    content: "";
    position: absolute;
    left: -6px;
    top: -6px;
    background: var(--fg3);
    z-index: 2;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.2s ease-in;
  }
  &:checked:after {
    transform: scale(1);
  }
`;

const ButtonStyle = styled.button`
  background: var(--buttonBg);
  width: 100%;
  text-align: center;
  padding: 14px;
  color: var(--white);
  font-weight: 600;
  font-size: 24px;
  border-radius: 5px;
  border: none;
  margin: 0px 6px;
  &.plain {
    background: var(--white);
    color: var(--primaryFont);
  }
  .spinner1 {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    // background-color: #fff;
    position: absolute;
    margin-left: 3px;
    margin-top: 9px;
    // right: 2%;
    // top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 3px solid rgba(255, 255, 255, 0.2);
      border-right: 3px solid rgba(255, 255, 255, 0.2);
      border-bottom: 3px solid rgba(255, 255, 255, 0.2);
      border-left: 3px solid #ffffff;
      border-radius: 100%;
      animation: spinner 900ms linear infinite;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const PopupBodyStyle = styled.div`
  h3 {
    color: var(--dark5);
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    gap: 12px;
    button {
    }
  }
`;
