import React, {useState} from 'react'
import styled from 'styled-components'

export default function RangeSlider({func, value}) {
    const [range, setRange] = useState(value)
  return (
    <RangeStyle>
        <div className="themeSlide">
            <div className="sliderMain"></div>
            <div className="slider" style={{width: `calc(100% - ${range}%)`}}></div>
            <div className="thumb" style={{left: `${range}%`}}></div>
        </div>
        <input type="range" name="agentAllocationSplit" min="0" max="100" value={range} onChange={(e) => {
            setRange(e.target.value)
            func && func(e.target.value)
        }} />
    </RangeStyle>
  )
}

const RangeStyle = styled.div`
    width: 100%;
    position: relative;
    .themeSlide {
        position: relative;
        width: calc(100% - 14px);
        left: 7px;
    }
    .slider, .sliderMain {
        right: 0;
        position: absolute;
        height: 3px;
        background: #daeeff;
        border-radius: 2px;
    }
    .sliderMain {
        width: 100%;
        background-image: linear-gradient(90deg, #38568F 2.78%, #38A1F7 96.7%);
    }
    .thumb {
        position: absolute;
        top: -6px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: linear-gradient(180deg, #38568F 0%, #38A1F7 100%);
    }
    input[type=range] {
        opacity: 0;
        position: absolute;
        top: -5px;
        left: 7px;
    }
`