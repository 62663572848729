
export function validateEmail(value) {
    let error;
    if (!value || value === undefined || value.toString().trim() === '') {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid Email Address';
    }
    return error;
}

export function validateIsRequired(value) {
    let error;
    if (value === null || value === undefined || value.toString().trim() === '') {
        error = 'Required';
    }

    return error;
}

export function validateIsNumeric(value) {
    const numericPattern = new RegExp(/^[0-9]*$/)
    const Num_Valid = "012345"
    const Unvalid = Num_Valid.includes(value.charAt())
    let error;
    if (!numericPattern.test(value)) {
        error = 'Invalid Number';
    } if (value.length >= 11 || Unvalid) {
        error = "Please enter valid mobile number"
    }
    return error;
}

export function minOrMaxLength(value, minOrMax, length) {
    let error;

    if (value !== undefined && minOrMax === 'minlength' && value.length < length) {
        error = 'Minimum Length ' + length.toString().split('').reduce((prevValue, currentValue) => {
            return prevValue + '' + currentValue;
        }, '');
    } else if (value !== undefined && minOrMax === 'maxlength' && value.length > length) {
        error = 'Maximum Length ' + length.toString().split('').reduce((prevValue, currentValue) => {
            return prevValue + '' + currentValue;
        }, '');
    }

    return error;
}

export function validateIsNumAndAlp(value) {
    let error;
    const numericPattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    if (!numericPattern.test(value)) {
        error = 'Password should have one upper-case, one lower-case, a digit and a non-alphanumeric character'
    } else {
        error = ""
    }

    return error;
}


export function validatePhoneNumber(value) {
    let error;
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!re.test(value)) {
        error = "Please Check your mobile number"
    } else {
        error = ""
    }

    return re.test(value);
}