import React from 'react'
import styled from 'styled-components';
import { Doughnut } from "react-chartjs-2";

export default function DoughnutChart({customData, count, disc, height, width}) {
    const data = {
        labels: {
            display: false
          },
        // labels: ["Critical case", "Urgent case", "Errors", "Reviewed", "Success"],
        datasets: [
          {
            data: [30, 30, 5, 15, 20],
            backgroundColor: [
              "rgb(242,165,152)",
              "rgb(255,232,157)",
              "rgb(236,107,109)",
              "rgb(122,231,125)",
              "rgb(195,233,151)"
            ],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
          }
        ],
       
        plugins: {
          labels: {
            render: "percentage",
            fontColor: ["green", "white", "red"],
            precision: 2
          },
        },
         text: "23%",
      };

  return (
    <DoughnutChartStyle  className="chart">
        <Doughnut
          data={customData || data}
          options={{
            elements: {
              center: {
                legend: { display: true, position: "right" },
                text: "Red is 2/3 the total numbers",
                color: "#FF6384", 
                fontStyle: "Arial", 
                sidePadding: 20, 
                minFontSize: 20, 
                lineHeight: 25 
              }
            },
          }}
          height={height}
          width={width}
        />
        <div className="totalCounts">
          <h3>{count}</h3>
          <p>{disc}</p>
        </div>
    </DoughnutChartStyle>
  )
}


const DoughnutChartStyle = styled.div`
  position: relative;
  .chartjs-render-monitor {
      height: 220px;
      /* transform: scale(0.8); */
  }
  .totalCounts {
      border-radius: 50%;
      position: absolute;
      text-align: center;
      left: 50%;
      top: 54%;
      transform: translate(-50%, -50%);
      h3 {
          font-weight: 800;
          font-size: 24px;
      }
      p {
          font-weight: 700;
          font-size: 14px;
          color: var(--dark5);
      }
  }
`