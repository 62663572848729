import addUserGrad from "./imgs/icons/addUserGrad.svg";
import addUserWhite from "./imgs/icons/addUserWhite.svg";
import boxMenu from "./imgs/icons/boxMenu.svg";
import calanderGrad from "./imgs/icons/calanderGrad.svg";
import callGrad from "./imgs/icons/callGrad.svg";
import circleDirect from "./imgs/icons/circleDirect.svg";
import clock from "./imgs/icons/clock.svg";
import clockGrad from "./imgs/icons/clockGrad.svg";
import closeBtn from "./imgs/icons/closeBtn.svg";
import deleteGrad from "./imgs/icons/deleteGrad.svg";
import docBundleGrad from "./imgs/icons/docBundleGrad.svg";
import downArrowGrad from "./imgs/icons/downArrowGrad.svg";
import downArrowWhite from "./imgs/icons/downArrowWhite.svg";
import downGray from "./imgs/icons/downGray.svg";
import editGrad from "./imgs/icons/editGrad.svg";
import eye from "./imgs/icons/eye.svg";
import eye_close from "./imgs/icons/eye_close.svg";
import file from "./imgs/icons/file.svg";
import fileSearch from "./imgs/icons/fileSearch.svg";
import filterGrad from "./imgs/icons/filterGrad.svg";
import headset from "./imgs/icons/headset.svg";
import hiColor from "./imgs/icons/hiColor.svg";
import linkUser from "./imgs/icons/linkUser.svg";
import linkUserGrad from "./imgs/icons/linkUserGrad.svg";
import lock from "./imgs/icons/lock.svg";
import lockColor from "./imgs/icons/lockColor.svg";
import lockWhite from "./imgs/icons/lockWhite.svg";
import mail from "./imgs/icons/mail.svg";
import mailGrad from "./imgs/icons/mailGrad.svg";
import multiUserGrad from "./imgs/icons/multiUserGrad.svg";
import multiUserGray from "./imgs/icons/multiUserGray.svg";
import multiUserWhite from "./imgs/icons/multiUserWhite.svg";
import orderGrad from "./imgs/icons/orderGrad.svg";
import playGrad from "./imgs/icons/playGrad.svg";
import plusGrad from "./imgs/icons/plusGrad.svg";
import refreshGrad from "./imgs/icons/refreshGrad.svg";
import rightArrowGrad from "./imgs/icons/rightArrowGrad.svg";
import screenShareGrad from "./imgs/icons/screenShareGrad.svg";
import searchGrad from "./imgs/icons/searchGrad.svg";
import searchGray from "./imgs/icons/searchGray.svg";
import settings from "./imgs/icons/settings.svg";
import tikGrad from "./imgs/icons/tikGrad.svg";
import timer from "./imgs/icons/timer.svg";
import timerGrad from "./imgs/icons/timerGrad.svg";
import uploadGrad from "./imgs/icons/uploadGrad.svg";
import userBoxGrad from "./imgs/icons/userBoxGrad.svg";
import userProfile from "./imgs/icons/userProfile.svg";
import userProfileGrad from "./imgs/icons/userProfileGrad.svg";
import userSelect from "./imgs/icons/userSelect.svg";
import video from "./imgs/icons/video.svg";
import videoGrad from "./imgs/icons/videoGrad.svg";
import worldGrad from "./imgs/icons/worldGrad.svg";
import backBlack from "./imgs/icons/backBlack.svg";
import downloadWhite from "./imgs/icons/downloadWhite.svg";
import manager from "./imgs/manager.svg";
import markTick from "./imgs/icons/markTick.svg";
import pencilMark from "./imgs/icons8-edit.svg";
import cameraIcon from "./imgs/icons/Border icon.svg";
import iconwrong from "./imgs/icons/iconwrong.svg";
import kycGreenTick from "./imgs/icons/kycGreenTick.svg";
import dummyProfile from "./imgs/dummyProfile.png";
import offline_img from "./imgs/offline_img.svg";
import busy_img from "./imgs/busy_img.svg";
import away_img from "./imgs/away_img.svg";
import inCall from "./imgs/inCall.svg";
import online_img from "./imgs/online_img.svg";
// import transImg from "./imgs/transImg.svg"
import transImg1 from "./imgs/transImg1.svg";
import queue_management_icon from "./imgs/icons/queue_management.svg";
import gridView from "./imgs/icons/grid_view.svg";
import gridViewFade from "./imgs/icons/grid_view_fade.svg";
import listViewIcon from "./imgs/icons/list_view.svg";

// Protean Imgs
import proteanLogo from "./imgs/proteanLogo.png";
import proteanFavicon from "./imgs/proteanFavicon.svg";

export const AddUserGrad = addUserGrad;
export const AddUserWhite = addUserWhite;
export const BoxMenu = boxMenu;
export const CalanderGrad = calanderGrad;
export const CallGrad = callGrad;
export const CircleDirect = circleDirect;
export const Clock = clock;
export const ClockGrad = clockGrad;
export const CloseBtn = closeBtn;
export const DeleteGrad = deleteGrad;
export const DocBundleGrad = docBundleGrad;
export const DownArrowGrad = downArrowGrad;
export const DownArrowWhite = downArrowWhite;
export const DownGray = downGray;
export const EditGrad = editGrad;
export const Eye = eye;
export const Eye_close = eye_close;
export const FileIcon = file;
export const FileSearch = fileSearch;
export const FilterGrad = filterGrad;
export const Headset = headset;
export const HiColor = hiColor;
export const LinkUser = linkUser;
export const LinkUserGrad = linkUserGrad;
export const Lock = lock;
export const LockColor = lockColor;
export const LockWhite = lockWhite;
export const Mail = mail;
export const MailGrad = mailGrad;
export const MultiUserGrad = multiUserGrad;
export const MultiUserGray = multiUserGray;
export const MultiUserWhite = multiUserWhite;
export const OrderGrad = orderGrad;
export const PlayGrad = playGrad;
export const PlusGrad = plusGrad;
export const RefreshGrad = refreshGrad;
export const RightArrowGrad = rightArrowGrad;
export const ScreenShareGrad = screenShareGrad;
export const SearchGrad = searchGrad;
export const SearchGray = searchGray;
export const Settings = settings;
export const TikGrad = tikGrad;
export const Timer = timer;
export const TimerGrad = timerGrad;
export const UploadGrad = uploadGrad;
export const UserBoxGrad = userBoxGrad;
export const UserProfile = userProfile;
export const UserProfileGrad = userProfileGrad;
export const UserSelect = userSelect;
export const Video = video;
export const VideoGrad = videoGrad;
export const WorldGrad = worldGrad;
export const BackBlack = backBlack;
export const DownloadWhite = downloadWhite;
export const Manager = manager;
export const MarkTick = markTick;
export const PencilMark = pencilMark;
export const CameraIcon = cameraIcon;
export const RightTick = kycGreenTick;
export const WrongTick = iconwrong;
export const ProfileDummy = dummyProfile;
export const Offline = offline_img;
export const Online = online_img;
export const Away = away_img;
export const Busy = busy_img;
export const InCall = inCall;
export const Transaction = transImg1;
export const queueManagementIcon = queue_management_icon;
export const GridViewIcon = gridView;
export const GridViewFade = gridViewFade;
export const ListViewIcon = listViewIcon;

// Protean Imgs
export const ProteanLogo = proteanLogo;
export const ProteanFavi = proteanFavicon;
