import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ProfilePic from "../assets/imgs/Avatar.png";
import { SearchGrad, DownArrowWhite } from "../assets/ImageUrl";
import {
  actionaccid,
  actionReqAdminAccounts,
  globalAccountSetup,
  setClientAccountId,
} from "../Store/SagasActions/DashboardOverviewSagaAction";
import { useDispatch, useSelector } from "react-redux";
import { actionReqGetAdminDashboardCountSagaAction } from "../Store/SagasActions/DashboardOverviewSagaAction";

export default function NavBar() {
  const name = sessionStorage.getItem("username");
  const role = sessionStorage.getItem("role");
  const [Account, setAccount] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showActiveDropdwon, setShowDropdonw] = useState(false);
  const [accountlistname, setAccountListName] = useState();
  const [accountlistId, setAccountListId] = useState();
  const dispatch = useDispatch();

  // const takelang = useSelector((state) => state?.DashboardReducer.accid);
  const localstorageacc = sessionStorage.getItem("accid");
  const localstorageaccName = sessionStorage.getItem("acccode");
  // const roleName = sessionStorage.getItem("role")

  useEffect(() => {
    if (accountlistId != undefined) {
      dispatch(actionaccid(accountlistId));
    }
  }, [accountlistId]);

  useEffect(() => {
    dispatch(actionReqAdminAccounts({ callback: respGetAccounts }));
  }, []);

  useEffect(() => {
    if (Account.length > 0) {
      setAccountListName(localstorageaccName || Account[0]?.acccode);
      setAccountListId(localstorageacc || Account[0]?.accid);
    }
  }, [Account]);

  const respGetAccounts = (data) => {
    setAccount(data?.accounts);
    sessionStorage.setItem("accid", localstorageacc || data.accounts[0].accid);
    sessionStorage.setItem(
      "acccode",
      localstorageaccName || data.accounts[0].acccode
    );
  };

  const handleChange = (e) => {
    const e_value = e.target.value;
    const arrVal = e_value.split(",");
    const value = arrVal[0];
    const name = arrVal[1];
    setAccountListName(name);
    setAccountListId(value);
    dispatch(globalAccountSetup(value));
    dispatch(setClientAccountId(value));
    // localStorage.setItem('accid', value);
    sessionStorage.setItem("accid", value);
    sessionStorage.setItem("acccode", name);
  };
  // const showDropDown = (item, index) => {
  //   setShowDropdonw(!showActiveDropdwon);
  //   setAccountListName(item?.accid);
  // };

  // const toggleProfileDrop = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <NavStyle>
      <div
        className="welcome"
        style={{ display: "flex", alignItems: "center" }}
      >
        <h2 className="fl2"> Hello {name}!</h2>
        <select
          name="accountIds"
          id="accountIds"
          onChange={handleChange}
          value={accountlistId + "," + accountlistname}
          style={{ marginLeft: "21px" }}
          className="custom-border"
        >
          {/* {roleName == "Super Admin" && <option value="0">All accounts</option>} */}
          {Account?.map((item, i) => {
            return (
              <option value={item?.accid + "," + item?.acccode}>
                {item.accountname}
              </option>
            );
            // return <option value={item?.accid} >{item.accountname}</option>;
          })}
        </select>
      </div>
      <div className="userDetail">
        <div className="imgContainer">
          <img src={ProfilePic} alt="profile pic" />
        </div>

        <div className="userDisc">
          <h6
            className="fl3 dropbtn"
            href="#"
            data-bs-toggle="dropdown"
            for="dropcheck"
          >
            {name}
          </h6>
          <pre>{role}</pre>
        </div>
      </div>
    </NavStyle>
  );
}

const NavStyle = styled.nav`
  background: var(--bgGradientLR);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  .custom-border {
    right: 0;
    // padding: 2px 15px;

    border: 1px solid #fff;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12); */
    border-radius: 3px;
    top: 70px;

    width: 15rem;
    padding: 10px;
    font-size: 16px;
    /* border: 1px solid var(--border-color2); */
    border-radius: 5px;
    /* background: transparent; */

    li:last-child a {
      border-bottom: none;
    }

    .dropdown-item {
      font-family: "ProximaNova-semibold";
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0px;
      color: #6e6e6f;

      &:hover {
        font-family: "ProximaNova-semibold";
        background: linear-gradient(180deg, #38568f 0%, #38a1f7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .fa-solid {
        margin-right: 5px;
      }
    }
  }
  .select-dropdown {
    margin-left: 21px;
    padding: 7px 12px;
    border-radius: 2%;
    border: 1px solid gray;
    font-size: 14px;
    margin-top: -1%;
  }

  .dropbtn {
    font-size: 16px;
    border: none;
    position: relative;
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    a {
      text-decoration: none;
      color: #000000;
    }

    a:hover {
      color: #222222;
    }

    /* Dropdown */

    .dropdown {
      display: inline-block;
      position: relative;
    }

    .dd-button {
      display: inline-block;
      border-radius: 4px;
      padding: 10px 30px 10px 20px;
      cursor: pointer;
      white-space: nowrap;
    }

    .dd-button:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid white;
    }

    .dd-button:hover {
      // background-color: #eeeeee;
    }

    .dd-input {
      display: none;
    }

    .dd-menu {
      position: absolute;
      top: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0;
      margin: 2px 0 0 0;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      right: 0;
      list-style-type: none;
    }

    .dd-input + .dd-menu {
      display: none;
    }

    .dd-input:checked + .dd-menu {
      display: block;
      z-index: 1;
    }

    .dd-menu li {
      padding: 10px 20px;
      cursor: pointer;
      white-space: nowrap;
    }

    .dd-menu li:hover {
      background-color: #f6f6f6;
    }

    .dd-menu li a {
      display: block;
      margin: -10px -20px;
      padding: 10px 20px;
    }

    .dd-menu li.divider {
      padding: 0;
      border-bottom: 1px solid #cccccc;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    // min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
    box-shadow: var(--cardShadow);
  }
  .dropdown-content p {
    width: 248px;
    text-align: center;
  }

  .dropdown-content a {
    color: black;
    padding: 8px 8px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropcheck {
    position: absolute;
    left: -9999px;
  }

  .dropcheck:checked ~ .dropdown-content {
    display: block;
  }

  .dropcheck:checked + .dropbtn {
    background-color: #3e8e41;
  }
  .userDetail {
    display: flex;
    gap: 10px;
    .imgContainer {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
      }
    }
    .userDisc {
      h6 {
      }
      pre {
        color: var(--white);
      }
    }
  }
`;
