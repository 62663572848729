import React from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import data from "../Data/customers.json";
import { DownloadWhite } from "../assets/ImageUrl";
import NoRecordFound from "./NoRecordFound";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

export default function WaitingCustomerQueue(props) {
  const response = data["queue waiting"];
  const checkLength = response.length <= 1;

  const columns = [
    {
      name: "Schedule Date",
      selector: (row) => row["sdate"],
      format: (row) => moment(row["sdate"]).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Schedule time",
      selector: (row) => row["stime"],
      sortable: true,
    },
    {
      name: "VCIP ID",
      selector: (row) => row["vcipid"],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row["accname"],
      sortable: true,
      wrap: true,
    },

    {
      name: "Customer Type",
      selector: (row) => row["customertype"],
      sortable: true,
    },
    {
      name: "Assigned Agent",
      selector: (row) => row["agentassigned"],
      sortable: true,
    },
    {
      name: props.isWaitingList ? "Waiting Since" : "Call Duration",
      selector: (row) => row["callwtime"],
      sortable: true,
    },
    {
      name: "Schedule wait time",
      selector: (row) => row["swtime"],
      sortable: true,
    },
    // {
    //   name: "Scheduled Date and time",
    //   selector: (row) => row["swtime"],
    //   sortable: true,
    // },
  ];


  return (
    <WaitingCustomerQueueStyle>
      {props?.waitingList?.count > 0 ? (
        <DataTable
          columns={columns}
          data={props?.waitingList?.vciplist}
          pagination
        />
      ) : (
        // waitingList.vciplist
        <>
          <div className="norecord">
            <NoRecordFound />
          </div>
        </>
      )}
    </WaitingCustomerQueueStyle>
  );
}

const WaitingCustomerQueueStyle = styled.div`
  .download {
    border: none;
    border-radius: 3px;
    color: var(--white);
    font-weight: 600;
    padding: 4px 12px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(87.85deg, #38568f 7.3%, #38a1f7 95.41%);
  }
  .status {
    font-weight: 600;
    display: block;
    font-size: 16px;
    padding: 4px;
    min-width: 72px;
    text-align: center;
    &.green {
      background: #08c1521a;
      color: #08c152;
      border-radius: 3px;
    }
    &.orange {
      background: #f9c7171a;
      color: #f9c717;
      border-radius: 3px;
    }
  }
  .norecord {
    height: calc(100vh - 300px);
  }
  .rdt_TableBody {
    max-height: calc(100vh - 388px);
    overflow: auto;
  }
`;
