import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery, delay } from "redux-saga/effects";
import Axios from "../../Service/axios";
import {
  actionReqResStatusLoaderSagaAction,
  getCustomerQueueReducerSagaAction,
  getLanguagesReducerAction,
  getTokenNumberDashboardData,
} from "../SagasActions/DashboardOverviewSagaAction";
import {
  ACTION_GET_AGENT_LIST_REQ,
  ACTION_GET_CALL_HISTORY_REQ,
  ACTION_GET_SINGLE_REFERENCE_CALL_HISTORY_REQ,
  ACTION_GET_CUSTOMER_QUEQE_REQ,
  ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT,
  ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT_ACTIVITY,
  ACTION_POST_GET_LANGUAGES,
  GET_TOKEN_NUMBER_DASHBOARD,
} from "../SagasActions/SagaActionTypes";
import { actionReqResStatusLoaderClrSagaAction } from "../SagasActions/AuthSagaActions";

const getAgentListReq = (model) => {
  const URL = "GetAgentList";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getAgentListReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  yield put(actionReqResStatusLoaderClrSagaAction(true));

  try {
    const resp = yield call(getAgentListReq, action?.payload?.model);
    if (resp) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(actionReqResStatusLoaderClrSagaAction(false));
      }
    } else {
      toast.error(resp?.respdesc);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}

const updateAgentReq = (model) => {
  const URL = "UpdateAgent";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* updateAgentReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(updateAgentReq, action?.payload?.model);

  try {
    if (resp && resp?.respcode === "200") {
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getCallHistoryReq = (model) => {
  const URL = "GetAdminCallHistory";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getCallHistoryReqSaga(action) {
  yield put(actionReqResStatusLoaderClrSagaAction(true));
  try {
    const resp = yield call(getCallHistoryReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp?.vciplist);
        yield put(actionReqResStatusLoaderClrSagaAction(false));
      }
    } else {
      toast.error(resp?.respdesc);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}
// Single Reference Call History
const getSingleReferenceCallHistoryReq = ({ endpoint, model }) => {
  const URL = endpoint;
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getSingleReferenceCallHistoryReqSaga(action) {
  yield put(actionReqResStatusLoaderClrSagaAction(true));
  try {
    const resp = yield call(
      getSingleReferenceCallHistoryReq,
      action?.payload?.model
    );
    // if (resp && resp?.respcode === "200") {
    if (action?.payload?.callback) {
      action?.payload?.callback([resp, action?.payload?.model?.endpoint]);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
    // } else {
    //   toast.error(resp?.respdesc);
    //   yield put(actionReqResStatusLoaderClrSagaAction(false));
    // }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}

const getCustomerQueqeReq = (model) => {
  const URL = "GetAdminCustomerQueue";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getCustomerQueqeReqSaga(action) {
  // yield put(actionReqResStatusLoaderClrSagaAction(true));
  try {
    const resp = yield call(getCustomerQueqeReq, action?.payload?.model);
    action?.payload?.callback({
      vciplist: resp?.vciplist,
      count: resp?.vciplistcount,
    });
    if (resp && resp?.respcode === "200") {
      yield put(getCustomerQueueReducerSagaAction(resp));
      if (action?.payload?.callback) {
        yield put(actionReqResStatusLoaderSagaAction(false));
      }
    } else {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      // toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
    } else {
      // toast.error(err.message);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getAgentActivityReq = (model) => {
  const URL = "GetUserDashBoardCount";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getAgentActivityReqSaga(action) {
  // yield put(actionReqResStatusLoaderClrSagaAction(true));
  try {
    const resp = yield call(getAgentActivityReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(actionReqResStatusLoaderSagaAction(false));
      }
    } else {
      toast.error(resp?.respdesc);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// Get languages

const getLanguagesReq = (model) => {
  const URL = "GetLanguages";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};
function* getLanguagesReqSaga(action) {
  try {
    const resp = yield call(getLanguagesReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      yield put(getLanguagesReducerAction(resp.languages));

      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(actionReqResStatusLoaderSagaAction(false));
      }
    } else {
      toast.error(resp?.respdesc);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// Get Token Number Dashboard
const getTokenNumberDashboardReq = (model) => {
  const URL = "GetTokenNumberDashboard";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};
function* getTokenNumberDashboardReqSaga(action) {
  let path = window.location.pathname.includes("dashboard/q-management");
  try {
    const resp = yield call(getTokenNumberDashboardReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(actionReqResStatusLoaderClrSagaAction(false));

        yield delay(40000);
        if (path) {
          yield put(getTokenNumberDashboardData(action?.payload));
        }
      }
    } else {
      toast.error(resp?.respdesc);
      yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

export default function* AgentWatcherSaga() {
  yield takeLatest(ACTION_GET_AGENT_LIST_REQ, getAgentListReqSaga);
  yield takeLatest(ACTION_GET_CALL_HISTORY_REQ, getCallHistoryReqSaga);
  yield takeLatest(
    ACTION_GET_SINGLE_REFERENCE_CALL_HISTORY_REQ,
    getSingleReferenceCallHistoryReqSaga
  );
  yield takeEvery(ACTION_GET_CUSTOMER_QUEQE_REQ, getCustomerQueqeReqSaga);
  yield takeLatest(
    ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT,
    updateAgentReqSaga
  );
  yield takeLatest(
    ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT_ACTIVITY,
    getAgentActivityReqSaga
  );
  yield takeEvery(ACTION_POST_GET_LANGUAGES, getLanguagesReqSaga);
  yield takeLatest(GET_TOKEN_NUMBER_DASHBOARD, getTokenNumberDashboardReqSaga);
}
