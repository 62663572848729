import React, { useState } from 'react'
import { useNavigate } from "react-router";
import styled from 'styled-components';
import AsideBar from '../Components/AsideBar';
import NavBar from '../Components/NavBar';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';
// import VCIPListQuality from '../QualityCheck/subpages/VCIPListQuality';

export default function QualityCheck() {
    // const navigate = useNavigate();
    // const role = sessionStorage.getItem("role");
    // if (!authkey) {
    //     navigate("/")
    // }

    return (
        <>
            <DashboardStyle>
                <div className="asideBar">
                    <AsideBar />
                </div>
                <div className="mainSec">
                    <NavBar />
                    <main>
                        <Outlet/>
                    </main>
                </div>
            </DashboardStyle>
        </>
    )
}

const DashboardStyle = styled.div`
background: var(--lightBackground);
    display: flex;
    .mainSec {
        flex: 1;
        display: flex;
        flex-direction: column;
        main {
            padding: 20px;
            height: calc(100vh - 80px);
            overflow: auto;
        }
    }
`







