import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderFilters from "../../Components/HeaderFilters";
import response from "../../Data/auditor.json";
import uuid from "react-uuid";
import { AddUserWhite, Manager } from "../../assets/ImageUrl";
import CardNode from "../../Components/CardNode";
import Popup from "../../Components/Popup";
import { AddAuditor, headerActions } from "../../Components/AuditorSnippets";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { actionReqGetAuditorListSagaAction } from "../../Store/SagasActions/AuditorSagaAction";
import { AddAditionalInfo } from "../../Components/AgentSnippets";
import { actionReqGetCreateAuditor } from "../../Store/SagasActions/AuditorSagaAction";
import { actionReqAdminAccounts } from "../../Store/SagasActions/DashboardOverviewSagaAction";
import AgentActiveStatuses from "../../Constants/Status";
import { useSelector } from "react-redux";
import NoAgentFound from "../../Components/NoAgentFound";
import { actionReqResStatusLoaderClrSagaAction } from "../../Store/SagasActions/AuthSagaActions";
import LoaderClr from "../../Components/LoaderClr";
import moment from "moment";
import DataTable from "react-data-table-component";


export default function Auditor() {
  const [addAuditor, setAddAuditor] = useState("");
  const [getAuditorList, setGetAuditorList] = useState([]);
  const [getAuditorListOriginal, setGetAuditorListOriginal] = useState([]);
  const [auditorBody, setAuditorBody] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);

  // const count  = getAuditorList?.auditorlist?.map((auditor) => (auditor.status == "1" ? <div>{auditor.length}</div>:""))

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accid1 = useSelector(
    (state) => state.DashboardReducer?.accountaccid?.accounts
  );
  const roleID = sessionStorage.getItem("role")
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const globalAccountId = useSelector((state) => state?.GlobalRecuder.globalAccountId);
  const AccountSwap = roleID === "Account Admin" ? sessionStorage.getItem("accid") : account;


  useEffect(() => {
    dispatch(actionReqResStatusLoaderClrSagaAction(true));
    if (AccountSwap) {
      getAuditorUserList(AccountSwap);
    }
  }, [AccountSwap])

  
  // useEffect(() => {
  //   dispatch(actionReqResStatusLoaderClrSagaAction(true));
  //   getAuditorUserList(AccountSwap)
  // }, []);

  const getAuditorUserList = (accid) => {
    const model = {
      accid: accid,
      listtype: "0",
    };
    dispatch(
      actionReqGetAuditorListSagaAction({
        model: model,
        callback: getAuditorListResp,
      })
    );
  };

  // const getAccountsList = () => {
  //   dispatch(actionReqAdminAccounts({ callback: getAccountListRes }));
  // };

  // const getAccountListRes = (data) => {
  //   let list = [];
  //   if (data?.accounts && data?.accounts?.length > 0) {
  //     list = data.accounts?.map((item) => ({
  //       value: item?.accid,
  //       label: item?.accountname,
  //     }));
  //   }
  //   setAccountList(list);
  // };

  const getAuditorListResp = (respData) => {
    if (respData) {
      let auditorActiveList = respData?.auditorlist?.filter(
        (item) => item?.status === AgentActiveStatuses.ACTIVE
      );
      setGetAuditorList(auditorActiveList);
      setGetAuditorListOriginal(auditorActiveList)
    }
    // console.log(getAgentList,"getAgentList")
  };
  const applyAuditLeave = useNavigate();

  const addAuditorData = (stepName, model) => {
    setAddAuditor(stepName);
    // setAgentBody((prevState) => ({ ...prevState, model }));
    setAuditorBody(model);
  };

  const applyAuditorLeave = (auditor) => {
    navigate("/dashboard/applyAuditLeave", { state: { name: auditor } });
  };

  const createAuditor = (stepName, model) => {
    setIsLoading(true);
    setIsDisabled(true);
    let copyAuditModel = { ...auditorBody, ...model };
    dispatch(
      actionReqGetCreateAuditor({
        model: copyAuditModel,
        callback: createAuditorRespData,
      })
    );

    //   }else {
    //     toast.error('Please check all inputs')
    // }
  };

  const createAuditorRespData = (data) => {
    if (data.respcode == "200") {
      setAddAuditor("");
      // toast.success(data.respdesc);
      getAuditorUserList(AccountSwap);
    } else {
      setIsLoading(false);
      setIsDisabled(false);
      // toast.error(data.respdesc);
    }
  };

  const auditorSearchBar = (val) => {
    // setAgentSearch(val)
    let copyData = [...getAuditorListOriginal];
    const updtedSearh = copyData.filter((item) => {
      return item?.auditor_name?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.auditor_employeeid?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1

    })
    setGetAuditorList(updtedSearh);
  }

  const columns = [
    {
      name: "Auditor Profile",
      selector: (row) => (
        <>
          <img src=
            {row.auditor_pht.length >= 0
              ? row.auditor_pht
              : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
            } className="cus_table_imgs"/>
        </>
      ),
      sortable: true,
    },
    {
      name: "Auditor Name",
      selector: (row) => (
        <strong style={{ fontSize: "16px" }}>{row.auditor_name}</strong>
      ),
      sortable: true,
    },
    {
      name: "Auditor ID",
      selector: (row) => row["auditor_employeeid"],
      sortable: true,
    },
    {
      name: "Login Time",
      selector: (row) => (
        <h6 className="fg2" style={{ "fontSize": "14px", "fontWeight": "600" }}>{row["firstlogin"] ? moment(row.firstlogin).format("lll") : "-"}</h6>
      ),
      sortable: true,
    },
    {
      name: "Logout Time",
      selector: (row) => (
        <h6 className="fg2" style={{ "fontSize": "14px", "fontWeight": "600" }}>{row["lastlogout"] ? moment(row.lastlogout).format("lll") : "-"}</h6>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => <button onClick={() => applyAuditorLeave(row)} className="cus_view">View</button>,
      sortable: true,
    },
  ]

  return (
    <>
      <div className="main-loader">
        <LoaderClr dispname="Auditors are loading..."/>
      </div>
      <MainStyle className="mainContainer">
        <HeaderFilters
          heading={{ name: "Total Auditors :", count: getAuditorList?.length }}
          actionBtns={headerActions}
          getAuditorList={getAuditorList}
          change={(e) => auditorSearchBar(e.target.value)}
        />
        <div className="listContainer">
          {getAuditorList?.length >= 0 ?
            // getAuditorList?.map((auditor) =>
            //   auditor?.status == "1" ? (
            <DataTable
              columns={columns}
              data={getAuditorList}
              pagination
            />
            // )
            //   <div
            //     className="card"
            //     key={uuid()}
            //     onClick={() => applyAuditorLeave(auditor)}
            //   >
            //     <CardNode
            //       img={
            //         auditor?.auditor_pht.length >= 10
            //           ? "data:image/png;base64," + auditor?.auditor_pht
            //           : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
            //       }
            //       name={auditor.auditor_name ? auditor.auditor_name : "N/A"}
            //       id={auditor.auditor_employeeid ? auditor.auditor_employeeid : "N/A"}
            //       flogin={auditor?.firstlogin ? moment(auditor?.firstlogin).format("lll") : "N/A"}
            //       lastlogout={auditor?.lastlogout ? moment(auditor?.lastlogout).format("lll") : "N/A" }
            //     />
            //   </div>
            // )
            // : (
            //   ""
            // )

            :
            <NoRecordFoundStyle >
              <div className="norecord" style={{ marginTop: "6%" }}>
                {/* <NoAgentFound headings={true} /> */}
                <div className="centered">
                  <img src={Manager} alt="document image" />
                  <h6 className="fd5">No auditor Found</h6>
                  <p>We couldn’t find any auditors here.</p>
                </div>
              </div>
            </NoRecordFoundStyle>
          }
        </div>
        {/* <div className="listContainer">
        {response.auditors.map(agent => <div className="card" key={uuid()} ><CardNode 
        img={agent.img} name={agent.name} id={agent.id} /></div> )}
      </div> */}
        <button className="add" onClick={() => setAddAuditor("addAgent")}>
          {" "}
          <img src={AddUserWhite} alt="add Agent" />{" "}
        </button>
      </MainStyle>

      {addAuditor === "addAgent" && (
        <Popup
          heading="Add Auditor"
          body={
            <AddAuditor accountList={accid1} func={createAuditor} isLoading={isLoading} disabled={isdisabled} />
            // <AddAuditor
            //   func={(stateName) => {
            //     setAddAuditor(stateName);
            //     applyAuditLeave("/dashboard/applyAuditLeave");
            //   }}
            // />
          }
          close={() => setAddAuditor("")}
          className="px860"
        />
      )}
      {addAuditor === "additionalInfo" && (
        <Popup
          heading="Add Auditor"
          body={<AddAditionalInfo func={createAuditor} />}
          // body={<AddAditionalInfo  func={stateName => {
          //   setAddAuditor(stateName)
          //   // applyAuditLeave('/dashboard/applyLeave')
          // }} />}
          close={() => setAddAuditor("")}
          className="px860"
        />
      )}
    </>
  );
}

const MainStyle = styled.div`
.cus_view{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(269.82deg, #38A1F7 0.1%, #38568F 99.85%);
  border-radius: 5px;
  // font-family: 'ProximaNova-semibold';
  font-size: 14px;
  text-align: center;
  padding: 6px 20px;
  color: #FFFFFF;
  position: relative;
  z-index: 0;
  border: none
}
.cus_table_imgs{
  width:50px;
  height:50px;
  padding: 3px;
  border-radius: 60px;
}
  background: var(--white);
  &.mainContainer {
    box-shadow: var(--cardShadow);
    padding: 20px 24px 0;
    height: calc(100vh - 120px);
    overflow: auto;
    position: relative;
  }
  .listContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    justify-content: center;
    width: 100%;
  }
  .card {
    border: var(--border);
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    transition: transform 0.2s linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.03);
    }
  }
  .add {
    background: var(--fg3);
    padding: 22px 20px;
    border-radius: 50%;
    border: none;
    position: fixed;
    bottom: 50px;
    right: 50px;
  }
`;
const NoRecordFoundStyle = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
    .centered {
        img {}
        h2 {
            font-weight: 700;
            font-size: 32px;
            color: #333333;
        }
        p {
            font-weight: 400;
font-size: 22px;
line-height: 26px;
text-align: center;

color: #6E6E6F;
        }
    }
`;
