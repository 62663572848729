import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
// import Axios from '../../Service/axios'
import Axios from "../../Service/axios";
import { actionReqResStatusLoaderClrSagaAction } from "../SagasActions/AuthSagaActions";
import { actionReqResStatusLoaderSagaAction, getAccountListreducerAction } from "../SagasActions/DashboardOverviewSagaAction";
import {
  ACTION_GET_ADMIN_DASHBOARD_COUNT_REQ,
  ACTION_POST_ADMIN_ACCOUNTS,
  ACTION_POST_ADMIN_DASHBOARD_CREATE_AGENT,
  ACTION_POST_ADMIN_DASHBOARD_DELETE_AGENT,
  ACTION_GET_REPORT_MASTER_REQ,
  ACTION_POST_ADMIN_DASHBOARD_ACCOUNT_CONFIGURE_LIST,
  ACTION_POST_ADMIN_DASHBOARD_CONFIGURE_ACCOUNT,
  ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ,
  SERVICE_WISE_TXN_COUNT,
} from "../SagasActions/SagaActionTypes";

const GetAdminDashboardCountReq = ({ model, endpoint }) => {
  const URL = endpoint;
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* GetAdminDashboardCountReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(GetAdminDashboardCountReq, action?.payload?.model);
    if (resp) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getAccountReq = (model) => {
  const URL = "GetAccounts";
  return Axios.post(URL).then((res) => {
    return res?.data;
  });
};

function* getAccountsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getAccountReq, action?.payload);
    if (resp && resp?.respcode === "200") {
      yield put(getAccountListreducerAction(resp));
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);

      }
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const createAgentReq = (model) => {
  const URL = "CreateAgent";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* createAgentReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(createAgentReq, action?.payload?.model);

  try {
    // toast.success(resp?.respdesc);
    // if (action?.payload?.callback) {
    //     action?.payload?.callback(resp);
    // }
    if (resp && resp?.respcode === "200") {
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const deleteAgentReq = (model) => {
  const URL = "DeleteAgent";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* deleteAgentReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(deleteAgentReq, action?.payload?.model);

  try {
    // toast.success(resp?.respdesc);
    // if (action?.payload?.callback) {
    //     action?.payload?.callback(resp);
    // }
    if (resp && resp?.respcode === "200") {
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getReportMasterReq = (model) => {
  const URL = "GetReportMaster";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getReportMasterReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(getReportMasterReq, action?.payload?.model);

  try {
    // toast.success(resp?.respdesc);
    // if (action?.payload?.callback) {
    //     action?.payload?.callback(resp);
    // }
    if (resp && resp?.respcode === "200") {
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// const getAccountConfigurationReq = (model) => {
//     const URL = "GetAccountConfigurtion";
//     return Axios.post(URL, model).then(res => { return res?.data })
// }

// function* getAccountConfigurationReqSaga(action) {
//     yield put(actionReqResStatusLoaderSagaAction(true));
//     const resp = yield call(getAccountConfigurationReq, action?.payload?.model);

//     try {

//         // toast.success(resp?.respdesc);
//         // if (action?.payload?.callback) {
//         //     action?.payload?.callback(resp);
//         // }
//         if (resp && resp?.respcode === "200") {

//             toast.success(resp?.respdesc);
//             if (action?.payload?.callback) {
//                 action?.payload?.callback(resp);
//             }
//         } else {
//             toast.error(resp?.respdesc);
//             if (action?.payload?.callback) {
//                 action?.payload?.callback(resp);
//             }

//         }
//     } catch (err) {
//         if (resp.authflag === "1") {
//             // $('#login').modal('hide');
//         }
//         if (err.response) {
//             toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
//         } else {
//             toast.error(err.message);
//         }
//     } finally {
//         yield put(actionReqResStatusLoaderSagaAction(false));
//     }
// }

const getConfigureAccounReq = (model) => {
  const URL = "ConfigureAccount";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getConfigureAccountReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(getConfigureAccounReq, action?.payload?.model);
  try {
    if (resp && resp?.respcode === "200") {
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// GET VCIP ID details

const getVcipDetailsReq = (body) => {
  const URL = "GetVCIPIDDetailsV2";
  return Axios.post(URL, body).then((res) => res?.data);
};

function* getVcipDetialsReqSaga(action) {
  yield put(actionReqResStatusLoaderClrSagaAction(true));

  try {
    const resp = yield call(getVcipDetailsReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      action?.payload?.callback(resp, action?.payload?.model.type);
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}


// GetServiceWiseTxnCount

const getServiceWiseTxnCount = (body) => {
  const URL = "GetServiceWiseTxnCount";
  return Axios.post(URL, body).then((res) => res?.data);
};

function* getServiceWiseTxnReqSaga(action) {
  yield put(actionReqResStatusLoaderClrSagaAction(true));

  try {
    const resp = yield call(getServiceWiseTxnCount, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      action?.payload?.callback(resp, action?.payload?.model.type);
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}



export default function* DashboardWatcherSaga() {
  yield takeLatest(
    ACTION_GET_ADMIN_DASHBOARD_COUNT_REQ,
    GetAdminDashboardCountReqSaga
  );
  yield takeLatest(ACTION_POST_ADMIN_ACCOUNTS, getAccountsReqSaga);
  yield takeLatest(
    ACTION_POST_ADMIN_DASHBOARD_CREATE_AGENT,
    createAgentReqSaga
  );
  yield takeLatest(
    ACTION_POST_ADMIN_DASHBOARD_DELETE_AGENT,
    deleteAgentReqSaga
  );
  yield takeLatest(ACTION_GET_REPORT_MASTER_REQ, getReportMasterReqSaga);
  // yield takeLatest(ACTION_POST_ADMIN_DASHBOARD_ACCOUNT_CONFIGURE_LIST, getAccountConfigurationReqSaga);
  yield takeLatest(
    ACTION_POST_ADMIN_DASHBOARD_CONFIGURE_ACCOUNT,
    getConfigureAccountReqSaga
  );
  yield takeLatest(ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ, getVcipDetialsReqSaga)
  yield takeEvery(SERVICE_WISE_TXN_COUNT, getServiceWiseTxnReqSaga)
  // yield takeLatest(
  //   ACTION_SETUP_GLOBAL_ACCOUNT,
  //   getConfigureAccountReqSaga
  // );
}
