import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import {
  BoxMenu,
  Headset,
  UserSelect,
  MultiUserWhite,
  Settings,
  FileSearch,
  FileIcon,
  LockWhite,
  CircleDirect,
  DownArrowWhite,
  Lock,
  Eye,
  Eye_close,
  ProteanFavi,
  ProteanLogo,
  Transaction,
  DocBundleGrad,
  queueManagementIcon,
} from "../assets/ImageUrl";
import "react-pro-sidebar/dist/css/styles.css";
import shortLogo from "../assets/imgs/shortLogo.svg";
import logoName from "../assets/imgs/logoName.svg";
import { PopupBody, Button, Text } from "./Elements";
import { useNavigate } from "react-router";
import Popup from "./Popup";
import { useDispatch } from "react-redux";
import {
  UserLogoutSagaAction,
  changePasswordSagaAction,
} from "../Store/SagasActions/AuthSagaActions";
import toast from "react-hot-toast";
import base64 from "base-64";
import ReactTooltip from "react-tooltip";
import { actionReqGetLanguages } from "../Store/SagasActions/AgentSagaAction";
import { useEffect } from "react";
import { globalAccountSetup } from "../Store/SagasActions/DashboardOverviewSagaAction";
import {
  minOrMaxLength,
  validateIsNumAndAlp,
  validateIsRequired,
} from "../utils/Validations";
import QualityRoles from "../Constants/Roles";
export default function AsideBar() {
  const [popup, setPopup] = useState("");
  const [navActive, setNavActive] = useState("dashboard");
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [lang, setLang] = useState("");
  const userEmail = sessionStorage.getItem("user_email");
  console.log(userEmail, "mail");

  // Env Protean
  const Logo = process.env.REACT_APP_PROTEAN;
  const NIC = process.env.REACT_APP_NIC_CLIENT;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Local storage
  const roleName = sessionStorage.getItem("role");

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const shouldLogout = {
    disc: "Are you sure you want to Logout?",
    btn1: "Cancel",
    btn1Func: () => setPopup(""),
    btn2: "Logout",
    btn2Func: () => {
      setPopup("");
      dispatch(UserLogoutSagaAction({ callback: logoutUserResponse }));
      // navigate("/")
    },
  };

  const logoutUserResponse = (data) => {
    dispatch(globalAccountSetup(""));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const ChangePopup = () => {
    const [changepasswordObj, setChangePassWordObj] = useState({
      currentPsw: "",
      newPsw: "",
      reEnterNewPsw: "",
    });
    const [changePwdErrors, setChangePwdErrors] = useState({
      newPsw: "",
      reEnterNewPsw: "",
    });
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (value) {
        const isMin = minOrMaxLength(value, "minlength", 8);
        const alpha = validateIsNumAndAlp(value);
        setChangePwdErrors((prevState) => ({
          ...prevState,
          [name]: isMin || alpha,
        }));
      } else {
        const isEmpty = validateIsRequired(value);
        setChangePwdErrors((prevState) => ({ ...prevState, [name]: isEmpty }));
      }
      // setChangePassWordObj({ ...changepasswordObj, [name]: value });
      setChangePassWordObj((prevState) => ({ ...prevState, [name]: value }));
    };

    const changepassword = (e) => {
      e.preventDefault();
      // if (loginObj.username && loginObj.password && roleObj?.rid && roleObj?.rolename) {
      if (changepasswordObj.currentPsw && changepasswordObj.newPsw) {
        const encodePwd = base64.encode(changepasswordObj.currentPsw);
        const encodePwd1 = base64.encode(changepasswordObj.newPsw);

        const model = {
          current_pwd: encodePwd,
          new_pwd: encodePwd1,
        };
        dispatch(
          changePasswordSagaAction({
            model: model,
            callback: changepasswordRespData,
          })
        );
      } else {
        toast.error("Please check all inputs");
      }
    };

    const changepasswordRespData = (data) => {
      // setIsLoading(isLoading);

      if (data.authflag === "1") {
        // $('#login').modal('hide');
      }
      if (data.respcode == "200") {
        setPopup("");
        sessionStorage.setItem("username", data?.username);
      } else if (data.respcode == "4003") {
        toast.error(data.respdesc);
        // this.props.history.replace("/");
        navigate("/");
      } else if (data.respcode == "468") {
        this.props.history.replace("/");
        // toast.error(data.respdesc)
      } else if (data.respcode == "4002") {
        this.props.history.replace("/");

        // naviage(RouteNames.LOGIN);
        toast.error(data.respdesc);
      } else {
        toast.error(data.respdesc);
      }
    };

    return (
      <ChangePopupStyle>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="elementContainer">
            <Text
              name="currentPsw"
              type="password"
              placeholder="Current Password"
              icons={{
                left: { src: Lock, alt: "lock icon" },
                right: {
                  src: Eye,
                  src2: Eye_close,
                  iconClass: "eye",
                  alt: "eye icon",
                },
              }}
              change={handleChange}
            />
          </div>
          <div className="elementContainer">
            <Text
              name="newPsw"
              type="password"
              placeholder="New Password"
              icons={{
                left: { src: Lock, alt: "lock icon" },
                right: {
                  src: Eye,
                  src2: Eye_close,
                  iconClass: "eye",
                  alt: "eye icon",
                },
              }}
              change={handleChange}
            />
            <p className="error_msg">{changePwdErrors?.newPsw}</p>
          </div>
          <div className="elementContainer">
            <Text
              name="reEnterNewPsw"
              type="password"
              placeholder="Confirm Password"
              icons={{
                left: { src: Lock, alt: "lock icon" },
                right: {
                  src: Eye,
                  src2: Eye_close,
                  iconClass: "eye",
                  alt: "eye icon",
                },
              }}
              change={handleChange}
            />
            <p className="error_msg">{changePwdErrors?.reEnterNewPsw}</p>
          </div>
          <div className="btnContainer">
            <Button
              name="updatePassword"
              dispName="Change"
              click={changepassword}
            />
          </div>
        </form>
      </ChangePopupStyle>
    );
  };

  const handleSelectLang = () => {
    const model = {
      vcipkey: " ",
    };
    dispatch(actionReqGetLanguages({ model: model, callback: getResp }));
  };

  useEffect(() => {
    handleSelectLang();
  }, []);

  const getResp = (data) => {
    setLang(data?.languages);
  };

  return (
    <>
      <AsideBarStyle>
        {roleName === QualityRoles.QA ? (
          <ProSidebar collapsed={menuCollapse}>
            <SidebarHeader>
              {/* <h1 onClick={()=> menuIconClick()}>expand</h1> */}
              <div className="logoContainer">
                <img src={shortLogo} alt="short logo" />
                {!menuCollapse && <img src={logoName} alt="syntizen name" />}
                <div
                  className={`clickArrow ${menuCollapse && "turn"}`}
                  onClick={() => menuIconClick()}
                >
                  <img src={DownArrowWhite} alt="turn arrow" />
                </div>
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <NavLink
                  to="/qualitycheck/vciplist"
                  className={(navData) =>
                    navData.isActive ? "active-main" : ""
                  }
                >
                  <MenuItem
                    onClick={() => setNavActive("customers")}
                    icon={<img src={MultiUserWhite} alt="menu" />}
                  >
                    <h6 className="fl3">
                      {NIC === "1" ? "Pensioners List" : "VCIP List"}
                    </h6>
                  </MenuItem>
                </NavLink>
              </Menu>
            </SidebarContent>
            <SidebarFooter>
              <Menu iconShape="square">
                <MenuItem
                  icon={<img src={LockWhite} alt="menu" />}
                  onClick={() => setPopup("changePassword")}
                >
                  <h6 className="fl3">Password </h6>
                </MenuItem>
                <MenuItem
                  icon={<img src={CircleDirect} alt="menu" />}
                  onClick={() => setPopup("logout")}
                >
                  {" "}
                  <h6 className="fl3">Logout</h6>
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </ProSidebar>
        ) : roleName ? (
          <ProSidebar collapsed={menuCollapse}>
            <SidebarHeader>
              {/* <h1 onClick={()=> menuIconClick()}>expand</h1> */}
              <div className="logoContainer">
                {Logo ? (
                  <img src={ProteanFavi} width={30} height={30} />
                ) : (
                  <img src={shortLogo} alt="short logo" />
                )}
                {!menuCollapse &&
                  (Logo ? (
                    <p style={{ fontSize: "24px" }}>PROTEAN</p>
                  ) : (
                    <img src={logoName} alt="syntizen name" />
                  ))}
                <div
                  className={`clickArrow ${menuCollapse && "turn"}`}
                  onClick={() => menuIconClick()}
                >
                  <img src={DownArrowWhite} alt="turn arrow" />
                </div>
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">
                <NavLink
                  to="/dashboard/overview"
                  className={(navData) =>
                    navData.isActive ? "active-main" : ""
                  }
                >
                  <MenuItem
                    onClick={() => setNavActive("dashboard")}
                    icon={<img src={BoxMenu} alt="menu" />}
                  >
                    <h6 className="fl3">Dashboard</h6>
                  </MenuItem>
                </NavLink>
                <NavLink
                  to="/dashboard/agent"
                  className={(navData) =>
                    navData.isActive ? "active-main" : ""
                  }
                >
                  <MenuItem
                    onClick={() => setNavActive("agent" && handleSelectLang())}
                    icon={<img src={Headset} alt="menu" />}
                  >
                    {" "}
                    <h6 className="fl3">Agent</h6>{" "}
                  </MenuItem>
                </NavLink>
                <NavLink
                  to="/dashboard/auditor"
                  className={(navData) =>
                    navData.isActive ? "active-main" : ""
                  }
                >
                  <MenuItem
                    onClick={() =>
                      setNavActive("auditor" && handleSelectLang())
                    }
                    icon={<img src={UserSelect} alt="menu" />}
                  >
                    <h6 className="fl3">Auditor </h6>
                  </MenuItem>
                </NavLink>
                <NavLink
                  to="/dashboard/customers/queue"
                  className={(navData) =>
                    navData.isActive ? "active-main" : ""
                  }
                >
                  <MenuItem
                    onClick={() => setNavActive("customers")}
                    icon={<img src={MultiUserWhite} alt="menu" />}
                  >
                    <h6 className="fl3">Customers </h6>
                  </MenuItem>
                </NavLink>
                {/* <NavLink to="/dashboard/configure"> */}

                {/* </NavLink> */}

                {/* Audit */}
                {/* <NavLink to="/dashboard/audit"
                className={(navData) => (navData.isActive ? "active-main" : "")}
              > */}
                {/* <MenuItem
                // active={navActive === "audit"}
                // onClick={() => setNavActive("audit")}
                icon={<img src={FileSearch} alt="menu" />}
                data-tip
                data-for=""
                disabled
              >
                <h6 className="fl3" onClick={openAudit}>Audit Trail Logs</h6>
                {arrowDown == true ? <img src={DownArrowWhite} className="rotateArrow" alt="arrow" /> :
                  <img src={DownArrowWhite} alt="arrow" />}
              </MenuItem> */}


                <SubMenu
                  title={<h6 className="fl3">{QualityRoles.OPS ? "Operations Tools" : "Audit Trail Logs"}</h6>}
                  icon={<img src={FileSearch} alt="menu" />}
                >
                  <NavLink
                    to="/dashboard/operationsDashboard"
                    className={(navData) =>
                      navData.isActive ? "active-main" : ""
                    }
                  >
                    <MenuItem
                      onClick={() => {
                        console.log("ops dashboard")
                      }}
                      icon={<img src={BoxMenu} alt="menu" />}
                    >
                      <h6 className="fl3">Operations Dashboard</h6>
                    </MenuItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/vcip-roll-back"
                    className={(navData) =>
                      navData.isActive ? "active-main" : ""
                    }
                  >
                    <MenuItem
                      onClick={() => {
                        console.log("vcip-roll-back")
                      }}
                      icon={<img src={BoxMenu} alt="menu" />}
                    >
                      <h6 className="fl3">VcipRoll back</h6>
                    </MenuItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/service-incidents"
                    className={(navData) =>
                      navData.isActive ? "active-main" : ""
                    }
                  >
                    <MenuItem
                      onClick={() => {
                        console.log("vcip-roll-back")
                      }}
                      icon={<img src={BoxMenu} alt="menu" />}
                    >
                      <h6 className="fl3">Service Incidents</h6>
                    </MenuItem>
                  </NavLink>

                  <MenuItem
                    icon={<img src={FileSearch} alt="menu" />}
                    data-for="registerTip"
                    data-tip
                  >
                    VCIP Audit
                  </MenuItem>
                  <ReactTooltip id="registerTip" place="top" effect="solid">Coming Soon</ReactTooltip>
                  <MenuItem
                    icon={<img src={FileSearch} alt="menu" />}
                    data-for="registerTip"
                    data-tip
                  >
                    Managerial Audit
                  </MenuItem>
                  <ReactTooltip id="registerTip" place="top" effect="solid">Coming Soon</ReactTooltip>
                  <MenuItem
                    // active={navActive === "configure"}
                    onClick={() => setNavActive("configure")}
                    icon={<img src={Settings} alt="menu" />}
                    data-tip
                    data-for="registerTip"
                  >
                    <h6 className="fl3">Configure </h6>
                  </MenuItem>
                  <ReactTooltip id="registerTip" place="top" effect="solid">Coming Soon</ReactTooltip>
                </SubMenu>
                {/* Audit stop*/}

                <NavLink
                  to="/dashboard/report"
                  className={(navData) =>
                    navData.isActive ? "active-main" : ""
                  }
                >
                  <MenuItem
                    onClick={() => setNavActive("report")}
                    icon={<img src={FileIcon} alt="menu" />}
                    data-for="Reports"
                    data-tip
                    disabled
                  >
                    <h6 className="fl3">Reports</h6>
                  </MenuItem>
                </NavLink>
                {roleName === QualityRoles.SA && (
                  <NavLink
                    to="/dashboard/transaction"
                    className={(navData) =>
                      navData.isActive ? "active-main" : ""
                    }
                  >
                    <MenuItem
                      onClick={() => setNavActive("transaction")}
                      icon={<img src={Transaction} alt="menu" />}
                      data-for="transaction"
                      data-tip
                      disabled
                    >
                      <h6 className="fl3">Transactions</h6>
                    </MenuItem>
                  </NavLink>
                )}
                {roleName === QualityRoles.SA &&
                  userEmail &&
                  userEmail.includes("syntizen") && (
                    <NavLink
                      to="/dashboard/q-management"
                      className={(navData) =>
                        navData.isActive ? "active-main" : ""
                      }
                    >
                      <MenuItem
                        onClick={() => setNavActive("q-management")}
                        icon={<img src={queueManagementIcon} alt="queue" />}
                        data-for="q-management"
                        data-tip
                        disabled
                      >
                        <h6 className="fl3">Q’ Management</h6>
                      </MenuItem>
                    </NavLink>
                  )}
              </Menu>
            </SidebarContent>
            <SidebarFooter>
              <Menu iconShape="square">
                <MenuItem
                  icon={<img src={LockWhite} alt="menu" />}
                  onClick={() => setPopup("changePassword")}
                >
                  <h6 className="fl3">Password </h6>
                </MenuItem>
                <MenuItem
                  icon={<img src={CircleDirect} alt="menu" />}
                  onClick={() => setPopup("logout")}
                >
                  {" "}
                  <h6 className="fl3">Logout</h6>
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </ProSidebar>
        ) : null}
      </AsideBarStyle>

      {popup === "logout" && (
        <Popup
          heading="Logout"
          body={<PopupBody comp={shouldLogout} />}
          close={() => setPopup("")}
          className="px500"
        />
      )}
      {popup === "changePassword" && (
        <Popup
          heading="Change Password"
          body={<ChangePopup />}
          close={() => setPopup("")}
          className="px428"
        />
      )}
    </>
  );
}

const AsideBarStyle = styled.div`
  position: relative;
  .pro-sidebar {
    height: 100vh;
  }

  .pro-sidebar {
    width: 100%;
    min-width: 100%;
    background: transparent;
    transition: none;
  }
  .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  .pro-sidebar-inner {
    background: var(--bgGradientTB);
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    margin: 10px 0px;
    font-weight: bold;
  }
  .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    border-radius: 3px;
  }
  .pro-itemContent h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .inner-submenu-arrows .active-main .pro-menu-item {
    background: var(--menuHover);
  }
  // .pro-sidebar-inner .pro-sidebar-layout .active {
  //   background: var(--menuHover);
  // }
  .logo {
    padding: 20px;
  }
  .pro-sidebar .pro-menu a:before {
    display: none;
  }
  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background: transparent;
  }
  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    border-color: white;
  }
  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item {
    padding: 5px 30px 8px 1px;
  }
  .pro-sidebar .pro-menu {
    padding: 0;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 0;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border: none;
    position: relative;
    &:before {
      content: "";
      width: 80%;
      height: 1px;
      background: var(--menuHover);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 80px;
    position: relative;
    .clickArrow {
      position: absolute;
      right: 5%;
      bottom: 50%;
      transform: translateY(10px);
      transition: all 0.2s linear;
      &.turn {
        transform: translateY(10px) rotate(90deg);
      }
    }
  }
  .arrow_audit {
    display: flex;
    position: absolute;
    top: 17px;
    img {
      margin-left: 10%;
    }
    .rotateArrow {
      transform: rotate(180deg);
    }
  }
  .audit_down {
    background-color: rgba(39, 39, 39, 0.726);
  }
`;

const ChangePopupStyle = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .elementContainer {
    p {
      margin-top: 10px;
    }
    .error_msg {
      text-align: center;
      color: red;
      font-size: 14px;
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    button {
      font-size: 20px;
      padding: 12px;
      width: 75%;
    }
  }
`;
