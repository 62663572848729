import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import HeaderFilters from "../../Components/HeaderFilters";
import {
  headerActions,
  CallHistoryTabs,
  WebLinkCopy,
  CarouselElement,
} from "../../Components/CustomersSnippets";
import AllCallHistory from "../../Components/AllCallHistory";
import Popup from "../../Components/Popup";
import WaitingCustomerQueue from "../../Components/WaitingCustomerQueue";
import {
  actionGetVcipDetails,
  getCallHistorySagaAction,
  getSingleReferenceCallHistorySagaAction,
} from "../../Store/SagasActions/DashboardOverviewSagaAction";
import { vcipRollBackSagaAction } from '../../Store/SagasActions/OperationsActions/OperationsSagaAction';
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import LoaderClr from "../../Components/LoaderClr";
import {
  CalanderGrad,
  OrderGrad,
  ProfileDummy,
  RightTick,
  SearchGrad,
  WrongTick,
} from "../../assets/ImageUrl";
import moment from "moment";
import { toast } from "react-hot-toast";
import RollBackOptions from "../../Constants/RollBackOptions";
import VcipIdStatusOptions from '../../Constants/VcipIdStatusOptions';

// let customDateRange;
export default function CallHistory() {
  const location = useLocation();
  const [pop, setPop] = useState("");
  const [img, setImg] = useState("");
  const [callHistoryTab, setCallHistoryTab] = useState("all");
  const [refCallHistory, setRefCallHistory] = useState([]);
  const [refValues, setRefValues] = useState([]);
  const [refSearch, setRefSearch] = useState("");
  const [rollBackRemarks, setRollBackRemarks] = useState("");
  const [rollBackStage, setRollBackStage] = useState("");
  const [showCurrentVcipStatus, setShowCurrentVcipStatus] = useState("");
  // const [showDate, setShowDate] = useState(false)
  // const [dateRangeBtn, setDateRangeBtn] = useState("Today");
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 0),
  //     key: "selection",
  //   },
  // ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accid3 = useSelector(
    (state) => state.DashboardReducer?.accountaccid?.accounts
  );
  const roleID = sessionStorage.getItem("role");

  const account = useSelector((state) => state?.DashboardReducer.accid);

  const globalAccountId = useSelector(
    (state) => state?.GlobalRecuder.globalAccountId
  );
  const AccountSwap =
    roleID === "Account Admin" || roleID === "Operations" ? sessionStorage.getItem("accid") : account;

  const [viewdet, setViewDet] = useState("");
  const [singleCustomerDetails, setSingleCustomerDetails] = useState({});
  const [num, setNum] = useState(1);
  const [searchedText, setSearchedText] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [inputError, setInputError] = useState();

  // clearing Search & Dropdown on single VCIP Search
  useEffect(() => {
    setRefValues("");
    setRefSearch("");
    setRefCallHistory("")
  }, [location]);

  const getCallHistoryList = () => {
    const model = {
      accid: AccountSwap,
      listtype: "1",
      fdate: "",
      tdate: "",
    };
    dispatch(getCallHistorySagaAction({ model: model, callback: getLiveData }));
  };

  const getLiveData = (data) => { };

  // pop data calling GetVCIPIDDetails
  const getVcipUserDetails = (type, data) => {
    setSingleCustomerDetails(data);
    // if (data.vcipid !== viewdet.vcipid) {
    //   const model = {
    //     vcipkey: data?.vcipkey,
    //     type: type,
    //     vtid: data.vtid
    //   };
    //   dispatch(
    //     actionGetVcipDetails({ model: model, callback: getEachVcipDetails })
    //   );
    // } else {
    //   setPop(type);
    // }
    if (data.vcipid == viewdet.vcipid && type !== "viewReport") {
      setPop(type);
    } else {
      const model = {
        vcipkey: data?.vcipkey,
        type: type,
        vtid: data.vtid,
      };
      dispatch(
        actionGetVcipDetails({ model: model, callback: getEachVcipDetails })
      );
      setPop(type);
    }
  };

  const getEachVcipDetails = (data, typeid) => {
    setViewDet(data);
    setPop(typeid);
  };

  // View Details popup
  const CustomerDetails = () => {
    return (
      <>
        {/* <div className="main-loader">
          <LoaderClr />
        </div> */}
        <Cus_details>
          <div className="cus-customerDetails">
            <p>
              Application Type{" "}
              <strong style={{ color: "black" }}>
                {viewdet?.customerdetails?.apptype
                  ? viewdet?.customerdetails?.apptype
                  : "N/A"}
              </strong>
            </p>
            <p>
              Customer ID{" "}
              <strong style={{ color: "black" }}>
                {viewdet?.customerdetails?.customerid
                  ? viewdet?.customerdetails?.customerid
                  : "N/A"}
              </strong>
            </p>
            <p>
              Application ID{" "}
              <strong style={{ color: "black" }}>
                {viewdet?.customerdetails?.appid
                  ? viewdet?.customerdetails?.appid
                  : "N/A"}
              </strong>
            </p>
          </div>
          <div className="cus-TwoDetails">
            <div className="cus-personal ml-0 mb-0">
              <h4 className="fd6">Personal Details</h4>
              <hr></hr>
              <table className="table-w">
                <tbody>
                  <tr>
                    <td>Full Name: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.name
                          ? viewdet?.customerdetails?.name
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Birth: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.dob
                          ? viewdet?.customerdetails?.dob
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Gender: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.gender
                          ? viewdet?.customerdetails?.gender
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile Number: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.mobile
                          ? viewdet?.customerdetails?.mobile
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Email Address: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.email
                          ? viewdet?.customerdetails?.email
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Current Address: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.curr_address
                          ? viewdet?.customerdetails?.curr_address
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Permanent Address:</td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.customerdetails?.per_address
                          ? viewdet?.customerdetails?.per_address
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cus-personal mr-0 mb-0">
              <h4 className="fd6">
                {isKinaBank ? "As per Database" : "As per Aadhaar"}
              </h4>
              <hr></hr>
              <table className="table-w">
                <tbody>
                  <tr>
                    <td>Full Name: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.kycdetails?.name
                          ? viewdet?.kycdetails?.name
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Birth: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.kycdetails?.dob
                          ? viewdet?.kycdetails?.dob
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Gender: </td>
                    <td>
                      <strong style={{ color: "black" }}>
                        {viewdet?.kycdetails?.gender
                          ? viewdet?.kycdetails?.gender
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "baseline" }}>Address: </td>
                    <td style={{ wordBreak: "break-all" }}>
                      <strong style={{ color: "black" }}>
                        {viewdet?.kycdetails?.address
                          ? viewdet?.kycdetails?.address
                          : "N/A"}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Cus_details>
      </>
    );
  };

  // Activity Log
  const ActivityLog = () => {
    return <></>;
  };

  // ViewReport ---------------------> Code
  const FuzzyMatch = ({ value }) => {
    let floatValue = parseFloat(value) > 50;
    return floatValue ? (
      <span className="text-success">
        {value == 100 ? Number.parseInt(value) : parseFloat(value).toFixed(2)}%
      </span>
    ) : (
      <span className="text-danger">
        {value == 100 ? Number.parseInt(value) : parseFloat(value).toFixed(2)}%
      </span>
    );
  };

  const cstmrName = viewdet?.customerdetails?.name;
  const cstmrFname = viewdet?.customerdetails?.fname;
  const cstmrDOB = viewdet?.customerdetails?.dob;
  const cstmrGender = viewdet?.customerdetails?.gender;
  const cstmrAddr = viewdet?.customerdetails?.curr_address;
  const cstmrPrmntAddr = viewdet?.customerdetails?.per_address;
  const cstmrMobile = viewdet?.customerdetails?.mobile;
  const cstmrEmail = viewdet?.customerdetails?.email;
  const cstmrAccName = viewdet?.customerdetails?.accname;
  const ipaddress = viewdet?.customerdetails?.ipaddress;
  const geo_location = viewdet?.customerdetails?.geo_location;
  const browser_name = viewdet?.customerdetails?.browserdetails.split(",");
  const custmr_pannumber = viewdet?.customerdetails?.pannumber;

  const vciptemplate = viewdet?.vciptemplate;
  const vcipid = viewdet?.vcipid;

  const kycName = viewdet?.kycdetails?.name;
  const kycFname = viewdet?.kycdetails?.fname;
  const kycDOB = viewdet?.kycdetails?.dob;
  const kycGender = viewdet?.kycdetails?.gender;
  const kycAddr = viewdet?.kycdetails?.address;
  const kycPrmntAddr = viewdet?.kycdetails?.address;
  const kycPht = viewdet?.kycdetails?.pht;
  const KycReportMode = viewdet?.kycdetails?.kycmode;

  const panAIName = viewdet?.pandetails?.ainame;
  const panAIFname = viewdet?.pandetails?.aifname;
  const panAIDOB = viewdet?.pandetails?.aidob;
  const panNumber = viewdet?.pandetails?.aipannumber;

  const pancard = viewdet?.pandetails?.pancard;

  // Form 60
  const isform60declared = viewdet?.pandetails?.isform60declared;
  const form60declaration = viewdet?.pandetails?.form60declaration;

  const editPanAIName = viewdet?.pandetails?.edtname;
  const editPanAIFname = viewdet?.pandetails?.edtfname;
  const editPanAIDOB = viewdet?.pandetails?.edtdob;
  const editPanNumber = viewdet?.pandetails?.edtpannumber;
  const pan_verifed = viewdet?.pandetails?.pannumber_verifed;

  const isNsdlValid = viewdet?.pandetails?.oemstatus;
  const nsdlName = viewdet?.pandetails?.oemname;
  const nsdlFName = viewdet?.pandetails?.oemfname;
  const nsdlDOB = viewdet?.pandetails?.oemdob;

  // KINA BANK
  const panAIidType = viewdet?.pandetails?.idtype;
  const panAIref1 = viewdet?.pandetails?.airef1;
  const panAIref2 = viewdet?.pandetails?.airef2;
  const panAIref3 = viewdet?.pandetails?.airef3;
  const panAIref4 = viewdet?.pandetails?.airef4;
  const panAIref5 = viewdet?.pandetails?.airef5;
  const panAIref6 = viewdet?.pandetails?.airef6;
  const panAIref7 = viewdet?.pandetails?.airef7;
  const panAIref8 = viewdet?.pandetails?.airef8;
  const panAIgender = viewdet?.pandetails?.aigender;

  // const agentName = sessionStorage.getItem("username");
  // const auditorName = sessionStorage.getItem("username");
  const agentName = singleCustomerDetails.agentname;
  const auditorName = singleCustomerDetails.auditorname;
  const agentVideo = viewdet?.videoconfdetails?.videolink;

  const qadetails = viewdet?.qadetails?.length > 0 ? viewdet?.qadetails : [];

  const fuzzyMatchGender = viewdet?.fuzzymatchdetails?.kyc_gender;
  const fuzzyMatchAddr = viewdet?.fuzzymatchdetails?.kyc_curr_address;
  const fuzzyMatchPrmntAddr = viewdet?.fuzzymatchdetails?.kyc_per_address;

  const fuzzyMatchAdrPanName = viewdet?.fuzzymatchdetails?.kyc_pan_name;
  const fuzzyMatchAdrPanFname = viewdet?.fuzzymatchdetails?.kyc_pan_fname;
  const fuzzyMatchAdrPanDOB = viewdet?.fuzzymatchdetails?.kyc_pan_dob;
  const fuzzyMatchAdrPanNumber = viewdet?.fuzzymatchdetails?.pan_pannumber;
  const fuzzyMatchocr_nsdl_pannumber =
    viewdet?.fuzzymatchdetails?.ocr_nsdl_pannumber;

  const liveCapturePhtMatchLevel =
    viewdet?.livecapturedetails?.livecapturepht_matchlevel;

  const livenessCheckStatus = viewdet?.livecapturestatus;
  const liveAadharPhotoMatchstatus = viewdet?.live_aadhaar_pht_matchstatus;
  const livePanPhotoMatchStatus = viewdet?.live_pan_pht_matchstatus;

  let lat = viewdet?.customerdetails?.location.split(",")[0];
  let long = viewdet?.customerdetails?.location.split(",")[1];

  const mapURL =
    "https://maps.google.com/maps?width=720&height=600&hl=en&q=" +
    lat +
    "," +
    long +
    "+(encoding)&t=&z=14&ie=UTF8&iwloc=B&output=embed";

  // const currDate = viewdet?.videoconfdetails?.agentacceptedon;
  // const currDate = viewdet?.agentstatus_updatedon;
  // const date = moment(currDate).format("lll");
  const date = viewdet?.agentstatus_updatedon;

  const acccode = sessionStorage.getItem("acccode");
  const isKinaBank = acccode === "KINA" ? true : false;

  const KycReportModeCmp = ({ mode, ckycnumber }) => {
    switch (mode) {
      case "0":
        return "online";
      case "1":
        return "Offline";
      case "2":
        return "SteamlessOfflineKYC";
      case "3":
        return "Digilocker";
      case "4":
        return "AadhaarOCR";
      case "5":
        return (
          <p>
            {" "}
            CKYC,
            <span className="text-dark">
              ckycnumber:
              <p className="text-primary">{ckycnumber}</p>
            </span>
          </p>
        );
      case "6":
        return "Passport";
      case "7":
        return "VoterID";
      case "8":
        return "Driving Licence";

      default:
        return "";
    }
  };

  const ShowImg = ({ liveImg, pancard, kycPht }) => {
    return (
      <ReportImg>
        <div className="show_img">
          {kycPht && (
            <img src={`data:image/png;base64, ${kycPht}`} alt="img"></img>
          )}
          {liveImg && (
            <img src={`data:image/png;base64, ${liveImg}`} alt="img"></img>
          )}
          {pancard && (
            <img src={`data:image/png;base64, ${pancard}`} alt="img"></img>
          )}
        </div>
      </ReportImg>
    );
  };

  const ViewReport = () => {
    return (
      <>
        <div className="Container">
          <table className="table table-agent-borderless">
            <thead>
              <tr>
                <th>Agent Name</th>
                <th>Template Type</th>
                <th>VCIP ID</th>
                <th>Date & Time Stamp</th>
                {/* <th>Time Stamp</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {/* {props?.type == "auditor" ||
                    props?.type == "pending" ||
                    props?.type == "completed"
                    ? viewdet?.agentuserid
                    : agentName} */}
                  {agentName}
                </td>
                <td>{vciptemplate}</td>
                <td>{vcipid}</td>
                <td>{date}</td>
                {/* <td>{time}</td> */}
              </tr>
            </tbody>
          </table>
          {/* Customer details table */}
          <div className="">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th colSpan={6} className="text-center">
                    <h3 className="customer-heading">Customer details</h3>
                  </th>
                </tr>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header">User Parameters</th>
                  <th className="kyc-header">
                    Applicant Data <br />
                    <span className="text-primary">{cstmrAccName}</span>
                  </th>
                  <th className="kyc-header">
                    {isKinaBank ? "Database Data" : "Aadhaar Data"} <br />
                    <span className="text-primary">
                      {isKinaBank ? (
                        ""
                      ) : (
                        <KycReportModeCmp
                          mode={KycReportMode}
                          ckycnumber={viewdet?.kycdetails?.ckycnumber}
                        />
                      )}
                    </span>
                  </th>
                  <th className="kyc-header">
                    {isKinaBank ? "OVD OCR Details" : "PAN OCR Details"}
                  </th>
                  {isKinaBank ? "" : <th className="kyc-header">NSDL Data</th>}
                  <th className="kyc-header">Match</th>
                </tr>
              </thead>
              {/* ASDF // RELOOK */}
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{cstmrName ? cstmrName : "N/A"}</td>
                  <td>{kycName ? kycName : "N/A"}</td>
                  <td>
                    {editPanAIName
                      ? editPanAIName
                      : panAIName
                        ? panAIName
                        : "N/A"}
                  </td>
                  {isKinaBank ? "" : <td>{nsdlName ? nsdlName : "N/A"}</td>}
                  <td>
                    <FuzzyMatch value={fuzzyMatchAdrPanName} />
                    {/* <FuzzyMatch value={fuzzyMatchAdrPanName} /> */}
                  </td>
                </tr>
                <tr>
                  <td>Father’s name</td>
                  <td>{cstmrFname ? cstmrFname : "N/A"}</td>
                  <td>{kycFname ? kycFname : "N/A"}</td>
                  <td>
                    {editPanAIFname
                      ? editPanAIFname
                      : panAIFname
                        ? panAIFname
                        : "N/A"}
                  </td>
                  {isKinaBank ? "" : <td>{nsdlFName ? nsdlFName : "N/A"}</td>}
                  <td>
                    <FuzzyMatch value={fuzzyMatchAdrPanFname} />
                  </td>
                </tr>

                <tr>
                  <td>Date of Birth</td>
                  <td>{cstmrDOB ? cstmrDOB : "N/A"}</td>
                  <td>{kycDOB ? kycDOB : "N/A"}</td>
                  <td>{panAIDOB ? panAIDOB : "N/A"}</td>
                  {isKinaBank ? "" : <td>{nsdlDOB ? nsdlDOB : "N/A"}</td>}
                  <td>
                    <FuzzyMatch value={fuzzyMatchAdrPanDOB} />
                  </td>
                </tr>

                <tr>
                  <td>Gender</td>
                  <td>{cstmrGender ? cstmrGender : "N/A"}</td>
                  <td>{kycGender ? kycGender : "N/A"}</td>
                  <td>-</td>
                  {isKinaBank ? "" : <td>-</td>}
                  <td>
                    <FuzzyMatch value={fuzzyMatchGender} />
                  </td>
                </tr>

                <tr>
                  <td>Current address</td>
                  <td>{cstmrAddr ? cstmrAddr : "N/A"}</td>
                  <td style={{ wordBreak: "break-all" }}>
                    {kycAddr ? kycAddr : "N/A"}
                  </td>
                  <td>-</td>
                  {isKinaBank ? "" : <td>-</td>}
                  <td>
                    <FuzzyMatch value={fuzzyMatchAddr} />
                  </td>
                </tr>

                <tr>
                  <td>Permanent address </td>
                  <td>{cstmrPrmntAddr ? cstmrPrmntAddr : "N/A"}</td>
                  <td style={{ wordBreak: "break-all" }}>
                    {kycPrmntAddr ? kycPrmntAddr : "N/A"}
                  </td>
                  <td>-</td>
                  {isKinaBank ? "" : <td>-</td>}
                  <td>
                    <FuzzyMatch value={fuzzyMatchPrmntAddr} />
                  </td>
                </tr>

                <tr>
                  <td>Mobile number </td>
                  <td>{cstmrMobile ? cstmrMobile : "N/A"}</td>
                  <td>-</td>
                  <td>-</td>
                  {isKinaBank ? "" : <td>-</td>}
                  <td>-</td>
                </tr>

                <tr>
                  <td>Email address </td>
                  <td>{cstmrEmail ? cstmrEmail : "N/A"}</td>
                  <td>-</td>
                  <td>-</td>
                  {isKinaBank ? "" : <td>-</td>}
                  <td>-</td>
                </tr>
                {isKinaBank ? (
                  <tr>
                    <td>ID Type</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{panAIidType ? panAIidType : "N/A"} </td>
                    <td>-</td>
                  </tr>
                ) : (
                  ""
                )}

                {isKinaBank ? (
                  ""
                ) : (
                  <tr>
                    <td>PAN NSDL Status</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    {/* <td>-</td> */}
                    <td>
                      {isNsdlValid ? (
                        <span className="text-success">{isNsdlValid}</span>
                      ) : (
                        <span className="text-danger">{isNsdlValid}</span>
                      )}
                    </td>
                    <td>-</td>
                  </tr>
                )}

                <tr>
                  <td>{isKinaBank ? "OVD Number" : "PAN number"}</td>
                  <td>{custmr_pannumber ? custmr_pannumber : "-"}</td>
                  <td>-</td>
                  <td>
                    {editPanNumber
                      ? editPanNumber
                      : panNumber
                        ? panNumber
                        : "N/A"}
                  </td>
                  {isKinaBank ? (
                    ""
                  ) : (
                    <td>
                      {pan_verifed
                        ? pan_verifed
                        : panNumber
                          ? panNumber
                          : "N/A"}
                    </td>
                  )}
                  <td>
                    <FuzzyMatch value={fuzzyMatchocr_nsdl_pannumber} />
                    {/* {isNsdlValid ? (
                      <span className="text-success">{isNsdlValid}</span>
                    ) : (
                      <span className="text-danger">{isNsdlValid}</span>
                    )} */}
                  </td>
                </tr>
                {isKinaBank ? (
                  <>
                    {panAIref1 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref1?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref1?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref2 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref2?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref2?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref3 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref3?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref3?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref4 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref4?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref4?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref5 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref5?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref5?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref6 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref6?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref6?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref7 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref7?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref7?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                    {panAIref8 == "NA" ? (
                      ""
                    ) : (
                      <tr>
                        <td>{panAIref8?.split(":")[0]}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{panAIref8?.split(":")[1]}</td>
                        <td>-</td>
                      </tr>
                    )}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            {/* <table cl */}
          </div>

          {/* aaddhar photo */}
          <div
            className="align-horizontal cus-imgu"
            style={{
              justifyContent: isKinaBank ? "space-around" : "space-between",
            }}
          >
            {isKinaBank ? (
              ""
            ) : (
              <div className="face-box">
                <h6 className="text-center">Aadhaar Photo</h6>
                <hr />
                <div className="center">
                  <div className="match-img">
                    <img
                      src={
                        kycPht
                          ? `data:image/png;base64,${kycPht}`
                          : ProfileDummy
                      }
                      alt="aadhaar photo"
                      onClick={() => setImg("showimg", kycPht)}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* pan photo */}
            <div className="face-box">
              <h6 className="text-center">
                {isform60declared
                  ? "Form-60 Decleration"
                  : isKinaBank
                    ? "OVD Photo"
                    : "PAN Photo"}
              </h6>
              <hr />
              <div className="center">
                {isform60declared == "1" ? (
                  <>
                    <span className="cus-form">{form60declaration}</span>
                  </>
                ) : (
                  <div className="match-img">
                    <img
                      src={
                        pancard
                          ? `data:image/png;base64,${pancard}`
                          : ProfileDummy
                      }
                      alt="pan photo"
                      onClick={() => setImg("pancard", pancard)}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* live photo */}
            <div className="face-box">
              <h6 className="text-center">Live Photo</h6>
              <hr />
              <div className="center">
                <div className="match-img">
                  <img
                    src={
                      viewdet?.livecapturedetails?.livecapturepht
                        ? `data:image/png;base64,${viewdet?.livecapturedetails?.livecapturepht}`
                        : ProfileDummy
                    }
                    alt="live photo"
                    onClick={() =>
                      setImg(
                        "liveImg",
                        viewdet?.livecapturedetails?.livecapturepht
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Match table */}
          <div className="my">
            <table className="table table-borderd">
              <thead>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header">Face Verification</th>
                  <th className="kyc-header">Match</th>
                  <th className="kyc-header">Agent Status</th>
                  <th className="kyc-header">Remarks</th>
                </tr>
              </thead>

              <tbody>
                {/* Face Verification column */}
                <tr>
                  <td>Liveness Check</td>
                  <td className="text-center">
                    <FuzzyMatch value={liveCapturePhtMatchLevel} />
                  </td>
                  <td className="text-center">
                    {livenessCheckStatus ? (
                      livenessCheckStatus === "1" ? (
                        <img
                          src={RightTick}
                          style={{ width: "30px" }}
                          alt="tick icon"
                        />
                      ) : (
                        <img src={WrongTick} alt="check" />
                      )
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{viewdet?.livecaptureremark}</td>
                </tr>

                {/* match column */}
                {/* <tr>
                <td>Face match between Aadhaar & PAN</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td>-</td>
              </tr> */}

                {/* agent status column */}
                {isKinaBank ? (
                  ""
                ) : (
                  <tr>
                    <td>Face match between Aadhaar & Live Photo</td>
                    <td className="text-center">
                      <FuzzyMatch
                        value={viewdet?.live_aadhaar_pht_matchlevel}
                      />
                    </td>
                    <td className="text-center img-center">
                      {liveAadharPhotoMatchstatus ? (
                        liveAadharPhotoMatchstatus === "1" ? (
                          <img
                            src={RightTick}
                            style={{ width: "30px" }}
                            alt="tick icon"
                          />
                        ) : (
                          <img src={WrongTick} alt="check" />
                        )
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{viewdet?.live_aadhaar_remark}</td>
                  </tr>
                )}

                {/* remark */}
                <tr>
                  {isKinaBank ? (
                    <td>Face match between Live Photo & OVD</td>
                  ) : (
                    <td>Face match between Live Photo & PAN</td>
                  )}
                  <td className="text-center">
                    <FuzzyMatch value={viewdet?.live_pan_pht_matchlevel} />
                  </td>
                  <td className="text-center img-center">
                    {livePanPhotoMatchStatus ? (
                      livePanPhotoMatchStatus === "1" ? (
                        <img
                          src={RightTick}
                          style={{ width: "30px" }}
                          alt="tick icon"
                        />
                      ) : (
                        <img src={WrongTick} alt="check" />
                      )
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{viewdet?.live_pan_remark}</td>
                </tr>
              </tbody>
            </table>
            {/* <table cl */}
          </div>
          {/* Location check */}
          <div className="align-horizontal-top">
            <div className="col-lg-7 mt-1 ">
              <div className="location-box">
                <h6 className="text-center mb">Location check</h6>
                <hr />
                <div className="text-center my">
                  {/* <Map
                  zoom={15}
                  center={{ lat: parseFloat(lat), lng: parseFloat(long) }}
                /> */}
                  <iframe width="100%" height="auto" src={mapURL}></iframe>
                </div>
              </div>
            </div>

            {/* Browser IP Details */}
            <div className="col mt-1">
              <table className="table table-borderless">
                <thead>
                  <tr className="text-center vertical-align-middle">
                    <th colSpan={3} className="text-center">
                      <h3 className="customer-heading">Browser IP Details</h3>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>IP Address</td>
                    <td>{ipaddress ? ipaddress : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{geo_location ? geo_location : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Latitude and Longitude</td>
                    <td>
                      {lat}&deg; N, {long}&deg; E
                    </td>
                  </tr>
                  <tr>
                    <td>ISP</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>IP Country code</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Browser name</td>
                    <td>
                      {browser_name ? (
                        <ul>
                          {browser_name?.map((i, item) => (
                            <li>{i}</li>
                          ))}
                        </ul>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <table cl */}
            </div>
          </div>

          {/* {(props?.type == "auditor" ||
            props?.type == "pending" ||
            props?.type == "completed") && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="face-box mt-4">
                    <h6 className="text-center">Agent Screen Recording</h6>
                    <hr />
                    <div className="center">
                      <div className="match-img h-auto w-auto">
                        <video width="960" height="617" controls>
                          <source src={agentVideo} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}

          {/* <div className="row">
            <div className="col-lg-12">
              <div className="face-box mt-4">
                <h6 className="text-center">Agent Screen Recording</h6>
                <hr />
                <div className="center">
                  <div className="match-img h-auto w-auto">
                    <video width="960" height="617" controls>
                      <source src={agentVideo} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Question and answer table */}
          <div className="col-lg-12 mt-3">
            <table className="table table-questionAnswers">
              <thead>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header">Question & Answer</th>
                  <th className="kyc-header">Agent Status</th>
                  <th className="kyc-header">Remarks</th>
                </tr>
              </thead>

              <tbody>
                {qadetails.map((item, index) => (
                  <tr>
                    <td>
                      Q. {item?.ques} <br />
                      A. {item?.answer}
                    </td>
                    <td className="text-center">
                      {item?.status === "1" ? (
                        <img
                          src={RightTick}
                          style={{ width: "30px" }}
                          alt="tick icon"
                        />
                      ) : (
                        <img src={WrongTick} alt="check" />
                      )}
                    </td>
                    <td>{item?.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <table cl */}
          </div>

          <div className="col-lg-12">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th colSpan={6} className="text-center">
                    <h3 className="customer-heading">Final Remark</h3>
                  </th>
                </tr>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header col-md-2"></th>
                  <th
                    // className={
                    //   props?.type == "completed"
                    //     ? "kyc-header col-md-5 text-start px-4"
                    //     : "kyc-header text-start px-4"
                    // }
                    className={"kyc-header text-start px-4"}
                  >
                    Agent Remark
                  </th>
                  <th className="kyc-header">Auditor Remark</th>
                  {/* {props?.type == "completed" && (
                    <th className="kyc-header">Auditor Remark</th>
                  )} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Agent Name</td>
                  <td>{agentName ? agentName : "N/A"}</td>
                  <td>{auditorName ? auditorName : "N/A"}</td>
                  {/* {props?.type == "completed" && <td>{auditorName}</td>} */}
                </tr>

                <tr>
                  <td>KYC Date</td>
                  <td>
                    {/* {viewdet?.agentstatus_updatedon
                      ? moment(
                          viewdet?.agentstatus_updatedon
                        ).format("lll")
                      : "N/A"} */}
                    {viewdet?.agentstatus_updatedon || "N/A"}
                  </td>
                  {/* {props?.type == "completed" && (
                    // <td>
                    //   {date} {time}
                    // </td>
                  )} */}

                  {/* {props?.type == "completed" && ( */}
                  <td>{viewdet?.auditedon || "N/A"} </td>
                  {/* )} */}
                </tr>

                <tr>
                  <td>Status Update</td>
                  <td>
                    {singleCustomerDetails?.agentstatus === "1" ? (
                      <span className="text-success">Approved</span>
                    ) : singleCustomerDetails?.agentstatus === "2" ? (
                      <span className="text-danger">Rejected</span>
                    ) : singleCustomerDetails?.agentstatus === "3" ? (
                      <span className="yellow">Discrepancy</span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {singleCustomerDetails?.auditorstatus === "1" ? (
                      <span className="text-success">Approved</span>
                    ) : singleCustomerDetails?.auditorstatus === "2" ? (
                      <span className="text-danger">Rejected</span>
                    ) : singleCustomerDetails?.auditorstatus === "3" ? (
                      <span className="yellow">Discrepancy</span>
                    ) : (
                      "Pending"
                    )}
                  </td>
                </tr>

                <tr>
                  <td>Final Remark</td>
                  <td>{viewdet?.agentremarks}</td>
                  <td>{viewdet?.auditorremarks}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="risk">
            <div className="risk_rating">
              <table className="table table-borderless">
                <thead>
                  <tr className="text-center vertical-align-middle">
                    <th colSpan={3} className="text-center">
                      <h3 className="customer-heading">Risk Rating</h3>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Risk rating status</td>
                    <td>{viewdet?.risk_rating_status}</td>
                  </tr>
                  <tr>
                    <td>Risk rating report</td>
                    <td>
                      <a
                        href={viewdet?.risk_rating_report}
                        download
                        target="_blank" rel="noreferrer"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Rating rating txnid</td>
                    <td>{viewdet?.risk_rating_txnid}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="risk_rating">
              <table className="table table-borderless">
                <thead>
                  <tr className="text-center vertical-align-middle">
                    <th colSpan={3} className="text-center">
                      <h3 className="customer-heading">Risk Screening</h3>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Screening status</td>
                    <td>{viewdet?.screening_status}</td>
                  </tr>
                  <tr>
                    <td>Screening alert count</td>
                    <td>{viewdet?.screening_alerts_count}</td>
                  </tr>
                  <tr>
                    <td>Screening alert report</td>
                    <td>{viewdet?.screening_alerts_report}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const slideNext = (val) => {
    if (viewdet?.videolinks?.length > 0 && num > 0) {
      setNum((prevState) => prevState + val);
    }
  };

  const headerActions = [
    {
      img: SearchGrad,
      key: "4",
      func: (e) => e.persist(),
    },
    {
      img: CalanderGrad,
      key: "cal",
      func: () => setShowDate(true),
    },
  ];

  const searchCustomerDetails = (val) => {
    setSearchedText(val);
  };

  // Dropdown Handler
  const handleDropDownChange = (e) => {
    const { value } = e.target;
    setRefValues(value.split(","));
    setRefSearch("");
    setInputError("");
  };

  // Search Handler
  const searchInputHandler = (e) => {

    if (refValues[0] == "vcipid" && e.target.value.length <= 10) {
      setRefSearch(e.target.value);
      setInputError("");
    } else if (refValues[0] == "mobile" && e.target.value.length <= 10) {
      setRefSearch(e.target.value);
      setInputError("");
    } else if (refValues[0] == "customerid" && e.target.value.length < 100) {
      setRefSearch(e.target.value);
      setInputError("");
    } else {
      return;
    }
  };

  // Reference Call History API Hit
  const handleSubmitSingleVcip = () => {
    let payload = {
      endpoint: "GetReferenceCallHistory",
      model: {
        refid: refSearch,
        reftype: refValues[0],
        accid: AccountSwap,
      }
    };

    if (location.pathname === opsRollBackPath) {
      setCallHistoryTab("")
      payload = {
        endpoint: "GetCustomerVCIPStatus",
        model: {
          refid: refSearch, //1
          reftype: refValues[1], // 22181
          acccode: AccountSwap,
        }
      }
    }

    if (payload.model.reftype == "mobile" && payload.model.refid.length < 10) {
      setInputError("Enter Valid Mobile Number");
    } else if (!refSearch) {
      setInputError("Please fill all the fields.");
    } else {
      dispatch(
        getSingleReferenceCallHistorySagaAction({
          model: payload,
          callback: getSingleRefCallData,
        })
      );
    }
  };

  // handle role back
  const handleRollBack = (event) => {
    let model = {
      acccode: acccode, // accode
      type: refValues[1] == 1 ? "3" : refValues[1], // vcipid vciplink
      id: refSearch,
      stage: rollBackStage, //1-KYC Only, 2-PAN Only, 3-VideoCall only, 4-PAN & VideoCall, 5-KYC,PAN & VideoCall, 6.Agent Status, 7.Auditor Status
      remark: rollBackRemarks
    }
    // console.log(payload)
    dispatch(
      vcipRollBackSagaAction({
        model: model,
        callback: rollBackCallBack,
      })
    );
  }

  const handleDiscardBtn = () => {
    setRefCallHistory("")
    setInputError("");
    setShowCurrentVcipStatus("");
    setRollBackRemarks("")

  }

  const rollBackCallBack = (data) => {
    if (data?.respcode == "200") {
      toast.success(data?.respdesc)
      handleSubmitSingleVcip(true);
    } else {
      toast.error(data?.respdesc)
    }
    setRollBackRemarks("")
  }

  const getStatusOnNumber = (num) => {
    if (num == 1) {
      return "Completed"
    } else {
      return "Not Completed"
    }
  };

  const getSingleRefCallData = ([data, endpoint]) => {
    if (data?.respcode === "200" && endpoint === "GetReferenceCallHistory") {
      setRefCallHistory(data?.vciplist);
      toast.success(data?.respdesc);
    } else if (data?.respcode === "200" && endpoint === "GetCustomerVCIPStatus") {
      let obj = [
        {
          vcipidstatus: getStatusOnNumber(data?.data[0].vcipidstatus),
          kycstatus: getStatusOnNumber(data?.data[0].kycstatus),
          panstatus: getStatusOnNumber(data?.data[0].panstatus),
        }
      ]
      // const keysToFilter = ["agentstatus", "auditorstatus", "panstatus"];
      // const filteredPairs = Object.entries(data?.data[0])
      //   .filter(([key, value]) => keysToFilter.includes(key)
      //     .map(([key, value]) => ({ [key]: value })))
      setRefCallHistory(obj)
      // toast.success(data?.respdesc);
      setShowCurrentVcipStatus(VcipIdStatusOptions[data?.data[0].vcipidstatus])
    } else {
      toast.error(data?.respdesc);
      setRefCallHistory([]);
    }
  };

  // Object for Payload
  const rollBackDropdown = [
    {
      id: "1",
      value: "vcipid",
      label: "Vcip ID",
    },
    {
      id: "4",
      value: "vcipLink",
      label: "Vcip Link",
    },
  ];

  const dropoDown_client = [
    {
      id: "1",
      value: "vcipid",
      label: "VCIP ID",
    },
    {
      id: "2",
      value: "mobile",
      label: "Mobile Number",
    },
    {
      id: "3",
      value: "customerid",
      label: "Customer ID",
    },
  ];

  let opsRollBackPath = "/dashboard/vcip-roll-back"

  let dropDown_options = location.pathname === opsRollBackPath ? rollBackDropdown : dropoDown_client;
  let currentStatusColor = showCurrentVcipStatus == VcipIdStatusOptions[2] || showCurrentVcipStatus == VcipIdStatusOptions[4] ? "#4DAE68" : showCurrentVcipStatus == VcipIdStatusOptions[1] ? "#ffe54f" : "red"

  // Single VCIP Search JSX
  return (
    <>
      <div className="main-loader">
        <LoaderClr />
      </div>
      <CallHistoryStyle>
        {
          location.pathname != opsRollBackPath &&
          <HeaderFilters
            isComponent={true}
            heading={
              location.pathname === "/dashboard/customers/single-vcip-search" ? (
                <SingleVcipStyle>
                  <div className="actions">
                    <select
                      className="custom-select"
                      name="accid"
                      onChange={handleDropDownChange}
                      // value={refSearch}
                      defaultValue={"Default"}
                    >
                      <option disabled value="Default">
                        Select Type
                      </option>
                      {dropDown_options.map((item, x) => {
                        return (
                          <option value={[item.value, item.id]} key={item.id}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="search-div">
                        <input
                          type={refValues[0] == "customerid" ? "text" : "number"}
                          className="custom-search"
                          placeholder="Search Here..."
                          onChange={searchInputHandler}
                          value={refSearch}
                          disabled={!refValues[0]}
                          style={{
                            marginTop: inputError ? "1.4rem" : "0",
                          }}
                        />
                        <p style={{ color: "red" }}>{inputError}</p>
                      </div>
                      <button
                        className="btn-submit"
                        onClick={handleSubmitSingleVcip}
                        disabled={!refSearch}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </SingleVcipStyle>
              ) : (location.pathname != opsRollBackPath) ? <CallHistoryTabs
                status={callHistoryTab}
                action={setCallHistoryTab}
              /> : null
            }
            className="customerActions"
            actionBtns={
              location.pathname === "/dashboard/customers/single-vcip-search" || location.pathname === opsRollBackPath
                ? null
                : headerActions
            }
            change={(e) => searchCustomerDetails(e.target.value)}
          />
        }

        {/* Operations Rollback Page JSX */}
        {
          location.pathname === opsRollBackPath &&
          <VcipRollBackStyle>
            <div className="opsDas">
              <h4 className="fd3">VCIP Roll Back</h4>
              <div className="actions">
                <select
                  className="custom-select"
                  name="accid"
                  onChange={handleDropDownChange}
                  defaultValue={"Default"}
                >
                  <option disabled value="Default">
                    Select Type
                  </option>
                  {dropDown_options.map((item, x) => {
                    return (
                      <option value={[item.value, item.id]} key={item.id}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="search-div">
                    <input
                      type={refValues[0] == "customerid" ? "text" : "number"}
                      className="custom-search"
                      placeholder="Search Here..."
                      onChange={searchInputHandler}
                      value={refSearch}
                      disabled={!refValues[0]}
                      style={{
                        marginTop: inputError ? "1.4rem" : "0",
                      }}
                    />
                    <p style={{ color: "red" }}>{inputError}</p>
                  </div>
                  <button
                    className="btn-submit"
                    onClick={handleSubmitSingleVcip}
                    disabled={!refSearch}
                  >
                    Submit
                  </button>
                </div>
              </div>

              {showCurrentVcipStatus && (location.pathname === opsRollBackPath) ? <>
                {/* <h3 className="fd3">Current status of VCIP:  <span style={{ `${} color: "#4DAE68", paddingLeft: "5px"` }}> {showCurrentVcipStatus}</span></h3> */}
                <h3 className="fd3">Current status of VCIP:  <span style={{ paddingLeft: "5px", color: currentStatusColor }}> {showCurrentVcipStatus}</span></h3>
                {
                  showCurrentVcipStatus != VcipIdStatusOptions[1] ? // 1 - In-Progress
                    <>
                      <h2 style={{ margin: "6px 0px" }}>Roll Back Options </h2>
                      <div className="radioBtn">
                        {
                          Object.keys(RollBackOptions).map(key => {
                            let [rollBackRoleName, rollBackRoleValue] = RollBackOptions[key];
                            return <>
                              <div>

                                <input type="radio"
                                  name="key"
                                  style={{ marginRight: "3px" }}
                                  id={rollBackRoleName}
                                  value={rollBackRoleValue}
                                  onChange={() => {
                                    setRollBackStage(rollBackRoleValue)
                                  }}
                                />
                                <label htmlFor="" style={{ marginRight: "10px" }}>{rollBackRoleName}</label>
                              </div>
                            </>
                          })
                        }

                      </div>
                      <div className="search-div">
                        {/* <input className="custom-rollbackBtn" type="textarea" placeholder="Write Remark Here" name="rollBackRemark" id="" onChange={(e) => setRollBackRemarks(e.target.value)} value={rollBackRemarks} /> */}
                        <textarea className="custom-rollbackBtn" placeholder="Write Remark Here" name="postContent" rows={2} cols={20} onChange={(e) => setRollBackRemarks(e.target.value)} value={rollBackRemarks} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <button className="btn-submit" onClick={handleDiscardBtn} disabled={!rollBackRemarks}>Discard</button>
                        <button className="btn-submit" onClick={handleRollBack} disabled={!rollBackRemarks}>Roll Back</button>
                      </div>

                    </> :
                    <AllCallHistory
                      dispPop={getVcipUserDetails}
                      onClick={getCallHistoryList}
                      searchedText={searchedText}
                      showDate={showDate}
                      setShowDate={setShowDate}
                      refCallHistory={refCallHistory}
                      filteration={false}
                    />

                }
              </> : null
              }
            </div>

          </VcipRollBackStyle>
        }

        {
          <div className="callHistoryTableContainer">
            {callHistoryTab === "all" && (
              <AllCallHistory
                dispPop={getVcipUserDetails}
                onClick={getCallHistoryList}
                searchedText={searchedText}
                showDate={showDate}
                setShowDate={setShowDate}
                refCallHistory={refCallHistory}
                filteration={true}
              />
            )}
            {callHistoryTab === "direct" && <AllCallHistory dispPop={setPop} />}
            {callHistoryTab === "assigned" && <WaitingCustomerQueue />}
          </div>
        }
      </CallHistoryStyle>
      {pop === "customerDetails" && (
        <Popup
          className="px800"
          heading="Customer Details"
          body={<CustomerDetails />}
          close={() => setPop("")}
          isDelete={false}
          ishr={true}
        />
      )}
      {pop === "activeLog" && (
        <Popup
          className="px800"
          heading="Active Log"
          body={<ActivityLog />}
          close={() => setPop("")}
        />
      )}
      {pop === "webLink" && (
        <Popup
          className="px500"
          heading="Send Weblink"
          body={<WebLinkCopy vciplink={singleCustomerDetails?.vciplink} />}
          close={() => setPop("")}
        />
      )}
      {pop === "scheduleCall" && (
        <Popup
          className="px800"
          heading="Schedule Call"
          body={"working"}
          close={() => setPop("")}
        />
      )}
      {pop === "totalAgents" && (
        <Popup
          className="px800"
          heading="Total Agents"
          body={"working"}
          close={() => setPop("")}
        />
      )}
      {pop === "bookSlot" && (
        <Popup
          className="px800"
          heading="Book A Slot"
          body={"working"}
          close={() => setPop("")}
        />
      )}
      {pop === "videoRecording" && (
        <Popup
          className="px800"
          heading={`Video Recording (${viewdet?.videolinks?.length})`}
          body={
            <CarouselElement
              videotag={viewdet?.videolinks}
              slideNext={slideNext}
            />
          }
          close={() => setPop("")}
        />
      )}
      {pop === "viewReport" && (
        <Popup
          className="px1200"
          heading="KYC Report"
          clrHeading={true}
          body={<ViewReport />}
          close={() => setPop("")}
        />
      )}
      {img === "showimg" && (
        <Popup
          className="px500"
          heading="Image"
          body={<ShowImg kycPht={kycPht} />}
          close={() => setImg("")}
        />
      )}
      {img === "pancard" && (
        <Popup
          className="px500"
          heading="Image"
          body={<ShowImg pancard={pancard} />}
          close={() => setImg("")}
        />
      )}
      {img === "liveImg" && (
        <Popup
          className="px500"
          heading="Image"
          body={
            <ShowImg liveImg={viewdet?.livecapturedetails?.livecapturepht} />
          }
          close={() => setImg("")}
        />
      )}
      {/* {
        showDate && (
          <Popup
            className="px680 dateRangePicker"
            heading="Date range"
            body={<RangeDatePicker />}
            close={() => setShowDate(false)}
          />
        )
      } */}
    </>
  );
}

const ReportImg = styled.div`
  .show_img {
    img {
      width: 100%;
    }
  }
`;

const CallHistoryStyle = styled.div`
  .callHistoryTableContainer {
    width: 100%;
    overflow-x: auto;
  }
`;
const Cus_details = styled.div`
  .mr-0 {
    margin-right: 0px !important;
  }
  .ml-0 {
    margin-left: 0px !important;
  }
  .mb-0 {
    margin-bottom: 6px !important;
  }
  .pb-20 {
    padding-bottom: 20px !important;
  }
  .cus-customerDetails {
    padding: 10px;
    border: 2px solid #c7c7c7;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    color: #6e6e6f;
  }
  .cus-TwoDetails {
    max-width: 100%;
    display: flex;
    .cus-personal {
      padding: 10px;
      border: 2px solid #c7c7c7;
      margin: 1rem;
      margin-left: 0;
      border-radius: 5px;
      flex: 1;
      h4 {
        font-size: 16px;
        padding-bottom: 3px;
        margin: 0px;
        margin-bottom: 5px;
      }
      hr {
        margin-bottom: 5px;
        opacity: 0.5;
      }
      td {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 35px;
        color: #6e6e6f;
        width: 50%;
      }
    }
    .table-w {
      width: 100%;
    }
  }
`;

const VcipRollBackStyle = styled.div`
.radioBtn {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: space-between;
  gap: 8px;
}
.opsDas {
  box-shadow: var(--cardShadow);
  background: var(--white) !important;
  padding: 20px 24px 0 !important;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  border-radius: 8px;
  width: 100%;
}


.actions {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0 16px 0;
}
.custom-select {
  width: 15rem;
  padding: 9px;
  font-size: 16px;
  border: 1px solid var(--border-color2);
  border-radius: 5px;
  background: transparent;
}
.custom-search {
  width: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid var(--border-color2);
  border-radius: 5px;
  background: transparent;
}
.custom-rollbackBtn {
  display: inline-block;
  width: 100%;
  padding: 9px;
  font-size: 16px;
  border: 1px solid var(--border-color2);
  border-radius: 5px;
  background: transparent;
  margin: 20px 0;
  padding: 8px 15px;
}
.search-div {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 0.72rem;
  right: 10px;
}
.btn-submit {
  background: var(--buttonBg);
  text-align: center;
  padding: 0.4rem 1.2rem;
  color: var(--white);
  font-weight: 600;
  font-size: 22px;
  border-radius: 5px;
  border: none;
  margin: 0px 6px;
}
.fd3 {
  display: flex;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 20px;
  padding-bottom: 8px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}
`;
const SingleVcipStyle = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  background: var(--white);
  padding-top: 1rem;
  overflow: auto;
  width: 100%;
  position: relative;

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 0 16px 0;
  }
  .custom-select {
    width: 15rem;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
  }
  .custom-search {
    width: 20rem;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
  }
  .search-div {
    position: relative;
  }
  .search-icon {
    position: absolute;
    top: 0.72rem;
    right: 10px;
  }
  .btn-submit {
    background: var(--buttonBg);
    text-align: center;
    padding: 0.4rem 1.2rem;
    color: var(--white);
    font-weight: 600;
    font-size: 22px;
    border-radius: 5px;
    border: none;
    margin: 0px 6px;
  }
  .fd3 {
    display: flex;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
