import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
    actionReqGetAdminDashboardCountSagaAction,
} from "../../Store/SagasActions/DashboardOverviewSagaAction";
import { useSelector } from "react-redux";

export default function OperationsDashboard() {

    const [dashboardData, setDashboardData] = useState({});
    const dispatch = useDispatch();

    const account = useSelector((state) => state?.DashboardReducer.accid);

    const getDashaboardCountDataByAccnt = useCallback((accid) => {
        const payload = {
            endpoint: "opsdashboard",
            model: {
                accid: accid,
                type: "1",
            }
        }
        dispatch(
            actionReqGetAdminDashboardCountSagaAction({
                model: payload,
                callback: respUserDashboardCount,
            })
        );
    }, [dispatch])

    useEffect(() => {
        if (account) {
            getDashaboardCountDataByAccnt(account);
        }
    }, [account, getDashaboardCountDataByAccnt])


    const respUserDashboardCount = (data, selectedDashaboard) => {
        // setDashboardCount(data);
        setDashboardData(data);
    };

    return (
        <>
            <DashboardIndexStyle>
                <div className="overviewList">
                    <div className="overviewCard">
                        <h4 className="fd3">Customer VCIP Count Dashboard</h4>
                        <div className="actionContainer">
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.totalcustomers}</h5>
                                <h5 className="fg4">Total customers</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.fullkyccompletedcustomers}</h5>
                                <h5 className="fg4">Full KYC Completed Customers</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.fullkycpendingcustomers}</h5>
                                <h5 className="fg4">Full KYC Pending Customers</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.notinitiatedvkyc}</h5>
                                <h5 className="fg4">Not Initiated VKYC </h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.initiatedcompletedonly_kyc}</h5>
                                <h5 className="fg4">Initiated & Completed Only KYC</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.initiatedcompleted_kyc_pan}</h5>
                                <h5 className="fg4">Initiated & Completed KYC & PAN</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.agent_approved}</h5>
                                <h5 className="fg4">Agent Approved</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.agent_rejected}</h5>
                                <h5 className="fg4">Agent Rejected</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.agent_rejected_discripincy}</h5>
                                <h5 className="fg4">Agent Reject with Discrepancy</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.auditor_rejected}</h5>
                                <h5 className="fg4">Auditor Rejected</h5>
                            </div>
                            <div
                                className="actionCard"
                            >
                                <h5 className="fg1">{dashboardData?.auditor_approvedbutfailedin_m2p}</h5>
                                <h5 className="fg4">Auditor Approved But Failed in M2P Full KYC Status Update</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardIndexStyle>
        </>
    );
}

const DashboardIndexStyle = styled.div`
    .overviewList {
        display: flex;
        gap: 15;

        .overviewCard {
            box-shadow: var(--cardShadow);
            background: var(--white);
            padding: 20px 24px 0;
            height: calc(100vh - 120px);
            overflow: auto;
            position: relative;
            border-radius: 8px;
            width: 100%;
        .fd3 {
            display: flex;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 20px;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .actionContainer {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 16px;
            aspect-ratio: 3 / 1;
            
            .actionCard {
                background: #cae9ff80;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
                border-radius: 8px;
                padding: 18px;
                flex: 1;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 8px;
                cursor: pointer;
                transition: all 0.2s linear;
                &:hover {
                transform: scale(1.03);
                box-shadow: var(--selectShadow);
                }
                .fg1 {
                    font-family: 'Proxima Nova';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 30px;
                }
            }
            .fg4 {
                font-weight: 600;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
                text-align: center;
                width: 95%;
                transition: width .5s ease-in-out;

                &:hover {
                    width: 100%;
                    white-space: pre-wrap;
                    overflow:visible;
                }
            }
        }
    }
}
`;
