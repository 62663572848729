import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import DocumentationPanel from "./DocumentationPanel";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import DashboardIndex from "./Pages/SubPages/DashboardIndex";
import Agent from "./Pages/SubPages/Agent";
import Auditor from "./Pages/SubPages/Auditor";
import Customers from "./Pages/SubPages/Customers";
import Configure from "./Pages/SubPages/Configure";
import Audit from "./Pages/SubPages/Audit";
import Report from "./Pages/SubPages/Report";
import ApplyLeave from "./Pages/SubPages/ApplyLeave";
import ApplyAuditLeave from "./Pages/SubPages/ApplyAuditLeave";
import CustomerQueue from "./Pages/SubPages/CustomerQueue";
import CallHistory from "./Pages/SubPages/CallHistory";
import QManagement from "./Pages/SubPages/QManagement";
import { Provider } from "react-redux";
import store from "./Store/store";
import { Toaster } from "react-hot-toast";
import ManagerialAudit from "./Pages/SubPages/ManagerialAudit";
import ServiceIncidents from './Pages/SubPages/ServiceIncidents';
import Gleap from "gleap";
import VCIPListQuality from "./QualityCheck/subpages/VCIPListQuality";
import QualityCheck from "./QualityCheck/QualityCheck";
import Transactions from "./Pages/SubPages/Transactions";
import OperationsDashboard from "./Pages/Operations/OperationsDashboard";
import VcipRollBack from "./Pages/Operations/VcipRollBack";
Gleap.initialize("AynS0JYIBcbu4aXkjaHzkzEY91JP1gMh");

if (process.env.REACT_APP_PROTEAN) {
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = "assetsProtean/images/proteanFavicon.svg";
  document.title = "Protean Vkyc Portal";
} else {
  document.title = "Syntizen - Solutions for all your company needs";
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <Toaster
      containerStyle={{
        zIndex: "9999999",
      }}
      toastOptions={{
        duration: 700,
      }}
    />
    <Provider store={store}>
      <BrowserRouter basename="/">
        <Routes>
          {/* <Route path="/" element={<App />} /> */}
          <Route path="/" element={<Login type="psw" />} />
          <Route path="/reset-password" element={<Login type="reset" />} />
          <Route
            path="/resetpassword/token=:token"
            element={<Login type="change" />}
          />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="overview" element={<DashboardIndex />} />
            <Route path="agent" element={<Agent />} />
            <Route path="applyLeave" element={<ApplyLeave />} />
            <Route path="applyAuditLeave" element={<ApplyAuditLeave />} />
            <Route path="auditor" element={<Auditor />} />
            <Route path="customers" element={<Customers />}>
              <Route path="queue" element={<CustomerQueue />} />
              <Route path="callHistory" element={<CallHistory />} />
              <Route path="single-vcip-search" element={<CallHistory />} />
            </Route>
            {/* Operations Dropdown routes starts */}
               <Route path="operationsDashboard" element={<OperationsDashboard />} />
               <Route path="vcip-roll-back" element={<VcipRollBack />} />
             <Route path="vcip-roll-back" element={<VcipRollBack />} />  
             <Route path="service-incidents" element={<ServiceIncidents />} />
             <Route path="audit" element={<Audit />} />
             <Route path="managerialAudit" element={<ManagerialAudit />} />
             <Route path="configure" element={<Configure />} />
            {/* Operations Dropdown ends */}
            <Route path="report" element={<Report />} />
            <Route path="transaction" element={<Transactions />} />
            <Route path="q-management" element={<QManagement />} />
          </Route>
          <Route path="/qualitycheck" element={<QualityCheck />}>
            <Route path="vciplist" element={<VCIPListQuality />} />
          </Route>
          <Route path="/DocumentationPanel" element={<DocumentationPanel />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </>
);
