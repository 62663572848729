import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CallGrad,
  MailGrad,
  PencilMark,
  CloseBtn,
  CameraIcon,
} from "../assets/ImageUrl";

export default function ProfileCardAudit({
  data,
  isEditable,
  updatedAuditorProfilePic,
}) {
  const [updatedAuditProfile, setupdatedAuditProfile] = useState(
    data?.auditor_pht
  );
  const [cancelButtonfile, setCancelButtonfile] = useState(true);

  // const [staticImg, setStaticImg] = useState(
  //   "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
  // );

  const auditorHandleChange = (event) => {
    event.preventDefault();
    if (event.target.files) {
      const val = event.target.files.length;
      for (let i = 0; i < val; i++) {
        let reader = new FileReader();
        reader.onload = function (ev) {
          const img = ev.target.result.split(",")[1];
          setupdatedAuditProfile("data:image/png;base64," + img);
          updatedAuditorProfilePic(img);
          setCancelButtonfile(false);
        }.bind(this);
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  };
  const cancelButton = () => {
    if (data?.auditor_pht.length >= 10) {
      setupdatedAuditProfile("data:image/png;base64," + data?.auditor_pht);
      // setupdatedAuditProfile(data?.auditor_pht);
      updatedAuditorProfilePic(data?.auditor_pht);
      setCancelButtonfile(true);
    } else {
      setupdatedAuditProfile(
        "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
      );
      updatedAuditorProfilePic("https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg");
      setCancelButtonfile(true);
    }
  };

  useEffect(() => {
    if (data?.auditor_pht.length >= 10) {
      // profile = "data:image/png;base64," + updatedAuditProfile;
      // setupdatedAuditProfile("data:image/png;base64," + updatedAuditProfile);
      setupdatedAuditProfile(updatedAuditProfile);
      updatedAuditorProfilePic(updatedAuditProfile)
    } else {
      setupdatedAuditProfile(
        "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
      );
      updatedAuditorProfilePic("https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg");

    }
  }, [data?.auditor_pht]);

  return (
    <ProfileCardStyle>
      <>
        {
          cancelButtonfile ? (
            ""
          ) : (
            <button className="cancel-button" onClick={cancelButton}>
              <img src={CloseBtn} alt="check" />
            </button>
          )
          // <button className="cancel-button" onClick={cancelButton}>cancel</button>
        }
        <div className="imgContainer">
          <div className="prof-edt">
            <input
              type="file"
              name="auditor_pht"
              className="prof-edt-inp"
              accept=".png, .jpg, .jpeg"
              onChange={auditorHandleChange}
              disabled={isEditable}
            />
            <img
              src={updatedAuditProfile}
              // data?.auditor_pht.length >= 10
              //   ? "data:image/png;base64," + updatedAuditProfile
              //   : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
              // }
              alt={data.auditor_name}
            />
            {isEditable ? (
              ""
            ) : (
              <div className="edit-camera">
                <img src={CameraIcon} className="prof-edt-icon" alt="" />
              </div>
              // <img src={PencilMark} className="prof-edt-icon" alt="" />
            )}
            {/* <img
            src="https://previews.123rf.com/images/vectorgalaxy/vectorgalaxy1808/vectorgalaxy180803997/108517743-pencil-icon-isolated-on-transparent-background.jpg"
            className="prof-edt-icon"
            alt=""
          /> */}
          </div>
          {/* <img
          src={
            data?.auditor_pht.length >= 10
              ? "data:image/png;base64," + data?.auditor_pht
              : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
          }
          alt={data.auditor_name}
        /> */}
        </div>
        {/* {isEditable ? (
        ""
      ) : (
        <button className="cancel-button" onClick={cancelButton}>
          cancel
        </button>
      )} */}
      </>

      <h2>{data.auditor_name}</h2>
      <p>
        {" "}
        Added On <strong>
          {" "}
          {data.datecreated ? data.datecreated : "NA"}
        </strong>{" "}
      </p>
      <p>
        {" "}
        Last Updated{" "}
        <strong> {data.dateupdated ? data.dateupdated : "NA"}</strong>{" "}
      </p>
      <hr />
      <h3 className="phone">
        {data.auditor_mobile ? data.auditor_mobile : "NA"}
      </h3>
      <h3 className="email">
        {data.auditor_username ? data.auditor_username : "NA"}
      </h3>
    </ProfileCardStyle>
  );
}

const ProfileCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--border-color3);
  border-radius: 5px;
  .cancel-button {
    background: white;
    border: none;
    padding: 10px;
    border-radius: 4%;
    margin-right: 18%;
    // margin-left: 17%;
    position: absolute;
    // left: 5%;
  }
  .imgContainer {
    color: transparent;
    transition: all 0.3s ease;
    position: relative;
    width: 88px;
    height: 88px;
    margin-bottom: 20px;
    .prof-edt {
      // background-color: red;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      box-sizing: box-sizing;
      position: relative;
      // overflow: hidden;
      .edit-camera {
        width: 32px;
        height: 32px;
        /* position: relative; */
        background-color: white;
        position: absolute;
        border-radius: 50%;
        bottom: 0%;
        right: 0%;
        z-index: 1;

        // z-index: 99999999;
      }
    }
    .prof-edt .prof-edt-inp {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-progress-appearance: none;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
    }
    .prof-edt img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .prof-edt .prof-edt-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 56%;
      height: auto;
      // width: 30px;
      // height: 30px;
      object-fit: cover;
      border-radius: 50%;
      // z-index: 1;
      // opacity: 0;
      transition: all 300ms ease;
    }
    .prof-edt:hover .prof-edt-icon {
      opacity: 1;
    }
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: cover;
      border-radius: 50%;
      z-index: 0;
    }
  }
  .phone,
  .email {
    font-size: 18px;
    color: var(--dark3);
    padding-left: 33px;
    position: relative;
    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 20px;
      width: 20px;
      position: absolute;
      left: 0;
    }
  }
  .email:before {
    background-image: url(${MailGrad});
  }
  .phone:before {
    background-image: url(${CallGrad});
  }
  hr {
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid var(--hr);
  }
  h2 {
    margin-bottom: 12px;
  }
  h3 {
    margin: 7px 0;
  }
  p {
    margin-bottom: 12px;
    font-size: 16px;
    color: var(--dark6);
    strong {
      color: var(--dark1);
    }
  }
`;
