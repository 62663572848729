import React, { useState } from "react";
import styled from "styled-components";
import { Text, Button, Select, PopupBody } from "../../Components/Elements";
import {
  SearchGrad,
  OrderGrad,
  FilterGrad,
  UploadGrad,
  PlusGrad,
  CalanderGrad,
  DownArrowGrad,
} from "../../assets/ImageUrl";
import RangeSlider from "../../Components/RangeSlider";
import Popup from "../../Components/Popup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function Configure() {
  const dispatch = useDispatch();
  const navvigate = useNavigate();

  let configureObj = {
    accid: "",
    swskey: "",
    vciplevel: "",
    agentpool: "",
    isauditor_available: "",
    maxslot_duration: "",
    agentcooldown_period: "",
    customer_disconnect_buffer: "",
    reschedule_vcip_show_before_time: "",
    smtp_mail_from: "",
    smtp_mail_pwd: "",
    smtp_mail_host: "",
    smtp_mail_port: "",
    officerhours: "",
    breakhours: "",
  };

  const [popup, setPopup] = useState("");
  const [configureModel, setConfigureModel] = useState(configureObj);
  const [configureModelErrs, setConfigureModelErrs] = useState(configureObj);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfigureModel((prevState) => ({ ...prevState, [name]: value }));
  };
  // nothingToDiscard || discard || save

  // console.log(configureModel, "configureModel");

  const configureBtn = () => {};

  const nothingToDiscard = {
    disc: "Nothing to discard.",
    btn1: "Cancel",
    btn1Func: (e) => setPopup(""),
    btn2: "Discard",
    btn2Func: (e) => setPopup(""),
  };

  const discard = {
    ...nothingToDiscard,
    disc: "Are you sure you want to discard unsaved changes?",
  };

  const save = {
    disc: "Are you sure you want to save changes?",
    btn1: "Cancel",
    btn1Func: (e) => setPopup(""),
    btn2: "Save",
    btn2Func: (e) => setPopup(""),
  };

  return (
    <>
      <ConfigureStyle>
        <h4 className="fd5">Configure</h4>
        <div className="elementList">
          <div className="elementContainer">
            <Text
              type="text"
              name="accid"
              placeholder="Account ID"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.accid}</p>
          </div>

          <div className="elementContainer">
            <Text
              type="text"
              name="swskey"
              placeholder="SWS Key"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.swskey}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="vciplevel"
              placeholder="vciplevel"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.vciplevel}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="agentpool"
              placeholder="Agent Pool"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.agentpool}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="isauditor_available"
              placeholder="IsAuditor available *"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.isauditor_available}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="maxslot_duration"
              icons={{ right: { src: PlusGrad } }}
              placeholder="Duration"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.maxslot_duration}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="agentcooldown_period"
              icons={{ right: { src: PlusGrad } }}
              placeholder="Agentcooldown period"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.agentcooldown_period}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="customer_disconnect_buffer"
              icons={{ right: { src: CalanderGrad } }}
              placeholder="Customer DisConnect Buffer"
              change={handleChange}
            />
            <p className="error">
              {configureModelErrs.customer_disconnect_buffer}
            </p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="reschedule_vcip_show_before_time"
              icons={{ right: { src: CalanderGrad } }}
              placeholder="Reschedule Before Time"
              change={handleChange}
            />
            <p className="error">
              {configureModelErrs.reschedule_vcip_show_before_time}
            </p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="smtp_mail_from"
              icons={{ right: { src: CalanderGrad } }}
              placeholder="Smtp Mail From"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.smtp_mail_from}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="smtp_mail_pwd"
              icons={{ right: { src: CalanderGrad } }}
              placeholder="Smtp Mail Pwd"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.smtp_mail_pwd}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="smtp_mail_host"
              icons={{ right: { src: CalanderGrad } }}
              placeholder="Smtp Mail Host"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.smtp_mail_host}</p>
          </div>
          <div className="elementContainer">
            <Text
              type="text"
              name="smtp_mail_port"
              icons={{ right: { src: CalanderGrad } }}
              placeholder="Smtp Mail Port"
              change={handleChange}
            />
            <p className="error">{configureModelErrs.smtp_mail_port}</p>
          </div>
          {/* 
        {/* <div className="elementContainer">
          <Text 
            type="file"
            name="historyList"
            icons={{right: {src: UploadGrad}}}
            placeholder="Holiday List ( Excel sheet )"
          />
        </div>
        <div className="elementContainer">
          <Select
            options={['Monday to Friday ', 'Monday to Friday with 1st & 3rd Saturday', 'Monday to Sunday working']}
            clickChange={(val) => console.log(val)}
            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
            defaultOption="Work Plan"
            showSearch = {false}
          />
        </div>
        <div className="elementContainer">
          <Select
            options={['option1', 'option2', 'option3']}
            clickChange={(val) => console.log(val)}
            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
            defaultOption="Video KYC Slot Duration"
            showSearch = {false}
          />
        </div>
        <div className="elementContainer">
          <Select
            options={['option1', 'option2', 'option3']}
            clickChange={(val) => console.log(val)}
            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
            defaultOption="Customer Join Buffer"
            showSearch = {false}
          />
        </div>
        <div className="elementContainer">
          <Select
            options={['option1', 'option2', 'option3']}
            clickChange={(val) => console.log(val)}
            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
            defaultOption="Agent Cool Down Period"
            showSearch = {false}
          />
        </div>
        <div className="elementContainer">
          <Select
            options={['option1', 'option2', 'option3']}
            clickChange={(val) => console.log(val)}
            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
            defaultOption="Customer Disconnect Buffer"
            showSearch = {false}
          />
        </div>
        <div className="elementContainer">
          <Select
            options={['option1', 'option2', 'option3']}
            clickChange={(val) => console.log(val)}
            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
            defaultOption="Max VCIP per agent/day"
            showSearch = {false}
          />
        </div> */}

          <div className="elementContainer doubled">
            <label>Agent Allocation Split</label>
            <div className="leftRange">
              <h4>80%</h4>
              <label>Scheduled</label>
            </div>
            <div className="rangeContainer">
              <RangeSlider value="80" />
            </div>
            <div className="rightRange">
              <h4>20%</h4>
              <label>Live</label>
            </div>
          </div>

          <div className="elementContainer">
            <div className="rangeBox">
              <label>Office Timings *</label>
              <Text
                type="text"
                name="officerhours"
                value="12:30"
                className="timeBox"
              />
              <Text
                type="text"
                name="officerhours"
                value="12:30"
                className="timeBox"
              />
            </div>
          </div>
          <div className="elementContainer">
            <div className="rangeBox">
              <label>Break Timings *</label>
              <Text
                type="text"
                name="breakhours"
                value="12:30"
                className="timeBox"
              />
              <Text
                type="text"
                name="breakhours"
                value="12:30"
                className="timeBox"
              />
            </div>
          </div>
        </div>

        {/* </div> */}
        <div className="btnContainer">
          <Button
            name="applyLeave"
            dispName="Disard"
            click={() => setPopup("discard")}
            className="cancel fg1"
          />
          <Button
            name="applyLeave"
            dispName="Save"
            click={() => setPopup("save")}
            className="delete"
          />
        </div>
      </ConfigureStyle>
      {popup === "nothingToDiscard" && (
        <Popup
          heading="Discard Changes"
          body={<PopupBody comp={nothingToDiscard} />}
          close={() => setPopup("")}
          className="px500"
        />
      )}
      {popup === "discard" && (
        <Popup
          heading="Discard Changes"
          body={<PopupBody comp={discard} />}
          close={() => setPopup("")}
          className="px500"
        />
      )}
      {popup === "save" && (
        <Popup
          heading="Discard Changes"
          body={<PopupBody comp={save} />}
          close={() => setPopup("")}
          className="px500"
        />
      )}
    </>
  );
}

const ConfigureStyle = styled.div`
  box-shadow: var(--cardShadow);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  .fd5 {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 16px;
  }
  label {
    font-size: 18px;
    color: var(--dark6);
  }
  .elementList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 48px;
  }
  .elementContainer {
    width: 30%;
    align-self: center;
    input,
    .selected-option {
      font-size: 18px;
    }
    &.doubled {
      width: calc(60% + 20px);
      height: 51px;
      border: var(--border);
      border-radius: 5px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      .rangeContainer {
        flex: 1;
        input[type="range"] {
          width: 100%;
        }
      }
      .leftRange,
      .rightRange {
        text-align: center;
        h4 {
          color: #f24747;
          font-size: 18px;
        }
        label {
          font-size: 14px;
        }
      }
    }
  }
  .rangeBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    label {
      /* flex: 1; */
    }
    .timeBox {
      width: 60px;
      padding: 10px 5px;
      input {
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    button {
      width: 140px;
      font-size: 20px;
    }
  }
`;
