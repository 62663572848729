import { CREATE_UPDATE_DELETE_SERVICE_INCIDENTS, GET_SERVICE_INCIDENTS, GET_SERVICE_LIST, SUBMIT_SERVICE_INCIDENTS, VCIP_ROLLBACK } from '../OperationsActions/OperationActionTypes'
export const vcipRollBackSagaAction = (payload) => {
  return {
    type: VCIP_ROLLBACK,
    payload: payload,
  };
};

export const creUpdDelSerIncidentsSagaAction = (payload) => {
  return {
    type: CREATE_UPDATE_DELETE_SERVICE_INCIDENTS,
    payload: payload,
  };
};

export const getServicesListSagaAction = (payload) => {
  return {
    payload,
    type: GET_SERVICE_LIST
  };
};

export const getServiceIncidentsSagaAction = (payload) => {
  return {
    payload,
    type: GET_SERVICE_INCIDENTS
  };
};


