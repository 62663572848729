import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import data from '../Data/customers.json'
import NoRecordFound from "../../Components/NoRecordFound";
import {
  RightTick,
  WrongTick,
  PlayGrad,
  MultiUserGrad,
  SearchGrad,
  OrderGrad,
  ProfileDummy,
  CalanderGrad,
  DownloadWhite,
} from "../../assets/ImageUrl";
import { actionGetVcipDetails, getCallHistorySagaAction } from "../../Store/SagasActions/DashboardOverviewSagaAction";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import HeaderFilters from "../../Components/HeaderFilters";
import LoaderClr from "../../Components/LoaderClr";
import Popup from "../../Components/Popup";
import { CarouselElement } from "../../Components/CustomersSnippets";
import CallHistory from "../../Pages/SubPages/CallHistory";
import RangeDate from "../../Components/RangeDate";
import { addDays, subDays, format } from "date-fns";
import uuid from "react-uuid";
import QualityRoles from "../../Constants/Roles";


let customDateRange;
export default function VCIPListQuality() {
  // let placeIt = "";
  const [callHistory, setCallHistory] = useState([]);
  const [callHistoryOriginal, setCallHistoryOriginal] = useState([]);
  const [vcipdetails, setVcipDetails] = useState('');
  const [viewdet, setViewDet] = useState("");
  const [singleCustomerDetails, setSingleCustomerDetails] = useState({});
  const [popup, Setpopup] = useState("")
  const [num, setNum] = useState(1);
  const [img, setImg] = useState("")
  const [dateRangeBtn, setDateRangeBtn] = useState("Today");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [showDate, setShowDate] = useState(false);
  const [SelectedDate, setSelectedDate] = useState([])




  const roleID = sessionStorage.getItem("role")
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const globalAccountId = useSelector((state) => state?.GlobalRecuder.globalAccountId);
  const AccountSwap = roleID === "Account Admin" ? sessionStorage.getItem("accid") : account;
  const NIC = process.env.REACT_APP_NIC_CLIENT


  useEffect(() => {
    if (AccountSwap) {
      setCallHistory([]);
      getCallHistoryAction(AccountSwap);
    }
  }, [AccountSwap])

  // const customer_headers = ["Last Activity Timestamp", "VCIP ID", "Customer Name" , "Duration" , "Client Name" , "Action"];

  const column = [
    {
      name: "Last Activity Timestamp",
      selector: (row) => row["endtime"],
      format: (row) => moment(row.endtime).format("lll"),
      sortable: true,
      // width: "175px"
    },
    {
      name: "VCIP ID",
      selector: (row) => row["vcipid"],
      sortable: true,
      // width: "90px"
    },
    {
      name: "Customer Name",
      selector: (row) => row["name"],
      sortable: true,
      // width: "140px"
    },
    {
      name: "Duration",
      selector: (row) => row["duration"],
      sortable: true,
      // width: "110px"
    },
    {
      name: "Client Name",
      selector: (row) => row["accname"],
      sortable: true,
      // width: "200px"
    },
    {
      name: "Agent Name",
      selector: (row) => row["agentname"],
      sortable: true,
      // width: "170px"
    },
    {
      name: "Auditor Name",
      selector: (row) => row["auditorname"],
      sortable: true,
      // width: "170px"
    },
    {
      name: "Agent Status",
      selector: (row) => (
        <span
          className={`status 
            // ${row["agentstatus"] === "1" ? "green" : "#d3d3d3"}
            ${row["agentstatus"] === "1" && "green"}
            ${row["agentstatus"] === "2" && "red"}
            ${row["agentstatus"] === "3" && "orange"}
            ${(row["agentstatus"] === "" || row["agentstatus"] === "NA") && "#d3d3d3"}
            `}
        >
          {/* {row["agentstatus"]} */}
          {row["agentstatus"] === "1" && <td>Approved</td>}
          {row["agentstatus"] === "2" && <td>Rejected</td>}
          {row["agentstatus"] === "3" && <td>Discrepancy</td>}
          {row["agentstatus"] === "" ||
            (row["agentstatus"] === "NA" &&
              // <td>-</td>
              <td>Pending</td>
            )}
        </span>
      ),
      sortable: true,
      // width: "150px"
    },
    {
      name: "Auditor Status",
      selector: (row) => (
        <span
          className={`status 
            // ${row["auditorstatus"] === "1" ? "green" : "#d3d3d3"}
            ${row["auditorstatus"] === "1" && "green"}
            ${row["auditorstatus"] === "2" && "red"}
            ${row["auditorstatus"] === "3" && "orange"}
            ${(row["auditorstatus"] === "" || row["auditorstatus"] === "NA") &&
            "#d3d3d3"
            }
            `}
        >
          {/* {row["auditorstatus"]} */}
          {row["auditorstatus"] === "1" && <td>Approved</td>}
          {row["auditorstatus"] === "2" && <td>Rejected</td>}
          {/* {row["auditorstatus"] === "3" && <td>Issued</td>} */}
          {(row["auditorstatus"] === "" ||
            row["auditorstatus"] === "NA") && (
              // <td>-</td>
              <td>Pending</td>
            )}
        </span>
      ),
      sortable: true,
      // width: "150px",
    },
  ];

  const client_NIC = [
    {
      name: "Date of Verifications",
      selector: (row) => row["dateofverification"],
      format: (row) => moment(row.endtime).format("lll"),
      sortable: true,
      // width: "175px"
    },
    {
      name: "Pensioners ID",
      selector: (row) => row["appid"],
      sortable: true,
      // width: "110px"
    },
    {
      name: "Customers Name",
      selector: (row) => row["name"],
      sortable: true,
      // width: "140px"
    },
    {
      name: "Service RO",
      selector: (row) => row["servicero"],
      sortable: true,
      // width: "200px"
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
      // width: "90px"
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {row["vcippdf"] === "" ? (
            <button className="download" disabled>
              <img src={DownloadWhite} alt="download" style={{ opacity: 0.5, cursor: "default" }} />
              <a
                style={{ opacity: 0.5, cursor: "default" }}
                alt="download icon"
                disabled
              >
                Download
              </a>
            </button>
          ) : (
            <button className="download">
              <img src={DownloadWhite} alt="download" />
              <a href={row["vcippdf"]} target="_blank" alt="download icon" download="download">
                Download
              </a>
            </button>
          )}
        </>
      ),
      // sortable: true,
    },

  ]


  const dispatch = useDispatch();

  // useEffect(() => {
  //   getCallHistoryAction(AccountSwap);
  // }, []);

  //  getting Call history details function

  const getCallHistoryAction = () => {
    const model = {
      accid: AccountSwap,
      listtype: "1",
      fdate: "",
      tdate: "",
    };
    dispatch(
      getCallHistorySagaAction({ model: model, callback: getCallHistoryRes })
    );
  };

  const getCallHistoryRes = (data) => {
    setCallHistory(data);
    setCallHistoryOriginal(data)
    // setCallHistoryRange(data)

  };

  // getting Individual VCIP details function
  const getVcipUserDetails = (type, data) => {
    setSingleCustomerDetails(data)
    if (data.vcipid !== viewdet.vcipid) {
      const model = {
        vcipkey: data?.vcipkey,
        type: type,
        vtid: data.vtid
      };
      dispatch(
        actionGetVcipDetails({ model: model, callback: getEachVcipDetails })
      );
    } else {
      Setpopup(type);
    }
  };

  const getEachVcipDetails = (data, typeid) => {
    setViewDet(data);
    Setpopup(typeid);
  };


  // // Kyc Report 
  // const KycReportView = () => {
  //   alert("show Rpeort")
  // }




  const Activities = ({ data }) => {
    return (
      <ActivitiesStyle>
        <ul className="events">
          {NIC === "1" && QualityRoles.QA ? "" :
            <li data-tip data-for="">
              <div className="contains" onClick={() => getVcipUserDetails("videoRecording", data)}>
                <img src={PlayGrad} alt="" />
                <p>View video</p>
              </div>
            </li>}
          <li data-tip data-for="">
            {/* <div className="contains" onClick={() => props?.dispPop("viewReport", data)}> */}
            <div className="contains" onClick={() => getVcipUserDetails("viewReport", data)}>
              <img src={MultiUserGrad} alt="" />
              <p>View Report</p>
            </div>
          </li>
        </ul>
      </ActivitiesStyle>
    )
  };

  // headerActions
  const headerActions = [
    {
      img: SearchGrad,
      key: 4,
      // func: (e) => console.log(e),
    },
    {
      img: CalanderGrad,
      key: "cal",
      func: () => setShowDate(true),
    },

  ];

  // search in table
  const SearchItems = (val) => {
    let copyData = [...callHistoryOriginal];
    const updtedSearh = copyData.filter((item) => {
      return item?.accname?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.vcipid?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.name?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.mobile?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.agentname?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.auditorname?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.appid?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
    })
    setCallHistory(updtedSearh);
  }

  // slide videos
  const slideNext = (val) => {
    if (viewdet?.videolinks?.length > 0 && num > 0) {
      setNum((prevState) => prevState + val);
    }
  };


  // Kyc Report 
  const KycReportModeCmp = ({ mode, ckycnumber }) => {
    switch (mode) {
      case "0":
        return "online";
      case "1":
        return "Offline";
      case "2":
        return "SteamlessOfflineKYC";
      case "3":
        return "Digilocker";
      case "4":
        return "AadhaarOCR";
      case "5":
        return (
          <p>
            {" "}
            CKYC,
            <span className="text-dark">
              ckycnumber:
              <p className="text-primary">{ckycnumber}</p>
            </span>
          </p>
        );
      case "6":
        return "Passport";
      case "7":
        return "VoterID";
      case "8":
        return "Driving Licence";

      default:
        return "";
    }
  };

  const FuzzyMatch = ({ value }) => {
    let floatValue = parseFloat(value) > 50;
    return floatValue ? (
      <span className="text-success">
        {value == 100 ? Number.parseInt(value) : parseFloat(value).toFixed(2)}%
      </span>
    ) : (
      <span className="text-danger">
        {value == 100 ? Number.parseInt(value) : parseFloat(value).toFixed(2)}%
      </span>
    );
  };

  const cstmrName = viewdet?.customerdetails?.name;
  const cstmrFname = viewdet?.customerdetails?.fname;
  const cstmrDOB = viewdet?.customerdetails?.dob;
  const cstmrGender = viewdet?.customerdetails?.gender;
  const cstmrAddr = viewdet?.customerdetails?.curr_address;
  const cstmrPrmntAddr = viewdet?.customerdetails?.per_address;
  const cstmrMobile = viewdet?.customerdetails?.mobile;
  const cstmrEmail = viewdet?.customerdetails?.email;
  const cstmrAccName = viewdet?.customerdetails?.accname;
  const ipaddress = viewdet?.customerdetails?.ipaddress;
  const geo_location = viewdet?.customerdetails?.geo_location;
  const browser_name = viewdet?.customerdetails?.browserdetails.split(",");
  const ppoid = viewdet?.customerdetails?.customerid

  const vciptemplate = viewdet?.vciptemplate;
  const vcipid = viewdet?.vcipid;

  const kycName = viewdet?.kycdetails?.name;
  const kycFname = viewdet?.kycdetails?.fname;
  const kycDOB = viewdet?.kycdetails?.dob;
  const kycGender = viewdet?.kycdetails?.gender;
  const kycAddr = viewdet?.kycdetails?.address;
  const kycPrmntAddr = viewdet?.kycdetails?.address;
  const kycPht = viewdet?.kycdetails?.pht;
  const KycReportMode = viewdet?.kycdetails?.kycmode;

  const panAIName = viewdet?.pandetails?.ainame;
  const panAIFname = viewdet?.pandetails?.aifname;
  const panAIDOB = viewdet?.pandetails?.aidob;
  const panNumber = viewdet?.pandetails?.aipannumber;

  const pancard = viewdet?.pandetails?.pancard;

  // Form 60
  const isform60declared = viewdet?.pandetails?.isform60declared;
  const form60declaration = viewdet?.pandetails?.form60declaration;

  const editPanAIName = viewdet?.pandetails?.edtname;
  const editPanAIFname = viewdet?.pandetails?.edtfname;
  const editPanAIDOB = viewdet?.pandetails?.edtdob;
  const editPanNumber = viewdet?.pandetails?.edtpannumber;

  const isNsdlValid = viewdet?.pandetails?.oemstatus;
  const nsdlName = viewdet?.pandetails?.oemname;
  const nsdlFName = viewdet?.pandetails?.oemfname;
  const nsdlDOB = viewdet?.pandetails?.oemdob;

  // const agentName = sessionStorage.getItem("username");
  // const auditorName = sessionStorage.getItem("username");
  const agentName = singleCustomerDetails.agentname
  const auditorName = singleCustomerDetails.auditorname
  const agentVideo = viewdet?.videoconfdetails?.videolink;

  const qadetails =
    viewdet?.qadetails?.length > 0 ? viewdet?.qadetails : [];

  const fuzzyMatchGender = viewdet?.fuzzymatchdetails?.kyc_gender;
  const fuzzyMatchAddr = viewdet?.fuzzymatchdetails?.kyc_curr_address;
  const fuzzyMatchPrmntAddr = viewdet?.fuzzymatchdetails?.kyc_per_address;

  const fuzzyMatchAdrPanName = viewdet?.fuzzymatchdetails?.kyc_pan_name;
  const fuzzyMatchAdrPanFname = viewdet?.fuzzymatchdetails?.kyc_pan_fname;
  const fuzzyMatchAdrPanDOB = viewdet?.fuzzymatchdetails?.kyc_pan_dob;
  const fuzzyMatchAdrPanNumber = viewdet?.fuzzymatchdetails?.pan_pannumber;
  const accptedAgentDate = viewdet?.auditedon
  // const auditedOn= moment(accptedAgentDate).format("lll")
  // console.log(auditedOn , "si")

  const liveCapturePhtMatchLevel =
    viewdet?.livecapturedetails?.livecapturepht_matchlevel;

  let lat = viewdet?.customerdetails?.location.split(",")[0];
  let long = viewdet?.customerdetails?.location.split(",")[1];

  const mapURL =
    "https://maps.google.com/maps?width=720&height=600&hl=en&q=" +
    lat +
    "," +
    long +
    "+(encoding)&t=&z=14&ie=UTF8&iwloc=B&output=embed";

  // const currDate = new Date();
  const currDate = viewdet?.videoconfdetails?.agentacceptedon
  const nicDate = viewdet?.livecapturedetails?.capturedon
  const date = moment(currDate).format("lll");
  const dateNic = moment(nicDate).format("lll");
  // const time = moment(currDate).format("LTS");

  const ShowImg = ({ liveImg, pancard, kycPht }) => {
    return (
      <ReportImg>
        <div className="img_show">
          {kycPht && <img src={`data:image/png;base64, ${kycPht}`} alt="img"></img>}
          {liveImg && <img src={`data:image/png;base64, ${liveImg}`} alt="img"></img>}
          {pancard && <img src={`data:image/png;base64, ${pancard}`} alt="img"></img>}
        </div>
      </ReportImg>
    )

  }

  const ViewReport = () => {
    return (
      <>
        <div className="Container">
          <table className="table table-agent-borderless">
            <thead>
              <tr>
                {NIC === "1" ? "" :
                  <>
                    <th>Agent Name</th>
                    <th>Template Type</th>
                  </>
                }
                <th>VCIP ID</th>
                <th>Date & Time Stamp</th>
                {/* <th>Time Stamp</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                {NIC === "1" ? "" :
                  <><td>
                    {agentName}
                  </td>
                    <td>{vciptemplate}</td>
                  </>
                }
                <td>{vcipid}</td>
                <td>{NIC === "1" ? dateNic : date}</td>
                {/* <td>{time}</td> */}
              </tr>
            </tbody>
          </table>
          {/* Customer details table */}
          <div className="">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th colSpan={6} className="text-center">
                    <h3 className="customer-heading">Customer details</h3>
                  </th>
                </tr>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header">User Parameters</th>
                  <th className="kyc-header">
                    Applicant Data <br />
                    <span className="text-primary">{cstmrAccName}</span>
                  </th>
                  <th className="kyc-header">
                    Aadhaar Data <br />
                    <span className="text-primary">
                      <KycReportModeCmp
                        mode={KycReportMode}
                        ckycnumber={viewdet?.kycdetails?.ckycnumber}
                      />
                    </span>
                  </th>
                  {NIC === "1" ? "" :
                    <>
                      <th className="kyc-header">PAN OCR Details</th>
                      <th className="kyc-header">NSDL Data</th>
                      <th className="kyc-header">Match</th>
                    </>
                  }
                </tr>
              </thead>
              {/* ASDF // RELOOK */}
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{cstmrName ? cstmrName : "N/A"}</td>
                  <td>{kycName ? kycName : "N/A"}</td>

                  {NIC === "1" ? "" :
                    <>
                      <td>
                        {editPanAIName
                          ? editPanAIName
                          : panAIName
                            ? panAIName
                            : "N/A"}
                      </td>
                      <td>{nsdlName ? nsdlName : "N/A"}</td>
                      <td>
                        <FuzzyMatch value={fuzzyMatchAdrPanName} />
                      </td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Father’s name</td>
                  <td>{cstmrFname ? cstmrFname : "N/A"}</td>
                  <td>{kycFname ? kycFname : "N/A"}</td>
                  {NIC === "1" ? "" :
                    <><td>
                      {editPanAIFname
                        ? editPanAIFname
                        : panAIFname
                          ? panAIFname
                          : "N/A"}
                    </td>
                      <td>{nsdlFName ? nsdlFName : "N/A"}</td>
                      <td>
                        <FuzzyMatch value={fuzzyMatchAdrPanFname} />
                      </td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Date of Birth</td>
                  <td>{cstmrDOB ? cstmrDOB : "N/A"}</td>
                  <td>{kycDOB ? kycDOB : "N/A"}</td>
                  {NIC === "1" ? "" :
                    <>
                      <td>{panAIDOB ? panAIDOB : "N/A"}</td>
                      <td>{nsdlDOB ? nsdlDOB : "N/A"}</td>
                      <td><FuzzyMatch value={fuzzyMatchAdrPanDOB} /></td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Gender</td>
                  <td>{cstmrGender ? cstmrGender : "N/A"}</td>
                  <td>{kycGender ? kycGender : "N/A"}</td>
                  {NIC === "1" ? "" :
                    <>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <FuzzyMatch value={fuzzyMatchGender} />
                      </td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Current address</td>
                  <td>{cstmrAddr ? cstmrAddr : "N/A"}</td>
                  <td>{kycAddr ? kycAddr : "N/A"}</td>
                  {NIC === "1" ? "" :
                    <>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <FuzzyMatch value={fuzzyMatchAddr} />
                      </td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Permanent address </td>
                  <td>{cstmrPrmntAddr ? cstmrPrmntAddr : "N/A"}</td>
                  <td>{kycPrmntAddr ? kycPrmntAddr : "N/A"}</td>
                  {NIC === "1" ? "" :
                    <>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <FuzzyMatch value={fuzzyMatchPrmntAddr} />
                      </td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Mobile number </td>
                  <td>{cstmrMobile ? cstmrMobile : "N/A"}</td>
                  <td>-</td>
                  {NIC === "1" ? "" :
                    <>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </>
                  }
                </tr>

                <tr>
                  <td>Email address </td>
                  <td>{cstmrEmail ? cstmrEmail : "N/A"}</td>
                  <td>-</td>
                  {NIC === "1" ? "" :
                    <>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </>
                  }
                </tr>

                <tr>
                  {NIC === "1" ? "" :
                    <>
                      <td>PAN NSDL Status</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        {isNsdlValid ? (
                          <span className="text-success">{isNsdlValid}</span>
                        ) : (
                          <span className="text-danger">{isNsdlValid}</span>
                        )}
                      </td>
                      <td>-</td>
                    </>
                  }
                </tr>
                {NIC === "1" ?
                  <>
                    <tr>
                      <td>Pensioner ID</td>
                      <td>{ppoid}</td>
                    </tr>

                  </> : ""
                }

                <tr>
                  {NIC === "1" ? "" :
                    <>
                      <td>PAN number</td>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        {editPanNumber
                          ? editPanNumber
                          : panNumber
                            ? panNumber
                            : "N/A"}
                      </td>
                      <td>
                        {editPanNumber
                          ? editPanNumber
                          : panNumber
                            ? panNumber
                            : "N/A"}
                      </td>
                      <td>
                        <FuzzyMatch value={fuzzyMatchAdrPanNumber} />
                        {/* {isNsdlValid ? (
                      <span className="text-success">{isNsdlValid}</span>
                    ) : (
                      <span className="text-danger">{isNsdlValid}</span>
                    )} */}
                      </td>
                    </>
                  }
                </tr>
              </tbody>
            </table>
            {/* <table cl */}
          </div>

          {/* aaddhar photo */}
          <div className="align-horizontal cus-imgu" style={{ justifyContent: NIC === "1" ? "space-around" : "space-between" }}>
            <div className="face-box">
              <h6 className="text-center">Aadhaar Photo</h6>
              <hr />
              <div className="center">
                <div className="match-img">
                  <img
                    src={
                      kycPht
                        ? `data:image/png;base64,${kycPht}`
                        : ProfileDummy
                    }
                    alt="aadhaar photo"
                    onClick={() => setImg("showimg", kycPht)}
                  />
                </div>
              </div>
            </div>

            {/* pan photo */}
            {NIC === "1" ? "" :
              <>
                <div className="face-box">
                  <h6 className="text-center">{isform60declared ? "Form-60 Decleration" : "PAN Photo"}</h6>
                  <hr />
                  <div className="center">
                    {isform60declared == "1" ? (
                      <>
                        <span className="cus-form">{form60declaration}</span>
                      </>
                    ) : (
                      <div className="match-img">
                        <img
                          src={
                            pancard
                              ? `data:image/png;base64,${pancard}`
                              : ProfileDummy
                          }
                          alt="pan photo"
                          onClick={() => setImg("pancard", pancard)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            }

            {/* live photo */}
            <div className="face-box">
              <h6 className="text-center">Live Photo</h6>
              <hr />
              <div className="center">
                <div className="match-img">
                  <img
                    src={
                      viewdet?.livecapturedetails?.livecapturepht
                        ? `data:image/png;base64,${viewdet?.livecapturedetails?.livecapturepht}`
                        : ProfileDummy
                    }
                    alt="live photo"
                    onClick={() => setImg("liveImg", viewdet?.livecapturedetails?.livecapturepht)}

                  />
                </div>
              </div>
            </div>
          </div>
          {/* Match table */}
          <div className="my">
            <table className="table table-borderd">
              <thead>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header">Face Verification</th>
                  <th className="kyc-header">Match</th>
                  <th className="kyc-header">Agent Status</th>
                  <th className="kyc-header">Remarks</th>
                </tr>
              </thead>

              <tbody>
                {/* Face Verification column */}
                <tr>
                  <td>Liveness Check</td>
                  <td className="text-center">
                    <FuzzyMatch value={liveCapturePhtMatchLevel} />
                  </td>
                  <td className="text-center img-center">
                    {liveCapturePhtMatchLevel ? (
                      parseFloat(liveCapturePhtMatchLevel) > 50 ? (
                        <img src={RightTick} style={{ width: "30px" }} alt="tick icon" />
                      ) : (
                        <img src={WrongTick} alt="check" />
                      )
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{viewdet?.livecaptureremark}</td>
                </tr>

                {/* match column */}
                {/* <tr>
                <td>Face match between Aadhaar & PAN</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td>-</td>
              </tr> */}

                {/* agent status column */}
                <tr>
                  <td>Face match between Aadhaar & Live Photo</td>
                  <td className="text-center">
                    <FuzzyMatch value={viewdet?.live_aadhaar_pht_matchlevel} />

                    {/* {viewdet?.live_aadhaar_pht_matchlevel ? (
                    viewdet?.live_aadhaar_pht_matchlevel > "50" ? (
                      <span className="text-success">
                        {parseFloat(
                          viewdet?.live_aadhaar_pht_matchlevel
                        ).toFixed(2)}
                        %
                      </span>
                    ) : (
                      <span className="text-danger">
                        {parseFloat(
                          viewdet?.live_aadhaar_pht_matchlevel
                        ).toFixed(2)}
                        %
                      </span>
                    )
                  ) : (
                    "N/A"
                  )} */}
                  </td>
                  <td className="text-center img-center">
                    {viewdet?.live_aadhaar_pht_matchlevel ? (
                      parseFloat(viewdet?.live_aadhaar_pht_matchlevel) > 50 ? (
                        <img src={RightTick} style={{ width: "30px" }} alt="tick icon" />
                      ) : (
                        <img src={WrongTick} alt="check" />
                      )
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{viewdet?.live_aadhaar_remark}</td>
                </tr>

                {/* remark */}
                {NIC === "1" ? "" :
                  <>
                    <tr>
                      <td>Face match between Live Photo & PAN</td>
                      <td className="text-center">
                        <FuzzyMatch value={viewdet?.live_pan_pht_matchlevel} />
                      </td>
                      <td className="text-center img-center">
                        {viewdet?.live_pan_pht_matchlevel ? (
                          parseFloat(viewdet?.live_pan_pht_matchlevel) > 50 ? (
                            <img src={RightTick} style={{ width: "30px" }} alt="tick icon" />
                          ) : (
                            <img src={WrongTick} alt="check" />
                          )
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>{viewdet?.live_pan_remark}</td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
            {/* <table cl */}
          </div>
          {/* Location check */}
          <div className="align-horizontal-top">
            <div className="col-lg-7 mt-1 ">
              <div className="location-box">
                <h6 className="text-center mb">Location check</h6>
                <hr />
                <div className="text-center my">
                  {/* <Map
                  zoom={15}
                  center={{ lat: parseFloat(lat), lng: parseFloat(long) }}
                /> */}
                  <iframe width="100%" height="auto" src={mapURL}></iframe>
                </div>
              </div>
            </div>

            {/* Browser IP Details */}
            <div className="col mt-1">
              <table className="table table-borderless">
                <thead>
                  <tr className="text-center vertical-align-middle">
                    <th colSpan={3} className="text-center">
                      <h3 className="customer-heading">Browser IP Details</h3>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>IP Address</td>
                    <td>{ipaddress ? ipaddress : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{geo_location ? geo_location : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Latitude and Longitude</td>
                    <td>
                      {lat}&deg; N, {long}&deg; E
                    </td>
                  </tr>
                  <tr>
                    <td>ISP</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>IP Country code</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Browser name</td>
                    <td>
                      {browser_name ? (
                        <ul>
                          {browser_name?.map((i, item) => (
                            <li>{i}</li>
                          ))}
                        </ul>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <table cl */}
            </div>
          </div>

          {/* {(props?.type == "auditor" ||
            props?.type == "pending" ||
            props?.type == "completed") && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="face-box mt-4">
                    <h6 className="text-center">Agent Screen Recording</h6>
                    <hr />
                    <div className="center">
                      <div className="match-img h-auto w-auto">
                        <video width="960" height="617" controls>
                          <source src={agentVideo} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}


          {/* <div className="row">
            <div className="col-lg-12">
              <div className="face-box mt-4">
                <h6 className="text-center">Agent Screen Recording</h6>
                <hr />
                <div className="center">
                  <div className="match-img h-auto w-auto">
                    <video width="960" height="617" controls>
                      <source src={agentVideo} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Question and answer table */}
          {NIC === "1" ? "" :
            <>
              <div className="col-lg-12 mt-3">
                <table className="table table-questionAnswers">
                  <thead>
                    <tr className="text-center vertical-align-middle">
                      <th className="kyc-header">Question & Answer</th>
                      <th className="kyc-header">Agent Status</th>
                      <th className="kyc-header">Remarks</th>
                    </tr>
                  </thead>

                  <tbody>
                    {qadetails.map((item, index) => (
                      <tr>
                        <td>
                          Q. {item?.ques} <br />
                          A. {item?.answer}
                        </td>
                        <td className="text-center">
                          {item?.status === "1" ? (
                            <img src={RightTick} style={{ width: "30px" }} alt="tick icon" />
                          ) : (
                            <img src={WrongTick} alt="check" />
                          )}
                        </td>
                        <td>{item?.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          }

          <div className="col-lg-12" style={{ marginTop: NIC === "1" ? "16px" : "" }}>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th colSpan={6} className="text-center">
                    <h3 className="customer-heading">Final Remark</h3>
                  </th>
                </tr>
                <tr className="text-center vertical-align-middle">
                  <th className="kyc-header col-md-2"></th>
                  <th
                    className={
                      "kyc-header text-start px-4"
                    }
                  >
                    Agent Remark
                  </th>
                  {NIC === "1" ? "" : <th className="kyc-header">Auditor Remark</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {NIC === "1" ? "" :
                    <>
                      <td>Agent Name</td>
                      <td>{agentName ? agentName : "N/A"}</td>
                      <td>{auditorName ? auditorName : "N/A"}</td>
                    </>
                  }
                  {/* {props?.type == "completed" && <td>{auditorName}</td>} */}

                </tr>


                <tr>
                  <td>KYC Date</td>
                  {NIC === "1" ? <td>{viewdet?.livecapturedetails?.capturedon ? moment(viewdet?.livecapturedetails?.capturedon).format("lll") : "N/A"}</td>
                    : <td>{viewdet?.videoconfdetails?.agentacceptedon ? moment(viewdet?.videoconfdetails?.agentacceptedon).format("lll") : "N/A"}</td>}
                  {/* {props?.type == "completed" && (
                    // <td>
                    //   {date} {time}
                    // </td>
                  )} */}

                  {/* {props?.type == "completed" && ( */}
                  {NIC === "1" ? "" : <td>
                    {viewdet?.auditedon ? viewdet?.auditedon : "N/A"}
                  </td>}
                  {/* )} */}

                </tr>
                <tr>
                  <td>Status Update</td>
                  <td>
                    {singleCustomerDetails?.agentstatus === "1" ? (
                      <span className="text-success">Approved</span>
                    ) : singleCustomerDetails?.agentstatus === "2" ? (
                      <span className="text-danger">Rejected</span>
                    ) : singleCustomerDetails?.agentstatus === "3" ? (
                      <span className="text-danger">Discrepancy</span>
                    ) : (
                      "-"
                    )}
                  </td>
                  {NIC === "1" ? "" : <td>
                    {singleCustomerDetails?.auditorstatus === "1" ? (
                      <span className="text-success">Approved</span>
                    ) : singleCustomerDetails?.auditorstatus === "2" ? (
                      <span className="text-danger">Rejected</span>
                    ) : singleCustomerDetails?.auditorstatus === "3" ? (
                      <span className="text-danger">Discrepancy</span>
                    ) : (
                      "Pending"
                    )}
                  </td>}
                </tr>
                <tr>
                  <td>Final Remark</td>
                  <td>{viewdet?.agentremarks}</td>
                  {NIC === "1" ? "" : <td>{viewdet?.auditorremarks}</td>}
                </tr>
              </tbody>
            </table>
          </div>

          {NIC === "1" ? "" :
            <>
              <div className="risk">
                <div className="risk_rating">
                  <table className="table table-borderless">
                    <thead>
                      <tr className="text-center vertical-align-middle">
                        <th colSpan={3} className="text-center">
                          <h3 className="customer-heading">Risk Rating</h3>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Risk rating status</td>
                        <td>{viewdet?.risk_rating_status}</td>
                      </tr>
                      <tr>
                        <td>Risk rating report</td>
                        <td>
                          <a
                            href={viewdet?.risk_rating_report}
                            download
                            target="_blank"
                          >
                            Download
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Rating rating txnid</td>
                        <td>{viewdet?.risk_rating_txnid}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="risk_rating">
                  <table className="table table-borderless">
                    <thead>
                      <tr className="text-center vertical-align-middle">
                        <th colSpan={3} className="text-center">
                          <h3 className="customer-heading">Risk Screening</h3>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Screening status</td>
                        <td>{viewdet?.screening_status}</td>
                      </tr>
                      <tr>
                        <td>Screening alert count</td>
                        <td>{viewdet?.screening_alerts_count}</td>
                      </tr>
                      <tr>
                        <td>Screening alert report</td>
                        <td>{viewdet?.screening_alerts_report}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          }
        </div>
      </>
    );
  };

  // Range Calender
  const dateRangeBtns = [
    {
      name: "Today",
      dateRange: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ],
    },
    {
      name: "Yesterday",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 1),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 7 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 8),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 30 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 31),
          key: "selection",
        },
      ],
    },
    {
      name: "Last month",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 31),
          key: "selection",
        },
      ],
    },
    {
      name: "Custom",
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
    },
  ];

  const applyDateRange = () => {
    let tdate, fdate;
    if (dateRangeBtn === "Custom") {
      tdate = moment(customDateRange.selection.endDate).format("YYYY-MM-DD");
      fdate = moment(customDateRange.selection.startDate).format("YYYY-MM-DD")
    } else {
      tdate = moment(dateRange[0].startDate).format("YYYY-MM-DD");
      fdate = moment(dateRange[0].endDate).format("YYYY-MM-DD")
    }
    let copyData = [...callHistoryOriginal];
    const updateDataStatus = copyData.filter(item => {
      let dates = moment(item.endtime).format("YYYY-MM-DD");
      return (dates >= fdate && dates <= tdate);
    })
    setCallHistory(updateDataStatus);
    setShowDate(false);
  };

  const handleDate = (range) => {
    customDateRange = range;
    setDateRangeBtn("Custom");
  };

  const RangeDatePicker = () => (
    <RangeDatePickerStyle>
      <div className="labels">
        {dateRangeBtns.map((range) => (
          <button
            key={uuid()}
            className={`${dateRangeBtn === range.name ? "themeBtn" : "cancel"}`}
            onClick={() => {
              setDateRange(range.dateRange);
              setDateRangeBtn(range.name);
            }}
          >
            {" "}
            {range.name}{" "}
          </button>
        ))}
      </div>
      <RangeDate changes={handleDate} dateRange={dateRange} />
      <div className="buttonContainer">
        <button className="themeBtn" onClick={(val) => applyDateRange(val)}>
          Apply
        </button>
      </div>
    </RangeDatePickerStyle>
  );

  // Range Calender Funtionality Stop

  const getTableAtt = () => {
    let columns = column
    let expandableRows
    if (NIC === "1") {
      columns = client_NIC
    }
    return {
      columns,
      expandableRows: true
    }
  }
  return (
    <>
      <div className="main-loader">
        <LoaderClr />
      </div>
      <ReportStyle>
        <AllCallHistoryStyle>
          <HeaderFilters
            heading={NIC === "1" ? { name: "Pensioners List" } : { name: "VCIP List" }}
            actionBtns={headerActions}
            change={(e) => SearchItems(e.target.value)}
          />
          {callHistory?.length > 0 ? (
            // <DataTable
            //   columns={columns}
            //   data={callHistory}
            //   pagination
            //   expandableRows={NIC && QualityRoles.QA ? false : true}
            //   expandableRowsComponent={(row) => Activities(row)}
            // />
            <DataTable
              {...getTableAtt()
              }
              data={callHistory}
              pagination
              expandableRowsComponent={(row) => Activities(row)} />
          ) : (
            <div className="norecord">
              <NoRecordFound />
            </div>
          )}
        </AllCallHistoryStyle>
      </ReportStyle>


      <>
        {popup === "videoRecording" && (
          <Popup
            className="px800"
            // heading={`Video Recording`}
            heading={`Video Recording (${viewdet?.videolinks?.length})`}
            body={
              <CarouselElement
                videotag={viewdet?.videolinks}
                slideNext={slideNext}
              />
            }
            close={() => Setpopup("")}
          />
        )}
        {popup === "viewReport" && (
          <Popup
            className="px1200"
            clrHeading={true}
            heading={"Kyc Report"}
            body={<ViewReport />}
            close={() => Setpopup("")}
          />
        )}
        {img === "showimg" && (
          <Popup
            className="px800"
            heading="Aadhaar Photo"
            body={<ShowImg kycPht={kycPht} />}
            close={() => setImg("")}
          />
        )}
        {img === "pancard" && (
          <Popup
            className="px800"
            heading="Pan Photo"
            body={<ShowImg pancard={pancard} />}
            close={() => setImg("")}
          />
        )}
        {img === "liveImg" && (
          <Popup
            className="px800"
            heading="Live Photo"
            // body={<ShowImg liveImg={(viewdet?.livecapturedetails?.livecapturepht).length < 0 ? viewdet?.livecapturedetails?.livecapturepht : "NO Image found" } />}
            body={<ShowImg liveImg={viewdet?.livecapturedetails?.livecapturepht} />}
            close={() => setImg("")}
          />
        )}
      </>
      {
        showDate && (
          <Popup
            className="px680 dateRangePicker"
            heading="Date range"
            body={<RangeDatePicker />}
            close={() => setShowDate(false)}
          />
        )
      }
    </>
  );
}

const ReportImg = styled.div`
.img_show {
  img{
    width: 100%
  }
}
`

const ReportStyle = styled.div`
    background: var(--white);
    box-shadow: var(--cardShadow);
    padding: 20px 24px 0px;
    height: calc(100vh - 120px);
    overflow: auto;
    position: relative;
`;

const AllCallHistoryStyle = styled.div`
  .norecord {
    height: calc(100vh - 300px);
  }
  .rdt_TableBody {
    max-height: calc(100vh - 388px);
    overflow: auto;
  }
  .download {
    border: none;
    border-radius: 3px;
    color: var(--white);
    font-weight: 600;
    padding: 6px 12px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(87.85deg, #38568f 7.3%, #38a1f7 95.41%);
    a {
      color: white;
      text-decoration: none;
    }
  }
  .status {
    font-weight: 600;
    display: block;
    padding: 4px;
    min-width: 72px;
    text-align: center;
    &.green {
      background: #08c1521a;
      color: #08c152;
      border-radius: 3px;
    }
    &.orange {
      background: #f9c7171a;
      color: #f9c717;
      border-radius: 3px;
    }
    &.red {
      background: #f247471a;
      color: #f24747;
      border-radius: 3px;
    }
  }
`;

const RangeDatePickerStyle = styled.div`
  .buttonContainer {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    button {
      padding: 9px 14px;
      font-size: 18px;
      width: 140px;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 12px 0;
    button {
      padding: 10px 14px;
      font-size: 16px;
      border: none;
    }
  }
`;

const ActivitiesStyle = styled.div`
  background: var(--white);
  position: relative;
  top: -2px;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .events {
    display: flex;
    background: rgba(56, 161, 247, 0.08);
    border-radius: 5px;
    border: 1.2px solid #38568f;
    max-width: 700px;
    li {
      &:hover {
        .contains {
          background: rgb(56 161 247 / 5%);
          img {
            transform: scale(1.1);
          }
        }
      }
      .contains {
        text-align: center;
        padding: 14px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s linear;
        img {
          height: 24px;
          transition: all 0.2s linear;
        }
        p {
          font-size: 14px;
          background: linear-gradient(180deg, #38568f 0%, #38a1f7 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      &:not(:last-child) {
        .contains {
          &:after {
            content: "";
            height: calc(100% - 32px);
            width: 1px;
            background: rgba(56, 161, 247, 0.3);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
`;


