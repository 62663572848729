import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import data from '../Data/customers.json'
import NoRecordFound from "./NoRecordFound";
import {
  UserBoxGrad,
  DocBundleGrad,
  WorldGrad,
  CalanderGrad,
  ScreenShareGrad,
  PlayGrad,
  MultiUserGrad,
} from "../assets/ImageUrl";
import {
  actionGetVcipDetails,
  getSingleReferenceCallHistorySagaAction,
  getCallHistorySagaAction,
} from "../Store/SagasActions/DashboardOverviewSagaAction";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import RangeDate from "./RangeDate";
import { addDays, subDays, format } from "date-fns";
import uuid from "react-uuid";
import Popup from "./Popup";
import { useLocation } from "react-router-dom";

let customDateRange;
export default function AllCallHistory(props, { dispPop }) {
  const location = useLocation();
  const [callHistory, setCallHistory] = useState([]);
  const [callHistoryOriginal, setCallHistoryOriginal] = useState([]);
  const [dateRangeBtn, setDateRangeBtn] = useState("Today");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const roleID = sessionStorage.getItem("role");
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const globalAccountId = useSelector(
    (state) => state?.GlobalRecuder.globalAccountId
  );
  const AccountSwap =
    roleID === "Account Admin" || roleID === "Operations" ? sessionStorage.getItem("accid") : account;

  useEffect(() => {
    if (AccountSwap) {
      setCallHistory([]);
      getCallHistoryAction(AccountSwap);
    }
  }, [AccountSwap]);

  // Search table
  useEffect(() => {
    let copyData = [...callHistoryOriginal];
    const updtedSearh = copyData.filter((item) => {
      return (
        item?.accname
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.vcipid
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.name
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.mobile
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.agentname
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.auditorname
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.agentstatus
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1 ||
        item?.auditorstatus
          ?.toLowerCase()
          ?.indexOf(props?.searchedText?.toLowerCase()) !== -1
      );
    });
    setCallHistory(updtedSearh);
  }, [props?.searchedText]);

  // Range Date

  const dateRangeBtns = [
    {
      name: "Today",
      dateRange: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ],
    },
    {
      name: "Yesterday",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 1),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 7 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 8),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 30 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 31),
          key: "selection",
        },
      ],
    },
    {
      name: "Last month",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 31),
          key: "selection",
        },
      ],
    },
    {
      name: "Custom",
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
    },
  ];

  const applyDateRange = () => {
    let tdate, fdate;
    if (dateRangeBtn === "Custom") {
      tdate = moment(customDateRange.selection.endDate).format("YYYY-MM-DD");
      fdate = moment(customDateRange.selection.startDate).format("YYYY-MM-DD");
    } else {
      tdate = moment(dateRange[0].startDate).format("YYYY-MM-DD");
      fdate = moment(dateRange[0].endDate).format("YYYY-MM-DD");
    }
    let copyData = [...callHistoryOriginal];
    const updateDataStatus = copyData.filter((item) => {
      let dates = moment(item.endtime).format("YYYY-MM-DD");
      return dates >= fdate && dates <= tdate;
    });
    setCallHistory(updateDataStatus);
    props?.setShowDate(false);
  };

  const handleDate = (range) => {
    customDateRange = range;
    setDateRangeBtn("Custom");
  };

  const RangeDatePicker = () => (
    <RangeDatePickerStyle>
      <div className="labels">
        {dateRangeBtns.map((range) => (
          <button
            key={uuid()}
            className={`${dateRangeBtn === range.name ? "themeBtn" : "cancel"}`}
            onClick={() => {
              setDateRange(range.dateRange);
              setDateRangeBtn(range.name);
            }}
          >
            {" "}
            {range.name}{" "}
          </button>
        ))}
      </div>
      <RangeDate changes={handleDate} dateRange={dateRange} />
      <div className="buttonContainer">
        <button className="themeBtn" onClick={(val) => applyDateRange(val)}>
          Apply
        </button>
      </div>
    </RangeDatePickerStyle>
  );

  //  Search Table
  const handleAgentStatus = (searchItem) => {
    switch (searchItem) {
      case "1":
        return "Approved";
      case "2":
        return "Rejected";
      case "3":
        return "Discrepancy";
      default:
        return "N/A";
    }
  };

  const handleColorChange = (colorChange) => {
    switch (colorChange) {
      case "1":
        return "status green";
      case "2":
        return "status red";
      case "3":
        return "status orange";
      default:
        return "status black";
    }
  };

  // custom sorting
  // use the selector function to resolve your field names by passing the sort comparitors
  // const aField = selector(rowA)
  // const bField = selector(rowB)
  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = rowA.agentstatus || rowA;
      const bField = rowB.agentstatus || rowB;
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const columnsCustomer = [
    {
      name: "Last Activity Timestamp",
      selector: (row) => row["endtime"],
      format: (row) => moment(row.endtime).format("lll"),
      sortable: true,
      wrap: true,
    },
    {
      name: "VCIP ID",
      selector: (row) => row["vcipid"],
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "Customer Mobile",
      selector: (row) => row["mobile"],
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row["duration"],
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row["accname"],
      sortable: true,
      wrap: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row["agentname"],
      sortable: true,
      wrap: true,
    },
    {
      name: "Auditor Name",
      selector: (row) => row["auditorname"],
      sortable: true,
      wrap: true,
    },
    {
      name: "Agent Status",
      selector: (row) => (
        <span className={handleColorChange(row.agentstatus)}>
          {handleAgentStatus(row.agentstatus)}
        </span>
        // <span
        //   className={`status
        //     ${row["agentstatus"] === "1" && "green"}
        //     ${row["agentstatus"] === "2" && "red"}
        //     ${row["agentstatus"] === "3" && "orange"}
        //     ${(row["agentstatus"] === "" || row["agentstatus"] === "NA") && "#d3d3d3"}
        //     `}
        // >
        //   {/* {row["agentstatus"]} */}
        //   {row["agentstatus"] === "1" && <td>Approved</td>}
        //   {row["agentstatus"] === "2" && <td>Rejected</td>}
        //   {row["agentstatus"] === "3" && <td>Discrepancy</td>}
        //   {row["agentstatus"] === "" ||
        //     (row["agentstatus"] === "NA" &&
        //       // <td>-</td>
        //       <td>Pending</td>
        //     )}
        // </span>
      ),
      sortable: true,
      // width: "100px"
    },
    {
      name: "Auditor Status",
      selector: (row) => (
        <span
          className={`status 
            // ${row["auditorstatus"] === "1" ? "green" : "#d3d3d3"}
            ${row["auditorstatus"] === "1" && "green"}
            ${row["auditorstatus"] === "2" && "red"}
            ${row["auditorstatus"] === "3" && "orange"}
            ${(row["auditorstatus"] === "" || row["auditorstatus"] === "NA") &&
            "#d3d3d3"
            }
            `}
        >
          {/* {row["auditorstatus"]} */}
          {row["auditorstatus"] === "1" && <td>Approved</td>}
          {row["auditorstatus"] === "2" && <td>Rejected</td>}
          {/* {row["auditorstatus"] === "3" && <td>Issued</td>} */}
          {(row["auditorstatus"] === "" || row["auditorstatus"] === "NA") && (
            // <td>-</td>
            <td>Pending</td>
          )}
        </span>
      ),
      sortable: true,
      // width: "100px",
    },
  ];

  const rollBackColumns = [
    {
      name: "VCIP ID",
      selector: (row) => row["vcipidstatus"],
      sortable: true,
    },
    {
      name: "KYC Status",
      selector: (row) => row["kycstatus"],
      sortable: true,
    },
    {
      name: "Pan Status",
      selector: (row) => row["panstatus"],
      sortable: true,
    },
    // {
    //   name: "Agent Status",
    //   selector: (row) => (
    //     <span className={handleColorChange(row.agentstatus)}>
    //       {handleAgentStatus(row.agentstatus)}
    //     </span>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: "Auditor Status",
    //   selector: (row) => (
    //     <span
    //       className={`status 
    //         // ${row["auditorstatus"] === "1" ? "green" : "#d3d3d3"}
    //         ${row["auditorstatus"] === "1" && "green"}
    //         ${row["auditorstatus"] === "2" && "red"}
    //         ${row["auditorstatus"] === "3" && "orange"}
    //         ${(row["auditorstatus"] === "" || row["auditorstatus"] === "NA") &&
    //         "#d3d3d3"
    //         }
    //         `}
    //     >
    //       {/* {row["auditorstatus"]} */}
    //       {row["auditorstatus"] === "1" && <td>Approved</td>}
    //       {row["auditorstatus"] === "2" && <td>Rejected</td>}
    //       {/* {row["auditorstatus"] === "3" && <td>Issued</td>} */}
    //       {(row["auditorstatus"] === "" || row["auditorstatus"] === "NA") && (
    //         // <td>-</td>
    //         <td>Pending</td>
    //       )}
    //     </span>
    //   ),
    //   sortable: true,
    //   // width: "100px",
    // },
  ];



  const dispatch = useDispatch();

  // useEffect(() => {
  //   getCallHistoryAction(AccountSwap);
  // }, []);

  const getCallHistoryAction = () => {
    const model = {
      accid: AccountSwap,
      listtype: "1",
      fdate: "",
      tdate: "",
    };
    // if (location.pathname != "/dashboard/vcip-roll-back") {
    dispatch(
      getCallHistorySagaAction({ model: model, callback: getCallHistoryRes })
    );
    // }
  };

  const getCallHistoryRes = (data) => {
    setCallHistory(data);
    setCallHistoryOriginal(data);
  };

  const Activities = ({ data }) => {
    return (
      <ActivitiesStyle>
        <ul className="events">
          <li data-tip data-for="">
            <div
              className="contains"
              onClick={() => props?.dispPop("customerDetails", data)}
            >
              <img src={UserBoxGrad} alt="" />
              <p>View Details</p>
            </div>
          </li>
          {/* Need to uncomment in phase-2  */}
          {/* <li data-tip data-for="">
            <div className="contains" onClick={() => props?.dispPop("activeLog" , data)}>
              <img src={DocBundleGrad} alt="" />
              <p>Activity Log</p>
            </div>
          </li> */}
          {/* Need to uncomment in phase-2  */}
          <li data-tip data-for="">
            <div
              className="contains"
              onClick={() => props?.dispPop("webLink", data)}
            >
              <img src={WorldGrad} alt="" />
              <p>Send Weblink</p>
              {/* <ReactTooltip id="registerTip" place="top" effect="solid">
              Coming Soon
            </ReactTooltip> */}
            </div>
          </li>
          {/* <li data-tip data-for="registerTip">
          <div className="contains" onClick={() => dispPop("bookSlot", data?.vcipkey)}>
            <img src={CalanderGrad} alt="" />
            <p>Book a Slot</p>
            <ReactTooltip id="registerTip" place="top" effect="solid">
              Coming Soon
            </ReactTooltip>
          </div>
        </li> */}
          <li data-tip data-for="">
            <div
              className="contains"
              onClick={() => props?.dispPop("videoRecording", data)}
            >
              <img src={PlayGrad} alt="" />
              <p>View video</p>
            </div>
          </li>
          <li data-tip data-for="">
            <div
              className="contains"
              onClick={() => props?.dispPop("viewReport", data)}
            >
              <img src={MultiUserGrad} alt="" />
              <p>View Report</p>
            </div>
          </li>
          {/* <li data-tip data-for="registerTip">
          <div className="contains" onClick={() => dispPop("totalAgents", data?.vcipkey)}>
            <img src={ScreenShareGrad} alt="" />
            <p>Report issue</p>
            <ReactTooltip id="registerTip" place="top" effect="solid">
              Coming Soon
            </ReactTooltip>
          </div>
        </li> */}
        </ul>
      </ActivitiesStyle>
    );
  };

  const renderTables = () => {
    let singleVcipSearchPath = "/dashboard/customers/single-vcip-search";
    let vcipRollBackPath = "/dashboard/vcip-roll-back";
    let callHistoryPath = "/dashboard/customers/callHistory";
    switch (location.pathname) {
      case singleVcipSearchPath:
        return [props?.refCallHistory, columnsCustomer, singleVcipSearchPath];
      case vcipRollBackPath:
        return [props?.refCallHistory, rollBackColumns, vcipRollBackPath];
      default:
        return [callHistory, columnsCustomer, callHistoryPath];
    }
  };

  let [data, columns, path] = renderTables();
  let { vcipid, kycstatus, panstatus, agentstatus, agentremarks, auditorstatus, auditorremarks } = data.length && data[0];

  return (
    <>
      <AllCallHistoryStyle>
        {callHistory?.length > 0 && props?.filteration ? (
          <DataTable
            columns={columns}
            data={data}
            pagination
            expandableRows
            expandableRowsComponent={(row) => Activities(row)}
            sortFunction={customSort}
          // customStyles={customTableStyles}
          />
        ) : !props.filteration ?
          <table className="currentStatus">   {/* // TODo: enhance this */}
            <tbody>
              <tr>
                <th scope="row">VCIP ID</th>
                <td>{vcipid}</td>
              </tr>

              <tr>
                <th scope="row">KYC Status </th>
                <td>{kycstatus}</td>
              </tr>

              <tr>
                <th scope="row">PAN Status</th>
                <td>{panstatus}</td>
              </tr>

              <tr>
                <th scope="row">Agent Status</th>
                <td>{agentstatus}</td>
              </tr>
              <tr>
                <th scope="row">Agent Remarks</th>
                <td>{agentremarks}</td>
              </tr>
              <tr>
                <th scope="row">Auditor Status</th>
                <td>{auditorstatus}</td>
              </tr>
              <tr>
                <th scope="row">Auditor Remarks</th>
                <td>{auditorremarks}</td>
              </tr>
            </tbody>
          </table>
          :
          (location.pathname != "/dashboard/vcip-roll-back") ? // TODO fix this condition
            <div className="norecord">
              <NoRecordFound />
            </div> : null
        }
      </AllCallHistoryStyle>
      {props?.showDate && (
        <Popup
          className="px680 dateRangePicker"
          heading="Date range"
          body={<RangeDatePicker />}
          close={() => props?.setShowDate(false)}
        />
      )}
    </>
  );
}

const AllCallHistoryStyle = styled.div`
  .currentStatus {
    border: 1px solid rgba(0,0,0,0.1);
    padding: 8px;
    border-radius: 5px;
    tr > th {
      float: left;
      margin: 6px;
      padding-right: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
    }

    
    tr > td {
      border-left: 1px solid rgba(0,0,0,0.1);
      padding: 0px 10px;
      font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
      
    }

    margin-bottom: 20px;
  }
  .norecord {
    height: calc(100vh - 300px);
  }
  .rdt_TableBody {
    max-height: calc(100vh - 388px);
    overflow: auto;
  }
  .status {
    font-weight: 600;
    display: block;
    padding: 4px;
    min-width: 72px;
    text-align: center;
    &.green {
      background: #08c1521a;
      color: #08c152;
      border-radius: 3px;
    }
    &.orange {
      background: #f9c7171a;
      color: #f9c717;
      border-radius: 3px;
    }
    &.red {
      background: #f247471a;
      color: #f24747;
      border-radius: 3px;
    }
  }
`;

const ActivitiesStyle = styled.div`
  background: var(--white);
  position: relative;
  top: -2px;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .events {
    display: flex;
    background: rgba(56, 161, 247, 0.08);
    border-radius: 5px;
    border: 1.2px solid #38568f;
    max-width: 700px;
    li {
      &:hover {
        .contains {
          background: rgb(56 161 247 / 5%);
          img {
            transform: scale(1.1);
          }
        }
      }
      .contains {
        text-align: center;
        padding: 14px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s linear;
        img {
          height: 24px;
          transition: all 0.2s linear;
        }
        p {
          font-size: 14px;
          background: linear-gradient(180deg, #38568f 0%, #38a1f7 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      &:not(:last-child) {
        .contains {
          &:after {
            content: "";
            height: calc(100% - 32px);
            width: 1px;
            background: rgba(56, 161, 247, 0.3);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
`;

const RangeDatePickerStyle = styled.div`
  .buttonContainer {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    button {
      padding: 9px 14px;
      font-size: 18px;
      width: 140px;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 12px 0;
    button {
      padding: 10px 14px;
      font-size: 16px;
      border: none;
    }
  }
`;
