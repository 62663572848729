import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DoughnutChart from './DoughnutChart';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import ChartLabels from './ChartLabels';
import { actionReqGetAgentActivity } from '../Store/SagasActions/AgentSagaAction';

export default function ActivityList({ data, userid }) {
    const chart = [
        {
            "labels": {
                "display": false
            },
            "chartFor": "Total Calls",
            "count": 0,
            "datasets": [
                {
                    "data": [100, 10, 10],
                    "backgroundColor": [
                        "rgba(249, 199, 23, 1)",
                        "rgba(77, 174, 104, 1)",
                        "rgba(242, 71, 71, 1)"
                    ]
                }
            ]
        }
    ]

    const [dataSetter, setDataSetter] = useState(data.options);
    const [selectedActivity, setSelectedActivity] = useState("1");
    const [chartLabels, setChartLabels] = useState([]);
    const [doughnutchartData, setDoughnutchartData] = useState(chart);
    //     labels: ['Rejected', 'issued', 'Accepted'],
    //     datasets: [
    //         {
    //             label: '# of Votes',
    //             data: [10, 20, 30],
    //             backgroundColor: [
    //                 '#4DAE68',
    //                 '#F9C717',
    //                 '#F24747',
    //             ],
    //             borderWidth: 1,
    //         },
    //     ],
    // });

    // const chartData = data.chart
    // const ledgerData = data.ledgers
    let dataType = dataSetter.filter(node => node.selected === true)[0].name;

    const dispatch = useDispatch();

    useEffect(() => {
        getActivity()
    }, [])

    const getActivity = (id) => {
        const model = {
            userid: userid,
            // reqtype:selectedActivity
            reqtype: id ? id:"1"
        }
        // reqtype:selectedActivity
        dispatch(actionReqGetAgentActivity({ model: model, callback: getActivityData }))
    }

    const getActivityData = (data) => {
        const ledgerData = [
            {
                "name": "Approved",
                "no": data?.acceptedcount,
                "color": "#4DAE68"
            },
            {
                "name": "Discrepancy",
                "no": data?.issuedcount,
                "color": "#FFCE56"
            },
            {
                "name": "Rejected",
                "no": data?.rejectedcount,
                "color": "#F24747"
            }
        ];
        const total = parseInt(data?.acceptedcount) + parseInt(data?.issuedcount) + parseInt(data?.rejectedcount);

        const countArr = [data?.rejectedcount, data?.issuedcount, data?.acceptedcount]
        const chartObj = [
            {
                // "labels": {
                //     "display": false
                // },
                labels: ['Rejected', 'Discrepancy', 'Approved'],
                "chartFor": "Total Calls",
                "count": total,
                "datasets": [
                    {
                        "data": countArr,
                        "backgroundColor": [
                            "rgba(242, 71, 71, 1)",
                            "rgba(249, 199, 23, 1)",
                            "rgba(77, 174, 104, 1)",
                        ]
                    }
                ]
            }
        ];
        setDoughnutchartData(chartObj)
        setChartLabels(ledgerData);

    }


    const changeDataTye = (e, activity) => {
        // const selected = e.target.innerHTML
        // dataSetter.filter(node => node.selected === true)[0].selected = false
        // dataSetter.filter(node => node.name === selected)[0].selected = true
        // setDataSetter(dataSetter)
        // console.log(dataSetter)
        setSelectedActivity(activity?.id);
        getActivity(activity?.id);
    }

    return (
        <ActivityListStyle>
            <h3 className="heading">{dataType} Activity</h3>
            <ul className="datatype">
                {dataSetter.map((option, i) => <li key={uuid()} value ={option?.id} onClick={(e) => changeDataTye(e, option)} className={option?.id === selectedActivity ? 'active' : ""}>{option.name}</li>)}
            </ul>
            <div className="chartContainer">
                {doughnutchartData.map(chart => <DoughnutChart key={uuid()} customData={chart} count={chart.count} disc={chart.chartFor} height={100} width={100} />)}
            </div>
            <ChartLabels labels={chartLabels} />
        </ActivityListStyle>
    )
}

const ActivityListStyle = styled.div`
    padding: 20px;
    border: 1px solid var(--border-color3);
    border-radius: 5px;
    .heading {
        font-size: 16px;
        color: var(--dark2);
    }
    .datatype {
        margin: 12px 0;
        padding: 12px 0;
        border: 1px solid var(--border-color);
        border-left: 0;
        border-right: 0;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        text-align: center;
        li {
            flex: 1;
            padding: 10px;
            border: 1px solid var(--border-color2);
            border-radius: 5px;
            font-weight: 700;
            cursor: pointer;
            &.active {
                background: var(--fg3);
                border: none;
                color: var(--white)
            }
        }
    }
    .chartjs-render-monitor {
      transform: scale(0.7);
  }
  .ledgers {
      gap: 16px 20px;
      padding: 0 12px;
      .ledger {}
  }
`