// API STATUS
export const APISTATUS = "APISTATUS";

// // LOCATION
// export const ACTION_GET_LOCATION_DETAILS_REQ = '@ACTION/GET/LOCATION/DETAILS/REQUEST';

// HOME PAGE
export const ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ =
  "@ACTION/GET/CUSTOMER/VICPDETAILS/REQUEST";
// export const ACTION_GET_CUSTOMER_VCIP_DETAILS_RES = '@ACTION/GET/CUSTOMER/VICPDETAILS/RESPONSE';
// export const ACTION_GET_CUSTOMER_CREATE_SLOT_REQ = '@ACTION/GET/CUSTOMER/CREATE/SLOT/REQUEST';

// DASHBOARD OVERVIEW
export const ACTION_GET_ADMIN_DASHBOARD_COUNT_REQ =
  "@ACTION/GET/ADMIN/DASHBOARD/COUNT/REQUEST";
export const SAMPLE = "SAMPLE";

// GET AGENT LIST
export const ACTION_GET_AGENT_LIST_REQ = "@ACTION/GET/ADMIN/AGENT/LIST/REQUEST";

//GET AUDITOR LIST
export const ACTION_GET_AUDITOR_LIST_REQ =
  "@ACTION/GET/ADMIN/AUDITOR/LIST/REQUEST";

export const ACTION_POST_ADMIN_ACCOUNTS =
  "@ACTION/POST/ADMIN/DASHBOARD/ACCOUNT/REQUEST";

//create  Agent
export const ACTION_POST_ADMIN_DASHBOARD_CREATE_AGENT =
  "@ACTION/POST/ADMIN/DASHBOARD/CREATE/AGENT/REQUEST";
//Delete Agent
export const ACTION_POST_ADMIN_DASHBOARD_DELETE_AGENT =
  "@ACTION/POST/ADMIN/DASHBOARD/DELETE/AGENT/REQUEST";
//update Agent
export const ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT =
  "@ACTION/POST/ADMIN/DASHBOARD/UPDATE/AGENT/REQUEST";
export const ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT_ACTIVITY =
  "@ACTION/POST/ADMIN/DASHBOARD/UPDATE/AGENT/ACTIVITY/REQUEST";

//create  Auditor
export const ACTION_POST_ADMIN_DASHBOARD_CREATE_AUDITOR =
  "@ACTION/POST/ADMIN/DASHBOARD/CREATE/AUDITOR/REQUEST";
//Delete Auditor
export const ACTION_POST_ADMIN_DASHBOARD_DELETE_AUDITOR =
  "@ACTION/POST/ADMIN/DASHBOARD/DELETE/AUDITOR/REQUEST";
//update Auditor
export const ACTION_POST_ADMIN_DASHBOARD_UPDATE_AUDITOR =
  "@ACTION/POST/ADMIN/DASHBOARD/UPDATE/AUDITOR/REQUEST";

export const ACTION_GET_CALL_HISTORY_REQ =
  "@ACTION/GET/DASHBOARD/CALL/HISTORY/REQUEST";

//Get Reference Call History

export const ACTION_GET_SINGLE_REFERENCE_CALL_HISTORY_REQ =
  "@ACTION/GET/DASHBOARD/CALL/HISTORY/SINGLE/REFERENCE/REQUEST";

export const ACTION_GET_CUSTOMER_QUEQE_REQ =
  "@ACTION/GET/DASHBOARD/CUSTOMER/QUEQE/REQUEST";

export const ACTION_GET_CUSTOMER_QUEQE_REDUCER_REQ =
  "@ACTION/GET/DASHBOARD/CUSTOMER/QUEQE/REDUCER/REQUEST";

export const ACTION_GET_REPORT_MASTER_REQ =
  "@ACTION/GET/DASHBOARD/REPORT/MASTER/REQUEST";

export const ACTION_POST_ADMIN_DASHBOARD_ACCOUNT_CONFIGURE =
  "@ACTION/POST/ADMIN/DASHBOARD/ACCOUNT/CONFIGURE/REQUEST";

export const ACTION_POST_ADMIN_DASHBOARD_CONFIGURE_ACCOUNT =
  "@ACTION/POST/ADMIN/DASHBOARD/CONFIGURE/ACCOUNT/REQUEST";

export const ACTION_POST_SUBMIT_REPORT_MASTER_REQ =
  "@ACTION/POST/SUBMIT/REPORT/MASTER/REQUEST";

export const ACTION_POST_CHECK_REPORT_STATUS_REQ =
  "@ACTION/POST/CHECK/REPORT/MASTER/REQUEST";

export const ACTION_GET_REPORT_LIST_REQ = "@ACTION/GET/REPORT/MASTER/REQUEST";
export const ACTION_GET_ACCOUNT_REDUCER_REQ =
  "@ACTION/POST/ADMIN/REDUCER/ACCOUNT/REQUEST";

// Get languages

export const ACTION_POST_GET_LANGUAGES = "@ACTION/POST/GET/LANGUAGES";
export const ACTION_POST_GET_REDUCER_LANGUAGES =
  "@ACTION/POST/GET/REDUCER/LANGUAGES";

export const ACTION_SETUP_GLOBAL_ACCOUNT = "@ACTION/SETUP/GLOBAL/ACCOUNT";
export const ACTION_GET_SETUP_GLOBAL_ACCOUNT =
  "@ACTION/GET/SETUP/GLOBAL/ACCOUNT";

//  Global Setup
export const CLIENT_ACCOUNT_ID = "@ACTION/SET/CLIENT/ACCOUNT/ID";

// getServiceWiseTxnCount

export const SERVICE_WISE_TXN_COUNT = "@SERVICE/WISE/TXN/COUNT";

// getTokenNumberDashboard

export const GET_TOKEN_NUMBER_DASHBOARD =
  "@ACTION/POST/GET/TOKEN/NUMBER/DASHBOARD";


