import React, { useEffect, useState } from "react";
import HeaderFilters from "../../Components/HeaderFilters";
import { headerActions, QueueTabs } from "../../Components/CustomersSnippets";
import WaitingCustomerQueue from "../../Components/WaitingCustomerQueue";
import { useDispatch } from "react-redux";
import { getCustomerQueueSagaAction } from "../../Store/SagasActions/DashboardOverviewSagaAction";
import { useSelector } from "react-redux";
import {
  SearchGrad,
  OrderGrad,
  FilterGrad,
  Timer,
  MultiUserGray,
  MultiUserGrad,
  TimerGrad,
  LinkUserGrad,
  LinkUser,
  VideoGrad,
  Video,
  ClockGrad,
  Clock,
} from "../../assets/ImageUrl";
import styled from "styled-components";
import LoaderClr from "../../Components/LoaderClr";

export default function CustomerQueue() {
  const [queueTab, setQueueTab] = useState("waiting");
  const [type, setType] = useState(1);
  // const [intervalVal, setIntervalVal] = useState();
  const [waitingList, setWaitingList] = useState([]);
  const [liveList, setLiveList] = useState([]);
  const [sceduleList, setSceduleList] = useState([]);
  const [waitingListCount, setWaitingListCount] = useState(0);
  const [livelistCount, setLivelistCount] = useState(0);

  const account = useSelector((state) => state?.DashboardReducer.accid);

  const dispatch = useDispatch();

  const navClick = (tabnam, type, id) => {
    if (type == 2) {
      getCustomerQueueList(id);
    } else {
      getCustomerQueueWaitingList(id);
    }
    setQueueTab(tabnam);
    setType(type);
  };
  const roleID = sessionStorage.getItem("role");
  const globalAccountId = useSelector(
    (state) => state?.GlobalRecuder.globalAccountId
  );
  const AccountSwap =
    roleID === "Account Admin" ? sessionStorage.getItem("accid") : account;

  const clientAccountId = useSelector(
    (state) => state?.GlobalRecuder.clientAccountId
  );
  const accid = sessionStorage.getItem("accid");

  const finalAccId = clientAccountId ? clientAccountId : accid;

  useEffect(() => {
    if (clientAccountId || finalAccId) {
      clearInterval(interval1);
      clearInterval(interval);
      getCustomerQueueList(finalAccId);
      getCustomerQueueWaitingList(finalAccId);
      var interval = setInterval(() => {
        getCustomerQueueList(finalAccId);
      }, 4000);
      var interval1 = setInterval(() => {
        getCustomerQueueWaitingList(finalAccId);
      }, 5500);
    }
    return () => {
      clearInterval(interval);
      clearInterval(interval1)

    };
  }, [clientAccountId]);

  const getCustomerQueueList = (id) => {
    const model = {
      accid: id,
      listtype: 2,
    };
    dispatch(
      getCustomerQueueSagaAction({
        model: model,
        callback: (data) => getLiveData(data),
      })
    );
  };
  const getLiveData = (data) => {
    setLivelistCount(data?.count);
    if (data?.vciplist?.length > 0) {
      setLiveList(data);
    } else {
      setLiveList([]);
    }
  };

  const getCustomerQueueWaitingList = (accid) => {
    const model = {
      accid: accid,
      listtype: 1,
    };
    dispatch(
      getCustomerQueueSagaAction({
        model: model,
        callback: (data) => getWaitingData(data),
      })
    );
  };
  const getWaitingData = (data) => {
    setWaitingListCount(data?.count);
    if (data?.vciplist?.length > 0) {
      setWaitingList(data);
    } else {
      setWaitingList([]);
    }
  };

  return (
    <>
      {/* <div className="main-loader">
        <LoaderClr />
      </div> */}
      <div className="customerActions">
        <SelectedTabsStyle>
          <button
            className={`${queueTab === "waiting" ? "themeBtn" : "notSelected"}`}
            onClick={() => navClick("waiting", 1, accid)}
          >
            <img
              src={queueTab !== "waiting" ? LinkUserGrad : LinkUser}
              alt="waitIcon"
            />{" "}
            Waiting ({waitingListCount} ){" "}
          </button>
          <button
            className={`${queueTab === "live" ? "themeBtn" : "notSelected"}`}
            onClick={() => navClick("live", 2, accid)}
          >
            <img src={queueTab !== "live" ? VideoGrad : Video} alt="waitIcon" />{" "}
            Live & Scheduled ({livelistCount}){" "}
          </button>
          {/* <button
        className={`${status === "scheduled" ? "themeBtn" : "notSelected"}`}
        onClick={() => navClick("scheduled", 3)}
      >
        <img src={status !== "scheduled" ? ClockGrad : Clock} alt="waitIcon" />{" "}
        Scheduled 
        {/* ( 0 ) */}{" "}
          {/* </button> */}
        </SelectedTabsStyle>
        {/* <HeaderFilters
        isComponent={true}
        heading={<QueueTabs status={queueTab} navClick={navClick} action={setQueueTab} />}
        className="customerActions"
        actionBtns={headerActions}
      /> */}
        <div className="queueTableContainer" style={{ marginTop: "25px" }}>
          {queueTab === "waiting" && (
            <WaitingCustomerQueue waitingList={waitingList} isWaitingList />
          )}
          {queueTab === "live" && (
            <WaitingCustomerQueue waitingList={liveList} />
          )}
          {/* {queueTab === "scheduled" && <WaitingCustomerQueue waitingList={sceduleList} />} */}
        </div>
      </div>
    </>
  );
}

const SelectedTabsStyle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .themeBtn {
  }
  button {
    padding: 9px 12px;
    font-weight: 600;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    &.notSelected {
      background: var(--white);
      border: 1px solid #38568f;
      background: linear-gradient(180deg, #38568f 0%, #38a1f7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &.themeBtn {
      background: linear-gradient(86.6deg, #38568f 2.29%, #38a1f7 99.09%);
    }
  }
`;
