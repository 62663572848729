import React from 'react'
import styled from 'styled-components'
import uuid from 'react-uuid'

export default function ChartLabels({labels}) {
  return (
    <ChartLabelsStyle className="ledgers">
        {labels.map( ledger =><div key={uuid()} className="ledger">
            <span className="color" style={{backgroundColor: ledger.color}}></span>
            <span className="ledgerName">{ledger.name}</span>
            <span className="ledgerNo fg1">{ledger.no}</span>
        </div>)}
    </ChartLabelsStyle>
  )
}

const ChartLabelsStyle = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 48px;
    margin-bottom: 28px;
    padding: 0 50px;
    .ledger {
        display: flex;
        justify-content: center;
        align-items: center;
        .color {
            height: 8px;
            width: 8px;
            display: inline-block;
            border-radius: 2px;
            margin-right: 8px;
        }
        .ledgerName {}
        .ledgerNo {font-weight: 700;margin-left: 8px;}
    }
`
