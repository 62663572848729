import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Text, Select, Button } from "../Components/Elements";
import { Mail, Lock, Eye, HiColor, Eye_close } from "../assets/ImageUrl";
import { useState } from "react";

export default function LoginForm(props) {
  const [evnList, setEvnList] = useState([]);
  // const [acc, setAcc] = useState("Super Admin")

  // const logAcc = (val) => {
  //     console.log(val)
  // }

  const handleChange = (event) => {
    const { id, value } = event.target;
    // let res = arr.find(x => x.envName == id);
    let uatArr = [
      { envName: "uatOps", envUrl: "https://google.com" },
      { envName: "prodOps", envUrl: "https://google.com" },
      { envName: "devOps", envUrl: "https://google.com" }
    ]
    let prodArr = [
      { envName: "prodOps", envUrl: "https://google.com" },
      { envName: "devOps", envUrl: "https://google.com" }
    ]
    if (id === "uat") {
      setEvnList(uatArr)
    } else {
      setEvnList(prodArr)
    }
  };



  return (
    <>
      <LoginFormStyle className="form">
        <h1 className="fd1">Hello Again!</h1>
        <h2 className="fd2">Welcome Back</h2>
        <form onSubmit={props.login}>
          <div>
            <Text
              type="email"
              name="username"
              icons={{ left: { src: Mail, alt: "mail icon" } }}
              placeholder="Email Address"
              change={props.handleChange}
            />
            <p className="error">{props?.loginObjErr?.username}</p>
          </div>
          <div>
            <Text
              type="password"
              name="password"
              icons={{
                left: { src: Lock, alt: "mail icon" },
                right: {
                  src: Eye,
                  src2: Eye_close,
                  iconClass: "eye",
                  alt: "eye icon",
                },
              }}
              placeholder="Password"
              change={props.handleChange}
            // inputClass=""
            />
            <p className="error">{props?.loginObjErr?.password}</p>
          </div>
          <div className="">
            <select
              name="rolename"
              onChange={props?.handleChange}
              value={`${props?.roleObj?.rid}-${props?.roleObj?.rolename}`}
              className="cus-select"
              required
            >
              <option value="">Select Role</option>
              {props?.roles && props?.roles?.length > 0
                ? props?.roles.map((item, i) => (
                  <option key={i} value={`${item?.rid}-${item?.rolename}`}>
                    {item?.rolename}
                  </option>
                ))
                : null}
            </select>
          </div>
          {/* <div>
            <h3 className="my-1">Select Environment</h3>
            <div className="d-flex">
              <label>UAT</label>
              <input type="radio" value="1" onChange={handleChange} name="name" id="uat" />
              <label>PROD</label>
              <input type="radio" value="0" onChange={handleChange} name="name" id="prod" />
            </div>
          </div>
          {
            evnList?.length > 0 &&
            <div className="">
              <select
                name="rolename"
                onChange={props?.handleChange}
                value={`${props?.roleObj?.rid}-${props?.roleObj?.rolename}`}
                className="cus-select"
                required
              >
                <option value="">Select Role</option>
                {evnList?.map((item, i) => (
                  <option key={i} value={item?.envUrl}>
                    {item?.envName}
                  </option>
                ))}
              </select>
            </div>
          } */}
          <Button
            name="login_Btn"
            className="my-1"
            dispName="Login"
          // click={props.login}
          />
          {/* </Link> */}
          <Link to="/reset-password">
            <Button
              name="login_Btn"
              className="plain"
              dispName="Forgot Password?"
              click={(e) => console.log(e)}
            />
          </Link>
        </form>
        {/* pop up */}
      </LoginFormStyle>
    </>
  );
}

const LoginFormStyle = styled.div`

  .my-1 {
    margin: 5px 0px;
  }

  .d-flex {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  width: 70%;
  & > .fd1:after {
    background: url(${HiColor});
  }

  .error {
    position: absolute;
    color: red;
    font-size: 12px;
  }
  .cus-select {
    display: flex;
    background: bottom;
    width: 100%;
    font-size: 16px;
    padding: 14px;
    border: var(--border);
    border-radius: 5px;
    outline: none;
    text-indent: 1px;
    text-overflow: "";
    -webkit-box-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;
