import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  SearchGrad,
  OrderGrad,
  FilterGrad,
  UploadGrad,
  PlusGrad,
  CalanderGrad,
  DownArrowGrad,
  MarkTick,
} from "../assets/ImageUrl";
import {
  validateEmail,
  validateIsNumeric,
  validateIsRequired,
} from "../utils/Validations";
import { actionReqGetCreateAuditor } from "../Store/SagasActions/AuditorSagaAction";
import { useDispatch } from "react-redux";
import base64 from "base-64";
import { useNavigate, useLocation } from "react-router-dom";
import { Text, RadioBtn, Button, Select } from "../Components/Elements";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import { Country, State, City } from "country-state-city";

export const headerActions = [
  {
    img: SearchGrad,
    key: 4,
    func: (e) => console.log(e),
  },
  // {
  //   img: OrderGrad,
  //   key: 5,
  //   // func: (e) => console.log(e),
  // },
  {
    img: FilterGrad,
    key: 6,
    // func: (e) => console.log(e),
  },
];

export const AddAuditor = ({ accountList, func, isLoading, disabled }) => {
  let auditorObj = {
    auditor_accid: "",
    auditor_employeeid: "",
    auditor_pht: "",
    auditor_name: "",
    auditor_username: "",
    auditor_mobile: "",
    manager_id: "",
    manager_name: "",
    manager_email: "",
    istrained: "",
    trainingcompleteby: "",
    branchid: "",
    location: "",
    region: "",
    knownlanguages: [],
  };
  // const navigate = useNavigate();

  const [source, setSource] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [isdisabled, setIsDisabled] = useState(false);

  const takelang = useSelector((state) => state?.DashboardReducer.getlanguage);

  const [auditorModel, setAuditorModel] = useState(auditorObj);
  const [auditorModelErrs, setAuditorModelErrs] = useState(auditorObj);
  const [trainingdate, setTrainingDate] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const countryCode = "IN";

  function epoch(date) {
    return Date.parse(date);
  }

  const dispatch = useDispatch();
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      // let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const datetime = moment().format("YYYY-MM-DD");
    const timestamp = epoch(datetime);
    if (value && (name === "manager_email" || name === "auditor_username")) {
      const err = validateEmail(value);
      setAuditorModelErrs((prevState) => ({ ...prevState, [name]: err }));
    } else if (value && name === "auditor_mobile") {
      if (value.length <= 10) {
        const isNumber = validateIsNumeric(value);
        setAuditorModelErrs((prevState) => ({
          ...prevState,
          [name]: isNumber,
        }));
        setAuditorModel((prevState) => ({ ...prevState, [name]: value }));
      }
      return;
    } else if (value && name === "trainingcompleteby") {
      if (epoch(value) > timestamp) {
        const isError = "Future date should not be Selected";
        setAuditorModel((prevState) => ({ ...prevState, [name]: "" }));
        setTrainingDate(true);
        setAuditorModelErrs((prevState) => ({ ...prevState, [name]: isError }));
      } else {
        const isError = "";
        setTrainingDate(false);
        setAuditorModelErrs((prevState) => ({ ...prevState, [name]: isError }));
      }
    } else if (name === "region") {
      let modValue = JSON.parse(value);
      let selectedCities = City.getCitiesOfState(
        countryCode,
        modValue?.isoCode
      );
      setAuditorModel((prevState) => ({
        ...prevState,
        [name]: modValue?.name,
      }));
      setCities(selectedCities);
      return;
    } else {
      const isEmpty = validateIsRequired(value);
      setAuditorModelErrs((prevState) => ({ ...prevState, [name]: isEmpty }));
    }
    setAuditorModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const selectLanguage = (val) => {
    let list = [...auditorModel?.knownlanguages];
    let filterId = list?.length > 0 ? list?.find((item) => item === val) : "";
    if (val == filterId) {
      list = list.filter((item) => item !== val);
    } else {
      list.push(val);
    }
    setAuditorModel((prevState) => ({ ...prevState, knownlanguages: list }));
  };

  const fileHandleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const ImgName = event.target.files[0].name;
    setImgFileName(ImgName);
    if (event.target.files) {
      const val = event.target.files.length;
      for (let i = 0; i < val; i++) {
        let reader = new FileReader();
        reader.onload = function (ev) {
          setAuditorModel((prevState) => ({
            ...prevState,
            [name]: ev.target.result.split(",")[1],
          }));
        }.bind(this);
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  };
  const submit = (stepName) => {
    let isTrue = Object.values(auditorModel).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(auditorModelErrs).every(
      (v) => v === "" || v === undefined || v === null || v.length <= 0
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please check all inputs");
    } else {
      let knownlanguages = auditorModel.knownlanguages.join();
      func(stepName, { ...auditorModel, knownlanguages });
    }
  };

  const langIdShow = (langId) => {
    if (langId && auditorModel?.knownlanguages?.length > 0) {
      let id = auditorModel?.knownlanguages?.find((item) => item == langId);
      return id;
    } else {
      return "";
    }
  };

  useEffect(() => {
    let Ind = State.getStatesOfCountry(countryCode);
    setStates(Ind);
  }, []);

  return (
    <AddAuditorStyle>
      <div className="elementList">
        <div className="elementContainer">
          <select name="auditor_accid" onChange={handleChange}>
            <option value={""}>Select Account *</option>
            {accountList?.length > 0 &&
              accountList?.map((item) => (
                <option value={item.accid}>{item.accountname}</option>
              ))}
          </select>
        </div>
        <div className="elementContainer" style={{ position: "relative" }}>
          {auditorModel.auditor_pht ? (
            <img
              src={`data:image/png;base64,${auditorModel.auditor_pht}`}
              width="30px"
              height="30px"
              style={{
                right: "0",
                top: "47%",
                transform: "translate(-50%, -50%)",
                position: "absolute",
                borderRadius: "50%",
              }}
            />
          ) : (
            " "
          )}
          <Text
            type="file"
            name="auditor_pht"
            icons={
              auditorModel.auditor_pht.length > 0
                ? " "
                : { right: { src: UploadGrad } }
            }
            placeholder={
              auditorModel.auditor_pht ? imgFileName : "Auditor photo *"
            }
            change={fileHandleChange}
          />
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="auditor_employeeid"
            placeholder="Employee ID *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.auditor_employeeid}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="auditor_name"
            placeholder="Auditor Name *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.auditor_name}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="auditor_mobile"
            // icons={{ right: { src: PlusGrad } }}
            placeholder="Mobile No. *"
            change={handleChange}
            value={auditorModel?.auditor_mobile}
          />
          <p className="error">{auditorModelErrs.auditor_mobile}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="auditor_username"
            // icons={{ right: { src: PlusGrad } }}
            placeholder="Email ID *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.auditor_username}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="manager_id"
            placeholder="Auditor Manager ID *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.manager_id}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="manager_name"
            placeholder="Auditor Manager Name *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.manager_name}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="manager_email"
            placeholder="Auditor Manager Email ID *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.manager_email}</p>
        </div>

        <div className="elementContainer">
          <Text
            type="text"
            name="branchid"
            placeholder="Branch ID *"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.branchid}</p>
        </div>
        <div className="elementContainer">
          {/* <Text
            type="text"
            name="location"
            placeholder="City *"
            change={handleChange}
          /> */}
          <select name="region" onChange={handleChange}>
            <option value={""}>Select State *</option>
            {states?.length > 0 &&
              states?.map((item) => (
                <option value={JSON.stringify(item)}>{item.name}</option>
              ))}
          </select>
          <p className="error">{auditorModelErrs.location}</p>
        </div>
        <div className="elementContainer">
          {/* <Text
            type="text"
            name="region"
            placeholder="State *"
            change={handleChange}
          /> */}
          <select name="location" onChange={handleChange}>
            <option value={""}>Select City *</option>
            {cities?.length > 0 &&
              cities?.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
          </select>
          <p className="error">{auditorModelErrs.region}</p>
        </div>

        <div className="dateelementContainer">
          {/* <label htmlFor="leavesFrom">Training Completeby</label>
          <input
            type="date"
            className=""
            name="trainingcompleteby"
            placeholder="Training Completeby"
            onChange={handleChange} />
          <p className="error">{auditorModelErrs.trainingcompleteby}</p>
        </div> */}
          <label htmlFor="leavesFrom">Training Completion Date *</label>
          <input
            type="date"
            id="leavesFrom"
            placeholder="Select Date Here..."
            className=""
            name="trainingcompleteby"
            onChange={handleChange}
            max={moment().format("YYYY-MM-DD")}
          />
          <p className="error">{auditorModelErrs.trainingcompleteby}</p>
        </div>
        {/* <Text
            type="text"
            name="trainingcompleteby"
            icons={{ right: { src: CalanderGrad } }}
            placeholder="Training completion date"
            change={handleChange}
          />
          <p className="error">{auditorModelErrs.trainingcompleteby}</p> */}
        {/* </div> */}
      </div>

      <div className="selectTabContainer">
        <label>Auditor Language Support *</label>
        <ul>
          {takelang?.map((item, i) => {
            return (
              <li
                key={i}
                onClick={() => selectLanguage(item.langid)}
                className={
                  langIdShow(item.langid) == item.langid ? `active` : ""
                }
              >
                {item.lang}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="finalVerification">
        <p>Auditor VCIP Trained *</p>
        <RadioBtn
          name="istrained"
          value="1"
          // change={(e) => console.log(e.target.value)}
          change={handleChange}
        />
        <h6>Yes</h6>
        <RadioBtn
          name="istrained"
          value="0"
          // change={(e) => console.log(e.target.value)}
          change={handleChange}
        />
        <h6>No</h6>
      </div>
      {/* <div className="elementContainer">
          <div className="rangeBox">
            <label>Office Timings *</label>
            <Text
              type="text"
              name="auditorStartTime"
              value="12:30"
              className="timeBox"
              change={handleChange}
            />
            <Text
              type="text"
              name="auditorEndTime"
              value="12:30"
              className="timeBox"
              change={handleChange}
            />
          </div>
        </div>
        <div className="elementContainer">
          <div className="rangeBox">
            <label>Break Timings *</label>
            <Text
              type="text"
              name="auditorStartBreakTime"
              value="12:30"
              className="timeBox"
              change={handleChange}
            />
            <Text
              type="text"
              name="auditorEndBreakTime"
              value="12:30"
              className="timeBox"
              change={handleChange}
            />
          </div>
        </div> */}
      {/* </div> */}
      <div className="btnContainer">
        <Button
          name="next"
          dispName="Add Auditor"
          click={(e) => submit("")}
          isLoading={isLoading}
          disabled={trainingdate}
          className={disabled ? "loaderdisabled" : ""}

          // {isLoading ? <span className ="spinner1"></span>:null }
        />
      </div>
    </AddAuditorStyle>
  );
};

export const ApplyLeaveForm = ({
  form,
  isEditable,
  updateAuditorDetails,
  isLoading,
  disabled,
}) => {
  const applyLeaveFormData = form;
  const location = useLocation();
  const dispatch = useDispatch();
  const auditorList = location.state.name;
  const countryCode = "IN";

  const [auditorModel, setAuditorModel] = useState({});
  const [auditorModelErrs, setAuditorModelErrs] = useState({});
  const [formData, setFormData] = useState(applyLeaveFormData);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value && (name === "manager_email" || name === "auditor_username")) {
      const err = validateEmail(value);
      setAuditorModelErrs((prevState) => ({ ...prevState, [name]: err }));
    } else if (value && name === "auditor_mobile") {
      const isNumber = validateIsNumeric(value);
      setAuditorModelErrs((prevState) => ({ ...prevState, [name]: isNumber }));
    } else if (name === "region") {
      let modValue = JSON.parse(value);
      let selectedCities = City.getCitiesOfState(
        countryCode,
        modValue?.isoCode
      );
      setAuditorModel((prevState) => ({
        ...prevState,
        [name]: modValue?.name,
      }));
      setCities(selectedCities);
      return;
    } else {
      const isEmpty = validateIsRequired(value);
      setAuditorModelErrs((prevState) => ({ ...prevState, [name]: isEmpty }));
    }
    setAuditorModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitUpdatedAuditor = (e) => {
    e.preventDefault();
    let isTrue = Object.values(auditorModel).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(auditorModelErrs).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please check all inputs");
    } else {
      const finalData = { ...auditorList, ...auditorModel };
      updateAuditorDetails(finalData);
    }
  };

  // if wants to display langauges UI use this below code

  // let langListData = [];
  // auditorList?.languages?.forEach(item => {
  //   if (item?.isknown === "1") {
  //     langListData.push(item.lang)
  //   }
  // })
  useEffect(() => {
    let Ind = State.getStatesOfCountry(countryCode);
    setStates(Ind);
  }, []);

  return (
    <ApplyLeaveFormStyle>
      <h4>General Information</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="formContainer">
          <div className="elementContainer">
            <label htmlFor="agentManagerId">Auditor Manager ID</label>
            <input
              type="text"
              id="agentManagerId"
              className={disabled ? "" : "EditlabelDis"}
              value={auditorList.manager_id}
              disabled
              onChange={(e) =>
                setFormData({
                  ...formData,
                  "Auditor Manager ID": e.target.value,
                })
              }
            />
          </div>
          <div className="elementContainer">
            <label htmlFor="employeeID">Employee ID</label>
            <input
              type="text"
              id="employeeID"
              className={disabled ? "" : "EditlabelDis"}
              value={auditorList.auditor_employeeid}
              disabled
              onChange={(e) =>
                setFormData({ ...formData, "Employee ID": e.target.value })
              }
            />
          </div>
          <div className="elementContainer">
            <label
              htmlFor="agentManagerName"
              className={isEditable ? "" : "EditableLabel"}
            >
              Auditor Manager Name
            </label>
            <input
              type="text"
              id="agentManagerName"
              name="manager_name"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={auditorList.manager_name}
              disabled={isEditable}
              onChange={handleChange}
            />
            <p className="error">{auditorModelErrs.manager_name}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="agentManagerEmailID"
              className={isEditable ? "" : "EditableLabel"}
            >
              Auditor Manager Email ID
            </label>
            <input
              type="text"
              id="agentManagerEmailID"
              name="manager_email"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={auditorList.manager_email}
              disabled={isEditable}
              onChange={handleChange}
            />
            <p className="error">{auditorModelErrs.manager_email}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="trainingCompletionDate"
              className={isEditable ? "" : "EditableLabel"}
            >
              Training Completion Date
            </label>
            <input
              type="date"
              id="trainingCompletionDate"
              name="trainingcompleteby"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={auditorList.trainingcompleteby}
              disabled={isEditable}
              max={moment().format("YYYY-MM-DD")}
              onChange={handleChange}
            />
            <p className="error">{auditorModelErrs.trainingcompleteby}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="branchID"
              className={isEditable ? "" : "EditableLabel"}
            >
              Branch ID
            </label>
            <input
              type="text"
              id="branchID"
              name="branchid"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={auditorList.branchid}
              disabled={isEditable}
              onChange={handleChange}
            />
            <p className="error">{auditorModelErrs.branchid}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="region"
              className={isEditable ? "" : "EditableLabel"}
            >
              State
            </label>
            {isEditable ? (
              <input
                type="text"
                id="region"
                name="region"
                className="EditlabelDis"
                defaultValue={auditorList.region}
                disabled={isEditable}
                onChange={handleChange}
              />
            ) : (
              <select
                name="region"
                onChange={handleChange}
                className="EditableText"
              >
                <option value={""}>Select State *</option>
                {states?.length > 0 &&
                  states?.map((item) => (
                    <option value={JSON.stringify(item)}>{item.name}</option>
                  ))}
              </select>
            )}
            <p className="error">{auditorModelErrs.region}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="location"
              className={isEditable ? "" : "EditableLabel"}
            >
              City
            </label>
            {isEditable ? (
              <input
                type="text"
                id="location"
                name="location"
                className="EditlabelDis"
                defaultValue={auditorList.location}
                disabled={isEditable}
                onChange={handleChange}
              />
            ) : (
              <select
                name="location"
                onChange={handleChange}
                className="EditableText"
              >
                <option value={""}>Select State *</option>
                {cities?.length > 0 &&
                  cities?.map((item) => (
                    <option value={item.name}>{item.name}</option>
                  ))}
              </select>
            )}
            <p className="error">{auditorModelErrs.location}</p>
          </div>
          {/* <div className="elementContainer">
            <label htmlFor="workPlan">Work Plan</label>
            <input
              type="text"
              id="workPlan"
              // value={formData["Work Plan"] || ""}
              // onChange={(e) =>
              //   setFormData({ ...formData, "Work Plan": e.target.value })
              // }
            />
          </div> */}
          {/* Uncoment when it is required */}
          {/* <div className="elementContainer">
            <label htmlFor="officeTimings">Office Timings</label>
            <input
              type="text"
              id="officeTimings"
              className={disabled ? "" : "EditlabelDis"}
              disabled
              value={formData["Office Timings"] || ""}
              onChange={(e) =>
                setFormData({ ...formData, "Office Timings": e.target.value })
              }
            />
          </div>
          <div className="elementContainer">
            <label htmlFor="breakTiming1">Break Timing 1</label>
            <input
              type="text"
              id="breakTiming1"
              className={disabled ? "" : "EditlabelDis"}
              disabled
              value={formData["Break Timing 1"] || ""}
              onChange={(e) =>
                setFormData({ ...formData, "Break Timing 1": e.target.value })
              }
            />
          </div>*/}
          {/* <div className="elementContainer date">
            <label htmlFor="leavesFrom">Leaves from</label>
            <input
              type="text"
              id="leavesFrom"
              className={disabled ? "" : "EditlabelDis"}
              disabled
              value={formData["Leaves from"] || ""}
              onChange={(e) =>
                setFormData({ ...formData, "Leaves from": e.target.value })
              }
            />
          </div>
          <div className="elementContainer date">
            <label htmlFor="Leavesto">Leaves to</label>
            <input
              type="text"
              id="Leavesto"
              className={disabled ? "" : "EditlabelDis"}
              value={formData["Leaves to"] || ""}
              disabled
              onChange={(e) =>
                setFormData({ ...formData, "Leaves to": e.target.value })
              }
            />
          </div>  */}
          {/* Uncoment when it is required */}
        </div>

        <div className="btnContainer">
          {!isEditable && (
            <Button
              name="applyLeave"
              dispName="Submit"
              click={submitUpdatedAuditor}
              isLoading={isLoading}
              disabled={disabled}
              className={disabled ? "loaderdisabled" : ""}

              // click={(e) => console.log(e)}
            />
          )}
        </div>
      </form>
    </ApplyLeaveFormStyle>
  );
};

export const DeleteConfirm = ({ comp }) => {
  const [remarks, setRemarks] = useState("");

  return (
    <DeleteConfirmStyle>
      <h3>{comp.disc}</h3>
      <div className="elementContainer">
        <label htmlFor="leavesFrom">Remarks</label>
        <input
          type="text"
          id="remarks"
          onChange={(e) => setRemarks(e.target.value)}
        />
      </div>
      <div className="btnContainer">
        <Button
          name="applyLeave"
          dispName="Cancel"
          click={(e) => comp.cancel()}
          className="cancel fg1"
        />
        <Button
          name="applyLeave"
          dispName="Delete"
          disabled={!remarks}
          click={(e) => comp.delete(remarks)}
          className="delete"
        />
      </div>
    </DeleteConfirmStyle>
  );
};

const DeleteConfirmStyle = styled.div`
  h3 {
    color: var(--dark5);
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    gap: 12px;
    button {
    }
  }
  .finalVerification {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 22px;
    border-bottom: 1px solid var(--border-color);
    input[type="radio"] {
      transform: scale(0.9);
    }
    p {
      color: var(--dark6);
      margin-right: 20px;
      font-size: 18px;
    }
    h6 {
      font-size: 18px;
      color: var(--dark2);
      margin: 0 20px 0 12px;
    }
  }

  .elementContainer {
    // width: calc(50% - 10px);
    position: relative;
    margin: 12px 0;
    label {
      color: var(--dark6);
      font-size: 12px;
      position: absolute;
      top: -6px;
      left: 8%;
      background: var(--white);
      padding: 0 4px;
      z-index: 1;
    }
    input {
      width: 100%;
      padding: 14px;
      font-size: 16px;
      border: 1px solid var(--border-color2);
      border-radius: 5px;
    }
  }
`;

const ApplyLeaveFormStyle = styled.div`
  padding: 20px;
  border: 1px solid var(--border-color3);
  border-radius: 5px;
  margin-bottom: 20px;
  h4 {
    font-size: 16px;
    color: var(--dark2);
  }
  .formContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    .elementContainer {
      width: calc(50% - 10px);
      position: relative;
      margin: 12px 0;
      label {
        color: var(--dark6);
        font-size: 12px;
        position: absolute;
        top: -6px;
        left: 8%;
        background: var(--white);
        padding: 0 4px;
        z-index: 1;
      }
      input {
        width: 100%;
        padding: 14px;
        font-size: 16px;
        border: 1px solid var(--border-color2);
        border-radius: 5px;
      }
      select {
        width: 100%;
        padding: 14px;
        font-size: 16px;
        border: 1px solid black;
        border-radius: 5px;
      }
      .EditableText {
        color: black;
        border: 1px solid black;
      }
      .EditableLabel {
        color: black;
        font-weight: 600;
      }
      .EditlabelDis {
        opacity: 0.7;
      }
      &.date {
        position: relative;
        &:after {
          content: "";
          background-image: url(${CalanderGrad});
          background-repeat: no-repeat;
          height: 17px;
          width: 16px;
          background-size: cover;
          position: absolute;
          right: 4%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      font-weight: 400;
      font-size: 18px;
      padding: 9px;
      width: 166px;
    }
    .loaderdisabled {
      opacity: 0.5 !important;
    }
  }
`;

const AddAuditorStyle = styled.div`
  label {
    font-size: 18px;
    color: var(--dark6);
  }
  .elementList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .elementContainer {
    width: calc(33.3% - 10px);
    align-self: center;
    input,
    .selected-option {
      font-size: 18px;
    }
  }
  .dateelementContainer {
    // width: calc(50% - 10px);
    width: calc(33.3% - 10px);
    position: relative;
    margin: 12px 0;
    label {
      color: var(--dark6);
      font-size: 12px;
      position: absolute;
      top: -6px;
      left: 8%;
      background: var(--white);
      padding: 0 4px;
      z-index: 1;
    }
    input {
      width: 100%;
      padding: 14px;
      font-size: 16px;
      border: 1px solid var(--border-color2);
      border-radius: 5px;
    }
  }

  select,
  input[type="date"] {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
  }
  .finalVerification {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding: 6px 0 22px;
    border-bottom: 1px solid var(--border-color);
    input[type="radio"] {
      transform: scale(0.9);
    }
    p {
      color: var(--dark6);
      margin-right: 20px;
      font-size: 18px;
    }
    h6 {
      font-size: 18px;
      color: var(--dark2);
      margin: 0 20px 0 12px;
    }
  }

  select,
  input[type="date"] {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background-color: white;
    color: gray;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0;
    margin-bottom: -15px;
    button {
      width: 165px;
      font-size: 20px;
      padding: 9px;
    }
    .loaderdisabled {
      opacity: 0.5 !important;
    }
  }
  .rangeBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    label {
      flex: 1;
    }
    .timeBox {
      width: 60px;
      padding: 10px 5px;
      input {
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .selectTabContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    width: 100%;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    margin-bottom: 20px;
    ul {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      li {
        min-width: 74px;
        text-align: center;
        padding: 8px 12px;
        border: 1px solid var(--border-color2);
        border-radius: 5px;
        color: var(--dark2);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        &.active {
          border: none;
          background: var(--fg3);
          color: var(--white);
        }
      }
    }
  }
  .error {
    color: red;
    font-size: 12px;
  }
`;
