import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderFilters from "../../Components/HeaderFilters";
import {
  RefreshGrad,
  GridViewIcon,
  gridViewFade,
  GridViewFade,
  ListViewIcon,
} from "../../assets/ImageUrl";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getTokenNumberDashboardData } from "../../Store/SagasActions/DashboardOverviewSagaAction";

const QManagement = () => {
  const [tableData, setTableData] = useState([]);
  const [tableGridView, setTabeGridView] = useState(true);

  const [spin, setSpin] = useState(false);
  const account = useSelector((state) => state?.DashboardReducer.accid);

  const accid = sessionStorage.getItem("accid");
  const AccountSwap =
    accid === "Account Admin" ? sessionStorage.getItem("accid") : account;
  const dispatch = useDispatch();

  const getTokenNumber = () => {
    setSpin(true);
    const model = {
      accid: AccountSwap,
      reqtype: "1",
    };
    dispatch(
      getTokenNumberDashboardData({
        model: model,
        callback: getTokenNumberRes,
      })
    );
  };

  useEffect(() => {
    setSpin(true);

    getTokenNumber();
  }, [AccountSwap]);

  const getTokenNumberRes = (data) => {
    setSpin(false);
    setTableData(data);
  };
  const columns = [
    {
      name: "Token Numbers",
      selector: "tokennumber",
      textAlign: "center",
      center: true,
      style: {
        justifyContent: "center",
        textAlign: "center",
        padding: "0",
      },
    },
    {
      name: "Customers Count",
      selector: "count",
      textAlign: "center",
      center: true,
      style: {
        justifyContent: "center",
        padding: "0",
      },
    },
  ];

  const data = tableData?.tokenlist;

  //   Reload

  const reload = () => {
    getTokenNumber();
  };

  const gridViewFunc = () => {
    setTabeGridView(!tableGridView);
  };

  const handleView = () => (tableGridView ? GridViewIcon : ListViewIcon);

  // Header Actions
  const headerActions = [
    {
      img: handleView(),
      key: "GridViewIcon",
      func: gridViewFunc,
    },
    {
      img: RefreshGrad,
      key: "RefreshGrad",
      func: reload,
    },
  ];

  // Custom Styles
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        // paddingLeft: "", // override the cell padding for head cells
        borderRight: "1px solid #adb3bc80",
        borderLeft: "1px solid #adb3bc80",
      },
    },
    cells: {
      style: {
        borderRight: "1px solid #adb3bc80",
        borderLeft: "1px solid #adb3bc80",

        paddingLeft: "4rem", // override the cell padding for data cells
      },
    },
  };

  return (
    <>
      <ReportStyle>
        <HeaderFilters
          heading={{ name: "Q-Management" }}
          actionBtns={headerActions}
          spin={spin}
        />
        <HeadReport>
          <TotalCus>
            Total Customers In Queue:
            <TotCusNumber>{tableData?.totalcus_in_tokenscreen}</TotCusNumber>
          </TotalCus>
          <TotalCus>
            Customers Enabled Join Now:
            <TotCusNumber>{tableData?.totalcus_joinnow_enabled}</TotCusNumber>
          </TotalCus>
          <TotalCus>
            Customers Waiting For Agent:
            <TotCusNumber>
              {tableData.totalcus_joined_waiting_agent}
            </TotCusNumber>
          </TotalCus>
        </HeadReport>
        {tableGridView ? (
          <DataTableDiv>
            <DataTable
              columns={columns || []}
              data={data || []}
              customStyles={customStyles}
              pagination
              paginationPerPage={30}
            />
          </DataTableDiv>
        ) : (
          <GridView>
            {tableData?.tokenlist?.map((item) => {
              return (
                <Grids key={item?.tokennumber}>
                  <Circle className="circle">
                    <Label>Token</Label>
                    <Countval>{item?.tokennumber}</Countval>
                  </Circle>
                  <div style={{ paddingTop: "1rem" }}>
                    <TokenLabel>Customer's Count</TokenLabel>
                    <GridTokenNumber>{item?.count}</GridTokenNumber>
                  </div>
                </Grids>
              );
            })}
          </GridView>
        )}
      </ReportStyle>
    </>
  );
};

export default QManagement;

const ReportStyle = styled.div`
  background: var(--white);
  box-shadow: var(--cardShadow);
  padding: 20px 24px 4px;
  height: calc(100vh-120px);
  position: relative;
`;

const TotalCus = styled.h2`
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;
const TotCusNumber = styled.span`
  color: #2d4674;
  font-weight: 600;
`;
const HeadReport = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DataTableDiv = styled.div``;
const GridView = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem;
  border: 1px solid #adb3bc80;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  h4 {
    /* text-align: center; */
    font-weight: 400;
  }
`;
const Grids = styled.div`
  background: #cae9ff80;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Countval = styled.span`
  font-size: 2rem;
  background: linear-gradient(180deg, #021c7a 0%, #308de4 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
`;
const Circle = styled.div`
  height: 100px;
  width: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
`;
const Label = styled.p`
  margin-bottom: 0;
`;
const GridTokenNumber = styled.h5`
  color: #000;
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;
  font-size: 1.2rem;
`;

const TokenLabel = styled.p`
  color: #555;
  font-weight: 400;
`;
