import React, { useState } from 'react'
import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import { addDays } from 'date-fns';
import 'react-date-range/dist/theme/default.css'
import 'react-date-range/dist/styles.css'
import moment from 'moment';

export default function RangeDate({ changes, dateRange }) {
  const modifyDateSelection = dateRange || [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 4),
      key: 'selection'
    }
  ]
  const [state, setState] = useState(modifyDateSelection);
  const handleChange = details => {
    setState([details.selection])
    changes && changes(details)
  }
  return (
    <DateRangeStyle>
      <DateRange
        editableDateInputs={true}
        onChange={item => handleChange(item)}
        moveRangeOnFirstSelection={false}
        ranges={state}
        maxDate={moment().toDate()}
      />
    </DateRangeStyle>
  )
}

const DateRangeStyle = styled.div`
  .rdrDateRangeWrapper {
    width: 100%;
    font-size: 20px;
    .rdrMonth {
      width: 100%;
      padding: 0;
    }
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrWeekDays {
    .rdrWeekDay {
      font-size: 20px;
      color: var(--dark2);
      font-weight: 600;
      line-height: initial;
    }
  }
  .rdrDays {
    .rdrDay {
      height: 2.5rem;
      padding-bottom: 8px;
      span {
        font-size: 20px;
        color: var(--darkSecond);
        font-weight: 700;
      }
    }
  }
  .rdrStartEdge, .rdrEndEdge {
    color: transparent !important;
    background: linear-gradient(180deg, #38568F 0%, #38A1F7 100%);
  }
  .rdrInRange {
    color: transparent !important;
    background: linear-gradient(180deg, #38568F 0%, #38A1F7 100%);
    opacity: 0.1;
  }
  .rdrEndEdge {}
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #2D2D2D;
  }
  .rdrStartEdge, .rdrEndEdge, .rdrDayHoverd {
    border-radius: 5px;
  }
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after {
    background: transparent;
  }
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }.rdrMonthAndYearWrapper {
    padding-top: 0;
  }
  .rdrMonthAndYearPickers select {
    color: var(--dark);
    font-size: 20px;
    font-weight: 600;
  }
  .rdrDayToday .rdrDayNumber span:after {
    bottom: 0;
  }
`