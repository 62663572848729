import {  ACTION_GET_AGENT_LIST_REQ, ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT, ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT_ACTIVITY, ACTION_POST_GET_LANGUAGES } from "./SagaActionTypes"


// AGENT
export const actionReqGetAgentListSagaAction = (payload) => {
    return {
        type: ACTION_GET_AGENT_LIST_REQ,
        payload: payload
    }
}

export const actionReqGetUpdateAgent = (payload) => {
    return {
        type: ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT,
        payload: payload
    }
}

export const actionReqGetAgentActivity = (payload) => {
    return {
        type: ACTION_POST_ADMIN_DASHBOARD_UPDATE_AGENT_ACTIVITY,
        payload: payload
    }
}

export const actionReqGetLanguages = (payload) => {
    return {
        type: ACTION_POST_GET_LANGUAGES,
        payload: payload
    }
}
