import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo_Syn from "../assets/imgs/logo_Syn.svg";
import circleLinesBg from "../assets/imgs/circleLinesBg.svg";
import ResetPassword from "../Components/ResetPassword";
import ChangePassword from "../Components/ChangePassword";
import LoginForm from "../Components/LoginForm";
import Popup from "../Components/Popup";
import { Button } from "../Components/Elements";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import toast from "react-hot-toast";
import {
  loginSagaAction,
  getRolesSagaAction,
} from "../Store/SagasActions/AuthSagaActions";
import {
  minOrMaxLength,
  validateEmail,
  validateIsRequired,
} from "../utils/Validations";
import {
  actionReqAdminAccounts,
  setClientAccountId,
} from "../Store/SagasActions/DashboardOverviewSagaAction";
import QualityRoles from "../Constants/Roles";
import { ProteanLogo } from "../assets/ImageUrl";
import LoaderClr from "../Components/LoaderClr";

export default function Login({ type }) {
  let loginNames = {
    username: "",
    password: "",
  };

  let rolelist = [
    {
      rid: "1",
      rolename: "Super Admin",
    },
    {
      rid: "6",
      rolename: "Quality Check",
    },
    {
      rid: "2",
      rolename: "Account Admin"
    },
    {
      rid: "7",
      rolename: "Operations"
    }
  ]

  const [popup, setPopup] = useState("");
  const [loginObj, setLoginObj] = useState(loginNames);
  const [loginObjErr, setLoginObjErr] = useState(loginNames);
  const [roleObj, setRoleObj] = useState({});
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [AccountDetails, setAccountDetails] = useState([]);
  const naviage = useNavigate();
  const dispatch = useDispatch();
  const role = sessionStorage.getItem("role");
  const clientName = process.env.REACT_APP_CLIENT_ACCOUNT;
  const Protean = process.env.REACT_APP_PROTEAN
  const NIC = process.env.REACT_APP_NIC_CLIENT
  const OPERATIONS_TEAM = process.env.REACT_APP_OPERATIONS_TEAM
  // console.log(NIC , "djf")

  useEffect(() => {
    sessionStorage.clear();
    let updatedRoles = rolelist;
    if (clientName === "1") {
      updatedRoles = rolelist.splice(0, 2);
    } else if (Protean) {
      updatedRoles = rolelist.splice(0, 1)
    } else if (NIC) {
      updatedRoles = rolelist.filter(res => res.rid === "6");
      const roleObjData = {
        rid: updatedRoles[0].rid,
        rolename: updatedRoles[0].rolename,
      };
      setRoleObj(roleObjData);
    } else if (OPERATIONS_TEAM) {
      updatedRoles = rolelist.filter(x => x.rid === "7");
    }
    setRoles(updatedRoles);
    // dispatch(actionReqAdminAccounts({ callback: respGetAccounts }));
    // dispatch(getRolesSagaAction({ callback: getRolesData }));
  }, []);

  const respGetAccounts = (data) => {
    setAccountDetails(data?.accounts);
    sessionStorage.setItem("accid", data.accounts[0].accid);
    dispatch(setClientAccountId(data.accounts[0].accid));

    // setAccount(data.accounts[0].accountname);
  };

  // const getRolesData = (data) => {
  //   setRoles(data);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(e.target, "e");
    if (name === "rolename") {
      const role = value.split("-");
      const roleObjData = {
        rid: role[0],
        rolename: role[1],
      };
      setRoleObj(roleObjData);
    } else {
      if (value && name === "username") {
        const err = validateEmail(value);
        setLoginObjErr((prevState) => ({ ...prevState, [name]: err }));
      } else if (value && name === "password") {
        const isMin = minOrMaxLength(value, "minlength", 8);
        const isMax = minOrMaxLength(value, "maxlength", 16);
        setLoginObjErr((prevState) => ({
          ...prevState,
          [name]: isMin || isMax,
        }));
      } else {
        const isEmpty = validateIsRequired(value);
        setLoginObjErr((prevState) => ({ ...prevState, [name]: isEmpty }));
      }
      setLoginObj({ ...loginObj, [name]: value });
    }
  };
  const login = (e) => {
    e.preventDefault();
    // if (loginObj.username && loginObj.password && roleObj?.rid && roleObj?.rolename) {
    let isTrue = Object.values(loginObj).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(loginObjErr).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please check all inputs");
    } else {
      const encodePwd = base64.encode(loginObj?.password);
      const model = {
        username: loginObj.username,
        password: encodePwd,
        // rolename: "SuperAdmin",
        rid: roleObj?.rid,
        rolename: roleObj?.rolename,
        // rid: "1",
        authflag: "1",
      };
      setIsLoading(!isLoading);
      dispatch(loginSagaAction({ model: model, callback: loginRespData }));
    }
  };

  const loginRespData = (data) => {
    setIsLoading(isLoading);

    if (data.authflag === "1") {
      setPopup("");
    }
    if (data.respcode == "200") {
      // dispatch(actionReqAdminAccounts({ callback: respGetAccounts }));
      if (roleObj.rolename === QualityRoles.QA) {
        naviage("/qualitycheck/vciplist");
      } else {
        naviage("/dashboard/overview");
      }
      setTimeout(() => {
        toast.success(data.respdesc);
      }, 2000);
      // toast.success(data.respdesc);
      sessionStorage.setItem("username", data?.username);
      sessionStorage.setItem("authkey", data?.authkey);
    } else if (data.respcode == "4003") {
      toast.error(data.respdesc);
      naviage("/");
    } else if (data.respcode == "468") {
      setPopup("loggedIn");
    } else if (data.respcode == "4002") {
      naviage("/");
      toast.error(data.respdesc);
    } else {
      toast.error(data.respdesc);
    }
  };
  const MailSentBody = () => {
    return (
      <>
        <h2 className="fd3">
          Your password reset link is sent on your email. This link will expire
          after 30 minutes.
        </h2>
        <Button name="ok" dispName="ok" click={() => setPopup("")} />
      </>
    );
  };

  const validationno = () => {
    // naviage("/")
    window.location.reload();
  };
  // This is for AlreadyLoggedIN popup
  const AlreadyLoggedIn = () => {
    const cus_hr = {
      opacity: 0.3,
      marginTop: "11px",
    };

    return (
      <>
        {/* <form onSubmit={(event) => login(event, '1')} > */}
        <h4 className="fd2">
          Already Logged in Another Session/Browser. Are you sure want to clear
          all the other sessions?
        </h4>
        <hr style={cus_hr}></hr>
        <Button name="yes" dispName="Yes" click={login}>
          Yes
        </Button>
        <Button name="no" dispName="No" click={validationno}>
          No
        </Button>
        {/* </form> */}
      </>
    );
  };

  return (
    <>
      <div className="main-loader">
        <LoaderClr />
      </div>
      <LoginStyle>
        {process.env.REACT_APP_PROTEAN ? (
          <div className="slideContainerProtean">
            <div className="logoContainer">
              <div className="protean_img">
                <img src={ProteanLogo} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="slideContainer">
            <div className="logoContainer">
              <img src={logo_Syn} alt="" />
              <div className="logoDisc">
                <h1>Syntizen </h1>
                <p>Solutions for all your company needs</p>
              </div>
            </div>
          </div>
        )}
        <div className="formContainer">
          {type === "psw" && (
            <LoginForm
              loginObjErr={loginObjErr}
              handleChange={handleChange}
              login={login}
              roles={roles}
              roleObj={roleObj}
            //  isLoading={isLoading}
            //  validationyes={login}
            //  validationno={validationno}
            />
          )}
          {(type === "reset" && <ResetPassword pop={setPopup} />) ||
            (type === "change" && <ChangePassword pop={setPopup} />)}
        </div>
      </LoginStyle>
      {popup === "mailSent" && (
        <Popup
          className="px500"
          heading="Password reset link"
          body={<MailSentBody />}
          close={() => setPopup("")}
        />
      )}
      {popup === "loggedIn" && (
        <Popup
          className="cus-loggedIn"
          isHeader={true}
          body={<AlreadyLoggedIn />}
        />
      )}
    </>
  );
}

const LoginStyle = styled.div`
  height: 100vh;
  display: flex;
  .slideContainerProtean {
    background: var(--proteanBg),
      url(${process.env.REACT_APP_PROTEAN ? "" : circleLinesBg});
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-position: bottom left;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    .protean_img {
      flex: 0 1 66.6666666667%;
      justify-content: center !important;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .slideContainer {
    background: var(--slideBg),
      url(${process.env.REACT_APP_PROTEAN ? "" : circleLinesBg});
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-position: bottom left;
    width: 65%;
    .logoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 16px;
      .logoDisc {
        h1 {
          font-size: 80px;
          color: var(--white);
          font-weight: 700;
        }
        p {
          font-weight: 400;
          font-size: 30px;
          color: var(--white);
        }
      }
    }
    .protean_img {
      // background: linear-gradient(217.41deg, #6cb2ec 6.15%, #002b93 80.33%, #1b295c 93.64%);
      flex: 0 1 66.6666666667%;
      justify-content: center !important;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    .form {
      width: 70%;
      & > .fd1 {
        margin-bottom: 8px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 38px;
          width: 38px;
          background-size: cover;
          margin-left: 10px;
        }
      }
      & > h2 {
        margin-bottom: 28px;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
`;
