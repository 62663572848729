// LOGIN
export const ACTION_POST_LOGIN_REQ = '@ACTION/POST/LOCATION/LOGIN/REQUEST';
export const ACTION_GET_ROLES_REQ = '@ACTION/GET/ROLES/REQUEST';
// RESET
export const ACTION_POST_RESET_REQ = '@ACTION/POST/LOCATION/RESET/REQUEST';
// USER PROFILE DATA
export const ACTION_POST_USER_PROFILE_DATA = '@ACTION/POST/LOCATION/USER/PROFILE/DATA';
//RESET Password
export const ACTION_POST_RESETPASSWORD_REQ = '@ACTION/POST/LOCATION/RESETPASSWORD/REQUEST';
//CHANGEPASSWORD
export const ACTION_POST_CHANGEPASSWORD_REQ = '@ACTION/POST/LOCATION/CHANGEPASSWORD/REQUEST';
// LOGOUT USER
export const ACTION_POST_LOGOUTUSER_REQ = '@ACTION/POST/LOCATION/LOGOUTUSER/REQUEST';

export const ACTION_POST_ADD_LOADERCLR_REQ = '@ACTION/POST/LOCATION/LOADERCLR/REQUEST';

