import toast from 'react-hot-toast';
import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import Axios from '../../Service/axios';
import { actionReqResStatusLoaderSagaAction } from '../SagasActions/DashboardOverviewSagaAction';
import { ACTION_GET_AUDITOR_LIST_REQ, ACTION_POST_ADMIN_DASHBOARD_CREATE_AUDITOR, ACTION_POST_ADMIN_DASHBOARD_DELETE_AGENT, ACTION_POST_ADMIN_DASHBOARD_DELETE_AUDITOR, ACTION_POST_ADMIN_DASHBOARD_UPDATE_AUDITOR } from '../SagasActions/SagaActionTypes';
import {actionReqResStatusLoaderClrSagaAction} from "../SagasActions/AuthSagaActions"

const getAuditorListReq = (model) => {
    const URL = "GetAuditorList";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* getAuditorListReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    yield put(actionReqResStatusLoaderClrSagaAction(true));

    try {
        const resp = yield call(getAuditorListReq, action?.payload?.model);
        if (resp) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
                yield put(actionReqResStatusLoaderClrSagaAction(false));
            }
        } else {
            toast.error(resp?.respdesc);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } catch (err) {
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        } else {
            toast.error(err.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
        yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
}
const createAuditorReq = (model) => {
    const URL = "CreateAuditor";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* createAuditorReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(createAuditorReq, action?.payload?.model);

    try {
       
        // toast.success(resp?.respdesc);
        // if (action?.payload?.callback) {
        //     action?.payload?.callback(resp);
        // }
        if (resp && resp?.respcode === "200") {
            
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast.error(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }


        }
    } catch (err) {
        if (resp.authflag === "1") {
            // $('#login').modal('hide');
        }
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast.error(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

const deleteAuditorReq = (model) => {
    const URL = "DeleteAuditor";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* deleteAuditorReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(deleteAuditorReq, action?.payload?.model);

    try {
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast.error(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }


        }
    } catch (err) {
        if (resp.authflag === "1") {
            // $('#login').modal('hide');
        }
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast.error(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}



const updateAuditorReq = (model) => {
    const URL = "UpdateAuditor";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* updateAuditorReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(updateAuditorReq, action?.payload?.model);

    try {
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast.error(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }


        }
    } catch (err) {
        if (resp.authflag === "1") {
            // $('#login').modal('hide');
        }
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast.error(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}


export default function* AuditorWatcherSaga() {
    yield takeLatest(ACTION_GET_AUDITOR_LIST_REQ, getAuditorListReqSaga);
    yield takeLatest(ACTION_POST_ADMIN_DASHBOARD_CREATE_AUDITOR, createAuditorReqSaga);
    yield takeLatest(ACTION_POST_ADMIN_DASHBOARD_DELETE_AUDITOR, deleteAuditorReqSaga);
    yield takeLatest(ACTION_POST_ADMIN_DASHBOARD_UPDATE_AUDITOR, updateAuditorReqSaga);



}