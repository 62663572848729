const AgentActiveStatus = {
    INACTIVE: '0',
    ACTIVE: '1'
}

const AgentActiveStatuses = Object.freeze(AgentActiveStatus);

export default AgentActiveStatuses;


