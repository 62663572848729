import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
// import RouteNames from '../../Constants/RouteNames';
import Axios from "../../Service/axios";
import {
  ACTION_POST_RESET_REQ,
  ACTION_POST_LOGOUTUSER_REQ,
  ACTION_POST_LOGIN_REQ,
  ACTION_GET_ROLES_REQ,
  ACTION_POST_RESETPASSWORD_REQ,
  ACTION_POST_CHANGEPASSWORD_REQ,
} from "../SagasActions/actionTypes";
import { actionReqResStatusLoaderClrSagaAction } from "../SagasActions/AuthSagaActions";
import { actionReqResStatusLoaderSagaAction } from "../SagasActions/CommonSagaActions";
// import second from '../SagasActions/'
// import { ACTION_GET_DIGILOCKER_LINK_REQ, ACTION_GET_DIGILOCKER_STATUS_REQ } from '../SagaActions/SagaActionTypes';
const $ = window.$;

// LOGIN
const loginReq = (model) => {
  const URL = "UserAuthentication";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* loginReqSaga(action) {
  yield put(actionReqResStatusLoaderClrSagaAction(true));
  const resp = yield call(loginReq, action?.payload?.model);

  try {
    sessionStorage.setItem("authkey", resp?.authkey);
    sessionStorage.setItem("role", resp?.role);
    sessionStorage.setItem("user_email", action.payload.model.username);
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
  } catch (err) {
    if (resp.authflag === "1") {
      $("#login").modal("hide");
    }
    if (err.response) {
      console.log(err?.response?.data?.errors[0]?.message, "responses");
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
    console.log(err.response, "eerr");
  } finally {
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}

//RESET

const resetReq = (model) => {
  const URL = "SendPasswordResetLink";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* resetReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(resetReq, action?.payload?.model);
    if (resp.authflag === "1") {
      $("#login").modal("hide");
    }
    if (resp && resp?.respcode === "200") {
      sessionStorage.setItem("authkey", resp?.authkey);
      sessionStorage.setItem("role", resp?.role);
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

//reset password

const restPasswordReq = (model) => {
  const URL = "ResetPassword";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* resetPasswordReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(restPasswordReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const changePasswordReq = (model) => {
  const URL = "ChangePassword";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* changePasswordReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(changePasswordReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const logoutUserReq = () => {
  const URL = "UserLogout";
  return Axios.post(URL).then((res) => {
    return res?.data;
  });
};

function* logoutUserReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  yield put(actionReqResStatusLoaderClrSagaAction(true));
  try {
    const resp = yield call(logoutUserReq);
    // toast.success(resp?.respdesc);
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }

    // if (resp && resp?.respcode === "200") {
    //     toast.success(resp?.respdesc);
    //     if (action?.payload?.callback) {
    //         action?.payload?.callback(resp);
    //     }
    // } else {
    //     toast.error(resp?.respdesc);
    //     console.log(resp,"log")
    // }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
      console.group(err.message);
    }
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}

// GET ROLES
const getRolesReq = () => {
  const URL = "GetRolesList";
  return Axios.post(URL, {}).then((res) => {
    return res?.data;
  });
};

function* getRolesReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getRolesReq);
    if (resp && resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp?.rolelist);
      }
    } else {
      // toast.error(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

export default function* AuthWatcherSaga() {
  yield takeLatest(ACTION_POST_LOGIN_REQ, loginReqSaga);
  yield takeLatest(ACTION_GET_ROLES_REQ, getRolesReqSaga);
  yield takeLatest(ACTION_POST_RESET_REQ, resetReqSaga);
  yield takeLatest(ACTION_POST_RESETPASSWORD_REQ, resetPasswordReqSaga);
  yield takeLatest(ACTION_POST_CHANGEPASSWORD_REQ, changePasswordReqSaga);
  yield takeLatest(ACTION_POST_LOGOUTUSER_REQ, logoutUserReqSaga);
}
