import {  ACTION_GET_AUDITOR_LIST_REQ, ACTION_POST_ADMIN_DASHBOARD_CREATE_AUDITOR, ACTION_POST_ADMIN_DASHBOARD_DELETE_AUDITOR, ACTION_POST_ADMIN_DASHBOARD_UPDATE_AUDITOR , } from "./SagaActionTypes"

// AGENT
export const actionReqGetAuditorListSagaAction = (payload) => {
    return {
        type: ACTION_GET_AUDITOR_LIST_REQ,
        payload: payload
    }
}


export const actionReqGetCreateAuditor = (payload) => {
    return {
        type: ACTION_POST_ADMIN_DASHBOARD_CREATE_AUDITOR,
        payload: payload
    }
}

export const actionReqGetDeleteAuditor = (payload) => {
    return {
        type: ACTION_POST_ADMIN_DASHBOARD_DELETE_AUDITOR,
        payload: payload
    }
}


export const actionReqGetUpdateAuditor = (payload) => {
    return {
        type: ACTION_POST_ADMIN_DASHBOARD_UPDATE_AUDITOR,
        payload: payload
    }
}
// export const actionConfigureAccountSagaAction = (payload) => {
//     return {
//         type: ACTION_POST_ADMIN_DASHBOARD_CONFIGURE_ACCOUNT,
//         payload: payload
//     }
// }
