import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { creUpdDelSerIncidentsSagaAction, getServiceIncidentsSagaAction, getServicesListSagaAction } from '../../Store/SagasActions/OperationsActions/OperationsSagaAction';
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../Components/Popup";
import InputFields from "./InputFields";
import { validateIsRequired } from "../../utils/Validations";

export default function ServiceIncidents() {

    const [serviceData, setServiceData] = useState({ siid: "", accid: "", accname: "", servicename: "", incidentname: "", ftime: "", ttime: "", reason: "", datecreated: "" });
    const [serviceListDropdown, setServiceListDropdown] = useState([]);
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [togglePop, setTogglePop] = useState(false);
    const [uniqueIdSerIncident, setUniqueIdSerIncident] = useState({});
    const [serviceIncidents, setServiceIncidents] = useState([]);
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const account = useSelector((state) => state?.DashboardReducer.accid);
    const getAccounts = useSelector((state) => state?.DashboardReducer.accountaccid?.accounts);
    let accid = 0;
    // let accid = account || sessionStorage.getItem("accid");

    const getServiceList = useCallback(() => {
        dispatch(getServicesListSagaAction({ callback: respServiceList }));
    }, [dispatch])

    useEffect(() => {
        if (account) getServiceList(account);
    }, [account, getServiceList])

    const respServiceList = (data) => setServiceListDropdown(data?.serviceslist);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        const isEmpty = validateIsRequired(value);
        setErrors((prevState) => ({ ...prevState, [id]: isEmpty }));
        setDisabled(false)
        setServiceData({
            ...serviceData,
            [id]: value
        })
    }

    const handleFormSubmit = (event, btn) => {
        event.preventDefault();
        try {
            let payload = {  // update  status 1 , delete status 0
                endpoint: "SubmitServiceIncidents",
                model: {
                    "accid": serviceData?.accountid,
                    "sid": serviceData?.servicename,
                    "incidentname": serviceData?.incidentname,
                    "ftime": fromTime, //"2023-01-31 15:40:00",
                    "ttime": toTime,// "2023-01-31 16:40:00", // 2023-02-21T19:01:00Z
                    "reson": serviceData?.reason
                }
            }
            if (btn === "Update Incident") {
                payload.endpoint = "UpdateServiceIncidents";
                payload.model['status'] = 1;
                payload.model['siid'] = serviceData?.siid
            }
            // else {
            //     payload.endpoint = "UpdateServiceIncidents";
            //     payload.model['status'] = 0;
            // }
            let isErrTrue = Object.values(errors).every(
                (v) => v === "" || v === undefined || v === null
            );

            if (!isErrTrue || (serviceData?.accountid?.length <= 0 && serviceData?.servicename?.length <= 0)) {
                toast.error("Please check all inputs");
            } else {
                dispatch(creUpdDelSerIncidentsSagaAction({ model: payload, callback: respIncident }))
            }

        } catch (error) {
            console.log('error =>', error)
        }
    };

    const respIncident = (data) => {
        setServiceData({ siid: "", accid: "", accname: "", servicename: "", incidentname: "", ftime: "", ttime: "", reason: "", datecreated: "" })
        setFromTime("");
        setToTime("");
        setServiceListDropdown([])
        if (data?.respcode == "200") {
            toast.success(data?.respdesc)
            setTogglePop(false)
            dispatch(getServiceIncidentsSagaAction({ model: { accid }, callback: respServiceIncidents }))
        } else {
            toast.error(data?.respdesc)
        }
        getServiceList(account);
    };

    const filterSid = useCallback((str) => {
        let res = serviceListDropdown.map((item) => {
            if (item?.servicename == str) {
                return item.sid
            }
        }).filter((e) => {
            return e !== undefined
        })
        return res.toString();
    }, [serviceListDropdown])

    useEffect(() => {
        if (serviceIncidents.length > 0 && togglePop) {
            let fData = serviceIncidents.find(item => item.servicename == uniqueIdSerIncident.servicename)
            let res = getAccounts.find(item => item?.accountname == uniqueIdSerIncident?.accname)
            fData['servicename'] = filterSid(fData['servicename']);
            fData['accountid'] = res.accid
            setServiceData(fData)
        }
    }, [serviceIncidents, togglePop])

    const respServiceIncidents = (data) => {
        if (data?.respcode == "200") {
            let restructureForTableData = data?.serviceincidentslist.map((item) => {
                return {
                    siid: item?.siid,
                    accname: item?.accname,
                    servicename: item?.servicename,
                    incidentname: item?.incidentname,
                    ftime: item?.ftime,
                    ttime: item?.ttime,
                    reason: item?.reason,
                    datecreated: item?.datecreated,
                }
            })
            setServiceIncidents(restructureForTableData);
        } else {
            toast.error(data?.respdesc)
        }
    };

    const handleDropDownChange = (e) => {
        const { id, value } = e.target;

        const isEmpty = validateIsRequired(value);
        setErrors((prevState) => ({ ...prevState, [id]: isEmpty }));

        setServiceData({ ...serviceData, [id]: value })
    };

    function handleChange(ev) {

        if (ev.target['validity'].valid) {
            const isEmpty = validateIsRequired(ev.target['value']);
            setErrors((prevState) => ({ ...prevState, [ev.target.id]: isEmpty }));
        }

        const dt = ev.target['value'] + ':00'
        if (ev.target.id == "ftime") {
            setFromTime(dt.replace("T", " "));
        } else {
            setToTime(dt.replace("T", " "));
        }

    }


    const columnsIncidents = [

        {
            name: "SID",
            selector: (row) => row['siid'],
            sortable: true,
        },
        {
            name: "Account Name",
            selector: (row) => row["accname"],
            sortable: true,
        },
        {
            name: "Servcie Name",
            selector: (row) => row["servicename"],
            sortable: true,
            wrap: true,
        },
        {
            name: "Incident Name",
            selector: (row) => row["incidentname"],
            sortable: true,
            wrap: true,
        },
        {
            name: "From Time",
            selector: (row) => row["ftime"],
            format: (row) => moment(row.ftime).format("lll"),
            sortable: true,
            wrap: true,
        },
        {
            name: "To Time",
            selector: (row) => row["ttime"],
            format: (row) => moment(row.ftime).format("lll"),
            sortable: true,
            wrap: true,
        },
        {
            name: "Reason",
            selector: (row) => row["reason"],
            sortable: true,
            wrap: true,
        },
        {
            name: "Date Created",
            selector: (row) => row["datecreated"],
            sortable: true,
            wrap: true,
        },
        {
            name: "Actions",
            selector: (row) => (
                <div className="flex-child">
                    {/* <FontAwesomeIcon icon="fa-solid fa-trash" />
                    <FontAwesomeIcon icon="fa-duotone fa-pen-to-square" /> */}

                    <FontAwesomeIcon icon={faPenToSquare} onClick={(e) => {
                        setTogglePop(true);
                        setUniqueIdSerIncident(row)
                    }} />
                </div>
            )
        }
    ];

    const handlePopupClose = () => {
        setTogglePop(false);
        // setFilteredData([]);
        setServiceData({ siid: "", accid: "", accname: "", servicename: "", incidentname: "", ftime: "", ttime: "", reason: "", datecreated: "" })
    }


    return (
        <DashboardIndexStyle>
            <div className="outerLayout">
                <h4 className="main-heading">Service Incident</h4>
                <hr className='customHr' />
                <div className="wrapper">
                    <InputFields
                        fromTime={fromTime}
                        toTime={toTime}
                        handleChange={handleChange}
                        serviceListDropdown={serviceListDropdown}
                        handleInputChange={handleInputChange}
                        serviceData={serviceData}
                        handleFormSubmit={handleFormSubmit}
                        handleDropDownChange={handleDropDownChange}
                        btnName="Create Incident"
                        getAccounts={getAccounts}
                        errors={errors}
                        isDisabled={disabled}
                    />
                </div>
                <div className="mb-3">
                    {
                        serviceIncidents?.length > 0 &&
                        <DataTable
                            columns={columnsIncidents}
                            data={serviceIncidents}
                            pagination
                            customStyles={tableCustomStyles}
                        />
                    }
                </div>
            </div>

            {
                togglePop === true &&
                <Popup
                    heading="Update"
                    body={
                        <InputFields
                            fromTime={fromTime}
                            toTime={toTime}
                            handleChange={handleChange}
                            serviceListDropdown={serviceListDropdown}
                            handleInputChange={handleInputChange}
                            serviceData={serviceData}
                            handleFormSubmit={handleFormSubmit}
                            handleDropDownChange={handleDropDownChange}
                            btnName="Update Incident"
                            getAccounts={getAccounts}
                            errors={errors}

                        />
                    }
                    close={handlePopupClose}
                    className="px860"
                />
            }
        </DashboardIndexStyle >
    );
}

// rdt_TableHeader
const tableCustomStyles = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            // paddingLeft: '0 8px',
            // justifyContent: 'center',
            // backgroundColor: '#FFA500'
        },
    },
}
const DashboardIndexStyle = styled.div`
.error {
    color: red;
    font-size: 12px;
    margin-left: 14px;
}

.flex-child {
    display: flex;
    gap: 10px;
}
.flex-child > svg {
    padding: 5px;

}

.flex-child > svg:hover {
    background: #a09a9a99;
    border-radius: 5px;
}

.mb-3 {
    margin-bottom: 30px;
}

.wrapper {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
}
.main-heading {
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 6px;
  }


  .customHr {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  


.outerLayout {
    box-shadow: var(--cardShadow);
    // background: var(--white) !important;
    background: #f7f6f694;
    padding: 20px 24px 0 !important;
    height: calc(100vh - 120px);
    overflow: auto;
    position: relative;
    border-radius: 8px;
    width: 100%;
  }
.btn-submit {
    grid-column: 1 / -1;
    background: var(--buttonBg);
    text-align: center;
    padding: 0.6rem 1.2rem;
    color: var(--white);
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    height: auto;
    width: 300px;
    margin: 10px auto;
  }
  
.search-div {
    position: relative;
    height: auto;
    padding: 0px;
    margin: 0px;
  }
  .custom-search {
    width: 20rem;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
    margin: 5px;
  }
  .custom-select {
    width: 20rem;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
    margin: 5px;
  }  

    .formIncidents {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background: #fff;

        :last-child:nth-child(-1) {
            background: yellow;
        }
        .mainContainer {
            display: flex;
            flex-direction: column;
        

            span {
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #000000;
                margin-left: 10px;
                margin-top: 6px;
            }
            
        }
    }



  .overviewList {
    display: flex;
    gap: 16px;
    .overviewCard {
      flex: 1;
      background: var(--white);
      border: var(--cardBorder);
      box-shadow: var(--cardShadow);
      border-radius: 6px;
      padding: 20px 24px;
      .fd3 {
        font-weight: 700;
        font-size: 20px;
        color: var(--dark2);
        padding-bottom: 12px;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 16px;
        text-align: center;
      }
      .actionContainer {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 16px;
        aspect-ratio: 3 / 1.2;

        .actionCard {
          border: var(--border);
          padding: 18px;
          flex: 1;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 8px;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            transform: scale(1.03);
            box-shadow: var(--selectShadow);
          }
          .fg1 {
          }
        }
    }
  }
  .ledgers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 48px;
    margin-bottom: 28px;
    padding: 0 50px;
    .ledger {
      display: flex;
      justify-content: center;
      align-items: center;
      .color {
        height: 8px;
        width: 8px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 8px;
      }
      .ledgerName {
      }
      .ledgerNo {
        font-weight: 700;
      }
    }
  }
  
`;
