import React from 'react'
import styled from 'styled-components'
import {AddUserGrad, AddUserWhite, BoxMenu, CalanderGrad, CallGrad, CircleDirect, Clock, ClockGrad, CloseBtn, DeleteGrad, DocBundleGrad, DownArrowGrad, DownArrowWhite, DownGray, EditGrad, Eye, FileIcon, FileSearch, FilterGrad, Headset, HiColor, LinkUser, LinkUserGrad, Lock, LockColor, LockWhite, Mail, MailGrad, MultiUserGrad, MultiUserGray, MultiUserWhite, OrderGrad, PlayGrad, PlusGrad, RefreshGrad, RightArrowGrad, ScreenShareGrad, SearchGrad, Settings, TikGrad, Timer, TimerGrad, UploadGrad, UserBoxGrad, UserProfile, UserProfileGrad, UserSelect, Video, VideoGrad, WorldGrad, BackBlack, DownloadWhite} from './assets/ImageUrl'
import {Text, Select, RadioBtn, Button} from './Components/Elements'

export default function DocumentationPanel() {
  return (
    <Template>
        <div className="sec">
            <h2>Class name for fonts</h2>
            <div className="container">
                <h1 className="fd1">Testing font ( h1.fd1 )</h1>
                <h1 className="fd2">Testing font ( h2.fd2 )</h1>
                <h1 className="fd3">Testing font ( h2.fd3 )</h1>
                <h1 className="fd4">Testing font ( h3.fd4 )</h1>
                <h1 className="fd5">Testing font ( h4.fd5 )</h1>
                <h1 className="fd6">Testing font ( h4.fd6 )</h1>
                <h1 className="fp1">Testing font ( h4.fp1 )</h1>
                <h1 className="fd7">Testing font ( h5.fd7 )</h1>
                <h1 className="fd8">Testing font ( h6.fd8 )</h1>
                <h1 className="fd9">Testing font ( h6.fd9 )</h1>
                <h1 className="fg1">Testing font ( h5.fg1 )</h1>
                <h1 className="fg2">Testing font ( h6.fg2 )</h1>
                
                <h1 className="fl1 bg">Testing font ( h2.fl1 )</h1>
                <h1 className="fl2 bg">Testing font ( h2.fl2 )</h1>
                <h1 className="fl3 bg">Testing font ( h6.fl3 )</h1>
            </div>
        </div>
        <div className="sec">
            <h2>Image name from (../src/assets/ImageUrl.jsx)</h2>
            <div className="container">
                <div className="imgContainer">
                    <img src={AddUserWhite} alt="" />
                    <p>AddUserWhite</p>
                </div>
                <div className="imgContainer">
                    <img src={AddUserGrad} alt="" />
                    <p>AddUserGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={BoxMenu} alt="" />
                    <p>BoxMenu</p>
                </div>
                <div className="imgContainer">
                    <img src={CalanderGrad} alt="" />
                    <p>CalanderGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={CallGrad} alt="" />
                    <p>CallGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={CircleDirect} alt="" />
                    <p>CircleDirect</p>
                </div>
                <div className="imgContainer">
                    <img src={Clock} alt="" />
                    <p>Clock</p>
                </div>
                <div className="imgContainer">
                    <img src={ClockGrad} alt="" />
                    <p>ClockGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={CloseBtn} alt="" />
                    <p>CloseBtn</p>
                </div>
                <div className="imgContainer">
                    <img src={DeleteGrad} alt="" />
                    <p>DeleteGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={DocBundleGrad} alt="" />
                    <p>DocBundleGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={DownArrowGrad} alt="" />
                    <p>DownArrowGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={DownArrowWhite} alt="" />
                    <p>DownArrowWhite</p>
                </div>
                <div className="imgContainer">
                    <img src={DownGray} alt="" />
                    <p>DownGray</p>
                </div>
                <div className="imgContainer">
                    <img src={EditGrad} alt="" />
                    <p>EditGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={Eye} alt="" />
                    <p>Eye</p>
                </div>
                <div className="imgContainer">
                    <img src={FileIcon} alt="" />
                    <p>FileIcon</p>
                </div>
                <div className="imgContainer">
                    <img src={FileSearch} alt="" />
                    <p>FileSearch</p>
                </div>
                <div className="imgContainer">
                    <img src={FilterGrad} alt="" />
                    <p>FilterGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={Headset} alt="" />
                    <p>Headset</p>
                </div>
                <div className="imgContainer">
                    <img src={HiColor} alt="" />
                    <p>HiColor</p>
                </div>
                <div className="imgContainer">
                    <img src={LinkUser} alt="" />
                    <p>LinkUser</p>
                </div>
                <div className="imgContainer">
                    <img src={LinkUserGrad} alt="" />
                    <p>LinkUserGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={Lock} alt="" />
                    <p>Lock</p>
                </div>
                <div className="imgContainer">
                    <img src={LockColor} alt="" />
                    <p>LockColor</p>
                </div>
                <div className="imgContainer">
                    <img src={LockWhite} alt="" />
                    <p>LockWhite</p>
                </div>
                <div className="imgContainer">
                    <img src={Mail} alt="" />
                    <p>Mail</p>
                </div>
                <div className="imgContainer">
                    <img src={MailGrad} alt="" />
                    <p>MailGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={MultiUserGrad} alt="" />
                    <p>MultiUserGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={MultiUserGray} alt="" />
                    <p>MultiUserGray</p>
                </div>
                <div className="imgContainer">
                    <img src={MultiUserWhite} alt="" />
                    <p>MultiUserWhite</p>
                </div>
                <div className="imgContainer">
                    <img src={OrderGrad} alt="" />
                    <p>OrderGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={PlayGrad} alt="" />
                    <p>PlayGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={PlusGrad} alt="" />
                    <p>PlusGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={RefreshGrad} alt="" />
                    <p>RefreshGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={RightArrowGrad} alt="" />
                    <p>RightArrowGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={ScreenShareGrad} alt="" />
                    <p>ScreenShareGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={SearchGrad} alt="" />
                    <p>SearchGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={Settings} alt="" />
                    <p>Settings</p>
                </div>
                <div className="imgContainer">
                    <img src={TikGrad} alt="" />
                    <p>TikGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={Timer} alt="" />
                    <p>Timer</p>
                </div>
                <div className="imgContainer">
                    <img src={TimerGrad} alt="" />
                    <p>TimerGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={UploadGrad} alt="" />
                    <p>UploadGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={UserBoxGrad} alt="" />
                    <p>UserBoxGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={UserProfile} alt="" />
                    <p>UserProfile</p>
                </div>
                <div className="imgContainer">
                    <img src={UserProfileGrad} alt="" />
                    <p>UserProfileGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={UserSelect} alt="" />
                    <p>UserSelect</p>
                </div>
                <div className="imgContainer">
                    <img src={Video} alt="" />
                    <p>Video</p>
                </div>
                <div className="imgContainer">
                    <img src={VideoGrad} alt="" />
                    <p>VideoGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={WorldGrad} alt="" />
                    <p>WorldGrad</p>
                </div>
                <div className="imgContainer">
                    <img src={BackBlack} alt="" />
                    <p>BackBlack</p>
                </div>
                <div className="imgContainer">
                    <img src={DownloadWhite} alt="" />
                    <p>DownloadWhite</p>
                </div>
            </div>
        </div>
        <div className="sec">
            <h2>Input elements from (../src/Components/Elements)</h2>
            <div className="container">
                <div className="elementDoc">
                    <div className="element">
                    <Text 
                        type="text"
                        className="working"
                        name="test"
                        value="static or dynamic"
                        icons={{left: {src: Mail, alt: "mail icon"}}}
                        placeholder="Testing..."
                        inputClass="forInput"
                    />
                    <Text 
                        type="password"
                        className="working"
                        name="test"
                        icons={{left: {src: Lock, alt: "mail icon"}, right: {src: Eye, iconClass:"eye"}}}
                        placeholder="Testing..."
                        inputClass="forInput"
                    />
                    <Text 
                        type="text"
                        className="working"
                        name="test"
                        icons={{right: {src: UploadGrad}}}
                        placeholder="Agent photo"
                        inputClass="forInput"
                        change={(e)=>console.log(e.target.value)}
                    />
                    </div>
                    <div className="elementDisc">
                        <pre>
                    {`                    <Text
                        type="text || password" <--- optional = text by default
                        className="class for input container"
                        name="for input"
                        value="static or dynamic"
                        icons={{left: {src: Mail, alt: "mail icon"}, right: {src: Eye, iconClass:"eye"}}}
                        placeholder="for input"
                        inputClass="class for input"
                    />`}
                        </pre>
                    </div>
                </div>
                <div className="elementDoc">
                    <div className="element">
                        <Select
                            options={['option1', 'option2', 'option3']}
                            clickChange={(val) => console.log(val)}
                            arrowStyle= {{backgroundImage: `url(${DownArrowGrad})`}}
                            defaultOption="content here"
                            classNames="anything here"
                            showSearch = {true}
                        />
                    </div>
                    <div className="elementDisc">
                        <pre>
                            {`                    <Select
                            options={['option1', 'option2', 'option3']}
                            clickChange={(val) => console.log(val)}
                            arrowStyle= {arrow custom style here...}
                            defaultOption="content here"
                            classNames="anything here"
                            showSearch = {true}
                        />`}
                        </pre>
                    </div>
                </div>
                <div className="elementDoc">
                    <div className="element">
                        <RadioBtn
                            name="gender"
                            value="male"
                            change={(e)=>console.log(e.target.value)}
                            classNames="custom class here..."
                        />
                        <RadioBtn
                            name="gender"
                            value="female"
                            change={(e)=>console.log(e.target.value)}
                        />
                    </div>
                    <div className="elementDisc">
                        <pre>{`
                            <RadioBtn
                                name="gender"
                                value="male"
                                change={(e)=>console.log(e.target.value)}
                                classNames="custom class here..."
                            />
                        `}</pre>
                    </div>
                </div>
                
                <div className="elementDoc">
                    <div className="element">
                        <Button name="sample" dispName="Button name" click={(e) => console.log(e)} className={'if needed'} />
                        <Button name="sample" dispName="With plain class name" click={(e) => console.log(e)} className={'plain'} />
                    </div>
                    <div className="elementDisc">
                        <pre>
                           {`
                        <Button
                            name="sample"
                            dispName="Button name"
                            click={(e) => {alert(e)}}
                            className={'if needed'}
                        />
                           `}
                        </pre>
                    </div>
                </div>

                <div className="elementDoc">
                    <div className="element"></div>
                    <div className="elementDisc"></div>
                </div>
            </div>
        </div>
    </Template>
  )
}


const Template = styled.div`
h2 {
    background: #b7d0ff;
    padding: 10px;
}
    .bg {
        background: linear-gradient(89.7deg, #04207E 4.21%, #2D85DD 83.88%);
    }
    .sec {
        padding: 40px;
        border-bottom: 1px solid black;
        .container {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            padding: 20px 0;
            h1 {
                padding: 0 10px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .imgContainer {
                padding: 10px;
                background: #dbdbdb9e;
                text-align:  center;
                img {
                    height: 40px;
                }
            }
            .elementDoc {
                width: 100%;
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                border-bottom: 1px solid #b7d0ff;
                margin-bottom: 20px;
                .element {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    max-width: 50%;
                }
                .elementDisc {
                    width: 50%;
                }
            }
        }
    }
`
