import React from 'react'
import styled from 'styled-components'
import { SearchGrad, OrderGrad, FilterGrad, RightArrowGrad } from '../../assets/ImageUrl'
import HeaderFilters from '../../Components/HeaderFilters'
import data from '../../Data/audit.json'
import DataTable from 'react-data-table-component';

export default function Audit() {

  const response = data.audit

  const headerActions = [
    {
      img: SearchGrad,
      key:4,
      func: (e) => e.persist()
    },
    {
      img: OrderGrad,
      key:5,
      func: (e) => e.persist()
    },
    {
      img: FilterGrad,
      key:6,
      func: (e) => e.persist()
    },
  ] 

  const columns = [
    {
        name: 'Timestamp',
        selector: row => row["Timestamp"],
        sortable: true
    },
    {
        name: 'Managerial Name',
        selector: row => <span className="adminName">{row["Admin Name"]}</span>,
        sortable: true
    },
    {
        name: 'Action',
        selector: row => row["Action"],
        sortable: true
    },
    {
        name: 'Section',
        selector: row => row["Section"] ,
        sortable: true
    },
];

  return (
    <AuditStyle>
      <HeaderFilters heading={{name: 'Admin Audit Trail'}} actionBtns={headerActions} />

      <div className="tableContainer">
        <DataTable
          columns={columns}
          data={response}
          // selectableRows
          pagination
        />
      </div>
    </AuditStyle>
  )
}

const AuditStyle = styled.div`
  background: var(--white);
  box-shadow: var(--cardShadow);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  .adminName {
      font-weight: 600;
    }
    /* #pagination-last-page, #pagination-first-page {
      background-image: url(${RightArrowGrad});
      background-repeat: no-repeat;
      background-position: center;
      &:disabled {
          opacity: 0.3;
        }
      svg {
        path {
          fill: transparent;
        }
      }
    }
    #pagination-first-page {
        transform: rotate(180deg);
      } */

    /* .rdt_Table {
  display: table;         
  width: auto;         
  background-color: #eee;         
  border: 1px solid #666666;         
  border-spacing: 5px;
} */
`