import React, { useState } from "react";
import styled from "styled-components";
import {
  SearchGrad,
  SearchGray,
  OrderGrad,
  FilterGrad,
  Timer,
  MultiUserGray,
  MultiUserGrad,
  TimerGrad,
  LinkUserGrad,
  LinkUser,
  VideoGrad,
  Video,
  ClockGrad,
  Clock,
} from "../assets/ImageUrl";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/captioned.css";

export const MainTabs = ({ action, status }) => {
  // console.log(action(), "569365983659365938655");
  const landTo = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <CustomerMainTabsStyle>
      <button
        className={`queue ${
          pathname === "/dashboard/customers/queue" ? "active" : ""
        }`}
        onClick={() => {
          landTo("/dashboard/customers/queue");
          action("queue");
        }}
      >
        Customer Queue
      </button>
      <button
        className={`callHistory ${
          pathname === "/dashboard/customers/callHistory" ? "active" : ""
        }`}
        onClick={() => {
          landTo("/dashboard/customers/callHistory");
          action("callHistory");
        }}
      >
        Call History
      </button>
      <button
        className={`single-vcip-search ${
          pathname === "/dashboard/customers/single-vcip-search" ? "active" : ""
        }`}
        onClick={() => {
          landTo("/dashboard/customers/single-vcip-search");
          action("single-vcip-search");
        }}
      >
        Single VCIP Search
      </button>
    </CustomerMainTabsStyle>
  );
};

export const QueueTabs = ({ status, navClick, action }) => {
  const waitinglist = useSelector(
    (state) => state.DashboardReducer?.waitingList?.vciplistcount
  );
  const livelist = useSelector(
    (state) => state.DashboardReducer?.liveList?.vciplistcount
  );
  // const livelist  = (live === undefined) ? sessionStorage.getItem("livelist"):"";
  return (
    <SelectedTabsStyle>
      <button
        className={`${status === "waiting" ? "themeBtn" : "notSelected"}`}
        onClick={() => navClick("waiting", 1)}
      >
        <img
          src={status !== "waiting" ? LinkUserGrad : LinkUser}
          alt="waitIcon"
        />{" "}
        Waiting ({waitinglist > 0 ? waitinglist : "0"} ){" "}
      </button>
      <button
        className={`${status === "live" ? "themeBtn" : "notSelected"}`}
        onClick={() => navClick("live", 2)}
      >
        <img src={status !== "live" ? VideoGrad : Video} alt="waitIcon" /> Live
        & Scheduled ({livelist > 0 ? livelist : "0"}){" "}
      </button>
      {/* <button
        className={`${status === "scheduled" ? "themeBtn" : "notSelected"}`}
        onClick={() => navClick("scheduled", 3)}
      >
        <img src={status !== "scheduled" ? ClockGrad : Clock} alt="waitIcon" />{" "}
        Scheduled 
        {/* ( 0 ) */}{" "}
      {/* </button> */}
    </SelectedTabsStyle>
  );
};

export const CallHistoryTabs = ({ status, action }) => {
  return (
    <SelectedTabsStyle>
      <button
        className={`${status === "all" ? "themeBtn" : "notSelected"}`}
        onClick={() => action("all")}
      >
        <img src={status !== "all" ? LinkUserGrad : LinkUser} alt="waitIcon" />{" "}
        All{" "}
      </button>
      <button
        className={`${status === "direct" ? "themeBtn" : "notSelected"}`}
        onClick={() => action("direct")}
      >
        <img src={status !== "direct" ? VideoGrad : Video} alt="waitIcon" />{" "}
        Direct Live{" "}
      </button>
      {/* <button
        className={`${status === "assigned" ? "themeBtn" : "notSelected"}`}
        // onClick={() => action("assigned")}
        data-tip
        data-for="registerTip"
      >
        <img src={status !== "assigned" ? ClockGrad : Clock} alt="waitIcon" />{" "}
        Assigned{" "}
        <ReactTooltip id="registerTip" place="top" effect="solid">
          Coming Soon
        </ReactTooltip>{" "}
      </button> */}
    </SelectedTabsStyle>
  );
};

export const WebLinkCopy = (props) => {
  const [copied, setCopied] = useState("Copy");

  return (
    <WebLinkStyle>
      <h2 className="blue">{props?.vciplink}</h2>
      <div className="btnContainer">
        <button
          className="themeBtn"
          onClick={() => {
            setCopied("Copied");
            navigator.clipboard.writeText(`${props?.vciplink}`);
          }}
        >
          {copied}
        </button>
      </div>
    </WebLinkStyle>
  );
};

export const CarouselElement = (props) => {
  return (
    <AwesomeTabsStyle>
      <AwesomeSlider
        style={{ "--content-background-color": "white" }}
        organicArrows={true}
        infinite={false}
        bullets={false}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={6000}
        className="awesomslider"
      >
        {props?.videotag?.length > 0
          ? props?.videotag.map((item, i) => {
              return (
                <div className="">
                  <video
                    width="100%"
                    height="auto"
                    controls
                    style={{ borderRadius: "2%" }}
                    className=""
                    src={item}
                    type="video/mp4"
                  />
                  {/* <source  type="video/mp4" />
                </video> */}
                </div>
              );
            })
          : ""}
        {/* </div> */}
      </AwesomeSlider>
    </AwesomeTabsStyle>
  );
};

const CustomerMainTabsStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  border-bottom: 1px solid var(--border-color);
  button {
    color: var(--dark5);
    padding-left: 40px;
    padding-bottom: 14px;
    padding-right: 10px;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    background: transparent;
    border: none;
    &.active {
      background: linear-gradient(180deg, #38568f 0%, #38a1f7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      &:after {
        content: "";
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, #38568f 0.92%, #38a1f7 99.08%);
        position: absolute;
        bottom: -1px;
        left: 0;
        border-radius: 2px;
      }
    }
    &.queue,
    &.callHistory,
    &.single-vcip-search {
      &:before {
        content: "";
        background-repeat: no-repeat;
        height: 20px;
        width: 22px;
        position: absolute;
        top: 2px;
        left: 10px;
      }
    }
    &.queue {
      &:before {
        background-image: url(${MultiUserGray});
      }
      &.active {
        &:before {
          background-image: url(${MultiUserGrad});
        }
      }
    }
    &.callHistory {
      &:before {
        background-image: url(${Timer});
      }
      &.active {
        &:before {
          background-image: url(${TimerGrad});
        }
      }
    }
    &.single-vcip-search {
      &:before {
        background-image: url(${SearchGray});
      }
      &.active {
        &:before {
          background-image: url(${SearchGrad});
        }
      }
    }
  }
`;
const AwesomeSliderStyles = {
  backgroundColor: "white",
};

const SelectedTabsStyle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .themeBtn {
  }
  button {
    padding: 9px 12px;
    font-weight: 600;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    &.notSelected {
      background: var(--white);
      border: 1px solid #38568f;
      background: linear-gradient(180deg, #38568f 0%, #38a1f7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &.themeBtn {
      background: linear-gradient(86.6deg, #38568f 2.29%, #38a1f7 99.09%);
    }
  }
`;

const AwesomeTabsStyle = styled.div`
  .awesomslider {
    background-color: white !important;
  }
`;

const WebLinkStyle = styled.div`
  .blue {
    color: #38a1f7;
    padding-bottom: 20px;
    font-size: 18px;
  }
`;
const buttonStyle = {
  padding: "15px",
  borderRadius: "50%",
  // background: "red",
  opacity: 0.7,
  fontSize: "30px",
  color: " #38a1f7",
};
const contentStyle = {
  // color: "black",
  fontSize: "20px",
};
const bgImg = {
  position: "absolute",
  zIndex: -1,
  left: 0,
  top: 0,
  width: "100%",
};
