import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { CalanderGrad } from '../../assets/ImageUrl'
import { Button } from '../../Components/Elements'
import HeaderFilters from '../../Components/HeaderFilters'
import NoRecordFound from '../../Components/NoRecordFound'
import RangeDate from '../../Components/RangeDate'
import { getServiceTxnCount } from '../../Store/SagasActions/DashboardOverviewSagaAction'
import { addDays, subDays, format } from "date-fns";
import uuid from "react-uuid";
import Popup from '../../Components/Popup'
import moment from 'moment/moment'
import { useLocation } from 'react-router-dom'


let customDateRange;
const Transactions = () => {
  let placeIt = "";
  const dispatch = useDispatch()
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const [txndata, setTxnData] = useState([])
  const [showDate, setShowDate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [showReport, setShowReport] = useState('')
  const location = useLocation()
  const pathname = location.pathname

  //  Reducer Calls
  const accid = useSelector((state) => state.DashboardReducer?.accountaccid?.accounts);



  // Table
  const columns = [
    {
      name: "Date",
      selector: (row) => row["date"],
      format: (row) => moment(row.date).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row["account_name"],
      sortable: true,
    },
    {
      name: "Txn Code",
      selector: (row) => (
        <div style={{ color: `${row["error_code"]}` > 200 ? "red" : "" }}>
          {row["error_code"]}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Txn Description",
      selector: (row) => row["error_message"],
      sortable: true,
    },
    {
      name: "Service Name",
      selector: (row) => row["service_name"],
      sortable: true,
    },
    {
      name: "Txn Count",
      selector: (row) => row["txn_count"],
      sortable: true,
    }
  ];


  // Handle Accounts

  const handleDate = (range) => {
    placeIt = `${format(range.selection.startDate, "dd/mM/yyyy")} - ${format(range.selection.endDate,
      "dd/mM/yyyy"
    )}`;
    if (pathname) {
      setDateRange([range.selection])
    }
  };



  const applyDateRange = () => {
    const placeOn = document.getElementById("selectedDateRange");
    placeOn.value = moment(dateRange[0].startDate).format("DD-MM-YYYY")
    setShowDate(false);
  };


  const RangeDatePicker = () => (
    <RangeDatePickerStyle>
      <RangeDate changes={handleDate} dateRange={dateRange} />
      <div className="buttonContainer">
        <button className="themeBtn" onClick={() => applyDateRange()}>
          Apply
        </button>
      </div>
    </RangeDatePickerStyle>
  );


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "accid") {
      setShowReport(value)
    }
  };

  const SubmitReport = () => {
    let tdate, fdate;
    tdate = moment(dateRange[0].startDate).format("YYYY-MM-DD");
    fdate = moment(dateRange[0].endDate).format("YYYY-MM-DD")
    const model = {
      accid: showReport,
      tdate: fdate,
      fdate: fdate,

    };
    dispatch(getServiceTxnCount({ model: model, callback: getTxnCount }))

  }
  const getTxnCount = (data) => {
    if (data.respcode === "200") {
      setTxnData(data.data)
    } else if (data.respcode === "407") {
      console.log("No data found")
    }
  }

  useEffect(() => {
    setTxnData([])
  }, [showReport])


  return (
    <>
      <ReportStyle>
        <HeaderFilters
          heading={{ name: "Transaction History" }}
        // actionBtns={headerActions}
        // change={(e) => searchReport(e.target.value)}
        // spin={spin}
        />
        <div className='actions'>
          <div className="elementContainer">
            <select className="custom-select" name="accid" onChange={handleChange}>
              <option value={""} disabled selected >Select Account</option>
              {/* {accAdmin == "Super Admin" && <option value="0">All accounts</option>} */}
              {accid?.length > 0 &&
                accid?.map((item) => (
                  <option value={item.accid}>{item.accountname}</option>
                ))}
            </select>
          </div>
          <div className="elementContainer">
            <div className="rangeDatePic" onClick={() => setShowDate(true)}>
              <input
                type="text"
                disabled
                id="selectedDateRange"
                placeholder="Select Date Here..."
              // onChange={handleChange}
              />
            </div>
          </div>
          <div className="btnContainer">
            <Button
              name="sample"
              dispName="Submit Request"
              click={SubmitReport}
            // isLoading={isLoading}
            // disabled={isdisabled}
            // className={isdisabled ? "loaderdisabled" : ""}
            />
          </div>
        </div>

        <TransactionStyle>
          {txndata?.length > 0 ? (
            <DataTable
              columns={columns}
              data={txndata}
              pagination
            />
          ) : (
            <div className="norecord">
              <NoRecordFound />
            </div>
          )}
        </TransactionStyle>
      </ReportStyle>
      {
        showDate && (
          <Popup
            className="px680 dateRangePicker"
            heading="Select a date"
            body={<RangeDatePicker />}
            close={() => setShowDate(false)}
          />
        )
      }
    </>
  )
}

export default Transactions



const ReportStyle = styled.div`
  background: var(--white);
  box-shadow: var(--cardShadow);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  .actions {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 0 16px 0;
    .rs-picker-default .rs-picker-toggle.rs-btn {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;
    }
    .btnContainer {
      display: flex;
      gap: 16px;
      button {
        width: auto;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 32px;
      }
      .loaderdisabled {
        opacity: 0.5 !important;
      }
    }
  }
  .custom-select,
  input[type="date"] {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
    padding: 10px 30px 10px 16px
  }
  .rangeDatePic {
    position: relative;
    &:after {
      content: "";
      background-image: url(${CalanderGrad});
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
    input[type="text"] {
      color: var(--dark6);
      border: 1px solid var(--border-color2);
      border-radius: 5px;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 30px 10px 16px;
      min-width: 100%;
      &:disabled {
        background: transparent;
      }
    }
  }
`;

const TransactionStyle = styled.div`
  .norecord {
    height: calc(100vh - 300px);
  }
  .rdt_TableBody {
    max-height: calc(100vh - 388px);
    overflow: auto;
  }
  .status {
    font-weight: 600;
    display: block;
    padding: 4px;
    min-width: 72px;
    text-align: center;
    &.green {
      background: #08c1521a;
      color: #08c152;
      border-radius: 3px;
    }
    &.orange {
      background: #f9c7171a;
      color: #f9c717;
      border-radius: 3px;
    }
    &.red {
      background: #f247471a;
      color: #f24747;
      border-radius: 3px;
    }
  }
`;

const RangeDatePickerStyle = styled.div`
  .buttonContainer {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    button {
      padding: 9px 14px;
      font-size: 18px;
      width: 140px;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 12px 0;
    button {
      padding: 10px 14px;
      font-size: 16px;
      border: none;
    }
  }
`;
