import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Text, Button } from "../Components/Elements";
import { Mail, LockColor } from "../assets/ImageUrl";
import { useDispatch } from "react-redux";
import { resetSagaAction } from "../Store/SagasActions/AuthSagaActions";
import { validateEmail, validateIsRequired } from "../utils/Validations";

export default function ResetPassword({ pop }) {
  let loginNames = {
    username: "",
    password: "",
  };

  let rolelist = [
    {
      rid: "1",
      rolename: "Super Admin"
    },
    {
      rid: "6",
      rolename: "Quality Check"
    },
    {
      rid: "2",
      rolename: "Account Admin"
    }
  ]


  const [resetObj, setResetObj] = useState("");
  const [roleObj, setRoleObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState(rolelist);
  const [resetObjErr, setResetObjErr] = useState("");

  // Env variables
  const clientName = process.env.REACT_APP_CLIENT_ACCOUNT;
  const Protean = process.env.REACT_APP_PROTEAN
  const NIC = process.env.REACT_APP_NIC_CLIENT

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (value && (name === "email")) {
  //     const err = validateEmail(value);
  //     setResetObjErr(err)
  //   } else {
  //     const isEmpty = validateIsRequired(value);
  //     setResetObjErr(isEmpty);
  //   }
  //   setResetObj(value);
  // };
  useEffect(() => {
    let updatedRoles = rolelist;
    if (clientName === "1") {
      updatedRoles = rolelist.splice(0, 2);
    } else if (Protean) {
      updatedRoles = rolelist.splice(0, 1)
    } else if (NIC) {
      updatedRoles = rolelist.filter(res => res.rid === "6");
      const roleObjData = {
        rid: updatedRoles[0].rid,
        rolename: updatedRoles[0].rolename,
      };
      setRoleObj(roleObjData);
    }
    setRoles(updatedRoles);
    // dispatch(actionReqAdminAccounts({ callback: respGetAccounts }));
    // dispatch(getRolesSagaAction({ callback: getRolesData }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rolename") {
      const role = value.split("-");
      const roleObjData = {
        rid: role[0],
        rolename: role[1],
      };
      setRoleObj(roleObjData);
    } else {
      if (value && (name === "email")) {
        const err = validateEmail(value);
        setResetObjErr(err);
      } else {
        const isEmpty = validateIsRequired(value);
        setResetObjErr(isEmpty);
      }
      setResetObj(value);
    }
  }

  const resetBtn = (e) => {
    e.preventDefault();
    if (resetObj && roleObj && roleObj.rid) {
      const model = {
        username: resetObj,
        rid: roleObj.rid,
      };
      setIsLoading(true);
      dispatch(resetSagaAction({ model, callback: resetRespData }));
    } else {
      toast.error("Please check all inputs");
    }
    // if (resetObj && !resetObjErr) {
    //   const model = {
    //     username: resetObj,
    //     rid: "3",
    //   };
    //   dispatch(resetSagaAction({ model: model, callback: resetRespData }));
    // } else {
    //   toast.error("Please check all inputs");
    // }
  };

  const resetRespData = (data) => {
    setIsLoading(false);
    navigate("/");
    // navigate(RouteNames.LOGIN);
    // toast.success('Successfully sent Reset link to the User email')
  };

  return (
    <ResetStyle className="form">
      {/* {console.log(resetObj, "resetObj")} */}
      <h1 className="fd1">Reset Password</h1>
      <h2 className="fd2">Please enter your email address</h2>
      <form action="" onSubmit={resetBtn}>
        <div>
          <Text
            type="text"
            name="email"
            icons={{ left: { src: Mail, alt: "mail icon" } }}
            placeholder="Email Address"
            change={handleChange}
          />
          <p className='error'>{resetObjErr}</p>
        </div>

        <div className="">
          <select
            name="rolename"
            onChange={handleChange}
            value={`${roleObj?.rid}-${roleObj?.rolename}`}
            className="cus-select"
            required
          >
            <option value="">Select Role</option>
            {roles && roles?.length > 0
              ? roles.map((item, i) => (
                <option key={i} value={`${item?.rid}-${item?.rolename}`}>
                  {item?.rolename}
                </option>
              ))
              : null}
          </select>
        </div>
        <Button
          name="login_Btn"
          className="loginBtn"
          dispName="Submit"
          click={resetBtn}
          disabled={resetObjErr || !resetObj}

        // click={(e)=>{pop("mailSent")}}
        />
        <Link to="/">
          <Button
            name="login_Btn"
            className="plain"
            dispName="Login"
            click={(e) => console.log(e)}
          />
        </Link>
      </form>
    </ResetStyle>
  );
}

const ResetStyle = styled.div`
  width: 70%;
  & > .fd1:after {
    background: url(${LockColor});
  }
  .error{
    position: absolute;
    color: red;
    font-size: 12px;
  }
  .cus-select {
    display: flex;
    background: bottom;
    width: 100%;
    font-size:16px;
    padding: 14px;
    border: var(--border);
    border-radius: 5px;
    outline: none;
    text-indent: 1px;
    text-overflow: '';
    -webkit-box-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;
