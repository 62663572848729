import {
  ACTION_POST_SUBMIT_REPORT_MASTER_REQ,
  ACTION_POST_CHECK_REPORT_STATUS_REQ,
  ACTION_GET_REPORT_LIST_REQ,
} from "./SagaActionTypes";

export const getSubmitReportSagaAction = (payload) => {
  return {
    type: ACTION_POST_SUBMIT_REPORT_MASTER_REQ,
    payload: payload,
  };
};
export const getCheckReportSagaAction = (payload) => {
  return {
    type: ACTION_POST_CHECK_REPORT_STATUS_REQ,
    payload: payload,
  };
};
export const getReportListSagaAction = (payload) => {
  return {
    type: ACTION_GET_REPORT_LIST_REQ,
    payload: payload,
  };
};
