const vcipIdStatus = {
    0: "New V-CIP",
    1: "In-Progress",
    2: "Agent Approved",
    3: "Agent Rejected",
    4: "Auditor Approved (Completed)",
    5: "Auditor Rejected"
}

const VcipIdStatusOptions = Object.freeze(vcipIdStatus);

export default VcipIdStatusOptions;
