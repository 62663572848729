import React from 'react'
import styled from 'styled-components'

export default function CardNode({ img, name, id, flogin, lastlogout }) {
    return (
        <CardStyle>
            <div className="imgContainer">
                <img src={img} alt={name && name + ' pic'} />
            </div>
            <div className="contentContainer">
                <h3 className="fd4">{name}</h3>
                <p>{id}</p>
                <p style={{ "fontSize": "12px" , "fontWeight": "600"}}>Login Time: <span className="fg2 cus-time">{flogin}</span></p>
                <p style={{ "fontSize": "12px" , "fontWeight": "600"}}>Logout Time: <span className="fg2 cus-time">{lastlogout}</span></p>
            </div>
        </CardStyle>
    )
}

const CardStyle = styled.div`
.cus-time{
    font-size: 10px;
}
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    .imgContainer {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    .contentContainer {
        width: calc(100% - 66px);
        h3 {
            color: var(--dark2);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            font-size: 18px;
            color: var(--dark5)
        }
    }
`;