import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import HeaderFilters from "../../Components/HeaderFilters";
import { ApplyLeaveForm, DeleteConfirm } from "../../Components/AgentSnippets";
import { BackBlack } from "../../assets/ImageUrl";
import ProfileCard from "../../Components/ProfileCard";
import ActivityList from "../../Components/ActivityList";
import data from "../../Data/agent.json";
import { EditGrad, DeleteGrad } from "../../assets/ImageUrl";
import Popup from "../../Components/Popup";
import { actionReqGetDeleteAgent } from "../../Store/SagasActions/DashboardOverviewSagaAction";
import { actionReqGetUpdateAgent } from "../../Store/SagasActions/AgentSagaAction";

export default function ApplyLeave() {
  const location = useLocation();
  const agentList = location.state.name;

  const [act, setAct] = useState("");
  const [isEditable, setIsEditable] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  const cardData = data.agents[0].additionalInfo.card;
  const activityData = data.agents[0].additionalInfo.dailyActivity;

  const naviage = useNavigate();
  const dispatch = useDispatch();

  const HeadWithBackButton = () => (
    <>
      <img
        src={BackBlack}
        style={{ marginRight: "14px", cursor: "pointer" }}
        alt="back to agent page"
        onClick={() => naviage("/dashboard/agent")}
      />{" "}
      Agent view
      {/* Agent list view */}
    </>
  );

  const headerLeaveActions = [
    {
      img: EditGrad,
      key: 8,
      func: (e) => editAgentUser(),
    },
    {
      img: DeleteGrad,
      key: 9,
      func: () => setAct("delete"),
    },
  ];

  const editAgentUser = () => {
    setIsEditable((prev) => !prev);
  };

  const deleteComp = {
    disc: "Are you sure you want to delete the Agent?",
    cancel: () => setAct(""),
    delete: (remarks) => deleteAgentUser(remarks),
  };

  const deleteAgentUser = (remarks) => {
    if (remarks) {
      const model = {
        agent_userid: agentList.agent_userid,
        remarks: remarks,
      };
      dispatch(
        actionReqGetDeleteAgent({ model: model, callback: getDeleteRes })
      );
    } else {
      toast.error("Please enter remarks");
    }
  };

  const getDeleteRes = (data) => {
    if (data && data?.respcode === "200") {
      naviage("/dashboard/agent");
    }
  };

  const updateAgentDetails = (data) => {
    setIsLoading(true);
    setIsDisabled(true);
    // const model = {
    //   userid: data
    // }
    // console.log(data, "data");
    let model;
    if (selectedImg) {
      model = { ...data, agent_pht: selectedImg };
    } else {
      model = data;
    }
    dispatch(
      actionReqGetUpdateAgent({ model: model, callback: getUpdatedAgentRes })
    );
  };

  const getUpdatedAgentRes = (data) => {
    if (data && data?.respcode === "200") {
      naviage("/dashboard/agent");
    } else {
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  const updatedProfilePic = (val) => {
    setSelectedImg(val);
  };

  return (
    <>
      <ApplyLeaveStyle>
        <HeaderFilters
          heading={{ name: <HeadWithBackButton /> }}
          actionBtns={headerLeaveActions}
        />
        <div className="secContainer">
          <div className="userCard">
            <ProfileCard
              data={agentList}
              form={data?.applyLeaveForm}
              isEditable={isEditable}
              updateAgentDetails={updateAgentDetails}
              updatedProfilePic={updatedProfilePic}
            />
          </div>
          <div className="formContainer">
            <ApplyLeaveForm
              form={data?.applyLeaveForm}
              isEditable={isEditable}
              updateAgentDetails={updateAgentDetails}
              isLoading={isLoading}
              disabled={isdisabled}
            />
          </div>
          <div className="activities">
            <ActivityList
              data={activityData}
              userid={agentList?.agent_userid}
            />
          </div>
        </div>
      </ApplyLeaveStyle>

      {act === "delete" && (
        <Popup
          heading="Delete Agent"
          body={<DeleteConfirm comp={deleteComp} />}
          close={() => setAct("")}
          className="px500"
        />
      )}
    </>
  );
}

const ApplyLeaveStyle = styled.div`
  box-shadow: var(--cardShadow);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  background: var(--white);
  .secContainer {
    display: flex;
    gap: 16px;
    .userCard {
      flex: 1;
    }
    .formContainer {
      flex: 2;
    }
    .activities {
      flex: 1;
    }
  }
`;
