import React, { useState } from "react";
import styled from "styled-components";
import { Text, RadioBtn, Button } from "../Components/Elements";
import {
  SearchGrad,
  OrderGrad,
  FilterGrad,
  UploadGrad,
  PlusGrad,
  CalanderGrad,
  DownArrowGrad,
  MarkTick,
} from "../assets/ImageUrl";
import {
  validateEmail,
  validateIsNumeric,
  validateIsRequired,
} from "../utils/Validations";
import { actionReqGetCreateAgent } from "../Store/SagasActions/DashboardOverviewSagaAction";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import { Country, State, City } from "country-state-city";

export const headerActions = [
  {
    img: SearchGrad,
    key: 4,
    // func: (e) => console.log(e),
  },
  // {
  //   img: OrderGrad,
  //   key: 5,
  //   func: (e) => console.log(e),
  // },
  {
    img: FilterGrad,
    key: 6,
    // func: (e) => console.log(e),
  },
];

// const sortIN = () => {
//   getAgentList.sort((a, b) => {
//     const nameA = a.agent_name.toUpperCase();
//     const nameB = b.agent_name.toUpperCase();
//     console.log(nameA, "aaaaaa->")
//     console.log(nameB, "bbbbbb->")
//     if (nameA < nameB) {
//       return -1;
//     }
//     if (nameA > nameB) {
//       return 1;
//     }
//     return 0;
//   });
// }

export const AddAgent = ({ accountList, func, isLoading, disabled }) => {
  const dispatch = useDispatch();
  let agentObj = {
    agent_accid: "",
    agent_employeeid: "",
    agent_pht: "",
    manager_id: "",
    manager_name: "",
    manager_email: "",
    agent_name: "",
    agent_mobile: "",
    agent_username: "",
    trainingcompleteby: "",
    istrained: "",
    branchid: "",
    location: "",
    region: "",
    knownlanguages: [],
    // languages :
    // knownlanguages: "",
  };

  const takelang = useSelector((state) => state?.DashboardReducer.getlanguage);

  // const [lang, setLang] = useState()
  const [agentModel, setAgentModel] = useState(agentObj);
  const [agentModelErrs, setAgentModelErrs] = useState(agentObj);
  const [trainingdate, setTrainingDate] = useState(false);
  const [Values, setValues] = useState([]);
  const [Cities, setCities] = useState([]);
  const [stateCode, setStateCode] = useState("");
  const [ImgfileName, setImgfileName] = useState("");

  const countryCode = "IN";

  function epoch(date) {
    return Date.parse(date);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const datetime = moment().format("YYYY-MM-DD");
    const timestamp = epoch(datetime);
    if (value && (name === "manager_email" || name === "agent_username")) {
      const err = validateEmail(value);
      setAgentModelErrs((prevState) => ({ ...prevState, [name]: err }));
    } else if (value && name === "agent_mobile") {
      if (value.length <= 10) {
        const isNumber = validateIsNumeric(value);
        setAgentModelErrs((prevState) => ({ ...prevState, [name]: isNumber }));
        setAgentModel((prevState) => ({ ...prevState, [name]: value }));
      }
      return;
    } else if (value && name === "trainingcompleteby") {
      if (epoch(value) > timestamp) {
        const isError = "Future date should not be Selected";
        setAgentModel((prevState) => ({ ...prevState, [name]: "" }));
        setTrainingDate(true);
        setAgentModelErrs((prevState) => ({ ...prevState, [name]: isError }));
      } else {
        const isError = "";
        setTrainingDate(false);
        setAgentModelErrs((prevState) => ({ ...prevState, [name]: isError }));
      }
    } else if (name === "region") {
      let modValue = JSON.parse(value);
      let selectedCities = City.getCitiesOfState(
        countryCode,
        modValue?.isoCode
      );
      setCities(selectedCities);
      setAgentModel((prevS) => ({ ...prevS, region: modValue?.name }));
      setAgentModel((prevState) => ({ ...prevState, [name]: modValue?.name }));
      return;
    } else {
      const isEmpty = validateIsRequired(value);
      setAgentModelErrs((prevState) => ({ ...prevState, [name]: isEmpty }));
    }
    setAgentModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const fileHandleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const ImgName = event.target.files[0].name;
    setImgfileName(ImgName);
    if (event.target.files) {
      const val = event.target.files.length;
      for (let i = 0; i < val; i++) {
        let reader = new FileReader();
        reader.onload = function (ev) {
          setAgentModel((prevState) => ({
            ...prevState,
            [name]: ev.target.result.split(",")[1],
          }));
          // this.setState({
          //   imgInpStatus: false,
          //   captureStatus: false,
          //   pdfFileStatus: true,
          //   imgPath: ev.target.result.split(',')[1]
          // })
        }.bind(this);
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  };

  const selectLanguage = (val) => {
    let list = [...agentModel?.knownlanguages];
    let filterId = list?.length > 0 ? list?.find((item) => item === val) : "";
    if (val === filterId) {
      list = list.filter((item) => item !== val);
    } else {
      list.push(val);
    }
    setAgentModel((prevState) => ({ ...prevState, knownlanguages: list }));
  };

  const next = (stepName) => {
    let isTrue = Object.values(agentModel).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(agentModelErrs).every(
      (v) => v === "" || v === undefined || v === null || v.length <= 0
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please check all inputs");
    } else {
      let knownlanguages = agentModel.knownlanguages.join(",");
      func(stepName, { ...agentModel, knownlanguages });
    }
    // dispatch(actionReqGetCreateAgent(agentModel));
  };

  const langIdShow = (langId) => {
    if (langId && agentModel?.knownlanguages?.length > 0) {
      let id = agentModel?.knownlanguages?.find((item) => item === langId);
      return id;
    } else {
      return "";
    }
  };

  useEffect(() => {
    let Ind = State.getStatesOfCountry(countryCode);
    setValues(Ind);
  }, []);

  // useEffect(() => {
  //   if (countries) {
  //     countries?.map((item, i) => {
  //       let countryName = item?.name?.toLowerCase();
  //       if (countryName == "india") {
  //       }
  //     })
  //   }
  // }, [countries])

  // useEffect(() => {
  //  let City = City.getCitiesOfState("BR")
  // }, [])

  return (
    <AddAgentStyle>
      <div className="elementList">
        <div className="elementContainer">
          <select name="agent_accid" onChange={handleChange}>
            <option value={""}>Select Account *</option>
            {accountList?.length > 0 &&
              accountList?.map((item) => (
                <option value={item.accid}>{item.accountname}</option>
              ))}
          </select>
        </div>
        <div className="elementContainer" style={{ position: "relative" }}>
          {agentModel.agent_pht ? (
            <img
              src={`data:image/png;base64,${agentModel.agent_pht}`}
              width="30px"
              height="30px"
              style={{
                right: "0",
                top: "47%",
                transform: "translate(-50%, -50%)",
                position: "absolute",
                borderRadius: "50%",
              }}
            />
          ) : (
            " "
          )}

          <Text
            type="file"
            name="agent_pht"
            icons={
              agentModel.agent_pht.length > 0
                ? " "
                : {
                    right: { src: UploadGrad },
                  }
            }
            placeholder={agentModel.agent_pht ? ImgfileName : "Agent photo *"}
            change={fileHandleChange}
          />
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="agent_employeeid"
            placeholder="Employee ID *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.agent_employeeid}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="agent_name"
            placeholder="Agent Name *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.agent_name}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="agent_mobile"
            // icons={{ right: { src: PlusGrad } }}
            placeholder="Mobile No. *"
            change={handleChange}
            value={agentModel?.agent_mobile}
          />
          <p className="error">{agentModelErrs.agent_mobile}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="agent_username"
            // icons={{ right: { src: PlusGrad } }}
            placeholder="Email ID *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.agent_username}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="manager_id"
            placeholder="Agent Manager ID *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.manager_id}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="manager_name"
            placeholder="Agent Manager Name *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.manager_name}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="manager_email"
            placeholder="Agent Manager Email ID *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.manager_email}</p>
        </div>

        <div className="elementContainer">
          <Text
            type="text"
            name="branchid"
            placeholder="Branch ID *"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.branchid}</p>
        </div>
        <div className="elementContainer">
          {/* <Text
            type="text"
            name="location"
            placeholder="City *"
            change={handleChange}
          /> */}
          <select name="region" onChange={handleChange}>
            <option value={""}>Select State *</option>
            {Values?.length > 0 &&
              Values?.map((item, index) => (
                <option key={index} value={JSON.stringify(item)}>
                  {item.name}
                </option>
              ))}
          </select>
          <p className="error">{agentModelErrs.location}</p>
        </div>
        <div className="elementContainer">
          {/* <Text
            type="text"
            name="region"
            placeholder="State *"
            change={handleChange}
          /> */}
          <select name="location" onChange={handleChange}>
            <option value={""}>Select City *</option>
            {Cities?.length > 0 &&
              Cities?.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
            {/* <option value={}>{}</option> */}
          </select>
          <p className="error">{agentModelErrs.region}</p>
        </div>
        {/* <div className="elementContainer">
          <select name="" onChange={handleChange}>
            <option value={""}>Select State *</option>
            {States?.length > 0 &&
              States?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
          </select>
          <p className="error">{agentModelErrs.region}</p>
        </div> */}
        <div className="dateelementContainer">
          {/* <Text
            type="text"
            name="trainingcompleteby"
            icons={{ right: { src: CalanderGrad } }}
            placeholder="Training completion date"
            change={handleChange}
          /> */}
          <label htmlFor="leavesFrom">Training Completion Date *</label>
          <input
            type="date"
            placeholder="Select Date Here..."
            className=""
            name="trainingcompleteby"
            max={moment().format("YYYY-MM-DD")}
            onChange={handleChange}
          />
          <p className="error">{agentModelErrs.trainingcompleteby}</p>
        </div>
      </div>
      {/* <div className="elementContainer">
        <Text
          type="text"
          name="agent_accid"
          placeholder="Account ID"
          change={handleChange}
        />
        <p className="error">{agentModelErrs.agent_accid}</p>
      </div> */}

      <div className="selectTabContainer">
        <label>Agent Language Support *</label>
        <ul>
          {takelang?.map((item, i) => {
            return (
              <li
                key={i}
                onClick={() => selectLanguage(item.langid)}
                className={
                  langIdShow(item.langid) === item.langid ? `active` : ""
                }
              >
                {item.lang}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="finalVerification">
        <p>Agent VCIP Trained *</p>
        <RadioBtn
          name="istrained"
          value="1"
          // change={(e) => console.log(e.target.value)}
          change={handleChange}
        />
        <h6>Yes</h6>
        <RadioBtn
          name="istrained"
          value="0"
          // change={(e) => console.log(e.target.value)}
          change={handleChange}
        />
        <h6>No</h6>
      </div>

      <div className="btnContainer">
        <Button
          name="next"
          dispName="Add Agent"
          click={(e) => next("additionalInfo")}
          isLoading={isLoading}
          disabled={trainingdate}
          className={disabled ? "loaderdisabled" : ""}
        />
      </div>
    </AddAgentStyle>
  );
};

export const AddAditionalInfo = ({ func, goBack }) => {
  let agentObj2 = {
    branchid: "",
    location: "",
    region: "",
    knownlanguages: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [agentModel, setAgentModel] = useState(agentObj2);
  const [agentModelErrs, setAgentModelErrs] = useState(agentObj2);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isEmpty = validateIsRequired(value);
    setAgentModelErrs((prevState) => ({ ...prevState, [name]: isEmpty }));
    setAgentModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const submit = (stepName) => {
    let isTrue = Object.values(agentModel).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(agentModelErrs).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please check all inputs");
    } else {
      func(stepName, agentModel);
    }
  };

  return (
    <AddAgentStyle>
      <div className="elementList">
        {/* <div className="elementContainer">
        <Select
          options={['option1', 'option2', 'option3']}
          clickChange={(val) => console.log(val)}
          arrowStyle={{ backgroundImage: `url(${DownArrowGrad})` }}
          defaultOption="Agent Call Type *"
          showSearch={false}
        />
      </div>
      <div className="elementContainer">
        <Select
          options={['option1', 'option2', 'option3']}
          clickChange={(val) => console.log(val)}
          arrowStyle={{ backgroundImage: `url(${DownArrowGrad})` }}
          defaultOption="Agent Customer Type *"
          showSearch={false}
        />
      </div>
      <div className="elementContainer">
        <Select
          options={['option1', 'option2', 'option3']}
          clickChange={(val) => console.log(val)}
          arrowStyle={{ backgroundImage: `url(${DownArrowGrad})` }}
          defaultOption="Agent Schedule Type *"
          showSearch={false}
        />
      </div> */}
        <div className="elementContainer">
          <Text
            type="text"
            name="branchid"
            placeholder="Branch ID"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.branchid}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="location"
            placeholder="Location"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.location}</p>
        </div>
        <div className="elementContainer">
          <Text
            type="text"
            name="region"
            placeholder="Region"
            change={handleChange}
          />
          <p className="error">{agentModelErrs.region}</p>
        </div>
      </div>
      <div className="btnContainer">
        {/* <Button
          name="back"
          dispName="Back"
          className={'plain'}
          click={(e) => goBack("addAgent")}
        /> */}

        <Button
          name="next"
          dispName="Next"
          // click={createAgent}
          click={(e) => submit("")}
          // disabled ={isdisabled}
          // click={(e) => func('additionalInfo')}
        />
      </div>
    </AddAgentStyle>
  );
};

export const ApplyLeaveForm = ({
  form,
  isEditable,
  updateAgentDetails,
  isLoading,
  disabled,
}) => {
  const applyLeaveFormData = form;
  const location = useLocation();
  const agentList = location.state.name;
  const countryCode = "IN";

  const [agentModel, setAgentModel] = useState({});
  const [agentModelErrs, setAgentModelErrs] = useState({});
  const [formData, setFormData] = useState(applyLeaveFormData);
  const [Values, setValues] = useState([]);
  const [Cities, setCities] = useState([]);

  const selectlang = (val) => {
    let langUpdated = [];
    const dropLang = val;
    dropLang.forEach((item) => {
      langUpdated.push(item.value);
    });
    const newLangs = langUpdated.join(",");
    setAgentModel((prevState) => ({ ...prevState, knownlanguages: newLangs }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value && (name === "manager_email" || name === "agent_username")) {
      const err = validateEmail(value);
      setAgentModelErrs((prevState) => ({ ...prevState, [name]: err }));
    } else if (value && name === "agent_mobile") {
      const isNumber = validateIsNumeric(value);
      setAgentModelErrs((prevState) => ({ ...prevState, [name]: isNumber }));
    } else if (name === "region") {
      let modValue = JSON.parse(value);
      let selectedCities = City.getCitiesOfState(
        countryCode,
        modValue?.isoCode
      );
      setCities(selectedCities);
      setAgentModel((prevState) => ({ ...prevState, [name]: modValue?.name }));
      return;
    } else {
      const isEmpty = validateIsRequired(value);
      setAgentModelErrs((prevState) => ({ ...prevState, [name]: isEmpty }));
    }
    setAgentModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitUpdatedAgent = (e) => {
    e.preventDefault();
    let isTrue = Object.values(agentModel).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(agentModelErrs).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please check all inputs");
    } else {
      // func(stepName, agentModel);
      const finalData = { ...agentList, ...agentModel };
      updateAgentDetails(finalData);
    }
  };

  let langListData = [];
  let langOptionsList = agentList?.languages.map((item) => ({
    value: item.langid,
    label: item.lang,
  }));
  agentList?.languages?.forEach((item) => {
    if (item?.isknown === "1") {
      const obj = { value: item.langid, label: item.lang };
      langListData.push(obj);
    }
  });

  useEffect(() => {
    let Ind = State.getStatesOfCountry(countryCode);
    setValues(Ind);
  }, []);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #adb3bc",
      boxShadow: "none",
      borderRadius: "6px",
      opacity: 0.7,
    }),
    input: (styles) => ({
      ...styles,
      padding: "14px",
    }),
  };

  return (
    <ApplyLeaveFormStyle>
      <h4>General Information</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="formContainer">
          <div className="elementContainer">
            <label htmlFor="agentManagerId">Agent Manager ID</label>
            <input
              type="text"
              id="agentManagerId"
              className={disabled ? "" : "EditlabelDis"}
              value={agentList.manager_id}
              disabled
              onChange={(e) =>
                setFormData({ ...formData, "Agent Manager ID": e.target.value })
              }
            />
          </div>
          <div className="elementContainer">
            <label htmlFor="employeeID">Employee ID</label>
            <input
              type="text"
              id="employeeID"
              className={disabled ? "" : "EditlabelDis"}
              value={agentList.agent_employeeid}
              disabled
              onChange={(e) =>
                setFormData({ ...formData, "Employee ID": e.target.value })
              }
            />
          </div>
          <div className="elementContainer">
            <label
              htmlFor="agentManagerName"
              className={isEditable ? "" : "EditableLabel"}
            >
              Agent Manager Name
            </label>
            <input
              type="text"
              id="agentManagerName"
              name="manager_name"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={agentList.manager_name}
              disabled={isEditable}
              onChange={handleChange}
            />
            <p className="error">{agentModelErrs.manager_name}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="agentManagerEmailID"
              className={isEditable ? "" : "EditableLabel"}
            >
              Agent Manager Email ID
            </label>
            <input
              type="text"
              id="agentManagerEmailID"
              name="manager_email"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={agentList.manager_email}
              disabled={isEditable}
              onChange={handleChange}
            />
            <p className="error">{agentModelErrs.manager_email}</p>
          </div>
          {/* <div className="elementContainer">
          <label htmlFor="scheduleType">Schedule Type</label>
          <input
            type="text"
            id="scheduleType"
            value={agentList.status}
            disabled={isEditable}
            onChange={(e) => setFormData({ ...formData, "Schedule Type": e.target.value })} />
        </div> */}
          <div className="elementContainer">
            <label htmlFor="agentV-IPVTrained">Agent V-IPV Trained</label>
            <input
              type="text"
              id="agentV-IPVTrained"
              className={disabled ? "" : "EditlabelDis"}
              value={agentList.istrained === "1" ? "Yes" : "No"}
              disabled
            />
          </div>
          {/* <div className="elementContainer">
          <label htmlFor="agentCallType">Agent Call Type</label>
          <input
            type="text"
            id="agentCallType"
            value={agentList.manager_name}
            disabled={isEditable}
            onChange={(e) => setFormData({ ...formData, "Agent Call Type": e.target.value })} />
        </div> */}
          <div className="elementContainer">
            <label
              htmlFor="trainingCompletionDate"
              className={isEditable ? "" : "EditableLabel"}
            >
              Training Completion Date
            </label>
            <input
              type="date"
              id="trainingCompletionDate"
              name="trainingcompleteby"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={agentList.trainingcompleteby}
              disabled={isEditable}
              max={moment().format("YYYY-MM-DD")}
              onChange={handleChange}
            />
            <p className="error">{agentModelErrs.trainingcompleteby}</p>
          </div>
          {/* <div className="elementContainer">
          <label htmlFor="agentCanbeAuditor">Agent can be Auditor</label>
          <input
            type="text"
            id="agentCanbeAuditor"
            value={agentList.dateupdated}
            disabled={isEditable}
            onChange={(e) => setFormData({ ...formData, "Agent can be Auditor": e.target.value })} />
        </div> */}
          {/* <div className="elementContainer">
            <label htmlFor="agentLanguageSupport">Agent Language Support</label>
            <input
              type="text"
              id="agentLanguageSupport"
              name="knownlanguages"
              defaultValue={langListData.join(",")}
              // defaultValue={agentList.knownlanguages}
              disabled={isEditable}
              title={langListData.join(",")}
              onChange={handleChange}
            />
            <p className="error">{agentModelErrs.knownlanguages}</p>
          </div> */}
          <div className="elementContainer">
            <label
              htmlFor="region"
              className={isEditable ? "" : "EditableLabel"}
            >
              State
            </label>
            {isEditable ? (
              <input
                type="text"
                id="region"
                name="region"
                defaultValue={agentList.region}
                className="EditlabelDis"
                disabled={isEditable}
                onChange={handleChange}
              />
            ) : (
              <select
                name="region"
                onChange={handleChange}
                className="EditlabelText"
              >
                <option value={""}>Select State *</option>
                {Values?.length > 0 &&
                  Values?.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
            <p className="error">{agentModelErrs.region}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="location"
              className={isEditable ? "" : "EditableLabel"}
            >
              City
            </label>
            {isEditable ? (
              <input
                type="text"
                id="location"
                name="location"
                className="EditlabelDis"
                defaultValue={agentList.location}
                disabled={isEditable}
                onChange={handleChange}
              />
            ) : (
              <select
                name="location"
                onChange={handleChange}
                className="EditlabelText"
              >
                <option value={""}>Select City *</option>
                {Cities?.length > 0 &&
                  Cities?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
            <p className="error">{agentModelErrs.location}</p>
          </div>
          <div className="elementContainer">
            <label
              htmlFor="branchID"
              className={isEditable ? "" : "EditableLabel"}
            >
              Branch ID
            </label>
            <input
              type="text"
              id="branchID"
              name="branchid"
              className={isEditable ? "EditlabelDis" : "EditableText"}
              defaultValue={agentList.branchid}
              disabled={isEditable}
              onChange={handleChange}
            />
            <p className="error">{agentModelErrs.branchid}</p>
          </div>
          {/* <div className="elementContainer date">
            <label htmlFor="leavesFrom" >Leaves from</label>
            <input
              type="text"
              id="leavesFrom"
              className={disabled ? "" : "EditlabelDis"}
              disabled
              value={formData["Leaves from"] || ""}
              onChange={(e) =>
                setFormData({ ...formData, "Leaves from": e.target.value })
              }
            />
          </div>
          <div className="elementContainer date">
            <label htmlFor="Leavesto">Leaves to</label>
            <input
              type="text"
              id="Leavesto"
              value={formData["Leaves to"] || ""}
              className={disabled ? "" : "EditlabelDis"}
              disabled
              onChange={(e) =>
                setFormData({ ...formData, "Leaves to": e.target.value })
              }
            />
          </div> */}
          <div className="elementContainer" style={{ width: "100%" }}>
            <label
              htmlFor="agentLanguageSupport"
              className={isEditable ? "" : "EditableLabel"}
            >
              Agent Language Support
            </label>
            <Select
              defaultValue={langListData}
              onChange={selectlang}
              options={langOptionsList}
              isMulti={true}
              isDisabled={isEditable}
              className={`${isEditable ? "EditlabelDis" : "EditableText"}`}
              // className={`${isEditable ? EditlabelDis : "EditableText"}`}
              styles={customStyles}
            />
            <p className="error">{agentModelErrs.knownlanguages}</p>
          </div>
        </div>
        <div className="btnContainer">
          {!isEditable && (
            <Button
              name="applyLeave"
              dispName="Submit"
              click={submitUpdatedAgent}
              isLoading={isLoading}
              disabled={disabled}
              className={disabled ? "loaderdisabled" : ""}
            />
          )}
        </div>
      </form>
    </ApplyLeaveFormStyle>
  );
};

export const DeleteConfirm = ({ comp }) => {
  const [remarks, setRemarks] = useState("");

  return (
    <DeleteConfirmStyle>
      <h3>{comp.disc}</h3>
      <div className="elementContainer">
        <label htmlFor="leavesFrom">Remarks</label>
        <input
          type="text"
          id="remarks"
          onChange={(e) => setRemarks(e.target.value)}
        />
      </div>
      <div className="btnContainer">
        <Button
          name="applyLeave"
          dispName="Cancel"
          click={(e) => comp.cancel()}
          className="cancel fg1"
        />
        <Button
          name="applyLeave"
          dispName="Delete"
          disabled={!remarks}
          click={(e) => comp.delete(remarks)}
          className="delete"
        />
      </div>
    </DeleteConfirmStyle>
  );
};

const DeleteConfirmStyle = styled.div`
  h3 {
    color: var(--dark5);
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    gap: 12px;
    button {
    }
  }

  .elementContainer {
    // width: calc(50% - 10px);
    position: relative;
    margin: 12px 0;
    label {
      color: var(--dark6);
      font-size: 12px;
      position: absolute;
      top: -6px;
      left: 8%;
      background: var(--white);
      padding: 0 4px;
      z-index: 1;
    }
    input {
      width: 100%;
      padding: 14px;
      font-size: 16px;
      border: 1px solid var(--border-color2);
      border-radius: 5px;
    }
  }
`;

const ApplyLeaveFormStyle = styled.div`
  padding: 20px;
  border: 1px solid var(--border-color3);
  border-radius: 5px;
  margin-bottom: 20px;
  h4 {
    font-size: 16px;
    color: var(--dark2);
  }
  .formContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    .elementContainer {
      width: calc(50% - 10px);
      position: relative;
      margin: 12px 0;

      label {
        color: var(--dark6);
        font-size: 12px;
        position: absolute;
        top: -6px;
        left: 8%;
        background: var(--white);
        padding: 0 4px;
        z-index: 1;
      }
      input {
        width: 100%;
        padding: 14px;
        font-size: 18px;
        border: 1px solid var(--border-color2);
        border-radius: 5px;
        // background-color: white;
        // color: gray;
      }
      select {
        width: 100%;
        padding: 14px;
        font-size: 18px;
        border: 1px solid black;
        border-radius: 5px;
      }
      .EditableText {
        color: black;
        border: 1px solid black;
      }
      .EditableLabel {
        color: black;
        font-weight: 600;
      }
      .EditlabelDis {
        opacity: 0.7;
      }
      &.date {
        position: relative;
        &:after {
          content: "";
          background-image: url(${CalanderGrad});
          background-repeat: no-repeat;
          height: 17px;
          width: 16px;
          background-size: cover;
          position: absolute;
          right: 4%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .btnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      font-weight: 400;
      font-size: 18px;
      padding: 9px;
      width: 166px;
    }
    .loaderdisabled {
      opacity: 0.5 !important;
    }
  }
  .error {
    color: red;
    font-size: 12px;
  }
`;

const AddAgentStyle = styled.div`
  label {
    font-size: 18px;
    color: var(--dark6);
  }
  .elementList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .elementContainer {
    width: calc(33.3% - 10px);
    align-self: center;
    input,
    .selected-option {
      font-size: 18px;
    }
  }

  .dateelementContainer {
    // width: calc(50% - 10px);
    width: calc(33.3% - 10px);
    position: relative;
    margin: 12px 0;
    label {
      color: var(--dark6);
      font-size: 12px;
      position: absolute;
      top: -6px;
      left: 8%;
      background: var(--white);
      padding: 0 4px;
      z-index: 1;
    }
    input {
      width: 100%;
      padding: 14px;
      font-size: 16px;
      border: 1px solid var(--border-color2);
      border-radius: 5px;
    }
  }
  .finalVerification {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 22px;
    border-bottom: 1px solid var(--border-color);
    input[type="radio"] {
      transform: scale(0.9);
    }
    p {
      color: var(--dark6);
      margin-right: 20px;
      font-size: 18px;
    }
    h6 {
      font-size: 18px;
      color: var(--dark2);
      margin: 0 20px 0 12px;
    }
  }

  select,
  input[type="date"] {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background-color: white;
    color: gray;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0;
    margin-bottom: -15px;
    button {
      width: 165px;
      font-size: 20px;
      padding: 9px;
    }
    .loaderdisabled {
      opacity: 0.5 !important;
    }
  }
  .rangeBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    label {
      flex: 1;
    }
    .timeBox {
      width: 60px;
      padding: 10px 5px;
      input {
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .selectTabContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    width: 100%;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    margin-bottom: 20px;
    ul {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      li {
        min-width: 74px;
        text-align: center;
        padding: 8px 12px;
        border: 1px solid var(--border-color2);
        border-radius: 5px;
        color: var(--dark2);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        &.active {
          border: none;
          background: var(--fg3);
          color: var(--white);
        }
      }
    }
  }
  .error {
    color: red;
    font-size: 12px;
  }
`;
