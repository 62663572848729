import * as SagaActionTypes from "../SagasActions/SagaActionTypes";
const localstorageacc = sessionStorage.getItem("accid");
const accid = sessionStorage.getItem("accid");
const initial = {
  globalAccountId: localstorageacc || "",
  clientAccountId: accid,
};
const GlobalRecuder = (state = initial, action) => {
  switch (action.type) {
    case SagaActionTypes.ACTION_SETUP_GLOBAL_ACCOUNT:
      return { ...state, globalAccountId: action.payload };

    case SagaActionTypes.CLIENT_ACCOUNT_ID:
      return { ...state, clientAccountId: action.payload };

    default:
      return state;
  }
};

export default GlobalRecuder;
