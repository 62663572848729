import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  CallGrad,
  MailGrad,
  PencilMark,
  CloseBtn,
  CameraIcon,
} from "../assets/ImageUrl";

export default function ProfileCard({
  data,
  isEditable,
  updateAgentDetails,
  updatedProfilePic,
}) {
  const [agentModel, setAgentModel] = useState({});
  const [updatedProfile, setupdatedProfile] = useState(data?.agent_pht);
  const [cancelButtonfile, setCancelButtonfile] = useState(true);

  const cancelButton = () => {
    if (data?.agent_pht.length >= 10) {
      setupdatedProfile("data:image/png;base64," + data?.agent_pht);
      updatedProfilePic(data?.agent_pht);
      setCancelButtonfile(true);
    } else {
      setupdatedProfile(
        "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
      );
      updatedProfilePic("https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg");
      setCancelButtonfile(true);
    }
  };

  const fileHandleChange = (event) => {
    setCancelButtonfile(true);
    event.preventDefault();
    const { name, value } = event.target;
    if (event.target.files) {
      const val = event.target.files.length;
      for (let i = 0; i < val; i++) {
        let reader = new FileReader();
        reader.onload = function (ev) {
          const img = ev.target.result.split(",")[1];
          setupdatedProfile("data:image/png;base64," + img);
          updatedProfilePic(img);
          setCancelButtonfile(false);
          // setAgentModel((prevState) => ({
          //   ...prevState,
          //   [name]: ev.target.result.split(",")[1],
          // }));
        }.bind(this);
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  };

  useEffect(() => {
    if (data?.agent_pht.length >= 10) {
      // setupdatedProfile("data:image/png;base64," + updatedProfile);
      setupdatedProfile(updatedProfile);
      updatedProfilePic(updatedProfile);
    } else {
      setupdatedProfile(
        "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
      );
      updatedProfilePic("https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg");

    }
  }, [data?.agent_pht]);

  return (
    <ProfileCardStyle>
      <>
        {
          cancelButtonfile ? (
            ""
          ) : (
            <button className="cancel-button" onClick={cancelButton}>
              <img src={CloseBtn} alt="check" />
            </button>
          )
          // <button className="cancel-button" onClick={cancelButton}>cancel</button>
        }
        <div className="imgContainer">
          <div className="prof-edt">
            <input
              type="file"
              name="agent_pht"
              className="prof-edt-inp"
              accept=".png, .jpg, .jpeg"
              onChange={fileHandleChange}
              disabled={isEditable}
            />
            <img
              src={updatedProfile}
              // {
              //   data?.agent_pht.length >= 10
              //     ? "data:image/png;base64," + updatedProfile
              //     : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
              // }
              alt={data.agent_name}
            />
            {isEditable ? (
              ""
            ) : (
              <div className="edit-camera">
                <img src={CameraIcon} className="prof-edt-icon" alt="" />
              </div>
            )}
            {/* <img
            src="https://previews.123rf.com/images/vectorgalaxy/vectorgalaxy1808/vectorgalaxy180803997/108517743-pencil-icon-isolated-on-transparent-background.jpg"
            className="prof-edt-icon"
            alt=""
          /> */}
          </div>
          {/* <img
          src={
            data?.agent_pht.length >= 10
              ? "data:image/png;base64," + data?.agent_pht
              : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
          }
          alt={data.agent_name}
        /> */}
        </div>
      </>

      <h2>{data.agent_name}</h2>
      <p>
        Added On <strong> {data.datecreated ? data.datecreated : "NA"}</strong>{" "}
      </p>
      <p>
        Last Updated{" "}
        <strong> {data.dateupdated ? data.dateupdated : "NA"}</strong>{" "}
      </p>
      <hr />
      <h3 className="phone">{data.agent_mobile ? data.agent_mobile : "NA"}</h3>
      <h3 className="email">
        {data.agent_username ? data.agent_username : "NA"}
      </h3>
    </ProfileCardStyle>
  );
}

const ProfileCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--border-color3);
  border-radius: 5px;
  .cancel-button {
    background: white;
    border: none;
    padding: 10px;
    border-radius: 4%;
    margin-right:18%;

    // margin-left: 14%;
    position:absolute;
  }
  .imgContainer {
    color: transparent;
    transition: all 0.3s ease;
    position: relative;
    width: 88px;
    height: 88px;
    margin-bottom: 20px;
    .prof-edt {
      // background-color: red;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      box-sizing: border-box;
      position: relative;
      border: 1px solid #cecece;
      // overflow: hidden;

      .edit-camera{
        width: 32px;
        height: 32px;
        /* position: relative; */
        background-color: white;
        position: absolute;
        border-radius: 50%;
        bottom: 0%;
        right: 0%;
        z-index: 1;
        border:1px solid black;
        // z-index: 99999999;
    }
    }
    
    }
    .prof-edt .prof-edt-inp {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-progress-appearance: none;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
    }
    .prof-edt img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .prof-edt .prof-edt-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 56%;
      height: auto;
      // width: 30px;
      // height: 30px;
      object-fit: cover;
      border-radius: 50%;
      // z-index: 1;
      // opacity: 0;
      transition: all 300ms ease;
    }
    .prof-edt:hover .prof-edt-icon {
      opacity: 1;
    }

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: cover;
      border-radius: 50%;
      z-index: 0;
    }
  }
  .phone,
  .email {
    font-size: 18px;
    color: var(--dark3);
    padding-left: 33px;
    position: relative;
    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 20px;
      width: 20px;
      position: absolute;
      left: 0;
    }
  }
  .email:before {
    background-image: url(${MailGrad});
  }
  .phone:before {
    background-image: url(${CallGrad});
  }
  hr {
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid var(--hr);
  }
  h2 {
    margin-bottom: 12px;
  }
  h3 {
    margin: 7px 0;
  }
  p {
    margin-bottom: 12px;
    font-size: 14px;
    color: var(--dark6);
    strong {
      color: var(--dark1);
    }
  }
`;
