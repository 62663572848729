import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderFilters from "../../Components/HeaderFilters";
import response from "../../Data/agent.json";
import uuid from "react-uuid";
import { AddUserWhite, Away, Busy, FileIcon, Manager, Offline, Online } from "../../assets/ImageUrl";
import CardNode from "../../Components/CardNode";
import Popup from "../../Components/Popup";
import {
  AddAgent,
  headerActions,
  AddAditionalInfo,
  ApplyLeaveForm,
} from "../../Components/AgentSnippets";
import {
  actionReqGetAgentListSagaAction,
  actionReqGetLanguages,
} from "../../Store/SagasActions/AgentSagaAction";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  actionReqAdminAccounts,
  actionReqGetCreateAgent,
} from "../../Store/SagasActions/DashboardOverviewSagaAction";
import AgentActiveStatuses from "../../Constants/Status";
import { useSelector } from "react-redux";
import NoAgentFound from "../../Components/NoAgentFound";
import { actionReqResStatusLoaderClrSagaAction } from "../../Store/SagasActions/AuthSagaActions";
import LoaderClr from "../../Components/LoaderClr";
import moment from "moment";
import DataTable from "react-data-table-component";
import base64 from "base-64";
import utf8 from "base-64";


export default function Agent() {
  const [addAgent, setAddAgent] = useState("");
  const [getAgentList, setGetAgentList] = useState([]);
  const [getAgentListOriginal, setGetAgentListOriginal] = useState([]);
  const [agentBody, setAgentBody] = useState({});
  const [applyLeave, setApplyLeave] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [lang, setLang] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);
  const [agentStatus1, setAgentStatus] = useState("");


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accid2 = useSelector(
    (state) => state.DashboardReducer?.accountaccid?.accounts
  );

  const roleID = sessionStorage.getItem("role")
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const globalAccountId = useSelector(
    (state) => state?.GlobalRecuder.globalAccountId
  );
  const AccountSwap = roleID === "Account Admin" ? sessionStorage.getItem("accid") : account;
  const base64Concat = "data:image/png;base64,"

  // useEffect(() => {
  //   dispatch(actionReqResStatusLoaderClrSagaAction(true));
  // })

  useEffect(() => {
    dispatch(actionReqResStatusLoaderClrSagaAction(true));
    if (AccountSwap) {
      getAgentUserList(AccountSwap);
    }
  }, [AccountSwap]);

  // useEffect(() => {
  //   dispatch(actionReqResStatusLoaderClrSagaAction(true));
  //   getAgentUserList(AccountSwap);
  // }, []);



  // const handleSelectLang = () => {
  //     const model = {
  //       vcipkey: " "
  //     }
  //     dispatch(actionReqGetLanguages({ model: model, callback: getResp }))
  //   }

  //   const getResp = (data) => {
  //     // setLang(data?.languages)
  //     console.log(data?.languages)
  //   }

  // const getAccountsList = () => {
  //   dispatch(actionReqAdminAccounts({ callback: getAccountListRes }));
  // };

  // const getAccountListRes = (data) => {
  //   let list = [];
  //   if (data?.accounts && data?.accounts?.length > 0) {
  //     list = data.accounts?.map((item) => ({
  //       value: item?.accid,
  //       label: item?.accountname,
  //     }));
  //   }
  //   setAccountList(list);
  // };

  const getAgentUserList = (accid) => {
    const model = {
      accid: accid,
      listtype: "0",
    };
    dispatch(
      actionReqGetAgentListSagaAction({
        model: model,
        callback: getAgentListResp,
      })
    );
  };

  const getAgentListResp = (respData) => {
    if (respData) {
      let agentActiveList = respData?.agentlist?.filter(
        (item) => item?.status === AgentActiveStatuses.ACTIVE
      );
      setGetAgentList(agentActiveList);
      setGetAgentListOriginal(agentActiveList)
    }
  };

  const addAgentData = (stepName, model) => {
    setIsLoading(true);
    setIsDisabled(true);
    // setAddAgent(stepName);
    dispatch(
      actionReqGetCreateAgent({
        model: model,
        callback: createAgentRespData,
      })
    );
  };

  const goBack = (stepName) => {
    setAddAgent(stepName);
  };

  const applyAgentLeave = (agent) => {
    navigate("/dashboard/applyLeave", { state: { name: agent } });
    setApplyLeave(true);
  };

  // const createAgentUser = (stepName, model) => {
  //   let copyAgentModel = { ...agentBody, ...model }
  //   setAddAgent(stepName);
  //   setAgentBody(copyAgentModel);
  // }

  const createAgentUser = (stepName, model) => {
    setIsLoading(true);
    setIsDisabled(true);
    let copyAgentModel = { ...agentBody, ...model };
    dispatch(
      actionReqGetCreateAgent({
        model: copyAgentModel,
        callback: createAgentRespData,
      })
    );
  };

  const createAgentRespData = (data) => {
    if (data.respcode == "200") {
      setAddAgent("");
      getAgentUserList(AccountSwap);
    } else {
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  const agentSearchBar = (val) => {
    let copyData = [...getAgentListOriginal];
    const updtedSearh = copyData.filter((item) => {
      return item?.agent_name?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
        || item?.agent_employeeid?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
    })
    setGetAgentList(updtedSearh);
  }

  /**
   * @param {string} status of the row
   * @returns {string} classname 
   */

  const getClassNames = (status) => {
    switch (status) {
      case "online":
        return 'online_status';
      case "offline":
        return 'offline_status';
      case "logout":
        return "logout_status"
      case "away":
        return "away_status"
      case "break":
        return "break_status"
      case "busy":
        return "busy_status"
      case "In Call":
        return "busy_status"
      case "NA":
        return "offline_status"
      default:
        break;
    }
  }


  const columns = [
    {
      name: "Agent Profile",
      selector: (row) => (
        <>
          <img src=
            {row.agent_pht.length >= 0
              ? row["agent_pht"]
              : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
            } className="cus_table_imgs" />
          <span className={`online ${getClassNames(row.agentstatus)}`}></span>
        </>
      ),
      sortable: true,
    },
    {
      name: "Agent Name",
      selector: (row) => (
        <strong style={{ fontSize: "16px" }}>{row.agent_name}</strong>
      ),
      sortable: true,
    },
    {
      name: "Agent ID",
      selector: (row) => row["agent_employeeid"],
      sortable: true,
    },
    {
      name: "Agent Status",
      selector: (row) => (
        <>
          <p>
            {row["agentstatus"].toUpperCase()}
          </p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Login Time",
      selector: (row) => (
        <h6 className="fg2 cus_logTimes">{row["firstlogin"] ? moment(row.firstlogin).format("lll") : "-"}</h6>
      ),
      sortable: true,
    },
    {
      name: "Logout Time",
      selector: (row) => (
        <>
          {(row.agentstatus === "online" || row.agentstatus === "In Call") ? "" : <h6 className="fg2 cus_logTimes">{row["lastlogout"] ? moment(row.lastlogout).format("lll") : "-"}</h6>}
        </>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => <button onClick={() => applyAgentLeave(row)} className="cus_view">View</button>,
      sortable: true,
    },
  ]




  return (
    <>
      <div className="main-loader">
        <LoaderClr dispname="Agents are loading..." />
      </div>
      <MainStyle className="mainContainer">
        <HeaderFilters
          heading={{ name: "Total Agents :", count: getAgentList?.length }}
          actionBtns={headerActions}
          change={(e) => agentSearchBar(e.target.value)}
        />
        <div className="listContainer">
          {getAgentList?.length >= 0 ? (
            <DataTable
              columns={columns}
              data={getAgentList}
              pagination
            />

            // getAgentList?.map((agent) => (
            //   <div
            //     className="card"
            //     key={uuid()}
            //     onClick={() => applyAgentLeave(agent)}
            //   >
            //     <CardNode
            //       img={
            //         agent?.agent_pht.length >= 10
            //           ? "data:image/png;base64," + agent?.agent_pht
            //           : "https://as1.ftcdn.net/v2/jpg/01/32/20/08/1000_F_132200844_AhtrAxCNIwQV7LxCw6be9CBrnZloB5lB.jpg"
            //       }
            //       name={agent.agent_name}
            //       id={agent.agent_employeeid}
            //       flogin={agent?.firstlogin ? moment(agent?.firstlogin).format("lll") : "N/A"}
            //       lastlogout={agent?.lastlogout ? moment(agent?.lastlogout).format("lll") : "N/A"}
            //     />
            //   </div>
            // ))
          ) : (
            <>
              <NoRecordFoundStyle>
                <div className="norecord" style={{ marginTop: "6%" }}>
                  <div className="centered">
                    <img src={Manager} alt="document image" />
                    <h6 className="fd5">No agents Found</h6>
                    <p>We couldn’t find any agents here.</p>
                  </div>
                </div>
              </NoRecordFoundStyle>
            </>
          )}
        </div>
        {/* {applyLeave === "true" ? <ApplyLeaveForm/>:""} */}

        {/* <div className="listContainer">
        {response.agents.map(agent => <div className="card" key={uuid()} ><CardNode
          img={agent.img} name={agent.name} id={agent.id} /></div>)}
      </div> */}
        <button className="add" onClick={() => setAddAgent("addAgent")}>
          {" "}
          <img src={AddUserWhite} alt="add Agent" />{" "}
        </button>
      </MainStyle>

      {
        addAgent === "addAgent" && (
          <Popup
            heading="Add Agent"
            body={
              <AddAgent
                accountList={accid2}
                func={addAgentData}
                isLoading={isLoading}
                disabled={isdisabled}
              />
            }
            close={() => setAddAgent("")}
            className="px860"
          />
        )
      }

      {
        addAgent === "additionalInfo" && (
          <Popup
            heading="Add Agent"
            body={
              <AddAditionalInfo
                // func={stateName => {
                //   setAddAgent(stateName)
                //   applyLeave('/dashboard/applyLeave')
                // }}
                func={createAgentUser}
                isLoading={isLoading}
                disabled={isdisabled}
                goBack={goBack}
              />
            }
            close={() => setAddAgent("")}
            className="px860"
          />
        )
      }
    </>
  );
}

const MainStyle = styled.div`
.cus_view{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(269.82deg, #38A1F7 0.1%, #38568F 99.85%);
  border-radius: 5px;
  // font-family: 'ProximaNova-semibold';
  font-size: 14px;
  text-align: center;
  padding: 6px 20px;
  color: #FFFFFF;
  position: relative;
  z-index: 0;
  border: none;
}
.cus_table_imgs{
  width:50px;
  height:50px;
  padding: 3px;
  border-radius: 60px;
  position: relative
}
.cus_logTimes {
  font-size: 14px; 
  font-weight: 600
}


.online{
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  top: 33px;
  left:50px;
  justify-content: center;
  align-items: center;
  // border: 2px solid white;
  &.online_status{
    background-Image: url(${Online});
    border: 3px solid white;
  }
  &.break_status{
    background-Image: url(${Away});
    border: 3px solid white;
  }
  &.offline_status{
    background-Image: url(${Offline});
    border: 3px solid white;  
  }
  &.away_status{
    background-Image: url(${Away});
    border: 3px solid white;
  }
  &.logout_status{
    background-Image: url(${Offline});
    border: 3px solid white;
  }
  &.busy_status{
    background-Image: url(${Busy});
    border: 3px solid white;

  }
}

  background: var(--white);
  &.mainContainer {
    box-shadow: var(--cardShadow);
    padding: 20px 24px 0;
    height: calc(100vh - 120px);
    overflow: auto;
    position: relative;
    &::--webkit-scrollbar{
      width : 10px
    }
  }
  .listContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    justify-content: center;
    width: 100%;
  }
  .card {
    border: var(--border);
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    transition: transform 0.2s linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.03);
    }
  }
  .add {
    background: var(--fg3);
    padding: 22px 20px;
    border-radius: 50%;
    border: none;
    position: fixed;
    bottom: 50px;
    right: 50px;
  }
`;
const NoRecordFoundStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .centered {
    img {
    }
    h2 {
      font-weight: 700;
      font-size: 32px;
      color: #333333;
    }
    p {
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      text-align: center;

      color: #6e6e6f;
    }
  }
`;
