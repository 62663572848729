import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from "../../Service/axios";
import {
  getReportMasterSagaAction,
  getSubmitReportSagaAction,
  getCheckReportSagaAction,
  getReportListSagaAction,
} from "../SagasActions/ReportSagaActions";
import {
  ACTION_POST_SUBMIT_REPORT_MASTER_REQ,
  ACTION_POST_CHECK_REPORT_STATUS_REQ,
  ACTION_GET_REPORT_LIST_REQ,
  ACTION_GET_REPORT_MASTER_REQ,
} from "../SagasActions/SagaActionTypes";

import { actionReqResStatusLoaderSagaAction } from "../SagasActions/DashboardOverviewSagaAction";
import {actionReqResStatusLoaderClrSagaAction} from "../SagasActions/AuthSagaActions"


const getCheckReportStatusReq = (model) => {
  const URL = "CheckReportStatus";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getCheckReportStatusReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(getCheckReportStatusReq, action?.payload?.model);

  try {
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getSubmitReportReq = (model) => {
  const URL = "SubmitReportRequest";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getSubmitReportReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(getSubmitReportReq, action?.payload?.model);
  try {
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      // $('#login').modal('hide');
    }
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getReportListReq = (model) => {
  const URL = "GetReportList";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getReportListReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(getReportListReq, action?.payload?.model);


  try {
    // toast.success(resp?.respdesc);
    // if (action?.payload?.callback) {
    //     action?.payload?.callback(resp);
    // }
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(actionReqResStatusLoaderClrSagaAction(false));
      }
    } else {
      // toast.error(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(actionReqResStatusLoaderClrSagaAction(false));
      }
    }
  } catch (err) {
    if (resp.authflag === "1") {
      yield put(actionReqResStatusLoaderClrSagaAction(false));
      // $('#login').modal('hide');
    }
    if (err.response) {
      yield put(actionReqResStatusLoaderClrSagaAction(false));
      toast.error(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      yield put(actionReqResStatusLoaderClrSagaAction(false));
      toast.error(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
    yield put(actionReqResStatusLoaderClrSagaAction(false));
  }
}
export default function* ReportWatcherSaga() {
  yield takeLatest(
    ACTION_POST_SUBMIT_REPORT_MASTER_REQ,
    getSubmitReportReqSaga
  );
  yield takeLatest(
    ACTION_POST_CHECK_REPORT_STATUS_REQ,
    getCheckReportStatusReqSaga
  );
  yield takeLatest(ACTION_GET_REPORT_LIST_REQ, getReportListReqSaga);
}
