import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import HeaderFilters from "../../Components/HeaderFilters";
import DataTable from "react-data-table-component";
import {
  DownloadWhite,
  RefreshGrad,
  CalanderGrad,
  SearchGrad,
} from "../../assets/ImageUrl";
import { addDays, subDays, format } from "date-fns";
import { Button } from "../../Components/Elements";
import Popup from "../../Components/Popup";
import RangeDate from "../../Components/RangeDate";
import uuid from "react-uuid";
import { getReportMasterSagaAction } from "../../Store/SagasActions/DashboardOverviewSagaAction";
import {
  getSubmitReportSagaAction,
  getReportListSagaAction,
  getCheckReportSagaAction,
} from "../../Store/SagasActions/ReportSagaActions";
import { actionReqGetAgentListSagaAction } from "../../Store/SagasActions/AgentSagaAction";
import moment from "moment";
import NoRecordFound from "../../Components/NoRecordFound";
import { actionReqGetAuditorListSagaAction } from "../../Store/SagasActions/AuditorSagaAction";
import { useSelector } from "react-redux";
import { actionReqResStatusLoaderClrSagaAction } from "../../Store/SagasActions/AuthSagaActions";
import LoaderClr from "../../Components/LoaderClr";
import toast from "react-hot-toast";

let customDateRange;
export default function Report() {
  // useStates
  let placeIt = "";
  const [showDate, setShowDate] = useState(false);
  const [dateRangeBtn, setDateRangeBtn] = useState("Today");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [reportdropdown, setReportDropdown] = useState({});
  const [reportmaster, setReportMaster] = useState({});
  const [agentlistreport, setAgentListReport] = useState({});
  const [auditorlistreport, setAuditorListReport] = useState({});
  const [submitreportlist, setSubmitReportList] = useState([]);
  const [reportuserlist, setRepotUserList] = useState([]);
  const [reportuserlistOriginal, setRepotUserListOriginal] = useState([]);

  const [isdisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tos, setTos] = useState("");
  const [spin, setSpin] = useState(false);
  const [showReport, setShowReport] = useState("");

  //  Storage items
  const accAdmin = sessionStorage.getItem("role");
  const getreportId = sessionStorage.getItem("reportAccid");

  // Reducer items
  const accid = useSelector(
    (state) => state.DashboardReducer?.accountaccid?.accounts
  );
  const account = useSelector((state) => state?.DashboardReducer.accid);
  const globalAccountId = useSelector(
    (state) => state?.GlobalRecuder.globalAccountId
  );

  // Conditions
  const AccountSwap =
    accAdmin === "Account Admin" ? sessionStorage.getItem("accid") : account;

  // Code Starts
  useEffect(() => {
    if (reportmaster?.accid && reportmaster.reportid) {
      setIsDisabled(false);
    }
  }, [reportmaster]);

  // Table showing in this page.
  const columns = [
    {
      name: "Account Name",
      selector: (row) => row["accname"],
      sortable: true,
    },
    {
      name: "Report Name",
      selector: (row) => row["reportname"],
      sortable: true,
    },
    {
      name: "Report Req Date",
      selector: (row) => row["requesttime"],
      format: (row) => moment(row.requesttime).format("lll"),
      sortable: true,
    },
    {
      name: "Time Taken",
      selector: (row) => row["timetaken"],
      sortable: true,
      width: "110px",
    },
    {
      name: "Date Range",
      selector: (row) => row["daterange"],
      sortable: true,
    },
    {
      name: "Status",
      width: "130px",
      selector: (row) => (
        <span
          className={`status ${
            row["status"] === "Success"
              ? "green"
              : row["status"] === "Completed"
              ? "black"
              : "orange"
          }`}
        >
          {row["status"]}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {row["url"] === "" || row["status"] === "Failed" ? (
            <button className="download" disabled>
              <img
                src={DownloadWhite}
                alt="download"
                style={{ opacity: 0.5, cursor: "default" }}
              />
              <a
                style={{ opacity: 0.5, cursor: "default" }}
                alt="download icon"
                disabled
              >
                Download
              </a>
            </button>
          ) : (
            <button className="download">
              <img src={DownloadWhite} alt="download" />
              <a href={row["url"]} alt="download icon" download="download">
                Download
              </a>
            </button>
          )}
        </>
      ),
      sortable: true,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    getReportUserListRes(getreportId || "0");
    getReportMasterReq(getreportId);
  }, []);

  useEffect(() => {
    dispatch(actionReqResStatusLoaderClrSagaAction(true));
    if (showReport) {
      getReportUserListRes(showReport);
      // getReportMasterReq(getreportId);
    }
  }, [showReport]);

  // const isEndDateValid = (startDate, endDate) => {
  //   console.log(startDate, endDate, "startEnddate")
  //   const diffInMilliseconds = startDate - endDate;
  //   console.log(diffInMilliseconds, "diffInMilliseconds")
  //   const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  //   console.log(sevenDaysInMilliseconds, "sevenDaysInMilliseconds");
  //   const valid = diffInMilliseconds > sevenDaysInMilliseconds;
  //   console.log(valid, "valid")
  //   return valid
  // }

  // const SevenDaysErr = () => {
  //   return (
  //     <>
  //       <SevenDaysError>
  //         <div className="error">
  //           <

  //         </div>
  //       </SevenDaysError>
  //     </>
  //   )
  // }

  // here we can able to handle the date
  const handleDate = (range) => {
    placeIt = `${format(range.selection.startDate, "dd/mM/yyyy")} - ${format(
      range.selection.endDate,
      "dd/mM/yyyy"
    )}`;
    customDateRange = range;
    if (
      customDateRange.selection.startDate &&
      customDateRange.selection.endDate.getTime() -
        customDateRange.selection.startDate.getTime() >
        2678400000
    ) {
      return toast.error("The maximum selection range is 31 days", {
        duration: 3000,
        position: "top-center",
      });
    }
    setDateRangeBtn("Custom");
    // if (reportmaster.reportid === "7" || reportmaster.reportid === "8") {
    //   setDateRange([range.selection]);
    // }
  };

  const getReportUserListRes = (accid) => {
    setSpin(true);
    const model = {
      accid: accid,
    };
    setTimeout(() => {
      dispatch(
        getReportListSagaAction({
          model: model,
          callback: getReportUserListResp,
        })
      );
    }, 1000);
  };
  const getReportUserListResp = (data) => {
    setRepotUserList(data?.reportlist);
    setRepotUserListOriginal(data?.reportlist);
    setSpin(false);
  };

  const getAgentUserListRes = (id) => {
    const model = {
      accid: id,
      listtype: "0",
      // accid: reportmaster?.accid,
    };
    if (reportmaster.reportid === "5" || reportmaster.reportid === "6") {
      return "";
    } else {
      dispatch(
        actionReqGetAgentListSagaAction({
          model: model,
          callback: getAgentUserListResp,
        })
      );
    }
  };

  const getAgentUserListResp = (data) => {
    if (data) {
      let agentActiveList = data?.agentlist?.filter(
        (item) => item?.status === "1"
      );
      setAgentListReport(agentActiveList);
    }
  };

  const getAuditorUserListRes = (id) => {
    const model = {
      accid: id,
      listtype: "0",
      // accid: reportmaster?.accid,
    };
    if (reportmaster.reportid === "5" || reportmaster.reportid === "6") {
      return "";
    } else {
      dispatch(
        actionReqGetAuditorListSagaAction({
          model: model,
          callback: getAuditorUserListResp,
        })
      );
    }
  };

  const getAuditorUserListResp = (data) => {
    if (data) {
      let auditorActiveList = data?.auditorlist?.filter(
        (item) => item?.status === "1"
      );
      setAuditorListReport(auditorActiveList);
    }
  };

  const applyDateRange = () => {
    const placeOn = document.getElementById("selectedDateRange");
    placeOn.value = placeIt || `Selected ${dateRangeBtn}`;
    setShowDate(false);
  };

  const getReportMasterReq = (accid) => {
    const model = {
      accid: "1",
    };
    dispatch(
      getReportMasterSagaAction({
        model: model,
        callback: getReportMasterResp,
      })
    );
  };

  const getReportMasterResp = (data) => {
    setReportDropdown(data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "accid") {
      setShowReport(value);
    }
    if ((name === "reportid" && value === "1") || value === "2") {
      getAgentUserListRes(showReport);
      getAuditorUserListRes(showReport);
    }
    setReportMaster((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitReport = () => {
    setIsLoading(true);
    setIsDisabled(true);
    let tdate, fdate;
    if (dateRangeBtn === "Custom") {
      tdate = moment(customDateRange.selection.endDate).format("YYYY-MM-DD");
      fdate = moment(customDateRange.selection.startDate).format("YYYY-MM-DD");
    } else {
      tdate = moment(dateRange[0].startDate).format("YYYY-MM-DD");
      fdate = moment(dateRange[0].endDate).format("YYYY-MM-DD");
    }

    const model = {
      reportid: reportmaster.reportid,
      input: {
        accid: reportmaster.accid,
        agents: reportmaster.reportid === "1" ? reportmaster.userid : "",
        auditors: reportmaster.reportid === "2" ? reportmaster.a_userid : "",
        // tdate:
        //   reportmaster.reportid === "7" || reportmaster.reportid === "8"
        //     ? fdate
        //     : tdate,
        tdate: tdate,
        fdate: fdate,
        // fdate:
        //   reportmaster.reportid === "7" || reportmaster.reportid === "8"
        //     ? fdate
        //     : fdate,
        ref1: "",
      },
    };

    dispatch(
      getSubmitReportSagaAction({
        model: model,
        callback: (data) => getSubmitReportResp(data, reportmaster.accid),
      })
    );
  };
  const getSubmitReportResp = (data, accid) => {
    sessionStorage.setItem("reportAccid", accid);
    setSubmitReportList([data]);
    setTimeout(() => {
      // getcheckReport();
      setIsLoading(false);
      setIsDisabled(false);
    }, 4000);
    if (data.respcode === "406") {
      setIsLoading(false);
      setIsDisabled(false);
    }
    getReportUserListRes(accid);
  };

  const reload = () => {
    getReportUserListRes(showReport);
  };

  const dateRangeBtns = [
    {
      name: "Today",
      dateRange: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ],
    },
    {
      name: "Yesterday",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 1),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 7 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 8),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 30 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 31),
          key: "selection",
        },
      ],
    },
    {
      name: "Last month",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 31),
          key: "selection",
        },
      ],
    },
    {
      name: "Custom",
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
    },
  ];

  const RangeDatePicker = () => (
    <RangeDatePickerStyle>
      <div className="labels">
        {dateRangeBtns.map((range) => (
          <button
            key={uuid()}
            className={`${dateRangeBtn === range.name ? "themeBtn" : "cancel"}`}
            onClick={() => {
              setDateRange(range.dateRange);
              setDateRangeBtn(range.name);
            }}
          >
            {" "}
            {range.name}{" "}
          </button>
        ))}
      </div>
      <RangeDate changes={handleDate} dateRange={dateRange} />
      <div className="buttonContainer">
        <button className="themeBtn" onClick={() => applyDateRange()}>
          Apply
        </button>
      </div>
    </RangeDatePickerStyle>
  );
  const headerActions = [
    {
      img: SearchGrad,
      key: 4,
      // func: (e) => console.log(e),
    },
    {
      img: RefreshGrad,
      key: "RefreshGrad",
      func: reload,
    },
  ];

  const searchReport = (val) => {
    let copyData = [...reportuserlistOriginal];
    const updtedSearh = copyData.filter((item) => {
      return (
        item?.reportname?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1 ||
        item?.status?.toLowerCase()?.indexOf(val?.toLowerCase()) !== -1
      );
    });
    setRepotUserList(updtedSearh);
  };

  return (
    <>
      <div className="main-loader">
        <LoaderClr />
        {/* dispname={reportmaster.reportid === "1" ? "Agents are loading..." : "" || reportmaster.reportid === "2" ? "Auditors are loading.." : ""} */}
      </div>
      <ReportStyle>
        <HeaderFilters
          heading={{ name: "Usage Report" }}
          actionBtns={headerActions}
          change={(e) => searchReport(e.target.value)}
          spin={spin}
        />
        <div className="actions">
          <div className="elementContainer">
            <select
              className="custom-select"
              name="accid"
              onChange={handleChange}
            >
              <option value={""} disabled selected>
                Select Account
              </option>
              {accAdmin == "Super Admin" && (
                <option value="0">All Accounts</option>
              )}
              {accid?.length > 0 &&
                accid?.map((item) => (
                  <option value={item.accid}>{item.accountname}</option>
                ))}
            </select>
          </div>
          <div className="elementContainer">
            <select
              className="custom-select"
              name="reportid"
              onChange={handleChange}
            >
              <option value={""} disabled selected>
                Select Report
              </option>
              {reportdropdown?.reportlist?.length > 0 &&
                reportdropdown?.reportlist?.map((item) => (
                  <option value={item.rid}>{item.reportname}</option>
                ))}
            </select>
          </div>
          {reportmaster.reportid === "1" ? (
            <div className="elementContainer">
              <select
                className="custom-select"
                name="userid"
                onChange={handleChange}
              >
                <option value={""} disabled selected>
                  Select Agent
                </option>
                <option value="0">All</option>
                {agentlistreport?.length > 0 &&
                  agentlistreport?.map((item) => (
                    <option value={item.agent_userid}>{item.agent_name}</option>
                  ))}
              </select>
            </div>
          ) : reportmaster.reportid === "2" ? (
            <div className="elementContainer">
              <select
                className="custom-select"
                name="a_userid"
                onChange={handleChange}
              >
                <option value={""} disabled selected>
                  Select Auditor
                </option>
                <option value="0">All</option>
                {auditorlistreport?.length > 0 &&
                  auditorlistreport?.map((item) => (
                    <option value={item.auditor_userid}>
                      {item.auditor_name}
                    </option>
                  ))}
              </select>
            </div>
          ) : (
            ""
          )}
          {reportmaster.reportid === "5" ? (
            ""
          ) : (
            <div className="rangeDatePic" onClick={() => setShowDate(true)}>
              <input
                type="text"
                disabled
                id="selectedDateRange"
                placeholder="Select Date Here..."
                onChange={handleChange}
              />
            </div>
          )}
          <div className="btnContainer">
            <Button
              name="sample"
              dispName="Submit Request"
              click={submitReport}
              isLoading={isLoading}
              disabled={isdisabled}
              className={isdisabled ? "loaderdisabled" : ""}
            />
            {/* <Button
              name="sample"
              dispName={
                <span>
                  {" "}
                  <img
                    src={RefreshGrad}
                    className="reloadIcon"
                    alt="reload"
                  />{" "}
                  Reload
                </span>
              }
              click={reload}
              className="cancel reload-button"
            /> */}
          </div>
        </div>
        <AllCallHistoryStyle>
          {reportuserlist?.length > 0 ? (
            <DataTable columns={columns} data={reportuserlist} pagination />
          ) : (
            <>
              <div className="norecord">
                <NoRecordFound />
              </div>
            </>
          )}
        </AllCallHistoryStyle>
      </ReportStyle>
      {showDate && (
        <Popup
          className="px680 dateRangePicker"
          // heading={
          //   reportmaster.reportid === "7" || reportmaster.reportid === "8"
          //     ? "Select a Date "
          //     : "Date range"
          // }
          heading="Date Range"
          body={<RangeDatePicker />}
          close={() => setShowDate(false)}
        />
      )}
    </>
  );
}

const ReportStyle = styled.div`
  background: var(--white);
  box-shadow: var(--cardShadow);
  padding: 20px 24px 0;
  height: calc(100vh - 120px);
  overflow: auto;
  position: relative;
  .reloadIcon {
    margin-right: 8px;
    position: relative;
    top: 2.5px;
  }
  .download {
    border: none;
    border-radius: 3px;
    color: var(--white);
    font-weight: 600;
    padding: 6px 12px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(87.85deg, #38568f 7.3%, #38a1f7 95.41%);
    a {
      color: white;
      text-decoration: none;
    }
  }
  .status {
    font-weight: 600;
    display: block;
    font-size: 16px;
    padding: 4px;
    min-width: 72px;
    text-align: center;
    &.green {
      background: #08c1521a;
      color: #08c152;
      border-radius: 3px;
    }
    &.orange {
      background: #f9c7171a;
      color: #f9c717;
      border-radius: 3px;
    }
  }
  .reload-button {
    text-decoration: none;
    border: none;
    padding: 12px 40px;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    outline: none;
    transition: 0.2s all;
  }
  .reload-button:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 9px 1px rgba(0, 0, 0, 0.24);
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 0 16px 0;
    .rs-picker-default .rs-picker-toggle.rs-btn {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;
    }
    .btnContainer {
      display: flex;
      gap: 16px;
      button {
        width: auto;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 32px;
      }
      .loaderdisabled {
        opacity: 0.5 !important;
      }
    }
  }
  .custom-select,
  input[type="date"] {
    width: 100%;
    padding: 9px;
    font-size: 16px;
    border: 1px solid var(--border-color2);
    border-radius: 5px;
    background: transparent;
  }
  .rangeDatePic {
    position: relative;
    &:after {
      content: "";
      background-image: url(${CalanderGrad});
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
    input[type="text"] {
      color: var(--dark6);
      border: 1px solid var(--border-color2);
      border-radius: 5px;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 30px 10px 16px;
      min-width: 320px;
      &:disabled {
        background: transparent;
      }
    }
  }
`;

const RangeDatePickerStyle = styled.div`
  .buttonContainer {
    margin-top: 18px;
    display: flex;
    justify-content: center;
    button {
      padding: 9px 14px;
      font-size: 18px;
      width: 140px;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 12px 0;
    button {
      padding: 10px 14px;
      font-size: 16px;
      border: none;
      /* width: 150px; */
    }
  }
`;

const AllCallHistoryStyle = styled.div`
  .norecord {
    height: calc(100vh - 300px);
  }
  .rdt_TableBody {
    max-height: calc(100vh - 388px);
    overflow: auto;
  }
  .status {
    font-weight: 600;
    display: block;
    padding: 4px;
    min-width: 72px;
    text-align: center;
    &.green {
      background: #08c1521a;
      color: #08c152;
      border-radius: 3px;
    }
    &.orange {
      background: #f9c7171a;
      color: #f9c717;
      border-radius: 3px;
    }
    &.red {
      background: #f247471a;
      color: #f24747;
      border-radius: 3px;
    }
  }
`;

const SevenDaysError = styled.div``;
