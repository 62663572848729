import './main.css';

function App() {
  // if (process.env.REACT_APP_PROTEAN) {
  //   var link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement("link");
  //     link.rel = "icon";
  //     document.getElementsByTagName("head")[0].appendChild(link);
  //   }
  //   link.href = "./assets/imgs/proteanFavicon.svg";
  // }
  return (
    <>
      <h1></h1>
    </>
  );
}

export default App;
