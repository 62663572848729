import toast from 'react-hot-toast';
import { put, takeLatest, call } from 'redux-saga/effects';
import { actionReqResStatusLoaderClrSagaAction } from '../../SagasActions/AuthSagaActions';
import { actionReqResStatusLoaderSagaAction } from '../../SagasActions/DashboardOverviewSagaAction';
import Axios from '../../../Service/axios';
import { GET_SERVICE_INCIDENTS, GET_SERVICE_LIST, CREATE_UPDATE_DELETE_SERVICE_INCIDENTS, VCIP_ROLLBACK } from '../../SagasActions/OperationsActions/OperationActionTypes';

const vcipRollBackReq = (model) => {
    const URL = "VcipRollback";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* vcipRollBackReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    yield put(actionReqResStatusLoaderClrSagaAction(true));

    try {
        const resp = yield call(vcipRollBackReq, action?.payload?.model);
        if (resp) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
                yield put(actionReqResStatusLoaderClrSagaAction(false));
            }
        } else {
            toast.error(resp?.respdesc);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } catch (err) {
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        } else {
            toast.error(err.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
        yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
}

const creUpdDelSerIncidentsReq = ({endpoint, model}) => {
    const URL = endpoint;
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* creUpdDelSerIncidentsReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    yield put(actionReqResStatusLoaderClrSagaAction(true));

    try {
        const resp = yield call(creUpdDelSerIncidentsReq, action?.payload?.model);
        if (resp) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
                yield put(actionReqResStatusLoaderClrSagaAction(false));
            }
        } else {
            toast.error(resp?.respdesc);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } catch (err) {
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        } else {
            toast.error(err.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
        yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
}

const getServicesListReq = () => {
    const URL = "GetServicesList";
    return Axios.post(URL, {}).then(res => { return res?.data })
}

function* getServicesListReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    yield put(actionReqResStatusLoaderClrSagaAction(true));

    try {
        const resp = yield call(getServicesListReq);
        if (resp) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
                yield put(actionReqResStatusLoaderClrSagaAction(false));
            }
        } else {
            toast.error(resp?.respdesc);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } catch (err) {
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        } else {
            toast.error(err.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
        yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
}

const getServiceIncidentsReq = (model) => {
    const URL = "GetServiceIncidents";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* getServiceIncidentsReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    yield put(actionReqResStatusLoaderClrSagaAction(true));

    try {
        const resp = yield call(getServiceIncidentsReq, action?.payload?.model);
        if (resp) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
                yield put(actionReqResStatusLoaderClrSagaAction(false));
            }
        } else {
            toast.error(resp?.respdesc);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } catch (err) {
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        } else {
            toast.error(err.message);
            yield put(actionReqResStatusLoaderClrSagaAction(false));
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
        yield put(actionReqResStatusLoaderClrSagaAction(false));
    }
}




export default function* OperationsWatcherSaga() {
    yield takeLatest(VCIP_ROLLBACK, vcipRollBackReqSaga);
    yield takeLatest(CREATE_UPDATE_DELETE_SERVICE_INCIDENTS, creUpdDelSerIncidentsReqSaga);
    yield takeLatest(GET_SERVICE_LIST, getServicesListReqSaga);
    yield takeLatest(GET_SERVICE_INCIDENTS, getServiceIncidentsReqSaga);
}