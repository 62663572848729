import React from 'react'

const InputFields = (props) => {

    let { isDisabled, getAccounts, errors, fromTime, toTime, handleChange, serviceListDropdown, handleInputChange, serviceData, btnName, handleFormSubmit, handleDropDownChange } = props;
    return (
        <>
            <form onSubmit={(e) => handleFormSubmit(e, btnName)} className="formIncidents">
                <div className="mainContainer">
                    <span
                        className={"EditableLabel"}
                    >
                        Account ID
                    </span>
                    <div>
                        <select
                            className="custom-select"
                            id="accountid"
                            value={serviceData?.accountid || "Default"}
                            defaultValue={"Default"}
                            onChange={handleDropDownChange}
                            required
                        >
                            <option disabled value="Default">
                                Select Type
                            </option>
                            {getAccounts?.map((item, x) => {
                                return (
                                    <option value={item.accid} key={x}>
                                        {item.accountname}
                                    </option>
                                );
                            })}

                        </select>
                        <p className="error">{errors.accountid}</p>
                    </div>
                </div>
                <div className="mainContainer">
                    <span
                        className={"EditableLabel"}
                    >
                        Services Type
                    </span>
                    <div >
                        <p className="error">{errors.servicename}</p>
                        <select
                            className="custom-select"
                            id="servicename"
                            value={serviceData?.servicename || "Default"}
                            defaultValue={"Default"}
                            onChange={handleDropDownChange}
                            required
                        >
                            <option disabled value="Default">
                                Select Type
                            </option>
                            {serviceListDropdown?.map((item, x) => {
                                return (
                                    <option value={item.sid} key={x}>
                                        {item.servicename}
                                    </option>
                                );
                            })}

                        </select>
                    </div>
                </div>

                <div className="mainContainer">
                    <span
                        className={"EditableLabel"}
                    >
                        From Time
                    </span>
                    <div className="search-div">
                        <input
                            className="custom-search" type="datetime-local"
                            id="ftime"
                            value={(fromTime || serviceData?.ftime?.slice(0, -3).replace("/", '-') || '').toString().substring(0, 16)}
                            onChange={handleChange}
                            required
                        />
                        <p className="error">{errors.ftime}</p>

                    </div>
                </div>
                <div className="mainContainer">
                    <span
                        className={"EditableLabel"}
                    >
                        To Time
                    </span>
                    <div className="search-div">
                        <input
                            className="custom-search" type="datetime-local"
                            id="ttime"
                            value={(toTime || serviceData?.ttime || '').toString().substring(0, 16)}
                            onChange={handleChange}
                            required
                        />
                        <p className="error">{errors.ttime}</p>
                    </div>
                </div>
                <div className="mainContainer">
                    <span
                        className={"EditableLabel"}
                    >
                        Incident Name
                    </span>
                    <div className="search-div">
                        <input
                            className="custom-search"
                            type="text"
                            id="incidentname"
                            value={serviceData?.incidentname}
                            onChange={handleInputChange}
                            required
                            placeholder="Incident Name"
                        />
                        <p className="error">{errors.incidentname}</p>

                    </div>
                </div>

                <div className="mainContainer">
                    <span
                        className={"EditableLabel"}
                    >
                        Reason
                    </span>

                    <div className="search-div">
                        <input
                            className="custom-search"
                            type="text"
                            id="reason"
                            onChange={handleInputChange}
                            required
                            value={serviceData?.reason}
                            placeholder="Reason"
                        />
                        <p className="error">{errors.reason}</p>

                    </div>
                </div>
                <button type="submit" className="btn-submit" disabled={isDisabled}>{btnName}</button>

            </form>
        </>
    )
}

export default InputFields