import React from 'react'
import { useSelector } from 'react-redux';
import styled from "styled-components";


export default function LoaderClr({ dispname }) {

  const LoaderClr = useSelector((state) => state?.DashboardReducer.isClrLoader);

  return (
    <LoaderClrStyle>
      <>
        {LoaderClr &&
          <div className='main-loader'>
            <div className={`${LoaderClr ? "loader" : null}`}>
              {/* <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div> */}
              {/* <div className='dot'></div> */}
              {/* <div className="yellow" />
              <div className="red" />
              <div className="blue" />
              <div className="violet" /> */}
            </div>
            <div className="ldsring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p className='loader_name'>{dispname}</p>
          </div>
        }
      </>
    </LoaderClrStyle>

  )
}

const LoaderClrStyle = styled.div`
.main-loader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 39, 39, 0.726);
    // background: black;
    // opacity: 0.5;
    z-index: 9999;
    /* background-color: #1a1940; */

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 153, 212, 0) calc(15% + 100px), rgba(0, 99, 138, 0) calc(85% + 100px), rgba(0, 0, 0, 0.15) 100%); */
  }

  // .loader-dots {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  // .loader-dots>div {
  //   width: 1vw;
  //   height: 1vw;
  //   border-radius: 100%;
  //   margin: 1vw;
  //   background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  //   animation: bounce 1.5s 0.5s linear infinite;
  // }

  // .yellow {
  //   background-color: #364054;
  // }

  // .red {
  //   background-color: #021B79;
  //   animation-delay: 0.1s;
  // }

  // .blue {
  //   background-color:  #364054;
  //   animation-delay: 0.2s;
  // }

  // .violet {
  //   background-color: #021B79;
  //   animation-delay: 0.3s;
  // }

  // @keyframes bounce {

  //   0%,
  //   50%,
  //   100% {
  //     transform: scale(1);
  //     filter: blur(0px);
  //   }

  //   25% {
  //     transform: scale(0.6);
  //     filter: blur(3px);
  //   }

  //   75% {
  //     filter: blur(3px);
  //     transform: scale(1.4);
  //   }
  // }


// .loader {
//   width : 38px;
//   height: 38px;
//   display: inline-block;
//   position: relative;
//   &::after , &::before {
//     content: '';
//     width : 38px;
//     height: 38px;
//     border:2.2px solid #302f2f;
//     position: absolute;
//     left:0;
//     top: 0;
//     animation: rotation 2s ease-in-out infinite alternate;
//   }
//   &::after {
//     border-color: #438bd3;
//     animation-direction: alternate-reverse;
//   }
// }
// @keyframes rotation {
//   0% { transform: rotate(0deg) }
//   100% { transform: rotate(360deg) }
// }

.ldsring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ffb93e;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffb93e transparent transparent transparent;
}
.ldsring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
.ldsring p {
  text-align: center;
  margin: 0;
  margin-top: 10px;
}

@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader_name{
  // margin-left: 2%;
  font-size: 18px;
  // color: white
}
  
`;


// colors
// #feb60a
// #ff0062
// #00dbf9
// #da00f7