import React from 'react'
import styled from 'styled-components'
import Manager from '../assets/imgs/manager.svg'

export default function NoAgentFound({ headings }) {
    return (
        <NoRecordFoundStyle>
            <div className="centered">
                <img src={Manager} alt="document image" />
                {!headings &&
                    <>
                        <h2>No Agents found</h2>
                        <p>We couldn’t find any agents here.</p>
                    </>
                }
            </div>
        </NoRecordFoundStyle>
    )
}

const NoRecordFoundStyle = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
    .centered {
        img {}
        h2 {
            font-weight: 700;
            font-size: 32px;
            color: #333333;
        }
        p {
            font-weight: 400;
font-size: 22px;
line-height: 26px;
text-align: center;

color: #6E6E6F;
        }
    }
`