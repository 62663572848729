import { combineReducers } from 'redux';
import DashboardReducer from './DashboardReducer';
import GlobalRecuder from './GlobalRecuder';


const rootReducer = combineReducers({
    DashboardReducer: DashboardReducer,
    GlobalRecuder: GlobalRecuder
})

export default rootReducer;