import {
    ACTION_GET_ROLES_REQ,
    ACTION_POST_LOGIN_REQ,
    ACTION_POST_RESET_REQ,
    ACTION_POST_RESETPASSWORD_REQ,
    ACTION_POST_CHANGEPASSWORD_REQ,
    ACTION_POST_LOGOUTUSER_REQ,
    ACTION_POST_ADD_LOADERCLR_REQ,

} from "./actionTypes"

// LOGIN
export const loginSagaAction = (payload) => {
    return {
        type: ACTION_POST_LOGIN_REQ,
        payload: payload
    }
}
export const resetSagaAction = (payload) => {
    return {
        type: ACTION_POST_RESET_REQ,
        payload: payload
    }
}
export const resetPasswordSagaAction = (payload) => {
    return {
        type: ACTION_POST_RESETPASSWORD_REQ,
        payload: payload
    }
}

export const changePasswordSagaAction = (payload) => {
    return {
        type: ACTION_POST_CHANGEPASSWORD_REQ,
        payload: payload
    }
}

// USER LOGOUT
export const UserLogoutSagaAction = (payload) => {
    return {
        type: ACTION_POST_LOGOUTUSER_REQ,
        payload: payload
    }
}


// GET ROLES
export const getRolesSagaAction = (payload) => {
    return {
        type: ACTION_GET_ROLES_REQ,
        payload: payload
    }
}

export const actionReqResStatusLoaderClrSagaAction = (payload) => {
    return {
        type: ACTION_POST_ADD_LOADERCLR_REQ,
        payload: payload
    }
}