import React, { useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export default function HeaderFilters({
  heading,
  actionBtns,
  className,
  isComponent,
  change,
  spin
}) {

  const [openSearchBar, setOpenSearchBar] = useState(false);
  // const [spin , setSpin] = useState(false)

  const openSearch = (e, action) => {
    if (action.key == "4") {
      setOpenSearchBar((preState) => !preState)
    } if (action.key === "RefreshGrad") {
      //  setSpin(true) 
    }
    action.func(e)


  }

  return (
    <HeaderStyle className={className ? className : ""}>
      {isComponent ? (
        <div className="comp">{heading}</div>
      ) : (
        <h4 className="fd5">
          {heading?.name}
          <span> {heading?.count}</span>
        </h4>
      )}
      <ul className="actions" data-tip
      >
        {actionBtns?.map((action, i) => (
          <>
            <li key={`action_${i}`} onClick={(e) => openSearch(e, action)} data-tip
              data-for={action.key == "4" || action.key == "5" || action.key == "6" ? "registerTip" + i : ""}>
              <img src={action.img} alt="action icon" className={spin && action.key === "RefreshGrad" ? "spin" : ""} />
            </li>
            {openSearchBar && (action.key == "4") &&
              <input className="cus-input" onChange={change} placeholder="search" />
            }
            {action.key === "5" || action.key == "6" ?
              <ReactTooltip id={"registerTip" + i} place="top" effect="solid">
                Coming Soon
              </ReactTooltip> : null
            }
          </>
        ))}
      </ul>
    </HeaderStyle>
  );
}

const HeaderStyle = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding-bottom: 12px;
border-bottom: 1px solid var(--border-color);
margin-bottom: 16px;
.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 26px;
    li {
      position: relative;
      cursor: pointer;
      &:not(:first-child):before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--thirdLight);
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .cus-input{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    height: 21px
  }
  .spin{
    animation: spin 1.5s infinite;

    @keyframes spin {
      from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
  }


`;
